import React, { Component } from 'react';
import Quotation from './components/quotation/Quotation';
import Dashboard from './components/quotation/Dashboard';
import SideBar from './components/common/Sidebar';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'typeface-roboto';
import './assets/scss/App.scss';
import OpportunitySearch from './components/quotation/OpportunitySearch';
import ViewQuotation from './components/quotation/ViewQuotation';
import QuotationHeaderEdit from './components/quotation/QuotationHeaderEdit';

import Header from './components/common/header';
import './assets/css/react-spinner-loader.css';
import './assets/css/main.css';
import Provider from './Providers';
import ExportQuotation from './components/quotation/ExportQuotation';
import EmailConfiguration from './components/quotation/EmailConfiguration';
import QuotationCondition from './components/quotation/QuotationCondition';
export default class App extends Component {
	state = {
		dashboardData: [],
		windowPage: true,
		showSearch: false
	};
	initialize = () => {};

	onSearchData = (responseData) => {
		this.setState({ dashboardData: responseData });
	};
	setWindowPage = (value) => {
		this.setState({ windowPage: value });
	};
	setShowSearch = (value) => {
		this.setState({ showSearch: value });
	};
	render = () => {
		return (
			<BrowserRouter>
				<div className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}>
					<Provider>
						<React.Fragment>
							<div className='app-content'>
								<div className='app-sidebar'>
									<SideBar />
								</div>
								<Header
									accountInfo={this.props.accountInfo}
									onSearchData={this.onSearchData}
									setWindowPage={this.setWindowPage}
									windowPage={this.state.windowPage}
									showSearch={this.state.showSearch}
								></Header>
								<div className='large-screen-width container-fluid'>
									<div className='row'>
										<div className='col-12'>
											<Switch>
												<Route
													exact
													path={'/opportunity/search'}
													render={(props) => (
														<OpportunitySearch
															{...props}
															setWindowPage={this.setWindowPage}
														/>
													)}
												/>
												<Route
													path={'/quotation/create'}
													render={(props) => (
														<Quotation
															{...props}
															accountInfo={this.props.accountInfo}
															setWindowPage={this.setWindowPage}
															windowPage={this.state.windowPage}
														/>
													)}
												/>
												<Route
													path={'/quotation/view'}
													render={(props) => (
														<ViewQuotation
															{...props}
															accountInfo={this.props.accountInfo}
															setWindowPage={this.setWindowPage}
															setShowSearch={this.setShowSearch}
														/>
													)}
												/>
												<Route
													path={'/quotation/header/edit'}
													render={(props) => (
														<QuotationHeaderEdit
															{...props}
															accountInfo={this.props.accountInfo}
															setWindowPage={this.setWindowPage}
														/>
													)}
												/>
												<Route
													exact
													path={'/'}
													render={() => (
														<Dashboard
															accountInfo={this.props.accountInfo}
															dashboardData={this.state.dashboardData}
															windowPage={this.state.windowPage}
															setWindowPage={this.setWindowPage}
															setShowSearch={this.setShowSearch}
														/>
													)}
												/>
												<Route
													exact
													path={'/quotation/export'}
													render={(props) => (
														<ExportQuotation
															{...props}
															setWindowPage={this.setWindowPage}
														/>
													)}
												/>
												<Route
													exact
													path={'/emailConfiguration/view'}
													render={(props) => (
														<EmailConfiguration
															{...props}
															setWindowPage={this.setWindowPage}
														/>
													)}
												/>
												<Route
													exact
													path={'/quotationCondition/view'}
													render={(props) => (
														<QuotationCondition
															{...props}
															setWindowPage={this.setWindowPage}
														/>
													)}
												/>
											</Switch>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					</Provider>
				</div>
			</BrowserRouter>
		);
	};
}
