import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './Dashboard.scss';
import '../../assets/scss/Icons.scss';
import { getFormattedDateTime } from '../common/tools';
import { hasEditAccess, isNumeric } from '../../Utils/commonFunctions';
import { saveQuotationLockStatus, getRecentQuotations, cloneQuotation,
	saveQuotationVersion, getQuotationVersions } from '../../Utils/apiCalls';
import { withTranslation, Trans } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { QuotationToolContext } from '../../Providers';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Table
} from 'reactstrap';
import DashBoardColumnHeaderComponent from '../shared/DashBoardColumnHeaderComponent';
import PriceComponent from '../shared/PriceComponent';
import { IntlProvider } from 'react-intl';
var DatePicker = require('reactstrap-date-picker');

export default class Dashboard extends Component {
	state = {
		tableData: [],
		columnFilterDictionary: {},
		sortAscending: true,
		showViewQuotationPage: false,
		quotationViewId: 0,
		responseData: [],
		loading: true,
		textFlag: true,
		creatorSortingFlag: null,
		statusSortingFlag: null,
		opportunitySortingFlag: null,
		accountSortingFlag: null,
		ownerSortingFlag: null,
		internalSalesSortingFlag: null,
		lastAdjustmentSortingFlag: null,
		versionSortingFlag: null,
		totalNetPriceSortingFlag: null,
		showVersionModal: false,
		versionData: [],
		recentQuotationTitle: 'YOUR_RECENT_QUOTES',
		selectedVersionQuotationId: null,
		searchDateRangeDDIsOpen: false,
		searchStatusDDIsOpen: false,
		selectedQuotationLockedByName:[],
		selectedSearchDateRange: {
			ID: 1,
			Value: 'PAST_TWO_MONTHS',
			Days: 60
		},
		selectedSearchStatus: {
			ID: 0,
			Value: 'SELECT_STATUS'
		},
		searchDateRangeList: [
			{
				ID: 0,
				Value: 'PAST_ONE_MONTH',
				Days: 30
			},
			{
				ID: 1,
				Value: 'PAST_TWO_MONTHS',
				Days: 60
			},
			{
				ID: 2,
				Value: 'PAST_SIX_MONTHS',
				Days: 180
			},
			{
				ID: 3,
				Value: 'CUSTOM_RANGE',
				Days: 0
			}
		],
		searchStatusList: [
			{
				ID: 0,
				Value: 'SELECT_STATUS'
			},
			{
				ID: 1,
				Value: 'DSGN_C'
			},
			{
				ID: 2,
				Value: 'SENT_C'
			},
			{
				ID: 3,
				Value: 'OK_CLNT_C'
			},
			{
				ID: 4,
				Value: 'NOK_CLNT_C'
			},
			{
				ID: 5,
				Value: 'DSGN_T'
			},
			{
				ID: 6,
				Value: 'SENT_T'
			},
			{
				ID: 7,
				Value: 'OK_CLNT_T'
			},
			{
				ID: 8,
				Value: 'NOK_CLNT_T'
			},
			{
				ID: 9,
				Value: 'ORDR_ERP'
			}
		],
		searchMinDate: new Date().toISOString(),
		searchMaxDate: new Date().toISOString(),
		searchString: '',
		isSalesAdmin:false,
		quotationLockedByMe : false,
		numberOfQuotationLockedByMe : 0,
	};

	componentDidMount() {
		const { updateAccountInfo, isEconPopUpOpen, updateIsEconPopUpOpen, updateQuotationToBeCopied } = this.context;
		updateQuotationToBeCopied(null);
		if (isEconPopUpOpen) {
			updateIsEconPopUpOpen(false);
			window.parent.window.location.href = '/quotation/view';
			return;
		}
		updateAccountInfo(this.props.accountInfo);
		this.initialize();
		this.getQuotations();
		this.props.setWindowPage(true);
		this.props.setShowSearch(true);
	}

	componentDidUpdate = (prevProps) => {
		
		if (this.props.dashboardData === '' && this.props.dashboardData !== prevProps.dashboardData) {
			this.getQuotations();

			this.setState({ textFlag: true });
		} else if (this.props.dashboardData !== prevProps.dashboardData) {
			this.setData();
		}		
	};

	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

  	isQuotationLocked = (lockedBy) =>{
		let loggedUserId = this.props.accountInfo.account.accountIdentifier;
		if(lockedBy && (lockedBy != loggedUserId))
			return true;
		else
		return false;
	}
	initialize = () => {};
	
	onQuotationStatusChanged = (quotation, e) => {
		this.setState({ selectedVersionQuotationId: quotation.Id });
	};

	getVersionList(x) {
	
		const { selectedRole} = this.context;
		if(selectedRole && selectedRole == "TheumaCalculation")
		{
			this.setState({	isSalesAdmin :true});
		}
		this.setState({ loading: true, selectedQuotationLockedByName : x });
		const { updateselectedQuotationId, updateViewModeOnly} = this.context;
		updateselectedQuotationId(0);
		
		updateViewModeOnly(false);
		var quotationUniqueId = x.quotationUniqueId;

		getQuotationVersions(quotationUniqueId)
		.then((responseData) => {
				this.setState({
					versionData: responseData,
					loading: false,
					selectedVersionQuotationId: responseData.find((x) => x.IsActiveVersion).Id,
					showVersionModal: true,
					quotationViewId: x.id
				});
			})
			.catch((reason) => {this.handleError(reason)});
	}
	setData() {
		let searchMaxDate = new Date();
		searchMaxDate.setDate(searchMaxDate.getDate() - 60);
		this.setState(
			{
				responseData: this.props.dashboardData,
				loading: false,
				textFlag: false,
				selectedSearchDateRange: {
					ID: 1,
					Value: 'PAST_TWO_MONTHS',
					Days: 60
				},
				selectedSearchStatus: {
					ID: 0,
					Value: 'SELECT_STATUS'
				},
				searchMinDate: new Date(searchMaxDate).toISOString(),
				searchMaxDate: new Date().toISOString(),
				searchString: ''
			},
			() => {
				this.searchWithAllFilters();
			}
		);
	}
	onCloseVersionStatus = () => {
		this.setState({
			showVersionModal: false,
			versionData: [],
			selectedVersionQuotationId: null
		});
	};
	viewModeOnly = () => {
		const { updateselectedQuotationId, updateViewModeOnly } = this.context;
		updateselectedQuotationId(this.state.selectedVersionQuotationId);
	
		updateViewModeOnly(true);
		this.setState({
			quotationViewId: this.state.selectedVersionQuotationId,
			showViewQuotationPage: true
		});
	};
	getQuotations = () => {	
		this.setState({ loading: true });
		let userId = this.props.accountInfo.account.accountIdentifier;
		
		getRecentQuotations(userId)
		.then((response) => {
			let maxDate = new Date(this.state.searchMaxDate);

			let minDate = new Date();
			minDate.setDate(maxDate.getDate()-60);

			this.setState(
				{
					searchMaxDate: maxDate.toDateString(),
					searchMinDate: minDate.toDateString(),
					responseData: response,
					loading: false
				},
				() => this.searchWithAllFilters()
			);
			this.sortBy('lastAdjustment');
		})
		.catch((reason) => {this.handleError(reason)});
	};

	mapDataToTable(responseData) {
		const { selectedLanguage } = this.context;
		let tableData = [];
		responseData.forEach((item) => {
			tableData.push({
				id: item.QuotationId,
				opportunity: item.OpportunityName,
				opportunityNumber: item.OpportunityNumber,
				account: item.AccountName,
				accountNumber: item.ClientNumber,
				owner: item.OwnerName,
				internalSales: item.InternalSalesName,
				lastAdjustment: item.LastUpdate,
				templastAdjustment: getFormattedDateTime(item.LastUpdate, selectedLanguage.split('-')[0]),
				version: item.VersionNumber,
				status: item.Status ?? '',
				creator: item.CreatedBy,
				quotationUniqueId: item.QuotationUniqueId,
				lockedBy : item.LockedBy,
				lockedByName:item.LockedByName,
				totalNetPrice:item.TotalNetPrice
			});
		});

		return tableData;
	}


	sortBy = (columnName) => {
		var arrayCss = [
			'creator',
			'status',
			'opportunity',
			'account',
			'owner',
			'internalSales',
			'lastAdjustment',
			'version',
			'totalNetPrice'
		];
		for (let i = 0; i < arrayCss.length; i++) {
			var objCss = document.getElementsByClassName(arrayCss[i]);
			objCss[0].style.color = '#a0a0a0';
			if (arrayCss[i] !== columnName) {
				this.setState({
					[arrayCss[i] + 'SortingFlag']: null
				});
			}
		}
		objCss = document.getElementsByClassName(columnName);
		objCss[0].style.color = 'black';

		this.setState({
			[columnName + 'SortingFlag']: this.state[columnName + 'SortingFlag'] ? false : true
		});
		
		let tableData = this.state.tableData.sort((a, b) => {
			if(isNumeric(a[columnName])) // Numbers
			{
				if(parseFloat(a[columnName]) < parseFloat(b[columnName])){
					return this.state[columnName + 'SortingFlag'] ? 1 : -1;
				}
				else if(parseFloat(a[columnName]) > parseFloat(b[columnName])){
					return this.state[columnName + 'SortingFlag'] ? -1 : 1;
				}
				else{
					return 0;
				}
			}
			else{
				if (a[columnName] < b[columnName]) {
					return this.state[columnName + 'SortingFlag'] ? 1 : -1;
				}
				if (a[columnName] > b[columnName]) {
					return this.state[columnName + 'SortingFlag'] ? -1 : 1;
				}
				return 0;
			}
		});

		this.setState({
			tableData: tableData
		});
	};

	filterBy = (columnName, searchValue) => {

		this.state.columnFilterDictionary[columnName] = searchValue;

		this.searchWithAllFilters();
	}

	onSearchPageClick = () => {
		window.location = '/opportunity/search';
	};
	redirectView = (x) => {
		this.setState({ quotationViewId: x.id, showViewQuotationPage: true });
	};
	redirectViewVersion = (x) => {
		const { updateselectedQuotationId } = this.context;
		updateselectedQuotationId(this.state.selectedVersionQuotationId);

		this.setState({ showViewQuotationPage: true });
	};
	toggleSearchDateRangeDD = () => {
		this.setState({
			searchDateRangeDDIsOpen: !this.state.searchDateRangeDDIsOpen
		});
	};
	toggleSearchStatusDD = () => {
		this.setState({
			searchStatusDDIsOpen: !this.state.searchStatusDDIsOpen
		});
	};
	onSearch = (searchString) => {
		let data = this.searchWithAllFilters();
		let contacts = this.mapDataToTable(data);
		let filteredContacts = [];
		if (searchString.indexOf(' ') !== -1) {
			let searchStrArray = searchString.split(' ');
			for (let i = 0; i < searchStrArray.length; i++) {
				if (!contacts) return;
				filteredContacts = contacts.filter((x) => {
					return (
						(x.opportunity && x.opportunity.toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.opportunityNumber &&
							x.opportunityNumber.toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.account && x.account.toString().toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.accountNumber && x.accountNumber.toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.owner && x.owner.toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.internalSales && x.internalSales.toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.templastAdjustment &&
							x.templastAdjustment.toLowerCase().includes(searchStrArray[i].toLowerCase())) ||
						(x.creator && x.creator.toLowerCase().includes(searchStrArray[i].toLowerCase()))
					);
				});
				contacts = filteredContacts;
			}
		} else {
			if (!contacts) return;
			filteredContacts = contacts.filter((x) => {
				return (
					x.opportunity.toLowerCase().includes(searchString.toLowerCase()) ||
					(x.opportunityNumber && x.opportunityNumber.toLowerCase().includes(searchString.toLowerCase())) ||
					(x.account && x.account.toString().toLowerCase().includes(searchString.toLowerCase())) ||
					(x.accountNumber && x.accountNumber.toLowerCase().includes(searchString.toLowerCase())) ||
					(x.owner && x.owner.toLowerCase().includes(searchString.toLowerCase())) ||
					(x.internalSales && x.internalSales.toLowerCase().includes(searchString.toLowerCase())) ||
					(x.templastAdjustment && x.templastAdjustment.toLowerCase().includes(searchString.toLowerCase())) ||
					(x.creator && x.creator.toLowerCase().includes(searchString.toLowerCase()))
				);
			});
		}
		this.setState({
			searchString: searchString,
			tableData: filteredContacts,
			recentQuotationTitle: 'SEARCH_RESULT'
		});
	};
	setQuotationVersion = () => {
		let quotationUniqueId = this.state.versionData[0].QuotationUniqueId;
		let quotationId = this.state.selectedVersionQuotationId;
		this.setState({ loading: true });

		saveQuotationVersion(quotationId, quotationUniqueId)
		.then((response) => {
			let versiondataNew = this.state.versionData;
			versiondataNew.find((x) => x.IsActiveVersion).IsActiveVersion = false;
			versiondataNew.find((x) => x.Id === response.QuotationId).IsActiveVersion = true;
			this.setState({
				versionData: versiondataNew,
				selectedVersionQuotationId: response.QuotationId,
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	};

	startNewVersion = () => {
		let quotationId = this.state.selectedVersionQuotationId;
		this.setState({ loading: true });

		cloneQuotation(quotationId)
		.then((responseData) => {
				if (responseData.IsSuccess) {
					const { updateselectedQuotationId } = this.context;
					updateselectedQuotationId(responseData.QuotationId);
					this.setState({
						selectedVersionQuotationId: responseData.QuotationId,
						showViewQuotationPage: true,
						loading: false
					});
				} else {
					toast.error(
						<Trans i18nKey='COPY_QUOTATIONLINE_CUSTOM_ERROR_MESSAGE'>
							COPY_QUOTATIONLINE_CUSTOM_ERROR_MESSAGE
						</Trans>
					);
					this.setState({loading:false});
				}
			})
			.catch((reason) => {this.handleError(reason)});
	};
	setSearchStatus = (status) => {
		this.setState(
			{
				selectedSearchStatus: status
			},
			() => this.onSearch(this.state.searchString)
		);
	};
	setSearchDateRange = (dateRange) => {
		let searchMaxDate = new Date();
		searchMaxDate.setDate(searchMaxDate.getDate() - dateRange.Days);
		this.setState(
			{
				selectedSearchDateRange: dateRange,
				searchMaxDate: new Date().toISOString(),
				searchMinDate: new Date(searchMaxDate).toISOString()
			},
			() => this.onSearch(this.state.searchString)
		);
		if (dateRange.ID == 3) {
			this.setState(
				{
					searchMinDate: new Date().toISOString(),
					searchMaxDate: new Date().toISOString()
				},
				() => this.onSearch(this.state.searchString)
			);
		}
	};
	handleStartDateChange(value) {
		this.setState(
			{
				searchMinDate: new Date(value).toISOString()
			},
			() => this.onSearch(this.state.searchString)
		);
	}
	handleEndDateChange(value) {
		this.setState(
			{
				searchMaxDate: new Date(value).toISOString()
			},
			() => this.onSearch(this.state.searchString)
		);
	}

	unLockQuotation =()=>
	{
		this.setState({ loading: true });

		saveQuotationLockStatus(this.state.selectedVersionQuotationId, '')
		.then((response) => {
			this.getVersionList(this.state.selectedQuotationLockedByName);
			this.setState({loading: false});	
		})
		.catch((reason) => {this.handleError(reason)});
	}

	searchWithAllFilters() {
		let data = this.state.responseData;	

		// Filter by changedOn date.
		let filteredData = data.filter(
			(x) =>
			{
				let d = new Date(x.LastUpdate).getTime();
				let min = new Date(this.state.searchMinDate).getTime();
				let max = new Date(this.state.searchMaxDate).getTime();
				
				return d >= min && d <= max;
			}
		);

		// Filter by status in filter
		if (this.state.selectedSearchStatus.ID !== 0)
			filteredData = filteredData.filter((x) => x.Status == this.state.selectedSearchStatus.Value);

		// Keep count for locked by me
	    this.state.numberOfQuotationLockedByMe =  filteredData.filter((x) => x.LockedBy == 
		                                         this.props.accountInfo.account.accountIdentifier).length;

		// Filter to show only locked by me quotations
	    if(this.state.quotationLockedByMe)
	        filteredData = filteredData.filter((x) => x.LockedBy == this.props.accountInfo.account.accountIdentifier);

		// Filter by column specific searchvalue 
		for(var key in this.state.columnFilterDictionary)
		{
			let value = this.state.columnFilterDictionary[key];
			if(value == null) continue;

			if(key == 'opportunity') filteredData = filteredData.filter((x) => x.OpportunityName.toLowerCase().includes(value.toLowerCase()));
			if(key == 'account') filteredData = filteredData.filter((x) => x.AccountName.toLowerCase().includes(value.toLowerCase()) || x.ClientNumber.includes(value));
			if(key == 'owner') filteredData = filteredData.filter((x) => x.OwnerName.toLowerCase().includes(value.toLowerCase()));
			if(key == 'internalSales') filteredData = filteredData.filter((x) => x.InternalSalesName.toLowerCase().includes(value.toLowerCase()));
			if(key == 'lastAdjustment') filteredData = filteredData.filter((x) => x.LastUpdate.toLowerCase().includes(value.toLowerCase()));
			if(key == 'version') filteredData = filteredData.filter((x) => x.VersionNumber.toLowerCase().includes(value.toLowerCase()));
			if(key == 'status') filteredData = filteredData.filter((x) => i18n.t(x.Status).toLowerCase().includes(value.toLowerCase()));
			if(key == 'creator') filteredData = filteredData.filter((x) => x.CreatedBy.toLowerCase().includes(value.toLowerCase()));
			if(key == 'totalNetPrice' && value > 0) filteredData = filteredData.filter((x) => x.TotalNetPrice <= value);
		}

		this.setState({ tableData: this.mapDataToTable(filteredData) });

		return filteredData;
	}

	onCopyQuotation = (quotation) => {
		quotation.AccountNumber = this.state.responseData.find(
			(x) => x.QuotationId == this.state.selectedVersionQuotationId
		).ClientNumber;

		const { updateQuotationToBeCopied } = this.context;
		updateQuotationToBeCopied(quotation);
		window.location = '/opportunity/search';
	};
	onClearSearch = () => {
		let searchMaxDate = new Date();
		searchMaxDate.setDate(searchMaxDate.getDate() - 60);
		var inputSearch = document.getElementById('inner_search');
		inputSearch.value = '';
		this.setState(
			{
				searchMaxDate: new Date().toISOString(),
				searchMinDate: new Date(searchMaxDate).toISOString(),
				selectedSearchDateRange: {
					ID: 1,
					Value: 'PAST_TWO_MONTHS',
					Days: 60
				},
				selectedSearchStatus: {
					ID: 0,
					Value: 'SELECT_STATUS'
				},
				searchString: '',
				quotationLockedByMe: false,
			},
			() => this.onSearch(this.state.searchString)
		);
	};

	onShowLockedQuotation = () => {
		this.setState(
			{
				quotationLockedByMe: true,
			},
			() => this.onSearch(this.state.searchString),
			() => this.validateQuotationLockedByme(),
		);
	};
	
	render() {
		const { selectedLanguage , userRoleDetail } = this.context;
		return (

			<>
				{/* IntlProvider provides a context for the i18n translations */}
				<IntlProvider locale={selectedLanguage.split('-')[0]} defaultLocale='nl'>
					<div className='row mt-4'>
						<div className='col-12'>
							<div className='dashboard-title'>
								<div className='display-inline'>
									{this.state.textFlag ? (
										<Trans i18nKey='YOUR_RECENT_QUOTES'>YOUR_RECENT_QUOTES</Trans>
									) : (
										<Trans i18nKey='QUOTATION_SEARCH_RESULT'>QUOTATION_SEARCH_RESULT</Trans>
									)}
								</div>{' '}
							</div>
						</div>
					</div>
					<div className='row mt-2 dashboard'>
						<div className='col-3'>
							<input
								type='text'
								id='inner_search'
								className='form-control rbt-input'
								onKeyUp={(e) => this.onSearch(e.target.value)}
								placeholder={i18n.t('SEARCH_WITHIN_QUOTATIONS')}
							/>
						</div>
						<div className='col-2 padding-organsiation mr-2'>
							<Dropdown isOpen={this.state.searchDateRangeDDIsOpen} toggle={this.toggleSearchDateRangeDD}>
								<DropdownToggle caret>
									{!!this.state.selectedSearchDateRange ? (
										<Trans i18nKey={this.state.selectedSearchDateRange.Value}>
											{this.state.selectedSearchDateRange.Value}
										</Trans>
									) : (
										i18n.t('SELECT_ORGANISATIOIN')
									)}
								</DropdownToggle>
								<DropdownMenu>
									{this.state.searchDateRangeList.map((x, i) => {
										return (
											<DropdownItem onClick={() => this.setSearchDateRange(x)} key={i}>
												<Trans i18nKey={x.Value}></Trans>
											</DropdownItem>
										);
									})}
								</DropdownMenu>
							</Dropdown>
						</div>
						{this.state.selectedSearchDateRange.ID === 3 && (
							<div className='col-3 search-daterange'>
								<div className='row'>
									<div className='col-6'>
										<DatePicker
											id='example-datepicker'
											value={this.state.searchMinDate}
											dateFormat={'DD.MM.YYYY'}
											onChange={(v) => this.handleStartDateChange(v)}
											showClearButton={false}
										/>
									</div>
									<div className='col-6'>
										<DatePicker
											id='example-datepicker'
											value={this.state.searchMaxDate}
											dateFormat={'DD.MM.YYYY'}
											onChange={(v) => this.handleEndDateChange(v)}
											showClearButton={false}
										/>
									</div>
								</div>
							</div>
						)}
						<div className='col-2 padding-organsiation'>
							<Dropdown isOpen={this.state.searchStatusDDIsOpen} toggle={this.toggleSearchStatusDD}>
								<DropdownToggle caret>
									{!!this.state.selectedSearchStatus ? (
										<Trans i18nKey={this.state.selectedSearchStatus.Value}>
											{this.state.selectedSearchStatus.Value}
										</Trans>
									) : (
										i18n.t('SELECT_ORGANISATIOIN')
									)}
								</DropdownToggle>
								<DropdownMenu>
									{this.state.searchStatusList.map((x, i) => {
										return (
											<DropdownItem onClick={() => this.setSearchStatus(x)} key={i}>
												<Trans i18nKey={x.Value}>{x.Value}</Trans>
											</DropdownItem>
										);
									})}
								</DropdownMenu>
							</Dropdown>						
						</div>
						<div className='col-3'>
							<button
								className={this.validateQuotationLockedByme()
									? 'btn btn-disabled full-width'
									: 'btn btn-enabled full-width'}
								onClick={this.onShowLockedQuotation}
								disabled={this.validateQuotationLockedByme()}
							>
							{this.state.numberOfQuotationLockedByMe == 0 ? <Trans i18nKey='NO_LOCKED'> NO_LOCKED</Trans>
																		: this.state.numberOfQuotationLockedByMe + ' - ' }	<Trans i18nKey='QUOTATION_LOCKED_BY_ME'> QUOTATION_LOCKED_BY_ME</Trans>
							</button>
							</div>				
						<div className='col-1 search-clear-section'>
							<div className='search-clear-icon' onClick={this.onClearSearch}></div>
						</div>
					</div>
					<div>
						<Modal
							isOpen={this.state.showVersionModal}
							size={'lg'}
							className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
						>
							<ModalHeader>
								<div className='modal-title-text'>
									<Trans i18nKey='HOW_DO_YOU_WANT_TO_CONTINUE'>HOW_DO_YOU_WANT_TO_CONTINUE</Trans>{' '}
								</div>
								<div className='modal-close' id='modal-close-econf' onClick={this.onCloseVersionStatus}>
									x
								</div>
							</ModalHeader>
							<ModalBody>
								<div className='quotation-status-modal-body '>
									<Table striped>
										<thead className={'table-header-content'}>
											<tr className='row'>
												<th className='col-1'>
													<div></div>
												</th>
												<th className='col-2'>
													<div>
														<Trans i18nKey='VERSION'>VERSION</Trans>
													</div>
												</th>
												<th className='col-3'>
													<div>
														<Trans i18nKey='CREATION_TIMESTAMP'>CREATION_TIMESTAMP</Trans>
													</div>
												</th>
												<th className='col-3'>
													<div>
														<Trans i18nKey='DESCRIPTION'>DESCRIPTION</Trans>
													</div>
												</th>
												<th className='col-2'></th>
												<th className='col-1'></th>
											</tr>
										</thead>
										<div className='row version-data'>
											<tbody className='table-content col-12'>
												{this.state.versionData.map((x, i) => {
													return (
														<tr
															className='row'
															key={i}
															style={
																this.state.selectedVersionQuotationId === x.Id
																	? {
																			backgroundColor: '#3E648B',
																			color: '#FFFFFF'
																	}
																	: {
																			backgroundColor: 'white',
																			color: '#000000'
																	}
															}
														>
															<td className='col-1'>
																<input
																	name='quotationStatus'
																	type='radio'
																	onChange={(e) => this.onQuotationStatusChanged(x, e)}
																	checked={this.state.selectedVersionQuotationId === x.Id}
																></input>
															</td>

															<td className='col-2'>
																{i18n.t('VERSION_LOWER') + ' ' + x.VersionNumber}
															</td>
															<td className='col-3'>
																{getFormattedDateTime(
																	x.CreatedDate,
																	selectedLanguage.split('-')[0]
																)}
															</td>
															<td className='col-3'>
																<i>{x.VersionDescription} </i>
															</td>
															<td className='col-2'>
																{x.IsActiveVersion && (
																	<Trans i18nKey='ACTIVE_VERSION'>ACTIVE_VERSION</Trans>
																)}
															</td>
															<td className='col-1'>
															{ hasEditAccess(userRoleDetail,"Quotation") && 	<div
																	className='copy-icon'
																	onClick={() => this.onCopyQuotation(x)}
																	title={i18n.t('COPY_QUOTATION')}
																></div>
															}
															</td>
														</tr>
													);
												})}
											</tbody>
										</div>
									</Table>
								</div>
							</ModalBody>
							<ModalFooter>
								<div className='modal-footer-button'>
									<div className='row'>
										<div className='col-6'>
											<button
												className={
													this.validateVersion(userRoleDetail)
														? 'btn btn-disabled full-width'
														: 'btn btn-enabled full-width'
												}
												onClick={this.redirectViewVersion}
												disabled={
													this.validateVersion(userRoleDetail)
												}
											>
												<Trans i18nKey='CONTINUE_WORKING_ON_SELECTED_VERSION'>
													CONTINUE_WORKING_ON_SELECTED_VERSION
												</Trans>
											</button>
										</div>
										<div className='col-6'>
											<button
												className={
													this.validateVersion(userRoleDetail)
														? 'btn btn-disabled full-width'
														: 'btn btn-enabled full-width'
												}
												disabled={
													this.validateVersion(userRoleDetail)
												}
												onClick={this.startNewVersion}
											>
												<Trans i18nKey='START_NEW_VERSION'>START_NEW_VERSION</Trans>
											</button>
										</div>
									</div>
									<div className='row mt-2'>
										<div className='col-6'>
											<button
												onClick={this.setQuotationVersion}
												className={
													this.setSelectedVersion(userRoleDetail) 
														? 'btn btn-disabled full-width'
														: 'btn btn-enabled full-width'
												}
												disabled = {this.setSelectedVersion(userRoleDetail)}
											>
												<Trans i18nKey='SET_SELECTED_VERSION'>SET_SELECTED_VERSION</Trans>
											</button>
										</div>
										<div className='col-6'>
											<button className='btn btn-enabled full-width' onClick={this.viewModeOnly}>
												<Trans i18nKey='DISPLAY_SELECTED_VERSION'>DISPLAY_SELECTED_VERSION</Trans>
											</button>
										</div>
									</div>	
									{this.showlockImage() && (<div className='row mt-2'>
										<div className='col-6'>				
											<div className= {this.state.isSalesAdmin ? 'lock-icon-grid' : 'lock-icon-grid  disable-lock-icon'}
											onClick={this.unLockQuotation}>
											</div>
											<span className="main-content display-block">
												{ this.state.isSalesAdmin ? (i18n.t('LOCKED_BY') + ' ' + this.state.selectedQuotationLockedByName.lockedByName + ' ' +i18n.t('CLICK_TO_UNLOCK')) : 
												i18n.t('LOCKED_BY') + ' ' + this.state.selectedQuotationLockedByName.lockedByName}
											</span>

										</div>
									</div>)}
																	
								</div>
							</ModalFooter>
						</Modal>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='table-container'>
								<table className='table table-striped dashboard-table'>
									
									<thead>

										<tr className='row'>

											<th scope='col' className='col-2'>
												<DashBoardColumnHeaderComponent
													columnParameter = "opportunity"
													columnIsSortedFlag = {this.state['opportunitySortingFlag']}
													textKey="OPPORTUNITY_DASHBOARD"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-2'>
												<DashBoardColumnHeaderComponent
													columnParameter = "account"
													columnIsSortedFlag = {this.state['accountSortingFlag']}
													textKey="ACCOUNT"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-1'>
												<DashBoardColumnHeaderComponent
													columnParameter = "owner"
													columnIsSortedFlag = {this.state['ownerSortingFlag']}
													textKey="OWNER"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-1'>
												<DashBoardColumnHeaderComponent
													columnParameter = "internalSales"
													columnIsSortedFlag = {this.state['internalSalesSortingFlag']}
													textKey="INTERNAL_SALES"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-2'>
												<DashBoardColumnHeaderComponent
													columnParameter = "lastAdjustment"
													columnIsSortedFlag = {this.state['lastAdjustmentSortingFlag']}
													textKey="LAST_ADJUSTMENT"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-1'>
												<DashBoardColumnHeaderComponent
													columnParameter = "version"
													columnIsSortedFlag = {this.state['versionSortingFlag']}
													textKey="VERSION"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-1'>
												<DashBoardColumnHeaderComponent
													columnParameter = "status"
													columnIsSortedFlag = {this.state['statusSortingFlag']}
													textKey="STATUS"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-1'>
												<DashBoardColumnHeaderComponent
													columnParameter = "creator"
													columnIsSortedFlag = {this.state['creatorSortingFlag']}
													textKey="CREATOR_DASHBOARD"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

											<th scope='col' className='col-1'>
												<DashBoardColumnHeaderComponent
													columnParameter = "totalNetPrice"
													columnIsSortedFlag = {this.state['totalNetPriceSortingFlag']}
													textKey="REPORT_TOTAL"
													sortBy = {this.sortBy}
													filterBy= {this.filterBy}
												/>
											</th>

										</tr>
									</thead>

									<tbody>
										{this.state.tableData.length === 0 ? (
											this.state.loading ? (
												<tr style={{ backgroundColor: 'white' }}>
													<td>
														<div className='loading'>
															{' '}
															<Trans i18nKey='LOADING'>LOADING</Trans>
														</div>
													</td>
												</tr>
											) : (
												<tr style={{ backgroundColor: 'white' }}>
													<td>
														<div className='loading'>
															{' '}
															<Trans i18nKey='NO_RECORDS_FOUND'>NO_RECORDS_FOUND</Trans>
														</div>
													</td>
												</tr>
											)
										) : null}
										{this.state.tableData.map((x, i) => {
											return (
												<tr
													className='table-content row'
													key={i}
													onClick={() => this.getVersionList(x)}
												>												
													<td className='padding-td col-2'>
														<div className={
																this.isQuotationLocked(x.lockedBy)?'lock-icon-grid':''}>													
														</div>
														<span className='main-content word-break display-block'>
															{x.opportunity}
														</span>

														<span className='opportunity-number sub-content word-break'>
															{x.opportunityNumber}
														</span>													
													</td>											
													<td className='padding-td  col-2'>
														<span className='word-break display-block'>{x.account}</span>

														<span className='account-number sub-content word-break'>
															{x.accountNumber}
														</span>
													</td>
													<td className='padding-td  col-1 word-break'>{x.owner}</td>
													<td className='padding-td col-1 word-break'>{x.internalSales}</td>
													<td className='padding-td col-2 word-break'>
														{getFormattedDateTime(
															x.lastAdjustment,
															selectedLanguage.split('-')[0]
														)}
													</td>
													<td className='padding-td col-1 word-break'>
														{i18n.t('VERSION_LOWER') + ' ' + x.version}
													</td>
													<td className='padding-td col-1 word-break'>
														<Trans i18nKey={x.status}>{x.status}</Trans>{' '}
													</td>
													<td className='padding-td col-1 word-break'>{x.creator}</td>
													<React.Fragment>
														<td className='padding-td col-1 word-break'>
															<PriceComponent 
																value={x.totalNetPrice} 
																incompletePriceStateId={false} />
														</td>
													</React.Fragment>
												</tr>
											);
										})}
										{this.state.showViewQuotationPage && (
											<Redirect
												to={{
													pathname: '/quotation/view'
												}}
											/>
										)}
									</tbody>
								</table>
							</div>
						</div>
						{this.state.loading && (
							<div className='fixed-bottom'>
								<Loader type='Bars' color='#ef2312' height={100} width={100} />
							</div>
						)}
						<div>
							<ToastContainer
								position='top-center'
								autoClose={5000}
								className='toast'
								hideProgressBar={false}
								newestOnTop={false}
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
							/>
						</div>
					</div>
			</IntlProvider>
				</>
		);
	}

	showlockImage() {
		return this.isQuotationLocked(this.state.versionData.length > 0 ? this.state.versionData.find((x)=>x.IsActiveVersion).LockedBy : '');
	}

	setSelectedVersion(userRoleDetail) {
		return !hasEditAccess(userRoleDetail, "Quotation") || 
		(this.state.versionData.length > 0 && this.state.versionData.find((x)=>x.IsActiveVersion).LockedBy != this.props.accountInfo.account.accountIdentifier && 
		this.state.versionData.find((x)=>x.IsActiveVersion).LockedBy) ;
	}

	validateVersion(userRoleDetail) {
		return (this.state.versionData.length > 0 &&
			this.state.versionData.find((x) => x.IsActiveVersion).Id !=
			this.state.selectedVersionQuotationId) || !hasEditAccess(userRoleDetail, "Quotation") || 
			(this.state.versionData.length > 0 && this.state.versionData.find((x)=>x.IsActiveVersion).LockedBy != this.props.accountInfo.account.accountIdentifier &&
			this.state.versionData.find((x)=>x.IsActiveVersion).LockedBy)
			
	}


	validateQuotationLockedByme() {
		return ((this.state.numberOfQuotationLockedByMe == 0) ||this.state.numberOfQuotationLockedByMe > 0 && this.state.quotationLockedByMe);
			
	}
}
Dashboard.contextType = QuotationToolContext;
const Dash = withTranslation()(Dashboard);
