import React, { Component } from 'react';
import './OpportunitySearch.scss';
import { Redirect } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import i18n from 'i18next';
import { QuotationToolContext } from '../../Providers';
import { getOpportunitiesBySearch, getOpportunityById } from '../../Utils/apiCalls'

export default class OpportunitySearch extends Component {

	state = {
		tableData: [],
		showQuotationPage: false,
		isLoading: false,
		setIsLoading: false,
		loading: false,
		options: [],
		setOptions: [],
		totalRecords: 0,
	};

	handleSearch = (query) => {
		var myHeaders = new Headers();
		myHeaders.append('pragma', 'no-cache');
		myHeaders.append('cache-control', 'no-cache');
		this.setState({ options: [], isLoading: true, loading: true });

		getOpportunitiesBySearch(query)
		.then((data) => {
				let options = data.Data;
				let totalRecords = data.TotalRecords;
				this.setState({ options: options, isLoading: false, totalRecords: totalRecords, loading: false });
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG.</Trans>);
				this.setState({ loading: false });
			});
	};

	onQuotationCreate = (opportunity) => {
		this.setState({ loading: true });

		getOpportunityById(opportunity.OpportunityNumber)
		.then((data) => {
			const opportunityDetails = data;
			const { updateCreatedQuotation } = this.context;
			updateCreatedQuotation(opportunityDetails);

			this.setState({ loading: false });
			this.setState({ showQuotationPage: true });
		})
		.catch(() => {
			toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG.</Trans>);
			this.setState({ loading: false });
		});
	};

	componentDidMount = () => {
		this.props.setWindowPage(false);
	};
	

	filterBy = () => true;

	render() {
		return (
			<div className='row mt-4'>
				<div className='col-12'>
					<div className='create-quotation-body'>
						<div className='create-quotation-title'>
							<Trans i18nKey='ADD_QUOTATION'>ADD_QUOTATION</Trans>
						</div>
						<br />
						<div className='opportunity-search-panel'>
							<div className='opportunity-search-title'>
								<Trans i18nKey='SELECT_CORRESPONDING_OPPORTUNITY'>
									SELECT_CORRESPONDING_OPPORTUNITY
								</Trans>
							</div>
							<div className='search-box'>
								<AsyncTypeahead
									defaultSelected={this.props.defaultSelected}
									filterBy={this.filterBy}
									id='async-example'
									isLoading={this.state.isLoading}
									labelKey='clientNumber'
									useCache={false}
									minLength={3}
									onSearch={this.handleSearch}
									options={this.state.options}
									placeholder={i18n.t('SEARCH_OPPORTUNITY_OR_CUSTOMER')}
									renderMenu={(results, menuProps) => (
										<div>
											<div className='div-table'>
												<table className='table table-striped dropdownTable'>
													<thead>
														<tr className='opportunity-search-table-header row'>
															<th className='col-xl-3 col-lg-3  col-sm-3'>
																<Trans i18nKey='OPPORTUNITY_NAME'>
																	OPPORTUNITY_NAME
																</Trans>
															</th>
															<th className='col-xl-3 col-lg-3  col-sm-3'>
																<Trans i18nKey='OPPORTUNITY_NUMBER'>
																	OPPORTUNITY_NUMBER
																</Trans>
															</th>
															<th className='col-xl-3 col-lg-3  col-sm-3'>
																<Trans i18nKey='ACCOUNT_NAME'>ACCOUNT_NAME</Trans>
															</th>
															<th className='col-xl-3 col-lg-3  col-sm-3'>
																<Trans i18nKey='ACCOUNT_NUMBER'>ACCOUNT_NUMBER</Trans>
															</th>
														</tr>
													</thead>
													<tbody>
														{this.state.isLoading && (
															<tr className='search-loading mt-4'>
																<td>
																	<Trans i18nKey='LOADING'>LOADING</Trans>
																</td>
															</tr>
														)}
														{results.length === 0 && !this.state.isLoading && (
															<tr className='search-loading mt-4'>
																<td>
																	<Trans i18nKey='NO_DATA_FOUND'>NO_DATA_FOUND</Trans>
																</td>
															</tr>
														)}
														{results.length !== 0 &&
															results.map((option, index) => (
																<tr
																	className='quotation-row table-content row'
																	onClick={() => this.onQuotationCreate(option)}
																	key={index}
																>
																	<td className='td-bold col-xl-3 col-lg-3  col-sm-3'>
																		{option.OpportunityName}
																	</td>
																	<td className='td-light col-xl-3 col-lg-3  col-sm-3'>
																		{option.OpportunityNumber}
																	</td>
																	<td className='td-light col-xl-3 col-lg-3  col-sm-3'>
																		{option.AccountName}
																	</td>
																	<td className='td-light col-xl-3 col-lg-3  col-sm-3'>
																		{option.AccountNumber}
																	</td>
																</tr>
															))}
														{this.state.showQuotationPage && (
															<Redirect
																to={{
																	pathname: '/quotation/create'
																}}
															/>
														)}
													</tbody>
													<tfoot>
														<tr></tr>
													</tfoot>
												</table>
											</div>
											<i>
												{this.state.options.length}
												<Trans i18nKey='NO_OF_RECORDS' count={this.state.totalRecords}>
													NO_OF_RECORDS
												</Trans>
											</i>
										</div>
									)}
								/>
							</div>
						</div>
						<br />
					</div>
				</div>
				{this.state.loading && (
					<div className='fixed-bottom'>
						<Loader type='Bars' color='#ef2312' height={100} width={100} />
					</div>
				)}
				<div>
					<ToastContainer
						position='top-center'
						autoClose={5000}
						className='toast'
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
			</div>
		);
	}
}
withTranslation()(OpportunitySearch);
OpportunitySearch.contextType = QuotationToolContext;
