import React, { Component } from 'react';
import { QuotationToolContext } from '../../Providers';
import { hasEditAccess,getDistinctOrganisation } from '../../Utils/commonFunctions';
import { getSalesOrganisations, getConditionImages, saveDefaultCondition, getDefaultConditions } from '../../Utils/apiCalls';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Trans } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './QuotationCondition.scss';
import Loader from 'react-loader-spinner';
import i18n from 'i18next';

export default class QuotationCondition extends Component {
	state = {
		languageDDIsOpen: false,
		SelectedLanguage: 'Nederlands(België)',
		SelectedExportType: 1,
		loading: false,
		condition1: '',
		condition2: '',
		condition3: '',
		condition4: '',
		Language: [
			{ Id: 1, Name: 'Nederlands(België)', Code: 'nl-BE' },
			{ Id: 2, Name: 'Nederlands(Nederland)', Code: 'nl-NL' },
			{ Id: 3, Name: 'Frans', Code: 'fr-BE' },
			{ Id: 4, Name: 'Engels', Code: 'en-GB' }
		],
		azureImages: [{ ImageName: null, ImageURL: null }],
		Layout: [
			{ Id: 1, Name: 'BE-standard' },
			{ Id: 2, Name: 'NL-standard' }
		],
		SelectedLayout: null,
		languageExport: 'Nederlands(België)',
		isLayoutDropDownOpen: false,
		isCondition1DropDownOpen: false,
		isCondition2DropDownOpen: false,
		isCondition3DropDownOpen: false,
		isCondition4DropDownOpen: false,
		selectedCondition1Image: { Id: 0, ImageName: null, ImageURL: null },
		selectedCondition2Image: { Id: 0, ImageName: null, ImageURL: null },
		selectedCondition3Image: { Id: 0, ImageName: null, ImageURL: null },
		selectedCondition4Image: { Id: 0, ImageName: null, ImageURL: null },
		isPreviewPopUpOpen: false,
		selectedImage: '',
		showQuotationViewPage: false,
		showSendToCRMPopup: false,
		accountName: '',
		contactPerson: '',
		contactEmail: '',
		selectedOrganization: null,
		salesOrganisatieDDIsOpen: false,
		organizationList: [],
	};

	componentDidMount = () => {

		getSalesOrganisations()
		.then((response) => {
			let distinctOrganisations = getDistinctOrganisation(response);
			this.setState({ 
				organizationList: distinctOrganisations, 
				selectedOrganization: distinctOrganisations[0].Name});
		})
		.catch((reason) => { this.handleError(reason)});

		getConditionImages()
		.then((response) => {
			response.unshift({ ImageName: null, ImageURL: null });
				this.setState({azureImages: response,loading: false});
				let language = this.state.Language.find((x) => x.Name == this.state.SelectedLanguage).Code;
		        this.onReset(language);
		})
		.catch((reason) => { this.handleError(reason)});

		this.props.setWindowPage(false);
	};

	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

	toggleSalesOrganisatieDD = () => {
		this.setState({
			salesOrganisatieDDIsOpen: !this.state.salesOrganisatieDDIsOpen
		});
	};

	openPreviewPopUp = (imgURL) => {
		this.setState({ isPreviewPopUpOpen: true, selectedImage: imgURL });
	};

	closePreviewPopUp = () => {
		this.setState({ isPreviewPopUpOpen: false, selectedImage: '' });
	};

	setOrganization = (organization) => {	
		this.setState({selectedOrganization: organization.Name});
		let language = this.state.Language.find((x) => x.Name == this.state.SelectedLanguage).Code;
		this.onReset(language);	
	};

	toggleLanguageDD = () => {
		this.setState({
			languageDDIsOpen: !this.state.languageDDIsOpen
		});
	};
	 
	saveExportCondition = () => {
		this.setState({ loading: true });

		const placeholder = '##IMAGE##';
		let condition1 = this.state.condition1;
		let condition2 = this.state.condition2;
		let condition3 = this.state.condition3;
		let condition4 = this.state.condition4;
		if (
			this.state.selectedCondition1Image.ImageURL &&
			condition1.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition1 += '\n' + placeholder;
		}
		if (
			this.state.selectedCondition2Image.ImageURL &&
			condition2.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition2 += '\n' + placeholder;
		}
		if (
			this.state.selectedCondition3Image.ImageURL &&
			condition3.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition3 += '\n' + placeholder;
		}
		if (
			this.state.selectedCondition4Image.ImageURL &&
			condition4.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition4 += '\n' + placeholder;
		}
		let data = {
			Condition1: condition1,
			Condition2: condition2,
			Condition3: condition3,
			Condition4: condition4,
			ConditionImg1: !this.state.selectedCondition1Image.ImageURL
				? null
				: this.state.selectedCondition1Image.ImageName,
			ConditionImg2: !this.state.selectedCondition2Image.ImageURL
				? null
				: this.state.selectedCondition2Image.ImageName,
			ConditionImg3: !this.state.selectedCondition3Image.ImageURL
				? null
				: this.state.selectedCondition3Image.ImageName,
			ConditionImg4: !this.state.selectedCondition4Image.ImageURL
				? null
				: this.state.selectedCondition4Image.ImageName,
			Language: this.state.Language.find((x) => x.Name == this.state.SelectedLanguage).Code
		};

		saveDefaultCondition(this.state.selectedOrganization, data)
		.then((response) => {
			toast.success(i18n.t('CONDIDTION_SAVED_SUCCESFULLY'));
			this.setState({ loading: false, showQuotationViewPage:true });	
		})
		.catch((reason) => { this.handleError(reason)});
	};

	onSave = () => {
		this.saveExportCondition();
	};
	

	setLanguage = (language) => {
		this.onReset(language.Code);
		this.setState({
			SelectedLanguage: language.Name,
			languageExport: language.Name
		});
	};

	setSelectedLayout = (layout) => {
		this.setState({
			SelectedLayout: layout.Name
		});
	};
	setSelectedCondition1Image = (image) => {
		this.setState({
			selectedCondition1Image: image
		});
	};
	setSelectedCondition2Image = (image) => {
		this.setState({
			selectedCondition2Image: image
		});
	};
	setSelectedCondition3Image = (image) => {
		this.setState({
			selectedCondition3Image: image
		});
	};
	setSelectedCondition4Image = (image) => {
		this.setState({
			selectedCondition4Image: image
		});
	};
	toggleLayoutDropDown = () => {
		this.setState({
			isLayoutDropDownOpen: !this.state.isLayoutDropDownOpen
		});
	};
	toggleCondition1DropDown = () => {
		this.setState({
			isCondition1DropDownOpen: !this.state.isCondition1DropDownOpen
		});
	};
	toggleCondition2DropDown = () => {
		this.setState({
			isCondition2DropDownOpen: !this.state.isCondition2DropDownOpen
		});
	};
	toggleCondition3DropDown = () => {
		this.setState({
			isCondition3DropDownOpen: !this.state.isCondition3DropDownOpen
		});
	};
	toggleCondition4DropDown = () => {
		this.setState({
			isCondition4DropDownOpen: !this.state.isCondition4DropDownOpen
		});
	};

	onCancel = () => {
		this.setState({
			showQuotationViewPage: true
		});
	};

	onReset = (language) => {
		this.setState({ loading: true });

		getDefaultConditions(this.state.selectedOrganization, language)
		.then((response) => {
			this.setState({
				condition1: response.Condition1 ?? '',
				condition2: response.Condition2 ?? '',
				condition3: response.Condition3 ?? '',
				condition4: response.Condition4 ?? '',
				selectedCondition1Image:
					this.state.azureImages.find(
						(x) => x.ImageName == response.ConditionImg1
					) ?? this.state.azureImages.find((x) => x.ImageName == null),
				selectedCondition2Image:
					this.state.azureImages.find(
						(x) => x.ImageName == response.ConditionImg2
					) ?? this.state.azureImages.find((x) => x.ImageName == null),
				selectedCondition3Image:
					this.state.azureImages.find(
						(x) => x.ImageName == response.ConditionImg3
					) ?? this.state.azureImages.find((x) => x.ImageName == null),
				selectedCondition4Image:
					this.state.azureImages.find(
						(x) => x.ImageName == response.ConditionImg4
					) ?? this.state.azureImages.find((x) => x.ImageName == null),
				loading: false
			});
		})
		.catch((reason) => { this.handleError(reason)});
	};
	
	render() {
		const { exportData, viewModeOnly, userRoleDetail } = this.context;
		return (
			<>
				<div className='mt-4'>
					<div className='view-quotation-panel-title mb-4'>
						<Trans i18nKey='DEFAULT_CONDITION'>DEFAULT_CONDITION</Trans>
					</div>
					<div className='language-panel container-fluid pb-2 pt-2'>
						<div>
							<div className='language-dropdown row'>
							<div className='add-quotation-line-title padding-top-title  export-title col-2'>
										<Trans i18nKey='SALES_ORGANISATION_LOWERCASE'>SALES_ORGANISATION_LOWERCASE</Trans>
								</div>
									<Dropdown
										isOpen={this.state.salesOrganisatieDDIsOpen}
										toggle={this.toggleSalesOrganisatieDD}
										className='display-inline-export col-4'
										id='conditions-sales-organisation'
									>
										<DropdownToggle caret>											
												 {this.state.selectedOrganization}												
										</DropdownToggle>
										<DropdownMenu>
											{this.state.organizationList.map((x, i) => {
												return (
													<DropdownItem onClick={() => this.setOrganization(x)} key={i}>
														{x.Name}
													</DropdownItem>
												);
											})}
										</DropdownMenu>
									</Dropdown>
																				
								<div className='col-6'>	
																
									<button
										className={
											viewModeOnly
												? 'btn btn-disabled ml-2 button-reset'
												: 'btn opportunity-next-step-btn ml-2 button-reset'
										}
										id={'save'}
										onClick={this.onSave}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									>
										<Trans i18nKey='SAVE_CONDITIONS'>SAVE_CONDITIONS</Trans>
									</button>
									
									<button
										onClick={() => this.onReset(this.state.Language.find((x) => x.Name == this.state.SelectedLanguage).Code)}
										type='button'
										className={
											(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions'))
												? 'btn btn-disabled ml-2 button-reset'
												: 'btn opportunity-next-step-btn ml-2 button-reset'
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									>
										<Trans i18nKey='RESET'>RESET</Trans>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='language-panel container-fluid pb-2 pt-2'>
					 	<div>
							<div className='language-dropdown row'>
					       <div className='add-quotation-line-title padding-top-title  export-title col-2'>
									<Trans i18nKey='LANGUAGE'>LANGUAGE</Trans>
								</div>
								<Dropdown
									isOpen={this.state.languageDDIsOpen}
									toggle={this.toggleLanguageDD}
									className='display-inline-export col-4'
									id={'openLanguage'}
								>
									<DropdownToggle caret>{this.state.SelectedLanguage}</DropdownToggle>
									<DropdownMenu>
										{this.state.Language
											? this.state.Language.map((x, i) => {
													return (
														<DropdownItem
															id={'language' + i}
															onClick={() => this.setLanguage(x)}
															key={i}
														>
															{x.Name}
														</DropdownItem>
													);
											  })
											: ''}
									</DropdownMenu>
								</Dropdown>
								</div>
						</div>
					</div>									
					<br />
					<div className='language-panel container-fluid pb-2 pt-2'>
						<div className='conditions'>
							<div className='condition1'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS1'>CONDITIONS1</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition1}
										onChange={(e) =>
											this.setState({
												condition1: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												isOpen={this.state.isCondition1DropDownOpen}
												toggle={this.toggleCondition1DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition1Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition1Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition1Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition1Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition1Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition1Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='condition2'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS2'>CONDITIONS2</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition2}
										onChange={(e) =>
											this.setState({
												condition2: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
												isOpen={this.state.isCondition2DropDownOpen}
												toggle={this.toggleCondition2DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition2Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition2Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition2Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition2Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition2Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition2Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='condition3'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS3'>CONDITIONS3</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition3}
										onChange={(e) =>
											this.setState({
												condition3: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
												isOpen={this.state.isCondition3DropDownOpen}
												toggle={this.toggleCondition3DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition3Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition3Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition3Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition3Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition3Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition3Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='condition4'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS4'>CONDITIONS4</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition4}
										onChange={(e) =>
											this.setState({
												condition4: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
												isOpen={this.state.isCondition4DropDownOpen}
												toggle={this.toggleCondition4DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition4Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition4Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition4Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition4Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition4Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition4Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
												
					<Modal
						isOpen={this.state.isPreviewPopUpOpen}
						size={'lg'}
						className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
					>
						<ModalHeader>
							<Trans i18nKey='PREVIEW_IMAGE'>PREVIEW_IMAGE</Trans>
							<div className='modal-close' id='modal-close-econf' onClick={this.closePreviewPopUp}>
								x
							</div>
						</ModalHeader>
						<ModalBody>
							<div className='preview-popup-body'>
								<div
									className='preview-image-popup'
									style={{
										backgroundImage: `url(${this.state.selectedImage})`
									}}
								></div>
							</div>
						</ModalBody>
					</Modal>
					{this.state.loading && (
						<div className='fixed-bottom'>
							<Loader type='Bars' color='#ef2312' height={100} width={100} />
						</div>
					)}
					
				</div>
				<div>
					<ToastContainer
						position='top-center'
						autoClose={5000}
						className='toast'
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
			</>
		);
	}
}
QuotationCondition.contextType = QuotationToolContext;
