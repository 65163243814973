import React from 'react';

export const QuotationToolContext = React.createContext();

const stateKey = 'quotationTool_state';
export default class Provider extends React.Component {
	state = this.initState();
	initState() {
		let result;
		try {
			result = JSON.parse(sessionStorage.getItem(stateKey));
		} catch (e) {
			// Ignore.
		}
		if (!result) {
			result = {
				selectedQuotationId: 0,
				selectedQuotationLineId:0,
				selectedQuotationLineDetailId:0,
				viewModeOnly: false,
				createdQuotation: null,
				accountInfo: null,
				selectedLanguage: '',
				isEconPopUpOpen: false,
				quotationToBeCopied: null,
				exportData: null,
				econRedirectUrl: '',
				userRoleDetail : null,
				selectedRole:null,
				isQuotationLockedByMe :false,
				discountValue: 0,
			};
		}
		result.updateselectedQuotationId = (data) => this.updateSelectedQuotationId(data);
		result.updateselectedQuotationLineId = (data) => this.updateSelectedQuotationLineId(data);
		result.updateselectedQuotationLineDetailId = (data) => this.updateSelectedQuotationLineDetailId(data);
		result.updateViewModeOnly = (data) => this.updateViewModeOnly(data);
		result.updateCreatedQuotation = (data) => this.updateCreatedQuotation(data);
		result.updateAccountInfo = (data) => this.updateAccountInfo(data);
		result.updateSelectedLanguage = (data) => this.updateSelectedLanguage(data);
		result.updateIsEconPopUpOpen = (data) => this.updateIsEconPopUpOpen(data);
		result.updateQuotationToBeCopied = (data) => this.updateQuotationToBeCopied(data);
		result.updateExportData = (data) => this.updateExportData(data);
		result.updateEconRedirectUrl = (data) => this.updateEconRedirectUrl(data);
		result.updateUserRoleDetail = (data) => this.updateUserRoleDetail(data);
		result.updateSelectedRole = (data) => this.updateSelectedRole(data);
		result.updateIsQuotationLockedByMe = (data) => this.updateIsQuotationLockedByMe(data);
		result.updatedDiscountValueFromUrl = (data) => this.updatedDiscountValueFromUrl(data);
		return result;
	}
	updateAndSave(stateUpdate) {
		return new Promise((resolve, reject) => {
			this.setState(stateUpdate, () => {
				try {
					this.saveState();
					resolve();
				} catch (error) {
					reject(error);
				}
			});
		});
	}
	saveState() {
		const newState = {};
		for (const prop in this.state) {
			if (typeof prop === 'function') {
				continue;
			}
			newState[prop] = this.state[prop];
		}
		sessionStorage.setItem(stateKey, JSON.stringify(newState));
	}
	updateIsQuotationLockedByMe(data) {
		return this.updateAndSave({ isQuotationLockedByMe: data });
	}
	updateSelectedQuotationId(data) {
		return this.updateAndSave({ selectedQuotationId: data });
	}
	updateSelectedQuotationLineId(data) {
		return this.updateAndSave({ selectedQuotationLineId: data });
	}
	updateSelectedQuotationLineDetailId(data) {
		return this.updateAndSave({ selectedQuotationLineDetailId: data });
	}
	updateViewModeOnly(data) {
		return this.updateAndSave({ viewModeOnly: data });
	}
	updateCreatedQuotation(data) {
		return this.updateAndSave({ createdQuotation: data });
	}
	updateAccountInfo(data) {
		return this.updateAndSave({ accountInfo: data });
	}
	updateSelectedLanguage(data) {
		return this.updateAndSave({ selectedLanguage: data });
	}
	updateIsEconPopUpOpen(data) {
		return this.updateAndSave({ isEconPopUpOpen: data });
	}
	updateQuotationToBeCopied(data) {
		return this.updateAndSave({ quotationToBeCopied: data });
	}
	updateExportData(data) {
		return this.updateAndSave({ exportData: data });
	}
	updateEconRedirectUrl(data) {
		return this.updateAndSave({ econRedirectUrl: data });
	}
	updateUserRoleDetail(data) {
		return this.updateAndSave({ userRoleDetail: data });
	}
	updateSelectedRole(data){
		return this.updateAndSave({selectedRole:data});
	}
	updatedDiscountValueFromUrl(data){
		return this.updateAndSave({discountValue:data});
	}
	render() {
		const { children } = this.props;
		return <QuotationToolContext.Provider value={this.state}>{children}</QuotationToolContext.Provider>;
	}
}
