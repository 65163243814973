import React, { Component } from 'react';
import './Sidebar.scss';
import { QuotationToolContext } from '../../Providers';
import { hasEditAccess } from '../../Utils/commonFunctions';
import ConfirmationDialog from '../../components/quotation/ConfirmationDialog';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { saveQuotationLockStatus } from '../../Utils/apiCalls';

export default class Sidebar extends Component {

	state = {
		showWarningAddQuotationPopup:false,
		redirectToEmailConfiguration:false,
		redirectToConditions:false,
		redirectToOpportunitySearch:false,
	};

	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

	onClickAddNewQuotationButton = () => {

		const { isQuotationLockedByMe } = this.context;

		this.setState({redirectToOpportunitySearch:false});

		isQuotationLockedByMe && this.originatedFromAQuotation()
			? this.setState({ showWarningAddQuotationPopup: true }) 
			: this.keepQuotationLockedAndContinue();	
			
		this.setState({redirectToOpportunitySearch:true});

		this.resetRedirects();
	};

	originatedFromAQuotation = () => {
		console.log(this.props);
		return true;
		// Todo: find out how to determine if we originated from a specific route.
		//return window.document.referrer.includes('/quotation/view');
	}

	onClickEmailConfigurationButton = () => {	
		const { isQuotationLockedByMe } = this.context;

		this.setState({redirectToEmailConfiguration:false}) 

		isQuotationLockedByMe && this.originatedFromAQuotation()
			? this.setState({ showWarningAddQuotationPopup: true }) 
			: this.keepQuotationLockedAndContinue();	

		this.setState({redirectToEmailConfiguration:true});

		this.resetRedirects();
	};

	onClickConditionsButton = () => {	
		const { isQuotationLockedByMe } = this.context;

		this.setState({redirectToConditions:false}) 

		isQuotationLockedByMe && this.originatedFromAQuotation()
			? this.setState({ showWarningAddQuotationPopup: true }) 
			: this.keepQuotationLockedAndContinue();	

		this.setState({redirectToConditions:true});

		this.resetRedirects();
	};

	keepQuotationLockedAndContinue = () => { 

		const { updateIsQuotationLockedByMe } = this.context;	
		updateIsQuotationLockedByMe(false);	
		this.setState({showWarningAddQuotationPopup:false});
	}

	handleLockedQuotation = () => {

		const { updateIsQuotationLockedByMe } = this.context;	
		updateIsQuotationLockedByMe(false);		
		this.unlockQuotation(); 
		
	}

	unlockQuotation = () => {
		this.setState({ loading: true });

		const { selectedQuotationId } = this.context;

		saveQuotationLockStatus(selectedQuotationId, "")
		.then((response) => {
			setTimeout(() => {this.setState({showWarningAddQuotationPopup:false})}, 500);
			this.setState({loading: false});
		})
		.catch((reason) => { this.handleError(reason); })
	}

	/**
	 * Resets the redirect variables with a delay, so that the page has time to navigate before resetting.
	 */
	resetRedirects = () => {
		setTimeout(() => {
			this.setState({ redirectToConditions: false, redirectToEmailConfiguration:false, redirectToOpportunitySearch:false})}, 500);
	}

	render() {
		const { userRoleDetail } = this.context;
		return (
			<React.Fragment>			
				<div className='sidebar'>
					<div row="true">
						<br />

						{hasEditAccess(userRoleDetail,"Quotation") && 

							<div className='sidebar-button' onClick={this.onClickAddNewQuotationButton}>
								<span className='sidebar-button-plus-icon'>+</span>
							</div>
						}

						<br />
	
						<div className="subnav">
							<button className="subnavbtn">
								<div className='settings-icon'/>			                             
								<div className="subnav-content">

									{hasEditAccess(userRoleDetail,"EmailConfiguration") &&
											<div className='email-configuration-icon' onClick={this.onClickEmailConfigurationButton}/>		 	
									}

									<div className='terms-and-conditions-icon' onClick={this.onClickConditionsButton}/>
								</div>
							</button>
						
						</div>
					</div>			
				</div>
				
					{this.state.redirectToEmailConfiguration && (<Redirect  to={{ pathname: '/emailConfiguration/view'}}/>)}	

					{this.state.redirectToConditions && (<Redirect to={{pathname: '/quotationCondition/view'}}/>)}	

					{this.state.redirectToOpportunitySearch && (<Redirect to={{pathname: '/opportunity/search'}}/>)}	

				<div>
					<ConfirmationDialog
						isModelOpen={this.state.showWarningAddQuotationPopup}
						modalSize=''
						headerText={i18n.t('WARNING_QUOTATION_LOCKED_HEADER')}
						confirmationText={i18n.t('CONFIRMATION_QUOTATION_LOCKED')}
						okText={i18n.t('LOCK')}
						onOk={this.keepQuotationLockedAndContinue}
						cancelText={i18n.t('UNLOCK')}
						onCancel={this.handleLockedQuotation}
					/>
				</div>
			</React.Fragment>
		);
	}
}
Sidebar.contextType = QuotationToolContext;
