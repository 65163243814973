import { AuthError } from 'msal';
import { toast } from 'react-toastify';
import { authProvider } from '../auth/authProvider';

const oauth2Config = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authorizationEndpoint: process.env.REACT_APP_API_AUTH_URL,
    tokenEndpoint: process.env.REACT_APP_API_TOKEN_URL,
    scope:process.env.REACT_APP_SCOPE_ID_URI,
    authority: process.env.REACT_APP_AUTHORITY_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL
};

function addContentLanguageHeader(language) {
	return { headers: { 'Content-Language': language ?? 'nl-BE' } }
}

function initializeOptions(options) {

	// Initialize Options object
	if (!options) {
		options = {};
	}

	// Initialize Options.Headers object
	if (!options.headers) {
		options.headers = {};
	}
	
	return options;
}

// ------------------------------
// -- Auth code flow with PKCE --
// ------------------------------

function generateCodeVerifier() {
    const array = new Uint32Array(56/2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

function generateCodeChallenge(verifier) {
    return crypto.subtle.digest('SHA-256', new TextEncoder().encode(verifier))
        .then(buffer => {
            const hash = new Uint8Array(buffer);
            return btoa(String.fromCharCode.apply(null, hash))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        });
}

const codeVerifier = generateCodeVerifier();

async function authorize(){

	generateCodeChallenge(codeVerifier).then(codeChallenge => {

		const queryString = "?response_type=code&client_id=" 
		+ oauth2Config.clientId + "&redirect_uri=" 
		+ encodeURIComponent(oauth2Config.redirectUri) +"&scope=" 
		+ encodeURIComponent(oauth2Config.scope) + "&code_challenge=" 
		+ codeChallenge + "&code_challenge_method=S256";
	
		localStorage.setItem('codeVerifier', codeVerifier);

		console.log('authUrl:', oauth2Config.authorizationEndpoint + queryString)

		window.location.href = oauth2Config.authorizationEndpoint + queryString;
	});
}

async function getOrRefreshToken(withRefresh){

	const urlParams = new URLSearchParams(window.location.search);
	const authorizationCode = urlParams.get('code');
	const codeVerifier = localStorage.getItem('codeVerifier');

	const params = new URLSearchParams();
	params.append('grant_type', withRefresh ? 'refresh_token' : 'authorization_code');
	params.append('client_id', oauth2Config.clientId);
	params.append('redirect_uri', oauth2Config.redirectUri);

	if(withRefresh)
	{
		params.append('refresh_token', localStorage.getItem('refreshToken'));
	}
	else{
		params.append('code', authorizationCode);
		params.append('code_verifier', codeVerifier);
	}

	fetch(oauth2Config.tokenEndpoint, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: params
	})
	.then(response => response.json())
	.then(data => {

		// Set all token data in local cache

		const expiresAt = Date.now() + (data.expires_in * 1000);
		localStorage.setItem('accessToken', data.access_token);
		localStorage.setItem('refreshToken', data.refresh_token);
		localStorage.setItem('expiresAt', expiresAt);

	})
	.catch(error => {
		console.error('Error:', error);
	});
}

async function getoauth2AccessToken()
{
	const expiresAt = localStorage.getItem('expiresAt');

	if(Date.now() >= expiresAt)
	{
		authorize();
		getOrRefreshToken(expiresAt == null ? false : true);
	}

	return localStorage.getItem('accessToken');
}

// ----------------------------------
// -- End Auth code flow with PKCE --
// ----------------------------------

async function getAccessToken()
{
	const account = authProvider.getAccountInfo();

	try {
		return await authProvider.acquireTokenSilent({
			account,
			scopes:[process.env.REACT_APP_SCOPE_ID_URI]
		});

	} catch (error) {
		if(typeof(error) === typeof(AuthError))
		{
			return await authProvider.acquireTokenPopup({
				account,
				scopes:[process.env.REACT_APP_SCOPE_ID_URI]
			});
		}
	}
}

/**
 * Sends a request to the QT Function API.
 * https://axios-http.com/docs/res_schema
 * @param  {[string]} route The target route (base address not needed) 
 * @param  {[object]} options A request initialisation object containing: headers, httpmethod, ...
 * @return {[Promise<Response>]} A response containing a message and / or a json body.
 */
async function sendAjaxRequest(route, options) {	
	const accessToken = await getAccessToken();

	const { REACT_APP_API_BASEURL } = process.env;

	let formattedURL = REACT_APP_API_BASEURL + route

	options.mode = 'cors';
	options = initializeOptions(options);

	if(accessToken)
	{		
	   options.headers['Authorization'] = "Bearer "+accessToken.idToken.rawIdToken;		 
	}
	return await 
		fetch(formattedURL, options)
		.then((response) => 
		{ 
			return response.json();
		 })
		.catch((error) => 
		{ 
			if(error.response) // status code buiten 2xx range:	
			{
				console.error("API response:", error.response.status + ": " + error.message);

			} else if (error.request) {

				console.error("API request:", error.request);
			} else {

				console.error('API Error: ', error.message);
			}
		});
}

/**
 * Sends a POST request to the QT Function API.
 * @param  {[string]} route The target route (base address not needed) 
 * @param  {[object]} options A request initialisation object containing: headers, httpmethod, ...
 * @return {[Promise<Response>]} A response containing a message and / or a json body.
 */
export const postRequest = async(route, options)=> {	
	
	options = initializeOptions(options);

	options.headers['Content-Type'] = 'application/json';
	options.method = 'POST'
	
	return await sendAjaxRequest(route, options);
}

/**
 * Sends a PATCH request to the QT Function API.
 * @param  {[string]} route The target route (base address not needed) 
 * @param  {[object]} options A request initialisation object containing: headers, httpmethod, ...
 * @return {[Promise<Response>]} A response containing a message and / or a json body.
 */
export const patchRequest = async(route, options)=> {	
	
	options = initializeOptions(options);

	options.headers['Content-Type'] = 'application/json';
	options.method = 'PATCH'
	
	return await sendAjaxRequest(route, options);
}

/**
 * Sends a GET request to the QT Function API.
 * @param  {[string]} route The target route (base address not needed) 
 * @param  {[object]} options A request initialisation object containing: headers, httpmethod, ...
 * @return {[Promise<Response>]} A response containing a message and / or a json body.
 */
export const getRequest = async(route, options)=> {	

	options = initializeOptions(options);

	options.method = 'GET'
	
	return await sendAjaxRequest(route, options);
}

/**
 * Sends a DELETE request to the QT Function API.
 * @param  {[string]} route The target route (base address not needed) 
 * @param  {[object]} options A request initialisation object containing: headers, httpmethod, ...
 * @return {[Promise<Response>]} A response containing a message and / or a json body.
 */
export const deleteRequest = async(route, options)=> {	

	options = initializeOptions(options);

	options.headers['Content-Type'] = 'application/json';
	options.method = 'DELETE'
	
	return await sendAjaxRequest(route, options);
}

////////////////////////////////////////////////////////////////////////
// QT api requests
////////////////////////////////////////////////////////////////////////

// ----------------------
// -- Company Endpoint --
// ----------------------

export const saveDefaultCondition = async(salesOrganisation, data) => {
	return await postRequest('/company/defaultcondition/' + salesOrganisation, 
	{
		body: JSON.stringify(data)
	})
}

export const getDefaultConditions = async(salesOrganisation, language) => {
	return await getRequest('/company/conditions/' + language + '/' + salesOrganisation);
}

export const getConditionImages = async() => {
	return await getRequest('/company/conditionimages');
};

export const getSalesOrganisations = async() => {
	return await getRequest('/company/salesorganization');
}

export const getActivePriceLists = async(language, quotationDate) => {
	return await getRequest('/company/pricelists/active/' + quotationDate, addContentLanguageHeader(language));
}

export const getPriceLists = async(language) => {
	return await getRequest('/company/pricelist', addContentLanguageHeader(language));
}

export const getEmailConfigurations = async() => {
	return await getRequest('/company/emailconfigurations');
}

export const saveEmailConfiguration = async(data) => {
	return await postRequest('/company/saveemailconfiguration', 
	{
		body: JSON.stringify(data)
	})
}

export const getStandardNotes = async(language) => {
	return await getRequest('/company/standardnotes/'+ language);
}

export const getRevenueInformation = async(quotationId, language) => {
	return await getRequest('/company/getreportoverview/'+ quotationId, addContentLanguageHeader(language));
}

// -------------------
// -- User Endpoint --
// -------------------

export const getUsers = async() => {
	return await getRequest('/users');
}

export const getUserPreferencedLanguage = (email) => {
	return getRequest('/users/userlanguage/' + email);
}

export const saveUserPreferencedLanguage = async(email, language) => {
	return await patchRequest('/users/userlanguage/' + email + '/' + language);
}

export const getUserRoleDetails = async(role) => {
	return await getRequest('/users/userroledetail/' + role);
}

export const getInternalSalesUsers = async() => {
	return await getRequest('/users/internalsales');
}

export const getOwnerUsers = async() => {
	return await getRequest('/users/owners');
}

// Usertraces

export const getUserTracesForAQuotation = async(quotationId) => {
	return await getRequest('/users/usertraces/' + quotationId);
}

export const getApprovalNeededUserTraces = async(quotationId) => {
	return await getRequest('/users/approvalusertraces/' + quotationId);
}

export const getUserTraces = async(entity, eventType, referenceId) => {
	return await getRequest('/users/usertrace/' + entity + '/' + eventType + '/' + referenceId);
}


// ------------------
// -- CRM Endpoint --
// ------------------

export const getOpportunitiesBySearch = async(searchString) => {
	return await getRequest('/crm/opportunity/' + searchString);
}

export const getOpportunityById = async(opportunityId) => {
	return await getRequest('/crm/opportunitydetails/' + opportunityId);
}

export const sendPDFtoCRM = async(data) => {
	return await postRequest('/crm/pdf/send',
	{ 
		body: JSON.stringify(data)
	})
}

export const sendRevenuetoCRM = async(data, language) => {
	return await postRequest('/crm/revenue/send',
	{ 
		body: JSON.stringify(data),
		headers: { 'Content-Language': language ?? 'nl-BE' }
	})
}

// --------------------------
// -- Supplements Endpoint --
// --------------------------

export const getSupplements = async(quotationId) => {
	return await getRequest('/supplements/' + quotationId);
}

export const saveSupplements = async(data) => {
	return await postRequest('/supplements/save',
	{ 
		body: JSON.stringify(data)
	})
}

// -----------------------
// -- Discount Endpoint --
// -----------------------

export const refreshAccountDiscounts = async(data) => {
	return await postRequest('/discounts/accountdiscounts/refresh',
	{ 
		body: JSON.stringify(data)
	})
}

export const saveAccountDiscounts = async(data) => {
	return await postRequest('/discounts/accountdiscounts/save',
	{ 
		body: JSON.stringify(data)
	})
}

export const applyInstallationDiscount = async(data) => {
	return await patchRequest('/discounts/installationdiscount/apply',
	{ 
		body: JSON.stringify(data)
	})
}

export const applyManualDiscount = async(data) => {
	return await patchRequest('/discounts/manualdiscount/apply',
	{ 
		body: JSON.stringify(data)
	})
}

export const applyManualDiscountForAll = async(data) => {
	return await patchRequest('/discounts/manualdiscount/applyForAll',
	{ 
		body: JSON.stringify(data)
	})
}

export const applyAccountDiscount = async(data, accountNumber, quotationLineId, language) => {
	return await postRequest('/discounts/accountdiscounts/' + accountNumber + "/" + quotationLineId +'/apply',
	{ 
		body: JSON.stringify(data),
		headers: { 'Content-Language': language ?? 'nl-BE' }
	})
}

export const processDiscountRequests = async(data) => {
	return await postRequest('/discounts/discountrequests/process',
	{ 
		body: JSON.stringify(data)
	})
}

export const notifyOfDiscountRequests = async(data) => {
	return await postRequest('/discounts/discountrequests/notify',
	{ 
		body: JSON.stringify(data)
	})
}

// -------------------
// -- Econ Endpoint --
// -------------------

export const createSession = async(data) => {
	return await postRequest('/econ/createsession',
	{ 
		body: JSON.stringify(data)
	})
}

// ----------------------
// -- Exports Endpoint --
// ----------------------

export const exportQuotationLineDoorwaysExcel = async(quotationLineId, language) => {
	return await postRequest('/exports/' + quotationLineId + '/doorways',
	{ 
		headers: { 'Content-Language': language ?? 'nl-BE' }
	})
}

export const exportQuotationByTypeAndLayout = async(quotationId, type, language, layout) => {
	return await getRequest('/exports/' + quotationId + '/' + type + '/' + language + '/' + layout);
}

// ----------------------
// -- Imports Endpoint --
// ----------------------

export const importQuotationLineDoorwaysExcel = async(data, language) => {
	return await postRequest('/imports/quotationline/doorways',
	{ 
		body: data, // No json parsing => read excel
		headers: { 'Content-Language': language ?? 'nl-BE' }
	})
}

// ----------------------
// -- Pricing Endpoint --
// ----------------------

export const recalculateQuotationPrices = async(quotationId) => {
	return await getRequest('/pricing/quotation/recaculculate/' + quotationId);
}

export const overrideQuotationLineDetailNetPrice = async(quotationId, netPrice) => {
	return await patchRequest('/pricing/quotationlinedetail/' + quotationId + '/overridenetprice',{
		body: JSON.stringify({netPrice})
	});
}

export const overrideQuotationLineDetailDiscount = async(quotationId, discount) => {
	return await patchRequest('/pricing/quotationlinedetail/' + quotationId + '/overridediscount', {
		body: JSON.stringify({discount})
	});
}

export const applyRateChangeToQuotation = async(quotationId, type, value) => {
	return await postRequest('/pricing/quotation/' + quotationId + '/applyratechange', {
		body: JSON.stringify({
			RateChangeType:type,
			Value:value
		 })
	});
}

export const getPriceListFreeItemsByFilter = async(username, language, pricelistId, query) => {
	return await getRequest('/pricing/'+ username + '/' + language + '/' + pricelistId + '?'+ (query ? '&searchText=' + query : ''));
}

// -------------------------
// -- Quotations Endpoint --
// -------------------------

export const getQuotation = async(quotationId, language) => {
	return await getRequest('/quotations/' + quotationId, addContentLanguageHeader(language));
}

export const getQuotationConditions = async(quotationId, language) => {
	return await getRequest('/quotations/' + quotationId + '/conditions/' + language);
}

export const saveQuotationConditions = async(quotationId, exportLayout,  data) => {
	return await postRequest('/quotations/' + quotationId + '/conditions/' + exportLayout + '/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const getQuotationSegments = async(quotationId, accountNumber, opportunityNumber) => {
	return await getRequest('/quotations/' + quotationId + '/segments/' + accountNumber + '/' + opportunityNumber);
}

export const saveQuotationSegments = async(data) => {
	return await postRequest('/quotations/quotation/segments/save', 
	{ 
		body: JSON.stringify(data)
	})
}

/**
 * Returns all statusses for the given quotation.
 * @param  {[Number]} quotationId The ID of the given Quotation.
 * @return {[Promise<Response>]} A response containing a list of statusses.
 */
 export const getQuotationStatus = async(quotationId) => {
	return await getRequest('/quotations/' + quotationId + '/statusses');
}

export const saveQuotationStatus = async(data) => {
	return await postRequest('/quotations/quotation/status/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const getQuotationVersions = async(quotationUniqueId) => {
	return await getRequest('/quotations/' + quotationUniqueId + '/versions');
}

export const saveQuotationVersion = async(quotationId, quotationUniqueId) => {
	return await postRequest('/quotations/' + quotationUniqueId + '/versions/' + quotationId + '/save')
}

export const getRecentQuotations = async(userId) => {
	return await getRequest('/quotations/recent/'+ userId);
}

export const getDashboardQuotations = async(query, salesOrganisationId) => {
	return await getRequest('/quotations/bydashboardfilter?'+ (query ? '&searchText=' + query : '') +'&salesOrgId=' + salesOrganisationId);
}

export const getSecondaryContacts = async(quotationId, language) => {
	return await getRequest('/quotations/'+ quotationId + '/secondarycontacts', addContentLanguageHeader(language));
}

export const saveQuotation = async(data) => {
	return await postRequest('/quotations/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const cloneQuotation = async(quotationId) => {
	return await postRequest('/quotations/' + quotationId + '/clone')
}

export const copyQuotation = async(quotationId, targetQuotationId) => {
	return await postRequest('/quotations/' + quotationId + '/copyTo/' + targetQuotationId)
}

export const deleteQuotation = async(quotationUniqueId) => {
	return await deleteRequest('/quotations/' + quotationUniqueId + '/delete')
}

export const saveSecondaryContacts = async(data) => {
	return await postRequest('/quotations/quotation/secondarycontacts/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const updateSecondaryContacts = async(quotationId, data) => {
	return await postRequest('/quotations/' + quotationId + '/secondarycontacts/update', 
	{
		body: JSON.stringify(data)
	})
}

export const saveQuotationLockStatus = async(quotationId, lockedBy) => {
	return await patchRequest('/quotations/quotation/lockedstatus/save', 
	{
		body: JSON.stringify({
			QuotationId: quotationId,
			LockedBy: lockedBy
		})
	})
}

export const saveQuotationVersionDescription = async(data, quotationId) => {
	return await postRequest('/quotations/'+ quotationId + '/versiondescription/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const saveQuotationNotes= async(data) => {
	return await postRequest('/quotations/quotation/notes/save', 
	{ 
		body: JSON.stringify(data)
	})
}

// -----------------------------
// -- QuotationLines Endpoint --
// -----------------------------

export const saveQuotationLine = async(data) => {
	return await postRequest('/quotationlines/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const cloneQuotationLine = async(quotationLineId) => {
	return await postRequest('/quotationlines/' + quotationLineId + '/clone')
}


export const saveAlternativeConfiguration = async(data, quotationLineId) => {
	return await postRequest('/quotationlines/' + quotationLineId + '/alternative/configuration/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const saveAlternative = async(data, quotationLineId) => {
	return await postRequest('/quotationlines/' + quotationLineId + '/alternative/save', 
	{ 
		body: JSON.stringify(data)
	})
}

export const updateAlternative = async(data) => {
	return await patchRequest('/quotationlines/quotationline/alternative/update', 
	{ 
		body: JSON.stringify(data)
	})
}

export const swapAlternative = async(quotationLineId) => {
	return await postRequest('/quotationlines/' + quotationLineId + '/alternative/swap')
}

export const deleteQuotationLine = async(quotationLineId) => {
	return await deleteRequest('/quotationlines/' + quotationLineId + '/delete')
}

export const getQuotationLinesForQuotation = async(quotationId) => {
	return await getRequest('/quotationlines/quotation/'+ quotationId);
}

export const getValidQuotationLineSearchParameters = async() => {
	return await getRequest('/quotationlines/searchparameters');
}

export const getQuotationLinesBySearchParameters = async(quotationId, data) => {
	return await postRequest('/quotationlines/searchparameters/'+ quotationId, 
	{ 
		body: JSON.stringify(data)
	});
}

export const getQuotationLinesBySimpleSearch = async(quotationId, data) => {
	return await postRequest('/quotationlines/simplesearch/'+ quotationId, 
	{ 
		body: JSON.stringify(data)
	});
}

export const getDetailsForQuotationLine = async(quotationLineId) => {
	return await getRequest('/quotationlines/' + quotationLineId + '/details');
}

export const sequenceQuotationLines = async(quotationId, quotationLineId, direction) => {
	return await postRequest('/quotationlines/' + quotationId + '/' + quotationLineId +  '/sequence/' + direction)
}

export const saveQuotationLineNotes= async(data) => {
	return await postRequest('/quotationlines/notes/save', 
	{ 
		body: JSON.stringify(data)
	})
}


// ------------------------
// -- Technical Endpoint --
// ------------------------

export const getJsonPDF = async(quotationId, language, layout) => {
	return await getRequest('/technical/jsonpdf/' + quotationId + '/' + language + '/' + layout);
}

// ---------------------------
// -- Translations Endpoint --
// ---------------------------

export const getTranslationsByKey = async(key) => {
	return await getRequest('/translations/key/' + key);
}

export const getTranslationsByLanguage = async(language) => {
	return await getRequest('/translations/language/' + language);
}

export const saveTranslations = async(data) => {
	return await postRequest('/translations/save', 
	{ 
		body: JSON.stringify(data)
	})
}

// --------------------------
// -- Validations Endpoint --
// --------------------------

export const validateXmlExport = async(quotationId) => {
	return await getRequest('/validations/xml-export/' + quotationId + '/validate');
}


