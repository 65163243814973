import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import configEnglish from './lang/en-GB.json';
import configDutch from './lang/nl-NL.json';
import configBelgium from './lang/nl-BE.json';
i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			'en-GB': {
				translations: configEnglish
			},
			'nl-NL': {
				translations: configDutch
			},
			'nl-BE': {
				translations: configBelgium
			}
		},
		fallbackLng: 'nl-BE',
		debug: true,
		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
