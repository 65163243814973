import React from 'react';
import {round} from '../common/tools';
import {FormattedNumber} from 'react-intl';

class PriceComponent extends React.Component {

    render() {
      return <React.Fragment>

        {/*€ sign*/}
        &euro;{' '}

        {/*Two point decimal value*/}
				<FormattedNumber value={round(this.props.value, 2)} style={'decimal'} />

        {/*Show asterix at end for incomplete prices*/}
				{this.props.incompletePriceStateId ? '*' : ''}

      </React.Fragment>  
    }
}
export default PriceComponent;
