
import { authProvider } from '../auth/authProvider';
import { toast } from 'react-toastify';
import i18n from 'i18next';

export function cloneArray(src) {
	return JSON.parse(JSON.stringify(src));
}

export function hasEditAccess(roleDetails , area){
	if(roleDetails == null || roleDetails.length === 0) return false;
	if(roleDetails.find(x=>x.Area === area))
	{
		return roleDetails.find(x=>x.Area === area).Permission === "Edit";
	}
	return false;
}

export function handleError(reasonPhrase){
	toast.error(i18n.t('SOMETHING_WENT_WRONG'));
	console.error(reasonPhrase)
}

export function getDistinctOrganisation (array) {
	const result = [];
	const map = new Map();
	for (const item of array) {

		if(!map.has(item.Id)){
			map.set(item.Id, true);    // set any value to Map
			result.push({
				Id: item.Id,
				Name: item.Name,
				CountryCode : item.CountryCode,
				DefaultExportLayout : item.DefaultExportLayout,
				InstallationRequired:item.InstallationRequired,
				BusinessId: item.BusinessId
			});
		}
	}
	return result;
};

export function isNumeric(str) {
	if (typeof str != "string") return false // we only process strings!  
	return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
		   !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }