import React, { Component } from 'react';
import { QuotationToolContext } from '../../Providers';
import { hasEditAccess, getDistinctOrganisation } from '../../Utils/commonFunctions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Trans } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './ExportQuotation.scss';
import Loader from 'react-loader-spinner';
import { Redirect } from 'react-router-dom';
import i18n from 'i18next';
import ConfirmationDialog from './ConfirmationDialog';
import { getSalesOrganisations, sendPDFtoCRM, exportQuotationByTypeAndLayout, getQuotationConditions, 
	getDefaultConditions, saveQuotationConditions, getConditionImages } from '../../Utils/apiCalls';

export default class ExportQuotation extends Component {
	state = {
		languageDDIsOpen: false,
		SelectedExportType: 'pdf',
		loading: false,
		condition1: '',
		condition2: '',
		condition3: '',
		condition4: '',
		SelectedLanguage: { Id:0, Name:null, Code:null},
		Language: [
			{ Id: 1, Name: 'Nederlands(België)', Code: 'nl-BE' },
			{ Id: 2, Name: 'Nederlands(Nederland)', Code: 'nl-NL' },
			{ Id: 3, Name: 'Frans', Code: 'fr-BE' },
			{ Id: 4, Name: 'Engels', Code: 'en-GB' }
		],
		azureImages: [{ ImageName: null, ImageURL: null }],
		Layout: [
			{ Id: 1, Name: 'BE-standard' },
			{ Id: 2, Name: 'NL-standard' }
		],
		SelectedLayout: null,
		isLayoutDropDownOpen: false,
		isCondition1DropDownOpen: false,
		isCondition2DropDownOpen: false,
		isCondition3DropDownOpen: false,
		isCondition4DropDownOpen: false,
		selectedCondition1Image: { Id: 0, ImageName: null, ImageURL: null },
		selectedCondition2Image: { Id: 0, ImageName: null, ImageURL: null },
		selectedCondition3Image: { Id: 0, ImageName: null, ImageURL: null },
		selectedCondition4Image: { Id: 0, ImageName: null, ImageURL: null },
		isPreviewPopUpOpen: false,
		selectedImage: '',
		showQuotationViewPage: false,
		showSendToCRMPopup: false,
		accountName: '',
		contactPerson: '',
		contactEmail: ''
	};

	componentDidMount = () => {

		const { exportData } = this.context;
		let accountLanguage = this.state.Language.find((x) => x.Code == exportData.Language);
		this.setState({ SelectedLanguage: accountLanguage});
		this.getImages(accountLanguage.Code);
		this.props.setWindowPage(false);
	};
	
	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

	openPreviewPopUp = (imgURL) => {
		this.setState({ isPreviewPopUpOpen: true, selectedImage: imgURL });
	};
	closePreviewPopUp = () => {
		this.setState({ isPreviewPopUpOpen: false, selectedImage: '' });
	};

	getImages = (language) => {

		this.setState({ loading: true });

		getConditionImages(language)
		.then((responseData) => {
				responseData.unshift({ ImageName: null, ImageURL: null });
				this.setState({azureImages: responseData,loading: false});
				this.setDefaultLayout();
				const { selectedQuotationId } = this.context;
				this.getExportText(selectedQuotationId, language);
			})
			.catch((reason) => {this.handleError(reason)});
	};
	onExportTypeChanged = (selectedExportType) => {
		this.setState({ SelectedExportType: selectedExportType });
	};
	toggleLanguageDD = () => {
		this.setState({
			languageDDIsOpen: !this.state.languageDDIsOpen
		});
	};

	getExportText = (quotationId, language) => {
		this.setState({ loading: true });

		getQuotationConditions(quotationId, language)
		.then((responseData) => {
				this.setState({
					condition1: responseData.QuotationCondition.Condition1 ?? '',
					condition2: responseData.QuotationCondition.Condition2 ?? '',
					condition3: responseData.QuotationCondition.Condition3 ?? '',
					condition4: responseData.QuotationCondition.Condition4 ?? '',
					selectedCondition1Image:
						this.state.azureImages.find(
							(x) => x.ImageName == responseData.QuotationCondition.ConditionImg1
						) ?? this.state.azureImages.find((x) => x.ImageName == null),
					selectedCondition2Image:
						this.state.azureImages.find(
							(x) => x.ImageName == responseData.QuotationCondition.ConditionImg2
						) ?? this.state.azureImages.find((x) => x.ImageName == null),
					selectedCondition3Image:
						this.state.azureImages.find(
							(x) => x.ImageName == responseData.QuotationCondition.ConditionImg3
						) ?? this.state.azureImages.find((x) => x.ImageName == null),
					selectedCondition4Image:
						this.state.azureImages.find(
							(x) => x.ImageName == responseData.QuotationCondition.ConditionImg4
						) ?? this.state.azureImages.find((x) => x.ImageName == null),
					loading: false
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};

	saveExportCondition = (saveAndGeneratePDF, saveAndSendToCRM) => {
		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;
		const placeholder = '##IMAGE##';
		let condition1 = this.state.condition1;
		let condition2 = this.state.condition2;
		let condition3 = this.state.condition3;
		let condition4 = this.state.condition4;
		if (
			this.state.selectedCondition1Image.ImageURL &&
			condition1.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition1 += '\n' + placeholder;
		}
		if (
			this.state.selectedCondition2Image.ImageURL &&
			condition2.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition2 += '\n' + placeholder;
		}
		if (
			this.state.selectedCondition3Image.ImageURL &&
			condition3.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition3 += '\n' + placeholder;
		}
		if (
			this.state.selectedCondition4Image.ImageURL &&
			condition4.toLowerCase().indexOf(placeholder.toLowerCase()) == -1
		) {
			condition4 += '\n' + placeholder;
		}

		let data = {
			Condition1: condition1,
			Condition2: condition2,
			Condition3: condition3,
			Condition4: condition4,
			ConditionImg1: !this.state.selectedCondition1Image.ImageURL
				? null
				: this.state.selectedCondition1Image.ImageName,
			ConditionImg2: !this.state.selectedCondition2Image.ImageURL
				? null
				: this.state.selectedCondition2Image.ImageName,
			ConditionImg3: !this.state.selectedCondition3Image.ImageURL
				? null
				: this.state.selectedCondition3Image.ImageName,
			ConditionImg4: !this.state.selectedCondition4Image.ImageURL
				? null
				: this.state.selectedCondition4Image.ImageName,
			Language: this.state.SelectedLanguage.Code
		};

		saveQuotationConditions(selectedQuotationId, this.state.SelectedLayout, data)
		.then((responseData) => {
				this.setState({ loading: false,showQuotationViewPage:true });
				if (saveAndGeneratePDF) this.getDocumentURL();
				else if (saveAndSendToCRM) this.sendToCRM();
				else this.setState({ showQuotationViewPage: true });
			})
			.catch((reason) => {this.handleError(reason)});
	};
	download = (dataurl, filename) => {
		if (dataurl && dataurl.documentURL) {
			var a = document.createElement('a');
			a.href = dataurl.documentURL;
			a.target = '_blank';
			a.setAttribute('download', filename);
			a.click();
		}
	};
	getDocumentURL = () => {		
		const { exportData } = this.context;
		let languageKey = this.state.SelectedLanguage.Code;
		let exportType = this.state.SelectedExportType;
		let exportLayout = this.state.SelectedLayout;

		exportQuotationByTypeAndLayout(exportData.QuotationId.toString(), exportType, languageKey, exportLayout)
		.then((responseData) => {
				this.download(responseData, 'quotation.' + exportType);
				this.setState({ loading: false,showQuotationViewPage:true });
			})
			.catch((reason) => {this.handleError(reason)});
	};
	onSaveAndExport = () => {
		toast.warning(<Trans i18nKey='GENERATING_PDF'>GENERATING_PDF</Trans>);

		this.saveExportCondition(true, false);
	};
	onSave = () => {
		this.saveExportCondition(false, false);
	};
	onShowSendToCRMConfirmation = () => {
		const { exportData } = this.context;
		this.setState({
			showSendToCRMPopup: true,
			accountName: exportData.AccountName,
		});
	};

	onCancelSendToCRM = () => {
		this.setState({
			showSendToCRMPopup: false
		});
	};

	onSaveAndSendToCRM = () => {
		if (this.state.SelectedExportType === 'excel' && !this.isNijkerkUser()) return;
		toast.warning(<Trans i18nKey='GENERATING_PDF'>GENERATING_PDF</Trans>);
		this.saveExportCondition(false, true);
	};

	sendToCRM = () => {	
		const { selectedQuotationId, exportData,selectedRole } = this.context;		
		const { accountInfo } = this.context;
		let data = {
			QuotationId: selectedQuotationId,
			OpportunityNumber: exportData.OpportunityNumber,
			SelectedLanguage: this.state.SelectedLanguage.Code,
			ExportType: this.state.SelectedExportType,
			ExportLayout: this.state.SelectedLayout,
			SalesOrg: exportData.SalesOrganization,
			ContactEmail: !!exportData.ContactInformation[0] ?!!exportData.ContactInformation[0].Email : '',
			OwnerEmail: exportData.OwnerEmail,
			Version: exportData.VersionNumber,
			UserName: accountInfo.account.name,
			QuotationDescription: exportData.Title,
			ContactName: !!exportData.ContactInformation[0] ? exportData.ContactInformation[0].Name : '',
			UserRole:selectedRole,
			Environment : process.env.REACT_APP_ICON_ENVIRONMENT,
			CreatedBy : exportData.CreatedBy,
			InternalSalesName : exportData.InternalSalesName,
			ContactInformation : exportData.ContactInformation,
			Comments : i18n.t('QUOTATION_SENT_TO_CUSTOMER'),
			SentByGuid :accountInfo.account.accountIdentifier
			
		};

		sendPDFtoCRM(data)
		.then((response) => {
				toast.success(<Trans i18nKey='PDF_SEND_CRM_SUCCESSFUL'>PDF_SEND_CRM_SUCCESSFUL</Trans>);
				this.setState({ loading: false,showQuotationViewPage:true });
			
			})
			.catch((reason) => {this.handleError(reason)});
	};

	setDefaultLayout = () => {
		this.setState({ loading: true });
		getSalesOrganisations()
			.then((data) => {
				const { exportData } = this.context;
				let quotation = exportData;
				let distinctSalesOrganization = getDistinctOrganisation(data);
				let defaultExportLayout = distinctSalesOrganization.find((x) => x.Id == quotation.SalesOrganizationId);
				let defaultLayout = defaultExportLayout.DefaultExportLayout
					? defaultExportLayout.DefaultExportLayout
					: '';
				if (quotation.ExportLayout) {
					defaultLayout = quotation.ExportLayout;
				}

				this.setState({ 
					SelectedLayout: defaultLayout,
					 loading: false 
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};
	setLanguage = (language) => {
		const { selectedQuotationId } = this.context;

		this.setState({ SelectedLanguage: language});
		this.getExportText(selectedQuotationId, language.Code);
	};

	setSelectedLayout = (layout) => {
		this.setState({
			SelectedLayout: layout.Name
		});
	};
	setSelectedCondition1Image = (image) => {
		this.setState({
			selectedCondition1Image: image
		});
	};
	setSelectedCondition2Image = (image) => {
		this.setState({
			selectedCondition2Image: image
		});
	};
	setSelectedCondition3Image = (image) => {
		this.setState({
			selectedCondition3Image: image
		});
	};
	setSelectedCondition4Image = (image) => {
		this.setState({
			selectedCondition4Image: image
		});
	};
	toggleLayoutDropDown = () => {
		this.setState({
			isLayoutDropDownOpen: !this.state.isLayoutDropDownOpen
		});
	};
	toggleCondition1DropDown = () => {
		this.setState({
			isCondition1DropDownOpen: !this.state.isCondition1DropDownOpen
		});
	};
	toggleCondition2DropDown = () => {
		this.setState({
			isCondition2DropDownOpen: !this.state.isCondition2DropDownOpen
		});
	};
	toggleCondition3DropDown = () => {
		this.setState({
			isCondition3DropDownOpen: !this.state.isCondition3DropDownOpen
		});
	};
	toggleCondition4DropDown = () => {
		this.setState({
			isCondition4DropDownOpen: !this.state.isCondition4DropDownOpen
		});
	};

	onCancel = () => {
		this.setState({
			showQuotationViewPage: true
		});
	};

	onReset = () => {
		const { exportData } = this.context;

		// Reset language to account.
		this.setState({ loading: true, SelectedLanguage: this.state.Language.find((x) => x.Code == exportData.Language)});
		let salesOrganisation = exportData.SalesOrganization;
		
		getDefaultConditions(salesOrganisation, this.state.SelectedLanguage.Code)
		.then((data) => {
				this.setState({
					condition1: data.Condition1,
					condition2: data.Condition2,
					condition3: data.Condition3,
					condition4: data.Condition4,
					loading: false
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};

	getAllContactDetails() {
		const { exportData } = this.context;
		var result = '';
		for(var i = 0 ;i < exportData.ContactInformation.length;i++)
		{
			result = result + 	
			'\n' +	
			exportData.ContactInformation[i].Name +
			'\n' +
			exportData.ContactInformation[i].Email +
			'\n' 

		}
		return result;
	};

	isNijkerkUser = () => {
		const { selectedRole } = this.context;
		return selectedRole == 'TMIQuotationDesk' || selectedRole == 'TDSNQuotationDesk' || selectedRole == 'TDSNExternalSales' || selectedRole == 'TMIExternalSales';
	}

	renderAllExportOptions = () =>{

		const { selectedRole } = this.context;
		var exportOptions = [ {key:'pdf', text: 'PDF' },
		{key:'pdf-overview', text: 'PDF_OVERVIEW' }, 
		{key:'excel', text: 'EXCEL' }]

		if(this.isNijkerkUser())
		{
			exportOptions = exportOptions.filter((o) => o.key === 'excel');
		}
	
		return (
			<React.Fragment>
				{
					exportOptions.map(opt => 
						<div key={opt.key} className={opt.key === 'excel' ? 'col-6' : 'col-2'}>
							<input
								key={opt.key}
								id={opt.key}
								value={exportOptions.indexOf(opt.key) +1 }
								name='exportType'
								type='radio'
								className='width-left-second mt-1'
								onChange={() => this.onExportTypeChanged(opt.key)}
								checked={this.state.SelectedExportType === opt.key}
								/>
								<span className='add-quotation-line-content'>
									{i18n.t(opt.text)}
								</span>
						</div>)
				}

		</React.Fragment>)
	}

	render() {
		const { exportData, viewModeOnly, userRoleDetail } = this.context;
		return (
			<>
				<div className='mt-4'>
					<div className='view-quotation-panel-title mb-4'>
						<Trans i18nKey='OFFER_LC'>OFFER_LC</Trans> '{exportData != null && exportData.Title}'
					</div>
					<div className='language-panel container-fluid pb-2 pt-2'>
						<div>
							<div className='language-dropdown row'>
								<div className='add-quotation-line-title padding-top-title  export-title col-2'>
									<Trans i18nKey='LANGUAGE'>LANGUAGE</Trans>
								</div>
								<Dropdown
									isOpen={this.state.languageDDIsOpen}
									toggle={this.toggleLanguageDD}
									className='display-inline-export col-4'
									id={'openLanguage'}
								>
									<DropdownToggle caret>{this.state.SelectedLanguage.Name}</DropdownToggle>
									<DropdownMenu>
										{this.state.Language
											? this.state.Language.map((x, i) => {
													return (
														<DropdownItem
															id={'language' + i}
															onClick={() => this.setLanguage(x)}
															key={i}
														>
															{x.Name}
														</DropdownItem>
													);
											  })
											: ''}
									</DropdownMenu>
								</Dropdown>

								<div className='col-6'>
									<button
										onClick={() => this.onCancel()}
										type='button'
										className='btn opportunity-next-step-btn ml-2 button-reset'
									>
										<Trans i18nKey='CANCEL'>CANCEL</Trans>
									</button>

									<button
										className={
											viewModeOnly
												? 'btn btn-disabled ml-2 button-reset'
												: 'btn opportunity-next-step-btn ml-2 button-reset'
										}
										id={'save'}
										onClick={this.onSave}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									>
										<Trans i18nKey='SAVE_CONDITIONS'>SAVE_CONDITIONS</Trans>
									</button>
									
									<button
										onClick={() => this.onReset()}
										type='button'
										className={
											(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions'))
												? 'btn btn-disabled ml-2 button-reset'
												: 'btn opportunity-next-step-btn ml-2 button-reset'
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									>
										<Trans i18nKey='RESET'>RESET</Trans>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className='language-panel container-fluid pb-2 pt-2'>
						<div>
							<div className='language-dropdown row'>
								<div className='add-quotation-line-title padding-top-title  export-title col-2'>
									<Trans i18nKey='LAYOUT'>LAYOUT</Trans>
								</div>
								<Dropdown
									isOpen={this.state.isLayoutDropDownOpen}
									toggle={this.toggleLayoutDropDown}
									className='display-inline-export col-4'
								>
									<DropdownToggle caret>{this.state.SelectedLayout}</DropdownToggle>
									<DropdownMenu>
										{this.state.Layout
											? this.state.Layout.map((x, i) => {
													return (
														<DropdownItem
															id={'layout' + i}
															onClick={() => this.setSelectedLayout(x)}
															key={i}
														>
															{x.Name}
														</DropdownItem>
													);
											  })
											: ''}
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
					</div>
					<br />
					<div className='language-panel container-fluid pb-2 pt-2'>
						<div className='conditions'>
							<div className='condition1'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS1'>CONDITIONS1</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition1}
										onChange={(e) =>
											this.setState({
												condition1: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												isOpen={this.state.isCondition1DropDownOpen}
												toggle={this.toggleCondition1DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition1Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition1Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition1Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition1Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition1Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition1Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='condition2'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS2'>CONDITIONS2</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition2}
										onChange={(e) =>
											this.setState({
												condition2: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
												isOpen={this.state.isCondition2DropDownOpen}
												toggle={this.toggleCondition2DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition2Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition2Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition2Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition2Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition2Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition2Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='condition3'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS3'>CONDITIONS3</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition3}
										onChange={(e) =>
											this.setState({
												condition3: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
												isOpen={this.state.isCondition3DropDownOpen}
												toggle={this.toggleCondition3DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition3Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition3Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition3Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition3Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition3Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition3Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div className='condition4'>
								<div className='row'>
									<div className='col-12 add-quotation-line-title export-title '>
										<Trans i18nKey='CONDITIONS4'>CONDITIONS4</Trans>
									</div>
								</div>
								<div>
									<textarea
										id={'conditionTextArea'}
										rows='10'
										className='padding5px'
										value={this.state.condition4}
										onChange={(e) =>
											this.setState({
												condition4: e.target.value
											})
										}
										disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
									></textarea>
									<div className='image-section'>
										<div className='image-selection-section row'>
											<span className='col-5 add-quotation-line-title export-title '>
												<Trans i18nKey='CONDITIONIMG'>CONDITIONIMG</Trans>
											</span>
											<Dropdown
												disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')}
												isOpen={this.state.isCondition4DropDownOpen}
												toggle={this.toggleCondition4DropDown}
												className={
													'display-inline-export col-7' +
													(viewModeOnly || !hasEditAccess(userRoleDetail, 'Conditions')
														? ' disabled-dropdown'
														: '')
												}
											>
												<DropdownToggle caret>
													{this.state.selectedCondition4Image.ImageName ?? (
														<Trans i18nKey='SELECT_IMAGE'>SELECT_IMAGE</Trans>
													)}
												</DropdownToggle>
												<DropdownMenu>
													{this.state.azureImages
														? this.state.azureImages.map((x, i) => {
																return x.ImageURL ? (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition4Image(x)
																		}
																		key={i}
																	>
																		<div
																			className='dropdown-icon'
																			style={{
																				backgroundImage: `url(${x.ImageURL})`
																			}}
																		></div>
																		<div className='dropdown-text'>
																			{x.ImageName}
																		</div>
																	</DropdownItem>
																) : (
																	<DropdownItem
																		id={'layout' + i}
																		onClick={() =>
																			this.setSelectedCondition4Image(x)
																		}
																		key={i}
																	>
																		<div className='dropdown-text'>
																			<Trans i18nKey='SELECT_IMAGE'>
																				SELECT_IMAGE
																			</Trans>
																		</div>
																	</DropdownItem>
																);
														  })
														: ''}
												</DropdownMenu>
											</Dropdown>
										</div>
										{this.state.selectedCondition4Image.ImageURL && (
											<div className='preview'>
												<span>
													<Trans i18nKey='PREVIEW'>PREVIEW</Trans>
												</span>
												<div
													className='preview-image'
													style={{
														backgroundImage: `url(${this.state.selectedCondition4Image.ImageURL})`
													}}
													onClick={() =>
														this.openPreviewPopUp(
															this.state.selectedCondition4Image.ImageURL
														)
													}
												></div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<br />
									
					<div className='language-panel container-fluid pb-2 pt-2 mb-2'>
						<div className='row'>

							<div className='col-2 export-title add-quotation-line-title '>
								<Trans i18nKey='EXPORT'>EXPORT</Trans>
							</div>

							{this.renderAllExportOptions()}
							
						</div>
					</div>

					<div className='modal-footer-button mb-4'>
						<button
							className='btn opportunity-next-step-btn ml-2'
							id={'saveAndExport'}
							onClick={this.onSaveAndExport}
						>
							<Trans i18nKey='SAVE_AND_EXPORT'>SAVE_AND_EXPORT</Trans>
						</button>
						<button
							className={
								(this.state.SelectedExportType === 'excel' && !this.isNijkerkUser()) || viewModeOnly
									? 'btn btn-disabled ml-2'
									: 'btn opportunity-next-step-btn ml-2'
							}
							id={'saveAndSendToCRM'}
							onClick={this.onShowSendToCRMConfirmation}
							disabled={viewModeOnly || !hasEditAccess(userRoleDetail, 'ClientPdf')}
						>
							<Trans i18nKey='SAVE_AND_SEND_TO_CRM'>SAVE_AND_SEND_TO_CRM</Trans>
						</button>
					</div>
					<div>
						<ConfirmationDialog
							isModelOpen={this.state.showSendToCRMPopup}
							modalSize=''
							headerText={i18n.t('WARNING_HEADER')}
							confirmationText={								
								i18n.t('CONTINUE_TO_SEND_TO_CRM') +
								'\n' +
								this.state.accountName +
								this.getAllContactDetails()+
								 '\n' 								
							}
							okText={i18n.t('OK')}
							cancelText={i18n.t('CANCEL')}
							onOk={this.onSaveAndSendToCRM}
							onCancel={this.onCancelSendToCRM}
						/>
					</div>
					<Modal
						isOpen={this.state.isPreviewPopUpOpen}
						size={'lg'}
						className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
					>
						<ModalHeader>
							<Trans i18nKey='PREVIEW_IMAGE'>PREVIEW_IMAGE</Trans>
							<div className='modal-close' id='modal-close-econf' onClick={this.closePreviewPopUp}>
								x
							</div>
						</ModalHeader>
						<ModalBody>
							<div className='preview-popup-body'>
								<div
									className='preview-image-popup'
									style={{
										backgroundImage: `url(${this.state.selectedImage})`
									}}
								></div>
							</div>
						</ModalBody>
					</Modal>
					{this.state.loading && (
						<div className='fixed-bottom'>
							<Loader type='Bars' color='#ef2312' height={100} width={100} />
						</div>
					)}
					{this.state.showQuotationViewPage && (
						<Redirect
							to={{
								pathname: '/quotation/view'
							}}
						/>
					)}
				</div>
				<div>
					<ToastContainer
						position='top-center'
						autoClose={5000}
						className='toast'
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
			</>
		);
	}
}
ExportQuotation.contextType = QuotationToolContext;



