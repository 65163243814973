import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
export default class ConfirmationDialog extends Component {
	render() {
		const externalCloseBtn = (
			<button
				className='close'
				style={{
					position: 'absolute',
					top: '15px',
					right: '15px',
					display: 'none'
				}}
			>
				&times;
			</button>
		);
		return (
			<>
				<Modal
					isOpen={this.props.isModelOpen}
					external={externalCloseBtn}
					size={this.props.modalSize}
					className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
				>
					<ModalHeader>
						<div className='modal-title-text'>{this.props.headerText}</div>
						<div className='modal-close' id='closePopUp' onClick={this.props.onCancel}>
							x
						</div>
					</ModalHeader>
					<ModalBody>
						<div className='container-fluid pb-2 pt-2'>
							<div className='row'>
								<div className='col-12 confirmationBody'>{this.props.confirmationText}</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='modal-footer-button'>
							<button className='btn btn-enabled' id='okPopUp' onClick={this.props.onOk}>
								{this.props.okText}
							</button>
							<button className='btn btn-enabled ml-3' id='cancelPopUp' onClick={this.props.onCancel}>
								{this.props.cancelText}
							</button>
						</div>
					</ModalFooter>
				</Modal>
			</>
		);
	}
}
