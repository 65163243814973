import React, { Component } from 'react';
import './StandardNotes.scss';

export default class StandardNotes extends Component {
	render() {
		return (
			<>
				<div className='standardNotes'>
					{this.props.standardNotes &&
						this.props.standardNotes.map((x, i) => {
							return (
								<div className='standard-notes-content mb-1'>
									{x.Short}{' '}
									<div
										className='icon-add-search-filter'
										onClick={() => this.props.addStandardNotes(x.Notes)}
									></div>
								</div>
							);
						})}
				</div>
			</>
		);
	}
}
