export function getFormattedDateTime(date, selectedLanguage) {
	let separator = '.';
	if (selectedLanguage === 'en') {
		separator = '/';
	}
	let newDate = new Date(date);
	newDate = new Date(newDate.getTime());
	let datePart = newDate.getDate();
	let month = newDate.getMonth() + 1;
	let dateString =
		(datePart < 10 ? '0' + datePart : datePart) +
		separator +
		(month < 10 ? '0' + month : month) +
		separator +
		newDate.getFullYear();

	let hours = newDate.getHours();
	let minutes = newDate.getMinutes();
	let timeString = (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
	return dateString + ' - ' + timeString;
}

export function getFormattedDate(date, selectedLanguage) {
	let separator = '.';
	if (selectedLanguage === 'en') {
		separator = '/';
	}
	let newDate = new Date(date);
	newDate = new Date(newDate.getTime());
	let datePart = newDate.getDate();
	let month = newDate.getMonth() + 1;
	let dateString =
		(datePart < 10 ? '0' + datePart : datePart) +
		separator +
		(month < 10 ? '0' + month : month) +
		separator +
		newDate.getFullYear();
	return dateString;
}

/**
 * This function is a way of correctly rounding currency values.
 * @param {number} n 
 * @param {number} digits 
 * @returns The currency rounded to the amount of digits specified in the arguments.
 */
export function round(n, digits) {
	if (digits === undefined) {
		digits = 0;
	}

	var multiplicator = Math.pow(10, digits);
	n = parseFloat((n * multiplicator).toFixed(11));
	return Math.round(n) / multiplicator;
}