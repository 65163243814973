import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import './i18n';
import App from './App';
import { authProvider } from './auth/authProvider';
import 'typeface-roboto';
import 'bootstrap/dist/css/bootstrap.min.css';
ReactDOM.render(
	<AzureAD provider={authProvider} forceLogin={true}>
		{({ login, logout, authenticationState, error, accountInfo }) => {
		
			switch (authenticationState) {	
				case AuthenticationState.Authenticated:
					return <App accountInfo={accountInfo} />;
				case AuthenticationState.Unauthenticated:
				
					return (
						<div>
							<p>
								<span>An error occured during authentication, please try again! -- {error}</span>
							</p>
						</div>
					);
					
				case AuthenticationState.InProgress:
					
					return <p>Authenticating...</p>;

				default:
					return <p>Loading...</p>;
			}
		}}
	</AzureAD>,
	document.getElementById('root')
);
