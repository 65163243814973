import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { IntlProvider, FormattedNumber } from 'react-intl';
import './ViewQuotationIcons.scss';
import './ViewQuotation.scss';
import { getFormattedDate } from '../common/tools';
import { round } from '../common/tools';
import { getFormattedDateTime } from '../common/tools';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Trans } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { cloneArray, hasEditAccess } from '../../Utils/commonFunctions';
import { getUserTracesForAQuotation, getApprovalNeededUserTraces, getUserTraces, getQuotationStatus,getActivePriceLists,
	applyRateChangeToQuotation, validateXmlExport, getStandardNotes, getRevenueInformation, refreshAccountDiscounts, sendRevenuetoCRM,
	applyInstallationDiscount, applyAccountDiscount, getSupplements, processDiscountRequests, applyManualDiscount, applyManualDiscountForAll,
	saveSupplements, notifyOfDiscountRequests, createSession, exportQuotationLineDoorwaysExcel, exportQuotationByTypeAndLayout, getQuotation,
	importQuotationLineDoorwaysExcel, getQuotationSegments, deleteQuotation, saveQuotationStatus, saveQuotationVersionDescription,
	saveQuotationNotes, saveQuotationSegments, saveQuotationLine, cloneQuotationLine, saveAlternativeConfiguration, saveAlternative,
	swapAlternative, updateAlternative, deleteQuotationLine, getPriceListFreeItemsByFilter, getQuotationLinesForQuotation,
	getValidQuotationLineSearchParameters, getQuotationLinesBySearchParameters, getQuotationLinesBySimpleSearch, getDetailsForQuotationLine,
	sequenceQuotationLines, saveQuotationLineNotes, getJsonPDF, saveQuotationLockStatus  } from '../../Utils/apiCalls';
import i18n from 'i18next';
import ConfirmationDialog from './ConfirmationDialog';
import { QuotationToolContext } from '../../Providers';
import StandardNotes from './StandardNotes';
import PriceComponent from '../shared/PriceComponent';
import { Fragment } from 'react';

var closeAccordian = '&#9658;';
var openAccordian = '&#9660;';

export default class ViewQuotation extends Component {
	state = {
		flag: false,
		flagSecond: false,
		quotationData: null,
		quotationStatus: [],
		defaultQuotationStatus: null,
		iframeURL: '',
		showAddOrEditQuotationModal: false,
		showSearchPanel: false,
		showAddAlternative: false,
		showIndividualArticleModal: false,
		showSupplementModal: false,
		showExportModal: false,
		showIframeModal: false,
		lineDetailsTotal: 0,
		totalPrice: 0,
		loading: false,
		flagTextEnter: false,
		showAccountRefreshWarning:false,
		addQuotationTitle: '',
		addAlternativeTitle: '',
		quotationId: null,
		quotationLineId: null,
		selectedLineCount: 1,
		selectedSupplementLineCount: 1,
		selectedSearchFilters: 1,
		noOfIdenticalDoorWays: 1,
		noOfIndividualArticles: 1,
		noOfSupplements: 1,
		organizationList: [],
		refreshAsyncahead: false,
		quotationLines: [],
		quotationIconAttributes: {},
		individualArticleDetails: [
			{
				Name: '',
				Price: 0
			}
		],
		selectedIndividualArticle: [],
		selectedSupplementArticle: [],
		supplementList:[],
		selectedIndividualArticlePrice: '',
		selectedSupplementQuantity:'',
		selectedSupplementDiscount:'',
		selectedSupplementDiscription:'',
		selectedSupplementComment:'',
		selectedSupplementPrice:[],
		openIndividualArticle: false,
		openSupplementArticle : false,
		IsIndividualArticleSelected: false,
		IsSupplementSelected: false,
		isSupplementPriceSelected:false,
		priceListTypeAheadIsOpenSupplement:false,
		quotationLineList: [
			{
				DoorOpeningReference: '',
				Building: '',
				Floor: '',
				Apartment: ''
			}
		],

		selectedSupplementArticleList:[
			{ 
			ShortDescription:'',
			Short:'',
			Id:'',
			GrossBasePrice:''
		}
		],		
		isSupplementUserDefined:false,
		appliedSearchFiltersList: [
			{
				key: '',
				value: '',
				parentCategory: '',
				Id: 'filter0',
				optionDescription: ''
			}
		],
		exportName: 'Appartementsgebouw 20C - Zichem',

		PrefaceSelected: true,
		Preface: 'New text',

		Language: [
			{ Id: 1, Name: 'Nederlands(België)', Code: 'nl-BE' },
			{ Id: 2, Name: 'Nederlands(Nederland)', Code: 'nl-NL' },
			{ Id: 3, Name: 'Frans', Code: 'fr-BE' },
			{ Id: 4, Name: 'Engels', Code: 'en-US' }
		],
		SelectedNotesLanguage: '',
		isLoading: false,
		showVersionDescriptionEdit: false,
		bufferVersionDescription: '',
		quotationLineOldDiscountValue : 0,
		showCopyQuotationPopup: false,
		copyQuotationLineId: 0,
		editQuotationLineId: 0,
		editQuotationLineEconConfiId: '',
		showEditQuotationPopup: false,
		priceList: [],
		priceListSupplement: [],
		selectedPriceList: null,
		selectedPriceListSupplement: [],
		priceListDDIsOpen: false,
		priceListDDIsOpenSupplement:false,		
		individualArticleSearchText: '',
		supplementSearchText: '',
		showSwapAlternativePopUp: false,
		swapAlternative: null,
		deleteQuotationLineId: 0,
		showDeleteQuotationPopUp: false,
		showDeleteAlternativePopUp: false,
		showEditAlternativePopUp: false,
		editAlternativeTitle: '',
		editAlternativeData: null,
		alternativeQuotationLine: null,
		alternativeCount: 0,
		masterSearchFilterList: [],
		bufferSearchQuery: [],
		isSearchApplied: false,
		isApplyForExistingLines:false,
		editIndividualArticleData: null,
		editSupplementData: null,
		isManualEntryIndividualArticle: false,
		showAllHistory : true,
		isManualEntrySupplement: false,
		selectedProductType: 1,
		showPdfJsonModal:false,
		individualArticleValidations: {
			isArticleNotSelected: false,
			isArticleNameEmpty: false,
			isArticlePriceEmpty: false,
			isArticleQuantityEmpty: false
		},
		supplementValidations: {
			isSupplementNotSelected: false,
			isSupplementNameEmpty: false,
			isSupplementPriceEmpty: false,
			isSupplementQuantityEmpty: false
		},
		simpleSearchString: '',
		isSimpleSearchApplied: false,
		simpleSearchedString: '',
		isDoorWaysEmpty: false,
		isQuotationNotesPopUpOpen: false,
		quotationNotes: '',
		qLineDetailDiscountComments: '',
		qLineDetailNetPriceComments: '',
		headerDiscountComments: '',
		quotationLineIdForNotes: 0,
		quotationLineNotes: '',
		isQuotationLineNotesPopUpOpen: false,
		isQuotationInternalNotesPopUpOpen: false,
		quotationInternalNotes: '',
		showGeneralDiscountComments: false,
		generalDiscountComments: '',
		isHeaderKebabMenuOpen: false,
		shareQuotationURL: '',
		showDeleteConfirmationPopUp: false,
		showHeaderDiscountApprovalFlow:false,
		showQLDiscountApprovalFlow:false,
		showLockedQuotationConfirmation:false,
		quotationLineDetailIdForEmail:0,
		quotationLineIdForEmail:0,
		quotationLineSequenceNumber :'',
		quotationLineDetailOptionDescription:'',
		quotationLineDetailNewDiscount:0,
		userTraceDiscountData: [],
		userTracesQuotationData: [],
		userTracesFilteredQuotationData: [],
		userTracesApprovalNeededData: [],
		userTracesRejectedApprovalNeededData: [],
		showUserTraceDiscountPopUp: false,
		showAllUserTracesPopUp:false,
		showApprovalNeededUserTracesPopup:false,
		showDiscountHeaderPopUp: false,
		headerDiscount: [],
		headerDiscountNeedApprovalSegments:[],
		headerDiscountWithoutApprovalSegments:[],
		theumaSpecificHeaderDiscount :[],
		crmAccountDiscounts :[],
		tempHeaderDiscount :[],
		reportingOverview: [],
		showReportingOverview: false,
		isHeaderDiscountInvalid: false,
		manualDiscountData: [],
		showManualDiscountPopUp: false,
		showAttributesPopUp: false,
		attributesPopUpData: [],
		showExportPage: false,
		excelParseErrorMessage: '',
		standardNotes: [],
		languageDDIsOpen: false,
		limitExceeded: true,
		isQuotationLocked :false,
		isQuotationLockedByMe :false,
		isSalesAdmin:false,
		quotationDate:new Date().toISOString(),
		showRatechangePopUp:false,
		quotationRate: 0,
		quotationRateType: 'None',
	};

	componentDidMount = () => {

		const {
			selectedQuotationId,
			selectedLanguage,
			updateIsEconPopUpOpen,
			updateselectedQuotationId,
			updateselectedQuotationLineId,
			updateselectedQuotationLineDetailId,		
			updateAccountInfo,
			econRedirectUrl,
			updateEconRedirectUrl,
			selectedRole,
			updatedDiscountValueFromUrl,
		} = this.context;

		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');
		const qLineId = params.get('qLineId');
		const qLineDetailId = params.get('qLineDetailId');
		const discountValue = params.get('discountValue');
		
		if (id) {
			updateselectedQuotationId(id);
			updateAccountInfo(this.props.accountInfo);
		
		}
		if(qLineId)
		{
			updateselectedQuotationLineId(qLineId);
			updateAccountInfo(this.props.accountInfo);
		}
		if(qLineDetailId)
		{
			updateselectedQuotationLineDetailId(qLineDetailId);
		}
		if(discountValue)
		{
			updatedDiscountValueFromUrl(discountValue);
		}

		updateIsEconPopUpOpen(false);
		this.props.setWindowPage(false);
		this.props.setShowSearch(true);

		if (econRedirectUrl !== '') {
			const econParams = new URL(econRedirectUrl);
			const quotationLineid = econParams.searchParams.get('quotationlineid');
			let accountNumber = econParams.searchParams.get('accountnumber');
			accountNumber = accountNumber.split('?')[0];
			updateEconRedirectUrl('');
			this.applyAccountDiscounts(i18n.language, quotationLineid, accountNumber);
		} else {
			this.loadQuotation(id, selectedQuotationId, updateIsEconPopUpOpen, selectedLanguage );
		}
	};	

	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

	handleErrorWithMessage = (reasonPhrase) => {
		toast.error(reasonPhrase);
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

	sortBy = (arrayToSort) => {		
		let sortedArray = arrayToSort.sort((a, b) => {
			if (a["SegmentId"].toLowerCase() < b["SegmentId"].toLowerCase()) {
				return this.state["SegmentId"] ? 1 : -1;
			}
			if (a["SegmentId"].toLowerCase() > b["SegmentId"].toLowerCase()) {
				return this.state["SegmentId"] ? -1 : 1;
			}
			return 0;
		});
		return sortedArray;
	};
	isFloat = (n) => {
		return Number(n) === n && n % 1 !== 0;
	};

	validateAndStoreDiscount = (e, index, detail) => {

		let discountValue = e.target.value;

		// Reset ErrorState
		this.setState({
			['DISCOUNT_VALUE_' + index + detail.Id]: discountValue,
			['COMPLETE_DISCOUNT_VALUE' + index + detail.Id]: discountValue,
			['DISCOUNT_ERROR_' + index + detail.Id]: ''
		});
		
		// Validations
		if (discountValue === '' || this.isFloat(discountValue)) {
			this.setState({
				['DISCOUNT_ERROR_' + index + detail.Id]: i18n.t('DISCOUNT_INTERGER_MESSAGE'),
				['DISCOUNT_FLAG_' + index + detail.Id]: true
			});
		} else if (parseFloat(discountValue).toFixed(2) < 0 || parseFloat(discountValue).toFixed(2) > 100) {
			this.setState({
				['DISCOUNT_ERROR_' + index + detail.Id]: i18n.t('DISCOUNT_RANGE_MESSAGE'),
				['DISCOUNT_FLAG_' + index + detail.Id]: true
			});
		} else {
			this.setState({
				['DISCOUNT_ERROR_' + index + detail.Id]: '',
				['DISCOUNT_FLAG_' + index + detail.Id]: false
			});
		}

		let discountTotal = 0;
		let grossUnitPrice = detail.Price;

		// When we overwrite the discount value
		if (discountValue !== '') 
		{			
			// Iterate over and add all detail attribute prices
			for (let i = 0; i < detail.Attributes.length; i++) 
			{
				grossUnitPrice = grossUnitPrice + detail.Attributes[i].AdditionalPrice;
			}

			discountTotal = (grossUnitPrice * parseFloat(discountValue)) / 100;
		
			this.setState({
				['NETPRICE_VALUE_' + index + detail.Id]: round(grossUnitPrice - discountTotal, 2)
			});
		}
	};

	validateAndStoreNetPrice = (e, index, detail) => {
		
		let netPriceValue = e.target.value;

		// Reset ErrorState
		this.setState({
			['NETPRICE_VALUE_' + index + detail.Id]: netPriceValue,
			['NETPRICE_ERROR_' + index + detail.Id]: ''
		});

		if (netPriceValue === '' || this.isFloat(netPriceValue)) {
			this.setState({
				['NETPRICE_ERROR_' + index + detail.Id]: i18n.t('DISCOUNT_INTERGER_MESSAGE'),
				['NETPRICE_FLAG_' + index + detail.Id]: true
			});
		} 
		else if (parseFloat(netPriceValue).toFixed(2) < 0) {
			this.setState({
				['NETPRICE_ERROR_' + index + detail.Id]: i18n.t('NET_RANGE_MESSAGE'),
				['NETPRICE_FLAG_' + index + detail.Id]: true
			});
		} else {
			this.setState({
				['NETPRICE_ERROR_' + index + detail.Id]: '',
				['NETPRICE_FLAG_' + index + detail.Id]: false
			});
		}
	
		let discount = 0;
		let grossUnitPrice = detail.Price;

		// When we overwrite the netvalue
		if (netPriceValue !== '') 
		{
			// Iterate over and add all detail attribute prices
			for (let i = 0; i < detail.Attributes.length; i++) 
			{
				grossUnitPrice = grossUnitPrice + detail.Attributes[i].AdditionalPrice;
			}

			// Recalculate the discount value.
			discount = 100 - (parseFloat(netPriceValue) * 100) / grossUnitPrice;

			this.setState({
				['DISCOUNT_VALUE_' + index + detail.Id]: discount.toFixed(2),
				['COMPLETE_DISCOUNT_VALUE' + index + detail.Id]: discount
			});
		}

	};
	applyInstallationDiscount = (quotationLine,discount) =>{
		
		this.setState({ loading: true });
		const { accountInfo, selectedQuotationId } = this.context;
		let comments = '';
		if (this.state.qLineDetailNetPriceComments.length > 0) {
			comments = this.state.qLineDetailNetPriceComments;
		} else if (this.state.qLineDetailDiscountComments.length > 0) {
			comments = this.state.qLineDetailDiscountComments;
		}

		let data = {
			QuotationLineId : quotationLine.QuotationLineId,
			Discount : discount,
			UserName: accountInfo.account.name,
			UserGuid: accountInfo.account.accountIdentifier,
			Comments: comments,
			QuotationId :selectedQuotationId,
			LevelText : i18n.t(quotationLine.ProductType)
		}

		applyInstallationDiscount(data)
		.then((responseData) => {
				this.setState({qLineDetailNetPriceComments:'',qLineDetailDiscountComments:''})
				
				if(responseData){
					toast.success(<Trans i18nKey='QUOTATION_SAVED_SUCCESSFULLY'>QUOTATION_SAVED_SUCCESSFULLY</Trans>);
					this.getQuotationLineDetailsConf(0);
				}
				else{
					toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
					
				}
				this.setState({ loading: false });
			})
			.catch((reason) => {this.handleError(reason)});
	}
	applyManualDiscount = (lineDetails, Discount, quotationLineId,sequenceNumber) => {
		
		const { accountInfo } = this.context;
		const { userRoleDetail,
			updateselectedQuotationLineId,
			updateselectedQuotationLineDetailId,selectedQuotationLineDetailId,
		selectedQuotationLineId} = this.context;
		var limitsOfARole =	userRoleDetail.find(x=>x.Area === 'Discount');
		this.setState({ loading: true });
		let isDouble = false;
		let comments = '';
		if (this.state.quotationLines.find((x) => x.Id == quotationLineId)) {
			let quotation = this.state.quotationLines.find((x) => x.Id == quotationLineId);
			if (!quotation.IsIndividualArticle)
				isDouble = quotation.Attributes?.find((y) => y.Key == 'IsDouble').Value == 'IsDouble-True';
		}
		if (this.state.qLineDetailNetPriceComments.length > 0) {
			comments = this.state.qLineDetailNetPriceComments;
		} else if (this.state.qLineDetailDiscountComments.length > 0) {
			comments = this.state.qLineDetailDiscountComments;
		}

		let data = {
			Id: lineDetails.Id.toString(),
			Discount: Discount,
			UserName: accountInfo.account.name,
			UserGuid: accountInfo.account.accountIdentifier,
			IsDouble: isDouble,
			Comments: i18n.t('QUOTATION_DETAIL_DISCOUNT_USER_TRACE_APPLIED_TEXT') +'-' +  comments,
			AccountNumber : this.state.quotationData.AccountNumber,
			OpportunityNumber : this.state.quotationData.OpportunityNumber,
			QuotationLineId : quotationLineId,
			QuotationId : this.state.quotationData.QuotationId,
			StepLimit : limitsOfARole.StepLimit,
			Limit : limitsOfARole.Limit,
			SequenceNumber : sequenceNumber,
			ProductTypeDescription : lineDetails.NameData ? lineDetails.NameData.OptionDescription : lineDetails.Name
		};

		applyManualDiscount(data)
		.then((response) => {
				if(response.QuotationLineDetailId == "0")
				{
					this.setState({ showQLDiscountApprovalFlow: true,loading: false, 
						quotationLineDetailIdForEmail:lineDetails.Id.toString(),
						quotationLineIdForEmail: quotationLineId,
						quotationLineDetailNewDiscount : Discount,
						quotationLineSequenceNumber : sequenceNumber,
						quotationLineDetailOptionDescription : lineDetails.NameData ? lineDetails.NameData.OptionDescription : lineDetails.Name
					 });
					
				}
				else
				{
					if(accountInfo.account.accountIdentifier !=  this.state.quotationData.CreatedByGuid)
					{
						this.setState({ 
							quotationLineDetailIdForEmail:lineDetails.Id.toString(),
							quotationLineIdForEmail: quotationLineId,
							quotationLineDetailNewDiscount :Discount,
							quotationLineOldDiscountValue :this.state.OldDiscountValue,
							quotationLineSequenceNumber : sequenceNumber,
							limitExceeded : false,
							quotationLineDetailOptionDescription : lineDetails.NameData ? lineDetails.NameData.OptionDescription : lineDetails.Name
						 });
					    this.sendQuotationLineDetailDiscountForApproval();
					}
					if(selectedQuotationLineId > 0 && selectedQuotationLineId == quotationLineId)
					{
						updateselectedQuotationLineId(0);
					}

					if(selectedQuotationLineDetailId > 0 && selectedQuotationLineDetailId == this.state.quotationLineDetailIdForEmail)
					{
						updateselectedQuotationLineDetailId(0);
					}

					this.getQuotationLineDetailsConf(0);
					this.setState({ showQLDiscountApprovalFlow: false,quotationLineDetailIdForEmail:0 , quotationLineIdForEmail : 0,
						quotationLineDetailNewDiscount :0,quotationLineOldDiscountValue: 0,limitExceeded : true});
					toast.success(<Trans i18nKey='QUOTATION_SAVED_SUCCESSFULLY'>QUOTATION_SAVED_SUCCESSFULLY</Trans>);
				}
			
			})
			.catch((reason) => {this.handleError(reason)});
	};

	getQuotation = (quotationId, language) => {
		this.setState({ loading: true });
		getQuotation(quotationId, language)
		.then((responseData) => {
				
				// Pass the getdetails as a callback after the setState, setState = async
				this.setState({ 
					quotationData: responseData,
					quotationDate: responseData.Date,
					quotationId: responseData.QuotationId
				}, function () { this.getQuotationLineDetailsConf(0, quotationId); });

				this.onGetQuotationSuccess();	

			})
			.catch((reason) => {this.handleError(reason)});
	};

	onGetQuotationSuccess = (quotation) => {

		const { updateViewModeOnly , accountInfo } = this.context;
		const { updateIsQuotationLockedByMe } = this.context;		
		this.updateQuotationLocked(this.state.quotationData.LockedBy, accountInfo.account.accountIdentifier);	
		updateViewModeOnly(this.state.isQuotationLocked);
		const {viewModeOnly} = this.context;
		if(!this.state.isQuotationLocked && !viewModeOnly)
		{
			this.lockQuotation(0);			

			updateIsQuotationLockedByMe(true);
		}
		
	}

	applyRateChangeToQuotation = () => {
		this.setState({
			showRatechangePopUp:true, 
			quotationRate:this.state.quotationData.RateChange,
			quotationRateType:this.state.quotationData.RateChangeType});
	}

	getUserTracesForAQuotation = () =>{
		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;
		
		getUserTracesForAQuotation(selectedQuotationId)
		.then((response) => {
			this.setState({
				showAllUserTracesPopUp:true,
				userTracesQuotationData:response,
				userTracesFilteredQuotationData:response,
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	}

	getApprovalNeededUserTraces = () =>{
		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;
		
		getApprovalNeededUserTraces(selectedQuotationId)
		.then((response) => {
			this.setState({
				showApprovalNeededUserTracesPopup:true,
				userTracesApprovalNeededData:response,
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	}

	getUserTraceDiscount = (quotationLineDetailId, isInstallationDiscountTrace,qLineId) => {
		this.setState({ loading: true });
		let eventType = 'DISCOUNT';
		let entity = 'QuotationLineDetail';

		if(isInstallationDiscountTrace)
		{
			eventType ='InstallationDiscount' ;
			entity = 'QuotationLine';
			quotationLineDetailId = qLineId;
		}
		
		getUserTraces(entity, eventType, quotationLineDetailId)
		.then((response) => {
			this.setState({
				showUserTraceDiscountPopUp: true,
				userTraceDiscountData: response.filter(
					(x) => round(parseFloat(x.OldValue), 2) !== round(parseFloat(x.NewValue), 2)),
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	};

	onCloseDiscountTrace = () => {
		this.setState({ showUserTraceDiscountPopUp: false, userTraceDiscountData: [] });
	};

	onCloseAllUserTracesData = () =>{
		
		this.setState({ showAllUserTracesPopUp: false, userTracesQuotationData: [], userTracesFilteredQuotationData:[], showAllHistory:true });
	}

	onCloseApprovalUserTracesData =() => {		
		for(let i = 0 ; i < this.state.userTracesApprovalNeededData.length;i++ )
		{
			this.setState({	['DISCOUNT_SEGMENT_VALUE_ERROR_'+  i ]: ''});
		}		 
		this.setState({ showApprovalNeededUserTracesPopup: false, userTracesApprovalNeededData: [],userTracesRejectedApprovalNeededData :[] });
	}
	toggleKebabDD = (quotationId) => {
		this.setState({ ['Kebab' + quotationId]: !this.state['Kebab' + quotationId] });
	};
	toggleHeaderKebabDD = () => {
		this.setState({ isHeaderKebabMenuOpen: !this.state.isHeaderKebabMenuOpen });
	};

	getQuotationLineDetailsConf = (flag = 0, id = 0) => {
		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;
		
		getQuotationLinesForQuotation((id == 0 ? selectedQuotationId : id))
		.then((responseData) => {
			this.onQuotationLineSuccess(responseData, flag, false);
			this.getSupplements((id == 0 ? selectedQuotationId : id));
		})
		.catch((reason) => {this.handleError(reason)});
	};

	onIndividualArticleSelect = (individualArticle) => {
		this.setState({
			selectedIndividualArticle: individualArticle,
			openIndividualArticle: false,
			IsIndividualArticleSelected: true,
			selectedIndividualArticlePrice: parseFloat(individualArticle.GrossBasePrice).toFixed(2)
		});
	};

	onSupplementSelect = (suppliment) => {	
		this.setState({priceListTypeAheadIsOpenSupplement: false,selectedSupplementArticle:suppliment });
	};

	onSearchFilterSelect = (searchFilter, index) => {
		this.state.appliedSearchFiltersList[index].key = searchFilter.ShortDescription;

		let newListOfSearchFilters = this.state.appliedSearchFiltersList;
		this.setState({ appliedSearchFiltersList: newListOfSearchFilters });
	};


	fetchStatusList = (quotationId) => {
		this.setState({ loading: true });
		getQuotationStatus(quotationId)
		.then((response) => {
			this.setState({loading:false,quotationStatus:response});
		})
		.catch((reason) => {this.handleError(reason)});
	}

	// GetManualAndCalculatedDiscount Function
	applyAccountDiscounts = (language, quotationLineId, accountNumber) => {
		this.setState({ loading: true, showIframeModal: false });
		const { updateIsEconPopUpOpen } = this.context;
		updateIsEconPopUpOpen(false);
		const { accountInfo, selectedQuotationId } = this.context;

		let data = {
			Id: quotationLineId.toString(),
			UserName: 'TheumaData',
			UserGuid: accountInfo.account.accountIdentifier,
			QuotationId: selectedQuotationId
		};

		applyAccountDiscount(data, accountNumber, quotationLineId, language)
		.then((responseData) => {
				if (responseData.length == 0) {
					const { selectedQuotationId } = this.context;
					window.parent.window.location.href = '/quotation/view?id=' + selectedQuotationId;
				} else {
					if (window.parent.document.getElementById('modal-close-econf'))
						window.parent.document.getElementById('modal-close-econf').style.display = 'none';
					this.setState({
						loading: false,
						manualDiscountData: responseData,
						showManualDiscountPopUp: true
					});
				}
			})
			.catch((reason) => {this.handleError(reason)});
	};

	setPrice = (price) => {
		this.setState({ selectedPriceList: price }, () => {
			this.searchIndividualArticles(this.state.individualArticleSearchText);
		});
	};

	setPriceSupplemnt = (price) => {
		this.setState({selectedPriceListSupplement:price});		
	};

	togglePriceListDD = () => {
		this.setState({
			priceListDDIsOpen: !this.state.priceListDDIsOpen
		});
	};

	togglePriceListDDSupplement = () => {	
		this.setState({priceListDDIsOpenSupplement : !this.state.priceListDDIsOpenSupplement});	
		
	};

	searchQuotationLineParameters = (query) => {
		let searchResults = this.state.searchFilters.filter((item) => item.Name.toLowerCase().indexOf(query) > -1);
		this.setState({
			searchResultsFiltered: searchResults
		});
	};

	searchIndividualArticles = (query) => {
		const { accountInfo, selectedLanguage } = this.context;
		if(query) 
		{
			this.setState({ options: [], isLoading: true });

			getPriceListFreeItemsByFilter(accountInfo.account.accountIdentifier, selectedLanguage, this.state.selectedPriceList.Id, query)
			.then((data) => {
				const options = data;
				this.setState({
					options: options,
					isLoading: false,
					openIndividualArticle: true,
					individualArticleSearchText: query
				});
			});
		}
	};

	searchSupplement = (query,index) => {
	
		const { accountInfo, selectedLanguage } = this.context;

		this.setState({ options: [], isLoading: true });

		getPriceListFreeItemsByFilter(accountInfo.account.accountIdentifier, selectedLanguage, this.state.selectedPriceListSupplement.Id, query)
		.then((data) => {
			const options = data;			
			this.setState({
				options: options,
				isLoading: false,
				openSupplementArticle: true,
				supplementSearchText: query,
				priceListTypeAheadIsOpenSupplement:true
			});
		});
	};

	deleteQuotationConfirm = () => {
		this.setState({ loading: true });

		deleteQuotation(this.state.quotationData.QuotationUniqueId)
		.then((response) => {
				window.location = '/';
		}).catch((reason) => {this.handleError(reason)});

	};

	deleteQuotationLineConfirm = () => {
		this.setState({ loading: true });

		deleteQuotationLine(this.state.deleteQuotationLineId)
		.then((response) => {
				this.setState({
					showDeleteQuotationPopUp: false,
					showDeleteAlternativePopUp: false,
					deleteQuotationLineId: 0,
					loading: false
				});
				if (this.state.isSearchApplied) this.onApplySearch();
				else this.getQuotationLineDetailsConf();
			})
			.catch((reason) => {this.handleError(reason)});
	};
	deleteQuotationLineCancel = () => {
		this.setState({ showDeleteQuotationPopUp: false, showDeleteAlternativePopUp: false, deleteQuotationLineId: 0 });
	};
	deleteQuotationLine = (quotationLine, isAlternative) => {
		this.setState({
			showDeleteQuotationPopUp: !isAlternative,
			showDeleteAlternativePopUp: isAlternative,
			deleteQuotationLineId: quotationLine.Id
		});
	};

	updateQuotationLocked = (lockedBy, loggedInUser) =>{		
	
		let tempIsLocked = false;
		if(lockedBy && (lockedBy != loggedInUser))
		{			
			tempIsLocked = true;
		}
		else
		{
			tempIsLocked = false;
		}	
		this.setState({
			isQuotationLocked:tempIsLocked,
			
		});	

		if(this.IsLoggedInUserSalesAdmin())
		{
			this.setState({
				isSalesAdmin:true
			})
		}
	}

	addNotesQuotationLine = (quotationLine) => {
		const { selectedLanguage } = this.context;
		let language = this.state.Language.find((x) => x.Code === selectedLanguage).Name;
		this.setState({ loading: true, SelectedNotesLanguage: language });

		getStandardNotes(selectedLanguage)
		.then((responseData) => {
			this.setState({
				quotationLineIdForNotes: quotationLine.Id,
				quotationLineNotes: quotationLine.Notes,
				isQuotationLineNotesPopUpOpen: true,
				standardNotes: responseData,
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	};

	getStandardNotes = (language) => {
		let languageName = this.state.Language.find((x) => x.Code === language).Name;
		this.setState({ loading: true, SelectedNotesLanguage: languageName });

		getStandardNotes(language)
		.then((responseData) => {
			this.setState({
				standardNotes: responseData,
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	};

	showAttributesAndDoorwayInfo = (quotationLine) => {
		this.setState({ showAttributesPopUp: true, attributesPopUpData: quotationLine });
	};

	closeAttributesAndDoorwayInfo = () => {
		this.setState({ showAttributesPopUp: false, attributesPopUpData: [] });
	};

	copyQuotationLineCancel = () => {
		this.setState({
			copyQuotationLineId: 0,
			showCopyQuotationPopup: false
		});
	};

	copyQuotationLineConfirmation = () => {
		this.setState({ loading: true });

		cloneQuotationLine(this.state.copyQuotationLineId)
		.then((responseData) => {
				if (responseData.IsSuccess) {
					if (this.state.isSearchApplied) this.onApplySearch();
					else this.getQuotationLineDetailsConf();
					this.setState({
						copyQuotationLineId: 0,
						showCopyQuotationPopup: false
					});
				} else {
					this.setState({
						copyQuotationLineId: 0,
						showCopyQuotationPopup: false,
						loading: false 
					});
					toast.error(
						<Trans i18nKey='COPY_QUOTATIONLINE_CUSTOM_ERROR_MESSAGE'>
							COPY_QUOTATIONLINE_CUSTOM_ERROR_MESSAGE
						</Trans>
					);
				}
			})
			.catch((reason) => {this.handleError(reason)});
	};

	editQuotationLineCancel = () => {
		this.setState({
			editQuotationLineId: 0,
			editQuotationLineEconConfiId: '',
			showEditQuotationPopup: false
		});
	};
	editQuotationLineConfirmation = () => {
		this.setState({ showIframeModal: true, showEditQuotationPopup: false, loading: true  });
		const { updateIsEconPopUpOpen,selectedRole, selectedLanguage } = this.context;
		updateIsEconPopUpOpen(true);

		let data = {
			model: {
				name: 'Doorway',
				version: '1.0.0'
			},
			configuration: {
				name: this.state.editQuotationLineEconConfiId ? this.state.editQuotationLineEconConfiId : ''
			},
			parameters: [
				{
					name: 'quoteid',
					valueType: 0,
					value: this.state.quotationId
				},

				{
					name: 'quotelineid',
					valueType: 0,
					value: this.state.editQuotationLineId
				},
				{
					name: 'callbackurl',
					valueType: 0,
					value:
						process.env.REACT_APP_REDIRECT_URL +
						'/quotation/view?quotationlineid=' +
						this.state.editQuotationLineId +
						'&accountnumber=' +
						this.state.quotationData.ClientNumber
				},
				{
					name: 'defaultpricelist',
					valueType: 0,
					value: !this.state.editQuotationLineEconConfiId ? this.state.quotationData.PriceListId : ''
				},
				{
					name: 'language',
					valueType: 0,
					value: selectedLanguage
				},
				{
					name: 'Role',
					valueType: 0,
					value: 'EconAll'
				}
			]
		};

		createSession(data)
		.then((responseData) => {
				this.setState({ loading: false });
				this.setState({ iframeURL: responseData.url });
				const { updateIsEconPopUpOpen, updateEconRedirectUrl } = this.context;
				updateIsEconPopUpOpen(true);
				updateEconRedirectUrl(
					process.env.REACT_APP_REDIRECT_URL +
						'/quotation/view?quotationlineid=' +
						this.state.editQuotationLineId +
						'&accountnumber=' +
						this.state.quotationData.ClientNumber
				);
				this.setState({ showIframeModal: true });
			})
			.catch((reason) => {this.handleError(reason)});
	};
	onMoveUpQuotationLine = (quotationLine, quotationLineDirection) => {
		const { selectedQuotationId } = this.context;
		this.setState({ loading: true });

		sequenceQuotationLines(selectedQuotationId, quotationLine.Id, quotationLineDirection)
		.then((responseData) => {
			this.setState({ loading: false });
			if (responseData.IsSuccess) this.getQuotationLineDetailsConf();
		}).catch((reason) => {this.handleError(reason)});
	};
	onMoveDownQuotationLine = (quotationLine, quotationLineDirection) => {
		const { selectedQuotationId } = this.context;
		this.setState({ loading: true });

		sequenceQuotationLines(selectedQuotationId, quotationLine.Id, quotationLineDirection)
		.then((responseData) => {
				this.setState({ loading: false });
				if (responseData.IsSuccess) this.getQuotationLineDetailsConf();
			})
			.catch((reason) => {this.handleError(reason)});
	};
	onArticleTypeChanged = (type) => {
		this.setState({
			isManualEntryIndividualArticle: type === 2,
			individualArticleDetails: [
				{
					Name: '',
					Price: 0
				}
			],
			selectedIndividualArticle: [],
			IsIndividualArticleSelected: false,
			selectedIndividualArticlePrice: '0',
			noOfIndividualArticles: 1,
			selectedPriceList: this.state.priceList.find((x) => x.Id == this.state.quotationData.PriceListId),
			individualArticleValidations: {
				isArticleNotSelected: false,
				isArticleNameEmpty: false,
				isArticlePriceEmpty: false,
				isArticleQuantityEmpty: false
			}
		});
	};

	onShowAllHistoryChanged =(type) =>{
	
		this.setState({
			showAllHistory : type === 2
		});
		if(type!=2)
		{
			let tempUserTracesData = this.state.userTracesQuotationData.filter(x=>x.EventType == 'Discount');
			this.setState({userTracesFilteredQuotationData:tempUserTracesData});
		}
		else
		{
			this.setState({userTracesFilteredQuotationData:this.state.userTracesQuotationData});
		}
	}


	onSupplementeChanged = (type) => {		    
			this.setState({isSupplementUserDefined: type == 2});
			this.state.selectedSupplementArticle.ShortDescription = '';
			
		};

	onProductTypeChanged = (selectedProductType) => {
		this.setState({
			selectedProductType: selectedProductType
		});
	};
	editIndividualArticle = (quotationLine) => {
		this.setState(
			{
				editIndividualArticleData: quotationLine,
				addQuotationTitle: quotationLine.LineTitle,
				selectedIndividualArticle: {
					ShortDescription: quotationLine.QuotationLineDetails[0].Name,
					GrossBasePrice: quotationLine.QuotationLineDetails[0].Price
				},
				IsIndividualArticleSelected: quotationLine.PriceListId !== null,
				selectedIndividualArticlePrice: parseFloat(quotationLine.QuotationLineDetails[0].Price).toFixed(2),
				noOfIndividualArticles: quotationLine.Quantity,
				selectedPriceList: this.state.priceList.find((x) => x.Id == quotationLine.PriceListId),
				isManualEntryIndividualArticle: quotationLine.PriceListId == null,
				selectedProductType : quotationLine.Attributes[0].Key == "Revenue" ? 2 : 1,
				individualArticleDetails: [
					{
						Name: quotationLine.QuotationLineDetails[0].Name,
						Price: quotationLine.QuotationLineDetails[0].Price
					}
				]
			},
			() => {
				this.setState({ showIndividualArticleModal: true });
			}
		);
	};
	setIndividualArticleDesc = (e) => {
		let individualArticleDetails = this.state.individualArticleDetails;
		individualArticleDetails[0].Name = e.target.value;
		this.setState({ individualArticleDetails: individualArticleDetails });
	};
	setIndividualArticlePrice = (e) => {
		let value = e.target.value.trim();
		value = value || 0; // Defaults the value to 0 even if it is NaN

		if (!isNaN(value)) {
			let individualArticleDetails = this.state.individualArticleDetails;
			individualArticleDetails[0].Price = value;
			this.setState({ individualArticleDetails: individualArticleDetails });
		}
	};
	editEconSession = (quotationLine) => {
		if (quotationLine.IsIndividualArticle) {
			this.editIndividualArticle(quotationLine);
			return;
		}

		this.setState({ loading: true, excelParseErrorMessage: '' });

		getDetailsForQuotationLine(quotationLine.Id)
		.then((responseData) => {
				this.setState({
					editQuotationLineId: quotationLine.Id,
					editQuotationLineEconConfiId: responseData.EconConfigId,
					addQuotationTitle: responseData.LineTitle,
					noOfIdenticalDoorWays: responseData.Quantity,
					selectedLineCount: responseData.DoorWays.length === 0 ? 1 : responseData.DoorWays.length,
					quotationLineList:
						responseData.DoorWays.length === 0
							? [
									{
										DoorOpeningReference: '',
										Building: '',
										Floor: '',
										Apartment: ''
									}
							  ]
							: responseData.DoorWays,
					showAddOrEditQuotationModal: true,
					loading: false
				});
				this.setLineCount();
			})
			.catch((reason) => {this.handleError(reason)});
	};
	editEconSessionAlternative = (alternative) => {
		this.setState(
			{
				editQuotationLineEconConfiId: alternative.EconConfigId,
				editQuotationLineId: alternative.Id,
				quotationLineId: alternative.Id
			},
			() => {
				let quotationLineDetailWithDiscount = alternative.QuotationLineDetails.find((x) => x.Discount);
				if (!quotationLineDetailWithDiscount) this.editQuotationLineConfirmation();
				else {
					this.setState({ showEditQuotationPopup: true });
				}
			}
		);
	};
	onSwapAlternative = (alternative) => {
		this.setState({ swapAlternative: alternative, showSwapAlternativePopUp: true });
	};
	swapAlternativeCancel = () => {
		this.setState({ swapAlternative: null, showSwapAlternativePopUp: false });
	};
	swapAlternativeConfirm = () => {

		this.setState({ loading: true });

		swapAlternative(this.state.swapAlternative.Id)
		.then((responseData) => {
				if (this.state.isSearchApplied) this.onApplySearch();
				else this.getQuotationLineDetailsConf();
				this.setState({ swapAlternative: null, showSwapAlternativePopUp: false });
			})
			.catch((reason) => {this.handleError(reason)});
	};
	copyQuotationLine = (quotationLine) => {
		this.setState({
			copyQuotationLineId: quotationLine.Id,
			showCopyQuotationPopup: true
		});
	};

	openAddQuotationModal = () => {
		this.setState({ showAddOrEditQuotationModal: true });
	};

	openSearchPanelModal = () => {
		this.setState({
			showSearchPanel: true,
			bufferSearchQuery: cloneArray(this.state.appliedSearchFiltersList)
		});
	};

	openAddAlternativeModal = (quotationLine) => {
		this.setState({
			showAddAlternative: true,
			alternativeQuotationLine: quotationLine,
			alternativeCount: quotationLine.Alternatives ? quotationLine.Alternatives.length + 1 : 1
		});
	};

	onCloseAlternativePopup = () => {
		this.setState({ showAddAlternative: false, addAlternativeTitle: '' });
	};
	openSupplementModal= () => {	
		this.setState(
			{ 
				showSupplementModal: true,IsSupplementSelected:false,
				selectedPriceListSupplement: this.state.quotationData.PriceListId 
			});
			const { selectedQuotationId } = this.context;
			this.getSupplements(selectedQuotationId);
		}

		getSupplements =(quotationId) =>{
			this.setState({ loading: true });		
			
			getSupplements(quotationId)
			.then((responseData) => 
			{				
					
				this.setState({
					supplementList:responseData,
					loading: false
				});
			}).catch((reason) => {this.handleError(reason)});
		}	


	openIndividualArticleModal = () => {
		this.setState(
			{
				addQuotationTitle: '',
				selectedIndividualArticle: [],
				IsIndividualArticleSelected: false,
				selectedIndividualArticlePrice: '',
				noOfIndividualArticles: 1,
				selectedPriceList: this.state.priceList.find((x) => x.Id == this.state.quotationData.PriceListId),
				editIndividualArticleData: null,
				individualArticleDetails: [
					{
						Name: '',
						Price: 0
					}
				],
				selectedProductType : 1,
				isManualEntryIndividualArticle: false,
				individualArticleValidations: {
					isArticleNotSelected: false,
					isArticleNameEmpty: false,
					isArticlePriceEmpty: false,
					isArticleQuantityEmpty: false
				}
			},
			() => {
				this.setState({ showIndividualArticleModal: true });
			}
		);
	};

	openExportModal = () => {
		const { updateExportData } = this.context;
		updateExportData(this.state.quotationData);
		this.setState({ showExportPage: true });
	};
	openPDFJsonModal = () =>{

		this.setState({ showPdfJsonModal: true,loading:true });
		const { selectedQuotationId } = this.context;
		const { selectedLanguage } = this.context;	
		let exportLayout = 'BE-Standard';

		getJsonPDF(selectedQuotationId, selectedLanguage, exportLayout)
		.then((responseData) => {					
				this.setState({ loading: false,pdfJsonData: JSON.stringify(responseData) });
			})
			.catch((reason) => {this.handleError(reason)});
	}

	downloadXMLModal = () => {	

		const { selectedQuotationId } = this.context;

		// Validate quotation for export
		toast.warning("Controle van export data...");

		validateXmlExport(selectedQuotationId)
		.then((response) => {
			if(!response.Succeeded){
				toast.error(response.HeaderMessage + "\n" + response.Failures.join('\n'), 
				{ autoClose:15000, pauseOnHover:true, hideProgressBar:true, style:{ width:'200%' } });
			}
			else
			{
				// Export xml
				toast.warning(<Trans i18nKey='GENERATING_XML'>GENERATING_XML</Trans>);	

				let languageKey = 'en-GB';
				let exportType = 'xml';
				let exportLayout = 'XML';

				exportQuotationByTypeAndLayout(selectedQuotationId, exportType, languageKey, exportLayout)
				.then((responseData) => {						
					this.download(responseData, 'quotation.' + exportType);
					this.setState({ loading: false });
				})
				.catch((reason) => { this.handleErrorWithMessage(reason.message)});
			
			}

		})
		.catch((reason) => { this.handleError(reason.message)});		
	};
	
	openIframeModal = () => {
		const { updateIsEconPopUpOpen } = this.context;
		updateIsEconPopUpOpen(true);
		this.setState({ showIframeModal: true });
	};
	openAssignStatus = () => {
		const { selectedQuotationId } = this.context;
		this.fetchStatusList(selectedQuotationId);
		this.setState({selectedQuotationStatus:1, showAssignStatusModal: true });
	};

	closeAddQuotationModal = () => {
		this.setState({ showAddOrEditQuotationModal: false });
	};

	setAddQuotationTitle = (e) => {
		this.setState({ addQuotationTitle: e.target.value });
	};

	setAddAlternativeTitle = (e) => {
		this.setState({ addAlternativeTitle: e.target.value });
	};
	onEditAlternative = (alternative) => {
		this.setState({
			showEditAlternativePopUp: true,
			editAlternativeTitle: alternative.LineTitle,
			editAlternativeData: alternative
		});
	};
	setEditAlternativeTitle = (e) => {
		this.setState({ editAlternativeTitle: e.target.value });
	};
	onCloseEditAlternativePopup = (e) => {
		this.setState({ editAlternativeTitle: '', editAlternativeData: null, showEditAlternativePopUp: false });
	};
	onSaveEditAlternativeTitle = (e) => {
		this.setState({ loading: true });
		
		let data = {
			Id: this.state.editAlternativeData.Id,
			LineTitle:
				this.state.editAlternativeTitle == null || this.state.editAlternativeTitle == ''
					? i18n.t('ALTERNATIVE_PLACEHOLDER')
					: this.state.editAlternativeTitle
		};

		updateAlternative(data)
		.then((response) => {
				let quotationLines = this.state.quotationLines;
				quotationLines.find((x) => x.Id == this.state.editAlternativeData.Id).LineTitle =
					this.state.editAlternativeTitle == null || this.state.editAlternativeTitle == ''
						? i18n.t('ALTERNATIVE_PLACEHOLDER')
						: this.state.editAlternativeTitle;
				this.setState({
					quotationLines: quotationLines,
					editAlternativeTitle: '',
					editAlternativeData: null,
					showEditAlternativePopUp: false,
					loading: false
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};

	addNewConfigurationAsAlternative = () => {
		// save quotation line API call
		const { selectedQuotationId } = this.context;
		let quotationLineId = this.state.alternativeQuotationLine.Id;

		this.setState({ loading: true });
		let data = {
			Id: 0,
			LineTitle:
				this.state.addAlternativeTitle == null || this.state.addAlternativeTitle == ''
					? i18n.t('ALTERNATIVE_PLACEHOLDER')
					: this.state.addAlternativeTitle,
			QuotationId: selectedQuotationId,
			Quantity: 0
		};

		saveAlternativeConfiguration(data, quotationLineId)
		.then((responseData) => {
				// Open Econ Iframe after receiving qutoationLine Id
				this.setState({
					editQuotationLineId: responseData.QuotationId,
					addAlternativeTitle: '',
					showAddAlternative: false
				});
				this.editQuotationLineConfirmation();
			})
			.catch((reason) => {this.handleError(reason)});
	};

	addExistingConfigurationAsAlternative = () => {
		let quotationLineId = this.state.alternativeQuotationLine.Id;
		this.setState({ loading: true });
	
		let data = {
			LineTitle:
				this.state.addAlternativeTitle == null || this.state.addAlternativeTitle == ''
					? i18n.t('ALTERNATIVE_PLACEHOLDER')
					: this.state.addAlternativeTitle,
			NewLineBasedOnExistingConfiguration: true
		};

		// Open Econ Iframe for reconfiguration
		saveAlternative(data, quotationLineId)
		.then((responseData) => {
				// Open Econ Iframe after receiving qutoationLine Id
				this.setState({
					editQuotationLineId: responseData.QuotationId,
					editQuotationLineEconConfiId: responseData.EconConfigurationId,
					addAlternativeTitle: '',
					showAddAlternative: false
				});
				this.editQuotationLineConfirmation();
			})
			.catch((reason) => {this.handleError(reason)});
		// DONE
	};

	onLineCountChange = (e) => {
		let value = e.target.value;
		value = value ?? 0;

		if (!isNaN(value)) {
			this.setState({ noOfIdenticalDoorWays: value });
		}
	};
	uploadExcel = () => {
		this.excelInput.click();
	};
	exportExcel = () => {
		const { selectedLanguage } = this.context;
		this.setState({ loading: true });

		exportQuotationLineDoorwaysExcel(this.state.editQuotationLineId, selectedLanguage)
		.then((responseData) => {
				this.download(responseData, 'quotation.excel');
				this.setState({ loading: false });
			})
			.catch((reason) => {this.handleError(reason)});
	};
	download = (dataurl, filename) => {
		if (dataurl && dataurl.documentURL) {
			var a = document.createElement('a');
			a.href = dataurl.documentURL;
			a.target = '_blank';
			a.setAttribute('download', filename);
			a.click();
		}
	};
	fileHandler = (event) => {
		const { selectedLanguage } = this.context;
		var data = new FormData();
		data.append('File', event.target.files[0]);
		
		this.setState({ loading: true });

		importQuotationLineDoorwaysExcel(data, selectedLanguage)
		.then((responseData) => {
				if (responseData.individualDoorWays)
					this.setState({
						noOfIdenticalDoorWays: responseData.individualDoorWays.length,
						selectedLineCount: responseData.individualDoorWays.length,
						quotationLineList: responseData.individualDoorWays,
						excelParseErrorMessage: '',
						loading: false
					});
				else
					this.setState({
						excelParseErrorMessage: responseData.ErrorMessage,
						loading: false
					});
			})
			.catch((reason) => {this.handleError(reason)});
	};
	onDeleteIndividualDoorway = (index) => {
		let noOfIdenticalDoorWays = parseInt(this.state.noOfIdenticalDoorWays) - 1;
		let quotationLineList = this.state.quotationLineList;
		quotationLineList.splice(index, 1);
		this.setState({
			noOfIdenticalDoorWays: noOfIdenticalDoorWays,
			quotationLineList: quotationLineList,
			selectedLineCount: parseInt(this.state.selectedLineCount) - 1
		});
	};

	onDeleteSupplement = (index) => {
		let noOfSupplements = parseInt(this.state.noOfSupplements) - 1;
		let supplementList = this.state.supplementList;
		supplementList.splice(index, 1);
		this.setState({
			noOfSupplements: noOfSupplements,
			supplementList: supplementList,
			selectedSupplementLineCount: parseInt(this.state.selectedSupplementLineCount) - 1,
		});
	};
	onAddIndividualDoorway = () => {
		let quotationLineList = this.state.quotationLineList;
		quotationLineList.push({
			DoorOpeningReference: '',
			Building: '',
			Floor: '',
			Apartment: ''
		});
		this.setState({
			noOfIdenticalDoorWays: parseInt(this.state.noOfIdenticalDoorWays) + 1,
			quotationLineList: quotationLineList,
			selectedLineCount: parseInt(this.state.selectedLineCount) + 1
		});
	};

	onAddSupplement = () => {	
		let supplementList = this.state.supplementList;

		supplementList.push({
				Id:0,
				AdditionalPrice:this.state.selectedSupplementArticle.GrossBasePrice,
				Quantity:this.state.selectedSupplementQuantity,
				Key:this.state.selectedSupplementArticle.Id,
				Value:this.state.selectedSupplementArticle.ShortDescription,
				Discount: this.state.selectedSupplementDiscount,
				IsUserDefined:this.state.isSupplementUserDefined,
				Comment:this.state.selectedSupplementComment,
				IsNetPrice : this.state.selectedSupplementDiscount == 0 ? false : true
		});	

		this.setState({
			noOfSupplements: parseInt(this.state.noOfSupplements) + 1,
			supplementList: supplementList,		
			
		},()=>{
			this.clearSupplementValues()
		});
	};

	clearSupplementValues = () =>{		
		this.setState(
			{
				selectedSupplementArticle:[],
				selectedSupplementQuantity:'',
				selectedSupplementComment:'',
				selectedSupplementDiscription:'',
				selectedSupplementDiscount:''
			}
		)

		let tempSelectedSupplementArticle = this.state.selectedSupplementArticle;
		tempSelectedSupplementArticle.GrossBasePrice = '';
		tempSelectedSupplementArticle.Id = '';
		tempSelectedSupplementArticle.ShortDescription = '';
		this.setState({ selectedSupplementArticle: tempSelectedSupplementArticle });
	}

	onIndividualArticleChange = (e) => {
		let value = e.target.value;
		value = value ?? 0;

		if (!isNaN(value)) {
			this.setState({ noOfIndividualArticles: value });
		}
	};

	unloadIframe = (locationURL) => {};
	setLineCount = () => {
		let array = [];
		let oldArray = this.state.quotationLineList;
		let lineNumber = this.state.noOfIdenticalDoorWays;
		for (let i = 0; i < lineNumber; i++) {
			if (oldArray.length > i) {
				array.push(oldArray[i]);
			} else {
				array.push({
					DoorOpeningReference: '',
					Building: '',
					Floor: '',
					Apartment: ''
				});
			}
		}
		this.setState({ selectedLineCount: lineNumber, quotationLineList: array });
	};

	setDoorOpeningReference = (e, i) => {
		let lineList = this.state.quotationLineList;
		lineList[i].DoorOpeningReference = e.target.value;
		this.setState({ quotationLineList: lineList });
	};

	setBuilding = (e, i) => {
		let lineList = this.state.quotationLineList;
		lineList[i].Building = e.target.value;
		this.setState({ quotationLineList: lineList });
	};

	setFloor = (e, i) => {
		let lineList = this.state.quotationLineList;
		lineList[i].Floor = e.target.value;
		this.setState({ quotationLineList: lineList });
	};

	setApartment = (e, i) => {
		let lineList = this.state.quotationLineList;
		lineList[i].Apartment = e.target.value;
		this.setState({ quotationLineList: lineList });
	};

	setQuantity = (e) => {
		let lineList = this.state.selectedSupplementQuantity;
		lineList= e.target.value;
		this.setState({ selectedSupplementQuantity: lineList });
	};

	setDiscount = (e) => {
		let lineList = this.state.selectedSupplementDiscount;
		lineList= e.target.value;
		this.setState({ selectedSupplementDiscount: lineList });
	};

	setDescription = (e) => {		
		let lineList = this.state.selectedSupplementArticle.ShortDescription;
		lineList= e.target.value;
		this.state.selectedSupplementArticle.ShortDescription= lineList;
		 this.setState({ selectedSupplementDiscription: lineList });
	};

	setComment = (e) => {
		let lineList = this.state.selectedSupplementComment;
		lineList= e.target.value;
		this.setState({ selectedSupplementComment: lineList });
	};

	setSupplementArticlePrice = (e, i) => {
		let selectedPrice =  e.target.value;
		let tempSelectedSupplementArticle = this.state.selectedSupplementArticle;
		tempSelectedSupplementArticle.GrossBasePrice = selectedPrice ?? 0;
		this.setState({ selectedSupplementArticle: tempSelectedSupplementArticle });
	};

	setSupplementArticleDesc = (e, i) => {
		 let selectedUserDefinedSupplement = e.target.value;
		 let tempSelectedSupplementArticle = this.state.selectedSupplementArticle;
		tempSelectedSupplementArticle.Id = selectedUserDefinedSupplement;
		this.setState({ selectedSupplementArticle: tempSelectedSupplementArticle });
	};


	

	filterData = (doorways) => {
		let arrayBuilding = [];
		let arrayData = {};

		for (let i = 0; i < doorways.length; i++) {
			if (arrayBuilding.indexOf(doorways[i].Building) === -1) {
				arrayBuilding.push(doorways[i].Building);
				arrayData[doorways[i].Building] = [];
			}
			arrayData[doorways[i].Building].push([
				doorways[i].Floor,
				doorways[i].Apartment,
				doorways[i].DoorOpeningReference
			]);
		}
		for (var k in arrayData) {
			arrayData[k] = this.sortValue(arrayData[k]);
		}
		let finalArray = [];
		for (k in arrayData) {
			for (let i = 0; i < arrayData[k].length; i++) {
				if (i === 0) {
					arrayData[k][i].push(k);
				} else {
					arrayData[k][i].push('');
				}
				finalArray.push(arrayData[k][i]);
			}
		}
		return finalArray;
	};
	sortValue = (arrayData) => {
		return arrayData.sort(this.sortFunction);
	};
	sortFunction = (a, b) => {
		if (a[0] === b[0]) {
			return 0;
		} else {
			return a[0] < b[0] ? -1 : 1;
		}
	};
	buildingDetails = (doorways) => {
		let scripts = [];
		doorways = this.filterData(doorways);
		for (let i = 0; i < doorways.length; i++) {
			scripts.push(
				<tr className='border-bottom-building-details' id={'doorway' + i}>
					<td className='attributes-text bottom-border-grey'>{doorways[i][3]}</td>
					<td className='attributes-text bottom-border-grey '>{doorways[i][0]}</td>
					<td className='attributes-text bottom-border-grey'>{doorways[i][1]}</td>
					<td className='attributes-text bottom-border-grey'>{doorways[i][2]}</td>
				</tr>
			);
		}
		return scripts;
	};
	buildingDetailsSpace = (doorways) => {
		let scripts = [];
		doorways = this.filterData(doorways);
		for (let i = 0; i < doorways.length; i++) {
			scripts.push(
				<tr className='border-bottom-building-details'>
					<td className='attributes-text bottom-border-grey padding-left-40 padding-top-30'></td>
					<td className='attributes-text bottom-border-grey padding-left-60'></td>
					<td className='attributes-text bottom-border-grey padding-left-50'></td>
					<td className='attributes-text bottom-border-grey padding-left-60'></td>
					<td className='attributes-text bottom-border-grey padding-left-60'> </td>
					<td className='attributes-text bottom-border-grey padding-left-60'> </td>
				</tr>
			);
		}
		return scripts;
	};

	onHeaderDiscountClick = () => {
		this.setState({ loading: true });

		getQuotationSegments(this.state.quotationData.QuotationId, this.state.quotationData.ClientNumber, this.state.quotationData.OpportunityNumber)
		.then((responseData) => {
				let headerdiscountSegments;

				if (responseData.QuotationSpecificSegments && responseData.QuotationSpecificSegments.length > 0)
				{
					headerdiscountSegments = responseData.QuotationSpecificSegments
				}
				else if (responseData.TheumaDataSpecificSegments && responseData.TheumaDataSpecificSegments.length > 0)
				{
					headerdiscountSegments = responseData.TheumaDataSpecificSegments					
				}
				else
				{
					headerdiscountSegments = responseData.TheumaDataSpecificSegments
				}		
				headerdiscountSegments = this.sortBy(headerdiscountSegments);	
				let tempHeaderDiscountBe = cloneArray(headerdiscountSegments);
				this.setState({ showDiscountHeaderPopUp: true,  headerDiscount: headerdiscountSegments, tempHeaderDiscount : tempHeaderDiscountBe, loading: false,
					theumaSpecificHeaderDiscount: responseData.TheumaDataSpecificSegments, crmAccountDiscounts : responseData.crmAccountDiscounts });				
			})
			.catch((reason) => {this.handleError(reason)});
	};

	onReportOverviewClick = () => {
		const { selectedLanguage } = this.context;
		this.setState({ loading: true });

		getRevenueInformation(this.state.quotationData.QuotationId, selectedLanguage)
		.then((responseData) => {
				this.setState({ showReportingOverview: true, reportingOverview: responseData, loading: false });
			})
			.catch((reason) => {this.handleError(reason)});
	};

	onCloseHeaderDiscount = () => {
		this.setState({ showDiscountHeaderPopUp: false, headerDiscount: [], isHeaderDiscountInvalid: false });
	};

	onCloseReportingOverview = () => {
		this.setState({ showReportingOverview: false, reportingOverview: [] });
	};

	sendRevenueToCRM = () =>{
		const { selectedLanguage } = this.context;
		this.setState({ loading: true });
		
		let data = {		
			QuotationId: this.state.quotationData.QuotationId,		
			Language: selectedLanguage,		
			OpportunityNumber: this.state.quotationData.OpportunityNumber	
		};

		sendRevenuetoCRM(data, selectedLanguage)
		.then((response) => {
				this.setState({ showReportingOverview: false, reportingOverview: [], loading: false });
		}).catch((reason) => {this.handleError(reason)});
	};
	getReportingOverviewTotal = () => {
		let total = 0;
		for (let i = 0; i < this.state.reportingOverview.length; i++) {
			total += this.state.reportingOverview[i].Price;
		}
		return total.toFixed(2);
	};

	getDefaultDiscount = (matchedSegmentId) =>{

		if(this.state.crmAccountDiscounts && this.state.crmAccountDiscounts.length > 0)
		{
			return this.state.crmAccountDiscounts.find(x=>x.SegmentName == matchedSegmentId) ? 
			this.state.crmAccountDiscounts.find(x=>x.SegmentName == matchedSegmentId).Discount : 0;
		}
		return this.state.theumaSpecificHeaderDiscount.find(x=>x.SegmentId == matchedSegmentId).Discount;

	}

	ValidateUserRoleDiscountLimits=(currentHeaderDiscount, newDiscountValue, prevDiscountValue)=>{

	const { userRoleDetail} = this.context;
	var limitsOfARole =	userRoleDetail.find(x=>x.Area === 'Discount');
	var currentDefaultTheumaSegment = this.state.theumaSpecificHeaderDiscount.find(x=>x.SegmentId == currentHeaderDiscount.SegmentId);

	var defaultDiscount = this.getDefaultDiscount(currentHeaderDiscount.SegmentId);
	var tempSegmentNeedsApproval = this.state.headerDiscountNeedApprovalSegments;
	var tempSegmentWithoutsApproval = this.state.headerDiscountWithoutApprovalSegments;
	if (limitsOfARole.Limit > 0 && newDiscountValue > limitsOfARole.Limit)
	{
		this.updateConditionalSegmentApproval(tempSegmentWithoutsApproval, currentDefaultTheumaSegment, tempSegmentNeedsApproval);
		tempSegmentNeedsApproval.push({
			'Id':currentHeaderDiscount.Id,
			'SegmentName':currentDefaultTheumaSegment.SegmentId,
			'SegmentDescription':currentDefaultTheumaSegment.Remarks,
			'CurrentDiscount':currentHeaderDiscount.Discount,
			'NewDiscount':newDiscountValue
		});

	}
	else if ((newDiscountValue- defaultDiscount) > limitsOfARole.StepLimit && limitsOfARole.StepLimit > 0)
	{
		this.updateConditionalSegmentApproval(tempSegmentWithoutsApproval, currentDefaultTheumaSegment, tempSegmentNeedsApproval);
	
		tempSegmentNeedsApproval.push({
			'Id':currentHeaderDiscount.Id,
			'SegmentName':currentDefaultTheumaSegment.SegmentId,
			'SegmentDescription':currentDefaultTheumaSegment.Remarks,
			'CurrentDiscount':currentHeaderDiscount.Discount,
			'NewDiscount':newDiscountValue
		});
	}
	else if (limitsOfARole.Limit > 0 && newDiscountValue < limitsOfARole.Limit)
	{
       	this.updateConditionalSegmentApproval(tempSegmentWithoutsApproval, currentDefaultTheumaSegment, tempSegmentNeedsApproval);
		tempSegmentWithoutsApproval.push({
			'Id':currentHeaderDiscount.Id,
			'SegmentName':currentDefaultTheumaSegment.SegmentId,
			'SegmentDescription':currentDefaultTheumaSegment.Remarks,
			'CurrentDiscount':currentHeaderDiscount.Discount,
			'NewDiscount':newDiscountValue
		});
		this.setState({ headerDiscountWithoutApprovalSegments: tempSegmentWithoutsApproval });
	}
	
	this.setState({ headerDiscountNeedApprovalSegments: tempSegmentNeedsApproval });

	}

	getHeaderDiscountSegmentsList =()=>{	
		var segmentName = '';
		for(let i=0; i < this.state.headerDiscountNeedApprovalSegments.length; i++)
		{
			segmentName = segmentName + '\n' + this.state.headerDiscountNeedApprovalSegments[i].SegmentDescription + " - " 
			                + this.state.headerDiscountNeedApprovalSegments[i].NewDiscount ;
		}

		return segmentName;
	}

	onDiscountApprovalCommentChange = (value, index) =>{
		let tempApprovalNeededUserTraces = this.state.userTracesApprovalNeededData;
		tempApprovalNeededUserTraces[index].ApprovalComments = value;
		tempApprovalNeededUserTraces[index].AppliedComment = value;
		this.setState({ userTracesApprovalNeededData: tempApprovalNeededUserTraces });

	}

	onDiscountApprovalNewValueChange = (value, index) =>{
		let tempApprovalNeededUserTraces = this.state.userTracesApprovalNeededData;
        let isDouble = false;
		isDouble = this.state.userTracesRejectedApprovalNeededData.some(item => item.Id == tempApprovalNeededUserTraces[index].Id) ;
		
		this.setState({
			['DISCOUNT_SEGMENT_VALUE_ERROR_' + index ]: ''
		});
		if (value === '' ) {
		 	tempApprovalNeededUserTraces[index].NewValue = '';
		 	this.setState({
		 		['DISCOUNT_SEGMENT_VALUE_ERROR_' + index ]: i18n.t('HISTORY_DISCOUNT_EMPTY_VALUE_MESSAGE'),
			});
		 }
         else if ( isNaN(value)) {
			tempApprovalNeededUserTraces[index].NewValue = tempApprovalNeededUserTraces[index].NewValue;
			this.setState({
				['DISCOUNT_SEGMENT_VALUE_ERROR_' + index ]: i18n.t('HISTORY_DISCOUNT_EMPTY_STRING_MESSAGE'),
			});
		}
		else if (parseFloat(value).toFixed(2) <= 0 || parseFloat(value).toFixed(2) > 100) {					
			tempApprovalNeededUserTraces[index].NewValue = tempApprovalNeededUserTraces[index].NewValue;
			this.setState({
				['DISCOUNT_SEGMENT_VALUE_ERROR_' + index ]: i18n.t('DISCOUNT_RANGE_MESSAGE'),
			});
		} 		
		else {
			if(!isDouble)
			{						
				this.state.userTracesRejectedApprovalNeededData.push({
				ApprovalComments : i18n.t('REJECTED'),
				Comments : tempApprovalNeededUserTraces[index].Comments,
				Entity: tempApprovalNeededUserTraces[index].Entity,
				EventTime: tempApprovalNeededUserTraces[index].EventTime,
				EventType: tempApprovalNeededUserTraces[index].EventType,
				Id: tempApprovalNeededUserTraces[index].Id,
				IsApprovalRequired: tempApprovalNeededUserTraces[index].IsApprovalRequired,
				LevelText: tempApprovalNeededUserTraces[index].LevelText,
				NewValue: tempApprovalNeededUserTraces[index].NewValue,
				OldValue: tempApprovalNeededUserTraces[index].OldValue,
				QuotationId: tempApprovalNeededUserTraces[index].QuotationId,
				ReferenceId: tempApprovalNeededUserTraces[index].ReferenceId,
				UserGuid: tempApprovalNeededUserTraces[index].UserGuid,
				UserName: tempApprovalNeededUserTraces[index].UserName						
				});		
			}
				tempApprovalNeededUserTraces[index].NewValue = value;			
			    this.setState({
				['DISCOUNT_SEGMENT_VALUE_ERROR_' + index]: '',
			});
		}
		this.setState({ userTracesApprovalNeededData: tempApprovalNeededUserTraces  });
	}

	setDiscountApprovalState = (flag, index) =>{
		let tempApprovalNeededUserTraces = this.state.userTracesApprovalNeededData;
		if(flag === 0)
		{						
			let isDataExist = false;
			isDataExist = this.state.userTracesRejectedApprovalNeededData.some(item => item.Id == tempApprovalNeededUserTraces[index].Id) ;
			if (isDataExist)
			{
				tempApprovalNeededUserTraces[index].NewValue = this.state.userTracesRejectedApprovalNeededData.find(item => item.Id == tempApprovalNeededUserTraces[index].Id).NewValue;
				this.state.userTracesRejectedApprovalNeededData.splice(	this.state.userTracesRejectedApprovalNeededData.findIndex(item => item.Id == tempApprovalNeededUserTraces[index].Id),1);
			}
		   tempApprovalNeededUserTraces[index].IsApproved = false;					
		}
		else
		{
			tempApprovalNeededUserTraces[index].IsApproved = true;			
		}
		this.setState({ userTracesApprovalNeededData: tempApprovalNeededUserTraces });
	}

	headerDiscountChange = (value, index) => {

		let headerDiscount = this.state.headerDiscount;
		let tempHeaderDiscount_local = this.state.tempHeaderDiscount;
		value = value.trim();
		if (!isNaN(value)) {
			this.ValidateUserRoleDiscountLimits(headerDiscount[index],value, this.state.tempHeaderDiscount[index].Discount);
			tempHeaderDiscount_local[index].Discount = value;			

			this.setState({ tempHeaderDiscount: tempHeaderDiscount_local });
		}
	};
	confirmHeaderDiscountUpdate = () => {
		const { selectedLanguage, accountInfo } = this.context;
		this.setState({ isHeaderDiscountInvalid: false, loading: true });

		let data = {
			QuotationSegments:  this.state.tempHeaderDiscount,
			QuotationId: this.state.quotationData.QuotationId,
			ApplyForExistingLines: this.state.isApplyForExistingLines,
			AccountNumber: this.state.quotationData.ClientNumber,
			Language: selectedLanguage,
			UserName: accountInfo.account.name,
			UserGuid: accountInfo.account.accountIdentifier,
			Comments:  this.state.headerDiscountComments,
			QuotationTitle: this.state.quotationData.Title,
			OpportunityNumber: this.state.quotationData.OpportunityNumber,
			AccountName : this.state.quotationData.AccountName,
			QuotationSegmentsForApproval:this.state.headerDiscountNeedApprovalSegments,
			QuotationSegmentsForEmailCreator : this.state.headerDiscountWithoutApprovalSegments,
			SalesOrganization : this.state.quotationData.SalesOrganization,
			UserTraceText: i18n.t('HEADER_DISCOUNT_USER_TRACE_TEXT') ,
			UserTraceTextApplied : i18n.t('HEADER_DISCOUNT_USER_TRACE_APPLIED_TEXT') ,
			CreatedByGuid :  this.state.quotationData.CreatedByGuid,
			QuotationUrl : window.location.origin + '/quotation/view?id=' + this.state.quotationData.QuotationId ,
			Environment : process.env.REACT_APP_ICON_ENVIRONMENT,
			DiscountComment : this.state.headerDiscountComments
		};
		let isValid = true;
		let validValue = 0;
		for (let i = 0; i < data.QuotationSegments.length; i++) {
			validValue = parseFloat(data.QuotationSegments[i].Discount);
			this.state.tempHeaderDiscount[i].isHeaderDiscountInvalid = false;
			if (validValue < 0 || validValue > 100) {
				isValid = false;
				this.state.tempHeaderDiscount[i].isHeaderDiscountInvalid = true;
			}
			data.QuotationSegments[i].Discount = validValue;
		}		

		if (!isValid) {
			this.setState({ isHeaderDiscountInvalid: true, loading: false });
			return;
		}		

		saveQuotationSegments(data)
		.then((response) => {
				this.setState({
					showDiscountHeaderPopUp: false,
					headerDiscount: [],
					tempHeaderDiscount:[],
					loading: false,
					isHeaderDiscountInvalid: false,
					headerDiscountComments: '',
					showHeaderDiscountApprovalFlow:false,
					showQLDiscountApprovalFlow:false,
					headerDiscountNeedApprovalSegments:[],
					headerDiscountWithoutApprovalSegments:[]
				});
				if (this.state.isApplyForExistingLines) this.getQuotationLineDetailsConf(0);
			})
			.catch((reason) => {this.handleError(reason)});

	}

	formatDiscountValue = (value) =>{
		if(isNaN(value))
		{
			if(value)
			{
				return value;
			}
			else
			{
				return '-';
			}
		}
		else if(value)
		{
			
			return value % 1!=0 ? parseFloat(value).toFixed(2) : parseFloat(value).toFixed(0);
		}
		else
		{
			return '-';
		}
	}

	sendQuotationLineDetailDiscountForApproval = () =>{

		const { selectedLanguage, accountInfo } = this.context;
		this.setState({ isHeaderDiscountInvalid: false, loading: true });

		let data = {			
			QuotationId: this.state.quotationData.QuotationId,		
			AccountNumber: this.state.quotationData.ClientNumber,
			Language: selectedLanguage,
			UserName: accountInfo.account.name,
			UserGuid: accountInfo.account.accountIdentifier,
			Comments: this.state.qLineDetailDiscountComments,
			QuotationTitle: this.state.quotationData.Title,
			OpportunityNumber: this.state.quotationData.OpportunityNumber,
			AccountName : this.state.quotationData.AccountName,		
			SalesOrganization : this.state.quotationData.SalesOrganization,
			UserTraceText: i18n.t('QUOTATION_DETAIL_DISCOUNT_USER_TRACE_TEXT'),
			QuotationUrl:window.location.origin + '/quotation/view?id=' + this.state.quotationData.QuotationId + '&qLineId=' + this.state.quotationLineIdForEmail + '&qLineDetailId=' +this.state.quotationLineDetailIdForEmail +'&discountValue='+this.state.quotationLineDetailNewDiscount,
			QuotationLineDetailId : this.state.quotationLineDetailIdForEmail,
			NewDiscount : this.state.quotationLineDetailNewDiscount,
			SequenceNumber : this.state.quotationLineSequenceNumber,
			ProductTypeDescription : this.state.quotationLineDetailOptionDescription,
			CreatedByGuid : this.state.quotationData.CreatedByGuid,
			LimitExceeded : this.state.limitExceeded,
			OldDiscountValue : this.state.quotationLineOldDiscountValue,
			Environment : process.env.REACT_APP_ICON_ENVIRONMENT
		};

		notifyOfDiscountRequests(data)
		.then((responseData) => {				
				this.setState({ showQLDiscountApprovalFlow: false,
					 quotationLineDetailIdForEmail:0 , 
					 quotationLineIdForEmail :0,
					quotationLineDetailNewDiscount :0,
					quotationLineSequenceNumber:'',
					quotationLineDetailOptionDescription:'',
					quotationLineOldDiscountValue :0
				 });
				this.getQuotationLineDetailsConf(0);				
			})
			.catch((reason) => {
				this.handleError(reason)
				this.setState({ quotationLineDetailIdForEmail:0 ,
					quotationLineIdForEmail:0,
					 quotationLineDetailNewDiscount :0,
					quotationLineSequenceNumber:'',
					quotationLineDetailOptionDescription:'',
					quotationLineOldDiscountValue : 0 });
			});

	}

	headerDiscountSave = (applyForExistingLines) => {
		
		if(this.state.headerDiscountNeedApprovalSegments.length>0)
		{
			this.setState({showHeaderDiscountApprovalFlow:true, loading: false});
		}
		else
		{	
			this.setState({isApplyForExistingLines:applyForExistingLines} , () => {
				this.confirmHeaderDiscountUpdate();
			});			
		
		}
	};

	refreshAccountDiscounts = () =>{

		this.saveAccountDiscounts(this.state.quotationData.QuotationId);
		

	}

	saveAccountDiscounts = (quotationId) =>{
		const {  accountInfo } = this.context;
		this.setState({ loading: true });
	
		let data = {
			Account:this.state.quotationData.ClientNumber,			
			OpportunityNumber: this.state.quotationData.OpportunityNumber,			
			QuotationId : quotationId,
			CommentPrefix : i18n.t('REFRESH_COMMENT'),
			UserName : accountInfo.account.name,
			CreatedOn : this.state.quotationData.Date
		}

		refreshAccountDiscounts(data)
		.then((response) => {
			this.onHeaderDiscountClick();
			this.setState({showAccountRefreshWarning:false});	
			toast.success(<Trans i18nKey='REFRESH_SUCCESSFUL'>REFRESH_SUCCESSFUL</Trans>);					
					
		}).catch((reason) => {this.handleError(reason)});
	}
	
	onDiscountApprovalOk = () =>{

		let tempUserTraces = this.state.userTracesApprovalNeededData;
		let tempRejectedUserTraces = this.state.userTracesRejectedApprovalNeededData;
	
		// Set the approval comments.
		for (let i = 0; i < tempUserTraces.length; i++)
		{	
			// When the discount is approved:
			// prepends the ApprovalComment(s) with "Approved" and prepends "Applied" to the ApprovalComment
			if(tempUserTraces[i].IsApproved)
			{					
				tempUserTraces[i].ApprovalComments =  i18n.t('APPROVED') +
											(tempUserTraces[i].ApprovalComments == null ? '' : tempUserTraces[i].ApprovalComments);
				
				tempUserTraces[i].AppliedComment = i18n.t('APPLIED') + (tempUserTraces[i].AppliedComment == null ? '' : tempUserTraces[i].AppliedComment);
			}
			else if(tempUserTraces[i].IsApproved == false)
			{
				tempUserTraces[i].ApprovalComments = i18n.t('REJECTED') + tempUserTraces[i].ApprovalComments;					                           
			}
		}
			
		// Loop over all the rejected discounts and add them to the usertraces.
		for (let i = 0; i < tempRejectedUserTraces.length; i++) {
			let value = '';
			value= tempUserTraces.find(item => item.Id == tempRejectedUserTraces[i].Id).ApprovalComments;
			tempUserTraces.push({
				ApprovalComments : i18n.t('REJECTED') + value.replace(i18n.t('APPROVED'),''),
				Comments : tempRejectedUserTraces[i].Comments,
				Entity: tempRejectedUserTraces[i].Entity,
				EventTime: tempRejectedUserTraces[i].EventTime,
				EventType: tempRejectedUserTraces[i].EventType,
				Id: tempRejectedUserTraces[i].Id,
				IsApprovalRequired: tempRejectedUserTraces[i].IsApprovalRequired,
				IsApproved: false,
				LevelText: tempRejectedUserTraces[i].LevelText,
				NewValue: tempRejectedUserTraces[i].NewValue,
				OldValue: tempRejectedUserTraces[i].OldValue,
				QuotationId: tempRejectedUserTraces[i].QuotationId,
				ReferenceId: tempRejectedUserTraces[i].ReferenceId,
				UserGuid: tempRejectedUserTraces[i].UserGuid,
				UserName: tempRejectedUserTraces[i].UserName						
			});
		}		
		
		this.setState({ loading: true });
		const { selectedLanguage, accountInfo } = this.context;		

		let data = {
			UserTraces : this.state.userTracesApprovalNeededData,
			AccountNumber: this.state.quotationData.ClientNumber,
			Language: selectedLanguage,
			UserName: accountInfo.account.name,			
			OpportunityNumber: this.state.quotationData.OpportunityNumber,
			AccountName : this.state.quotationData.AccountName,		
			SalesOrg : this.state.quotationData.SalesOrganization,		
			QuotationUrl : window.location.origin + '/quotation/view?id=' + this.state.quotationData.QuotationId ,		
			CreatedByGuid : this.state.quotationData.CreatedByGuid,		
			Environment : process.env.REACT_APP_ICON_ENVIRONMENT,
			QuotationDescription : this.state.quotationData.Title,
			LoggedInUserGuid:accountInfo.account.accountIdentifier,
		};

		processDiscountRequests(data)
		.then((response) => {				
				this.setState({ userTracesApprovalNeededData: [],userTracesRejectedApprovalNeededData: [],showApprovalNeededUserTracesPopup:false,loading:false });
				this.getQuotationLineDetailsConf(0);								
			})
			.catch((reason) => {this.handleError(reason)});
	}

	enableDiscountApprovalSaveButton = () =>{	
		if(this.state.userTracesApprovalNeededData)
		{
			const rejectedDiscounts = this.state.userTracesApprovalNeededData.filter(x=>x.IsApproved == false);
			const approvedDiscounts = this.state.userTracesApprovalNeededData.filter(x=>x.IsApproved);
						
			 if (approvedDiscounts.length > 0 || rejectedDiscounts.length > 0)
			{
				return true;
			}
			
		}
		return false;
	
	}

	manualDiscountUserChoice = (keepManualDiscount) => {
		let data = [];
		this.setState({ loading: true });
		const { accountInfo,selectedQuotationId } = this.context;
		for (let i = 0; i < this.state.manualDiscountData.length; i++) {
			data.push({
				Id: this.state.manualDiscountData[i].QuotationLineDetailId,
				Discount: keepManualDiscount
					? this.state.manualDiscountData[i].ExistingDiscount
					: this.state.manualDiscountData[i].CalculatedDiscount,
				IsDouble: this.state.manualDiscountData[i].IsDouble,
				IsManual: keepManualDiscount,
				UserName: accountInfo.account.name,
				UserGuid: accountInfo.account.accountIdentifier,
				Comments: this.state.generalDiscountComments,
				QuotationId : selectedQuotationId
			});
		}

		applyManualDiscountForAll(data)
		.then((responseData) => {
				const { selectedQuotationId } = this.context;
				this.setState({ showGeneralDiscountComments: false });
				window.parent.window.location.href = '/quotation/view?id=' + selectedQuotationId;
			})
			.catch((reason) => {this.handleError(reason)});
	};
	econfDetails = () => {

		const { viewModeOnly,selectedQuotationLineId,selectedQuotationLineDetailId } = this.context;
		let scripts = [];

		let actualQuotationLineCount = 
			this.state.quotationLines.filter((x) => x.BaseQuotationLineId == null).length;
		let loopCount = 0;

		// Loop 1: (i) Over all QLs
		for (let i = 0; i < this.state.quotationLines.length; i++) {
			
			if (this.state.quotationLines[i].BaseQuotationLineId == null) loopCount++;
			let lineDetails = this.state.quotationLines[i].QuotationLineDetails;
			let linesTotalPrice = 0;
			let quantity = this.state.quotationLines[i].Quantity;
			let hasNetPriceAgreement = this.state.quotationLines[i].SetId && this.state.quotationLines[i].Price !== 0;

			let allLinesDesc = '';
			let allLinesNetPrice = 0;
			
			// Get fixed attribute values
			this.getDimensionValuesFromAttributes(this.state.quotationLines[i].Attributes,this.state.quotationLines[i].Id);
		
			// Loop 2: (k) over all QLDs
			for (let k = 0; k < lineDetails.length; k++) {

				let discount = lineDetails[k].Discount;
				var grossUnitPrice = lineDetails[k].Price;
				let optionPrices = 0;
				
				// Get fixed attribute values
				this.getDimensionValuesFromAttributes(lineDetails[k].Attributes, this.state.quotationLines[i].Id);

				// Loop 3: (j) over all Attributes. Add all additional prices from attributes
				for (let j = 0; j < lineDetails[k].Attributes.length; j++) {
					optionPrices = optionPrices + lineDetails[k].Attributes[j].AdditionalPrice;
				}

				// Add option prices to total.
				grossUnitPrice = grossUnitPrice + optionPrices

				// Apply discount
				let netUnitPrice = grossUnitPrice - grossUnitPrice * (discount / 100);

				// Apply rate changes
				if(this.state.quotationData != null && this.state.quotationData.RateChangeType.toLowerCase() !== 'none')
				{
					if(this.state.quotationData.RateChangeType.toLowerCase() === 'percentageincrease')
					{
						netUnitPrice = netUnitPrice + ((netUnitPrice / 100) * this.state.quotationData.RateChange);
					}

					if(this.state.quotationData.RateChangeType.toLowerCase() === 'percentagedecrease')
					{
						netUnitPrice = netUnitPrice - ((netUnitPrice / 100) * this.state.quotationData.RateChange);
					}
				}

				// Take quantity into account
				let netTotalPrice = netUnitPrice * quantity;

				// Sum up the totals
				linesTotalPrice = linesTotalPrice + netTotalPrice;

				allLinesNetPrice += netUnitPrice;

				// Add descriptions for all details
				if (lineDetails[k].NameData) 
					allLinesDesc += lineDetails[k].NameData.OptionDescription + '\n';
				else 
					allLinesDesc += lineDetails[k].Name ?? '';
			}

			// Dealing with a set (net agreement).
			if (hasNetPriceAgreement) 
			{			
				// Add set-description
				allLinesDesc = this.state.quotationLines[i].SetData.OptionDescription;

				// Add all additional prices from all attributes
				let optionPrices = 0;
				for (let x = 0; x < lineDetails.length; x++) {

					for (let y = 0; y < lineDetails[x].Attributes.length; y++) {
						optionPrices = optionPrices + lineDetails[x].Attributes[y].AdditionalPrice;
					}
				}

				allLinesNetPrice = this.state.quotationLines[i].Price + optionPrices;

				linesTotalPrice = allLinesNetPrice * quantity;
			}
			
			scripts.push(
				<div className='container-fluid doorway-panel mb-4' key={i}>
					<div className='row'>
						<div className={this.state['A' + this.state.quotationLines[i].Id] ? 'col-12' : 'col-6'}>
							<div className={ (selectedQuotationLineId > 0) && (this.state.quotationLines[i].Id == selectedQuotationLineId) ? 'row header-row-view discountQLineNotification' : 'row header-row-view'}>
								<div className='move-buttons'>
									<div
										id={'moveUp' + i}
										className={
											'move-up-icon' + (i == 0 ||viewModeOnly ||
											this.state.isSearchApplied ||
											this.state.quotationLines[i].BaseQuotationLineId > 0 ||
											this.state.isSimpleSearchApplied
												? ' icon-disabled'
												: '')
										}
										onClick={() => {
											if (
												i != 0 &&
												this.state.quotationLines[i].BaseQuotationLineId == null
											)
												this.onMoveUpQuotationLine(this.state.quotationLines[i], 1);
										}}
									></div>
									<div
										id={'moveDown' + i}
										className={
											'move-down-icon' +
											(loopCount === actualQuotationLineCount ||
											viewModeOnly ||
											this.state.isSearchApplied ||
											this.state.quotationLines[i].BaseQuotationLineId > 0 ||
											this.state.isSimpleSearchApplied
												? ' icon-disabled'
												: '')
										}
										onClick={
											loopCount !== actualQuotationLineCount &&
											this.state.quotationLines[i].BaseQuotationLineId == null
												? () => {
														this.onMoveDownQuotationLine(
															this.state.quotationLines[i],
															0
														);
												  }
												: () => {
														return;
												  }
										}
									></div>
								</div>
								<div
									id={'sequenceNumber' + i}
									className={
										'sequence-number ' +
										(this.state.quotationLines[i].BaseQuotationLineId > 0
											? 'alternative-color alternative-sequence'
											: '')
									}
								>
									{this.state.quotationLines[i].SequenceUI}
								</div>
								<div
									className={
										'heading-left-arrow ' +
										(this.state.quotationLines[i].BaseQuotationLineId > 0
											? 'alternative-color'
											: '')
									}
									id={'headingLeftArrow' + i}
									onClick={() => this.toggleAccordianQuotationLine(i)}
									dangerouslySetInnerHTML={{
										__html: this.state['B' + this.state.quotationLines[i].Id]
									}}
								></div>

								{!viewModeOnly && (
									<Dropdown
										isOpen={this.state['Kebab' + this.state.quotationLines[i].Id]}
										toggle={() => this.toggleKebabDD(this.state.quotationLines[i].Id)}
										className='kebab-menu-dropdown'
										id={'kebabMenu' + i}
									>
										<DropdownToggle>
											<div
												className={
													'kebab-menu ' +
													(this.state.quotationLines[i].BaseQuotationLineId > 0
														? 'alternative-color'
														: '')
												}
											></div>
										</DropdownToggle>

										{this.state.quotationLines[i].BaseQuotationLineId == null ? (
											<DropdownMenu>

												<DropdownItem
													onClick={() => this.editEconSession(this.state.quotationLines[i])}
												>
													<div className='kebab-option' id={'configureQuotation' + i}>
														<div className='configure-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															{this.state.quotationLines[i].IsIndividualArticle ? (
																<Trans i18nKey='CONFIGURE_INDIVIDUAL_ARTICLE'>
																	CONFIGURE_INDIVIDUAL_ARTICLE
																</Trans>
															) : (
																<Trans i18nKey='CONFIGURE_QUOTATION'>
																	CONFIGURE_QUOTATION
																</Trans>
															)}
														</div>
													</div>
												</DropdownItem>

												{!this.state.quotationLines[i].IsIndividualArticle && (
													<>
														<DropdownItem
															onClick={() =>
																this.copyQuotationLine(
																	this.state.quotationLines[i]
																)
															}
														>
															<div className='kebab-option' id={'copyQuotation' + i}>
																<div className='copy-icon-kebab kebab-option-icon'></div>
																<div className='kebab-option-desc'>
																	<Trans i18nKey='COPY_QUOTATION'>
																		COPY_QUOTATION
																	</Trans>
																</div>
															</div>
														</DropdownItem>
														<DropdownItem
															onClick={() =>
																this.openAddAlternativeModal(
																	this.state.quotationLines[i]
																)
															}
														>
															<div className='kebab-option' id={'addAlternative' + i}>
																<div className='alternative-icon-kebab kebab-option-icon'></div>
																<div className='kebab-option-desc'>
																	<Trans i18nKey='ALTERNATIVES'>ALTERNATIVES</Trans>
																</div>
															</div>
														</DropdownItem>
													</>
												)}
												<DropdownItem
													onClick={() =>
														this.deleteQuotationLine(
															this.state.quotationLines[i],
															false
														)
													}
												>
													<div className='kebab-option' id={'deleteQuotation' + i}>
														<div className='delete-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='DELETE_QUOTATION'>DELETE_QUOTATION</Trans>
														</div>
													</div>
												</DropdownItem>

												<DropdownItem
													onClick={() =>
														this.addNotesQuotationLine(this.state.quotationLines[i])
													}
												>
													<div className='kebab-option' id={'deleteQuotation' + i}>
														<div className='quotationline-notes-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='ADD_EDIT_NOTES'>ADD_EDIT_NOTES</Trans>
														</div>
													</div>
												</DropdownItem>

												{!this.state.quotationLines[i].IsIndividualArticle && (
													<DropdownItem
														onClick={() =>
															this.showAttributesAndDoorwayInfo(
																this.state.quotationLines[i]
															)
														}
													>
														<div className='kebab-option' id={'deleteQuotation' + i}>
															<div className='attributes-icon-kebab kebab-option-icon'></div>
															<div className='kebab-option-desc'>
																<Trans i18nKey='SHOW_ATTRIBUTES_AND_DOORWAY_INFO'>
																	SHOW_ATTRIBUTES_AND_DOORWAY_INFO
																</Trans>
															</div>
														</div>
													</DropdownItem>
												)}
											</DropdownMenu>
										) : (
											<DropdownMenu>
												<DropdownItem
													onClick={() =>
														this.onSwapAlternative(this.state.quotationLines[i])
													}
												>
													<div className='kebab-option' id={'deleteQuotation' + i}>
														<div className='activate-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='ACTIVATE_ALTERNATIVE'>
																ACTIVATE_ALTERNATIVE
															</Trans>
														</div>
													</div>
												</DropdownItem>
												<DropdownItem
													onClick={() =>
														this.onEditAlternative(this.state.quotationLines[i])
													}
												>
													<div className='kebab-option' id={'editAlternativeDesc' + i}>
														<div className='edit-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='EDIT_ALTERNATIVE_DESC'>
																EDIT_ALTERNATIVE_DESC
															</Trans>
														</div>
													</div>
												</DropdownItem>
												<DropdownItem
													onClick={() =>
														this.editEconSessionAlternative(
															this.state.quotationLines[i]
														)
													}
												>
													<div className='kebab-option' id={'configureAlternative' + i}>
														<div className='configure-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='CONFIGURE_QUOTATION'>
																CONFIGURE_QUOTATION
															</Trans>
														</div>
													</div>
												</DropdownItem>
												<DropdownItem
													onClick={() =>
														this.deleteQuotationLine(
															this.state.quotationLines[i],
															true
														)
													}
												>
													<div className='kebab-option' id={'deleteQuotation' + i}>
														<div className='delete-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='DELETE_QUOTATION'>DELETE_QUOTATION</Trans>
														</div>
													</div>
												</DropdownItem>
												<DropdownItem
													onClick={() =>
														this.addNotesQuotationLine(this.state.quotationLines[i])
													}
												>
													<div className='kebab-option' id={'deleteQuotation' + i}>
														<div className='quotationline-notes-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='ADD_EDIT_NOTES'>ADD_EDIT_NOTES</Trans>
														</div>
													</div>
												</DropdownItem>
												{!this.state.quotationLines[i].IsIndividualArticle && (
													<DropdownItem
														onClick={() =>
															this.showAttributesAndDoorwayInfo(
																this.state.quotationLines[i]
															)
														}
													>
														<div className='kebab-option' id={'deleteQuotation' + i}>
															<div className='attributes-icon-kebab kebab-option-icon'></div>
															<div className='kebab-option-desc'>
																<Trans i18nKey='SHOW_ATTRIBUTES_INFO'>
																	SHOW_ATTRIBUTES_INFO
																</Trans>
															</div>
														</div>
													</DropdownItem>
												)}
											</DropdownMenu>
										)}
									</Dropdown>
								)}
								{this.state['A' + this.state.quotationLines[i].Id] && (
									<div
										className={
											'heading-left-content ' +
											(this.state.quotationLines[i].BaseQuotationLineId > 0
												? 'alternative-color'
												: '')
										}
										id={'headingLeftContent' + i}
									>
										<div className='row'>
											<div
												className='col-12 heading-left-content-head'
												title={this.state.quotationLines[i].LineTitle}
											>
												{this.state.quotationLines[i].LineTitle}
											</div>
										</div>
										<div className='row'>
											<div className='col-12 heading-left-content-sub'>
												{this.logicToDisplaySystemDoubleDoorProfileProperties(
													this.getAttributeValue(
														this.state.quotationLines[i].Attributes,
														'System'
													),
													this.getAttributeValue(
														this.state.quotationLines[i].Attributes,
														'IsDouble'
													),
													this.getAttributeValue(
														this.state.quotationLines[i].Attributes,
														'Profile'
													)
												)}
											</div>
										</div>
									</div>
								)}
								
								<div className='ml-2 heading-right'>

									<div className=''>
										<div className=''>
											
											{this.getDimensionValue(
												'Doorwidth' + '_' + this.state.quotationLines[i].Id
											) &&
												!this.getDimensionValue(
													'Doorwidthpassive' + '_' + this.state.quotationLines[i].Id
												) && (
													<div
														id={'doorWidthIcon' + i}
														className={'doorWidth-icon alignCenter'}
													>
														<span>
															{this.getDimensionValue(
																'Doorwidth' +
																	'_' +
																	this.state.quotationLines[i].Id
															)}
														</span>
														<span> mm</span>
													</div>
												)}

											{this.getDimensionValue(
												'Doorwidthpassive' + '_' + this.state.quotationLines[i].Id
											) &&
												this.getDimensionValue(
													'Doorwidth' + '_' + this.state.quotationLines[i].Id
												) && (
													<div
														id={'doorWidthPassiveIcon' + i}
														className={'doorWidthPassive-icon alignCenter'}
													>
														<span>
															{this.getDimensionValue(
																'Doorwidth' +
																	'_' +
																	this.state.quotationLines[i].Id
															) + ' + '}

															{this.getDimensionValue(
																'Doorwidthpassive' +
																	'_' +
																	this.state.quotationLines[i].Id
															)}
														</span>
														<span> mm</span>
													</div>
												)}

											{this.getDimensionValue(
												'Doorheight' + '_' + this.state.quotationLines[i].Id
											) && (
												<div
													id={'doorHeightIcon' + i}
													className={'doorHeight-icon alignCenter'}
												>
													<span>
														{this.getDimensionValue(
															'Doorheight' + '_' + this.state.quotationLines[i].Id
														)}
													</span>
													<span> mm</span>
												</div>
											)}

											{this.getDimensionValue(
												'DoorThickness' + '_' + this.state.quotationLines[i].Id
											) && (
												<div
													id={'doorThicknessIcon' + i}
													className={'doorThickness-icon alignCenter'}
												>
													<span className='pr-2'>
														{this.getDimensionValue(
															'DoorThickness' +
																'_' +
																this.state.quotationLines[i].Id
														)}
													</span>
													<span className='pr-2'> mm</span>
												</div>
											)}
											{this.getDimensionValue(
												'Jamblength' + '_' + this.state.quotationLines[i].Id
											) && (
												<div
													id={'jamblengthIcon' + i}
													className={'jambHeight-icon alignCenter'}
												>
													<span>
														{this.getDimensionValue(
															'Jamblength' + '_' + this.state.quotationLines[i].Id
														)}
													</span>
													<span> mm</span>
												</div>
											)}
											{this.getDimensionValue(
												'Wallthickness' + '_' + this.state.quotationLines[i].Id
											) && (
												<div
													id={'wallThicknessIcon' + i}
													className={'wallThickness-icon alignCenter'}
												>
													<span>
														{this.getDimensionValue(
															'Wallthickness' +
																'_' +
																this.state.quotationLines[i].Id
														)}
													</span>
													<span> mm</span>
												</div>
											)}
											{this.state['A' + this.state.quotationLines[i].Id] && (
												<>
													<div
														id={'benorIcon' + i}
														className={
															'benor-icon-' +
															this.getAttributeValue(
																this.state.quotationLines[i].Attributes,
																'Benor'
															)
														}
													></div>
													<div
														id={'fireResistanceIcon' + i}
														className={
															'fireResistance-icon-' +
															this.getFireAlarmResistanceIcon(
																this.getAttributeValue(
																	this.state.quotationLines[i].Attributes,
																	'FireResistance'
																)
															)
														}
													></div>
													<div
														id={'acousticResistanceIcon' + i}
														className={
															'acousticResistance-icon-' +
															this.getAttributeValue(
																this.state.quotationLines[i].Attributes,
																'AcousticResistance'
															)
														}
													></div>
													<div
														id={'burglarResistanceIcon' + i}
														className={
															'burglarResistance-icon-' +
															this.getAttributeValue(
																this.state.quotationLines[i].Attributes,
																'BurglarResistance'
															)
														}
													></div>
													<div
														id={'smokeResistanceIcon' + i}
														className={
															'smokeResistance-icon-' +
															this.getSmokeResistanceIcon(
																this.getAttributeValue(
																	this.state.quotationLines[i].Attributes,
																	'SmokeResistance'
																)
															)
														}
													></div>
													<div
														id={'hygrothermalResistanceIcon' + i}
														className={
															'hygrothermalResistance-icon-' +
															this.getAttributeValue(
																this.state.quotationLines[i].Attributes,
																'HygrothermalResistance'
															)
														}
													></div>
												</>
											)}
											{this.state.quotationLines[i].Notes && (
												<div
													className='notes-icon-ql'
													onClick={() =>
														this.addNotesQuotationLine(this.state.quotationLines[i])
													}
												></div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>

						{!this.state['A' + this.state.quotationLines[i].Id] && (
							<div className='right-panel-container col-6'>
								<div className='right-panel'>
									<div className='quantity' id={'quantity' + i}>
										<span>{this.state.quotationLines[i].Quantity}x</span>
									</div>
									<div className='description' id={'description' + i}>
										<span>{allLinesDesc} </span>
									</div>
									<div
										className={
											'net-unit-price' +
											(this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]
												? ' color-red'
												: '')
										}
										title={
											this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]
												? i18n.t('INCOMPLETE_PRICE')
												: ''
										}
										id={'netUnitPrice' + i}
									>
										<span>
											<PriceComponent 
												value={allLinesNetPrice} 
												incompletePriceStateId={this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]} />
										</span>
									</div>
									<div
										className={
											'net-price' +
											(this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]
												? ' color-red'
												: '') +
											(this.state.quotationLines[i].BaseQuotationLineId > 0
												? ' alternative-net-price'
												: '')
										}
										title={
											this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]
												? i18n.t('INCOMPLETE_PRICE')
												: ''
										}
										id={'netPrice' + i}
									>
										<span>
											<PriceComponent 
												value={linesTotalPrice} 
												incompletePriceStateId={this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]} />
										</span>
									</div>
								</div>
							</div>
						)}
						<div className='col-12'>
							{this.state['A' + this.state.quotationLines[i].Id] && (
								<div className='row'>
									<div className='col-12 panel-content'>
										{this.state.quotationLines[i].SetId ? (
											<div>
												<div className='row'>
													<div className='col-12'>
														<table className='table set-table'>
															<tbody>
																<tr>
																	<td width='65%'>
																		{' '}
																		<span
																			id={
																				'arrowButton' +
																				this.state.quotationLines[i].Id
																			}
																			className='cursor-pointer'
																			dangerouslySetInnerHTML={{
																				__html: this.state[
																					'SET' +
																						this.state.quotationLines[
																							i
																						].Id
																				]
																			}}
																			onClick={() =>
																				this.setState({
																					['SET' + this.state.quotationLines[i].Id] :
																						this.state[
																							'SET' +
																								this.state.quotationLines[
																									i
																								].Id
																						] === closeAccordian
																							? openAccordian
																							: closeAccordian
																				})
																			}
																		></span>
																		<span className='ml-2 line-detail-name'>
																			<Trans i18nKey='SET'>SET</Trans>{' '}
																			<span className='quantity-font'>
																				(x
																				{
																					this.state.quotationLines[i]
																						.Quantity
																				}
																				){' - '}
																			</span>
																			{
																				this.state.quotationLines[i]
																					.SetData.OptionDescription
																			}
																		</span>
																	</td>
																	<td width='35% set-price'>
																		<span
																			className={this.state['QLPriceIncomplete' + this.state.quotationLines[
																							i
																						].Id
																				]
																					? 'color-red'
																					: ''
																			}
																			title={
																				this.state[
																					'QLPriceIncomplete' +
																						this.state.quotationLines[
																							i
																						].Id
																				]
																					? i18n.t('INCOMPLETE_PRICE')
																					: ''
																			}
																		>
																			<span className='two-cols'>{i18n.t('NET_SET_PRICE')}</span>

																			<span className='two-cols-end bold-font'>
																				<PriceComponent 
																					value={allLinesNetPrice} 
																					incompletePriceStateId={false} />
																					{' x '}
																					{
																						this.state.quotationLines[i].Quantity
																					}
																					{' = '} 
																				<PriceComponent 
																					value={linesTotalPrice} 
																					incompletePriceStateId={this.state[
																						'QLPriceIncomplete' + this.state.quotationLines[i].Id]} />
																			</span>

																		</span>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												{this.state['SET' + this.state.quotationLines[i].Id] ==
													openAccordian && (
													<div className='ml-4'>
														{this.getLineDetailsAttributes(
															this.state.quotationLines[i].QuotationLineDetails,
															this.state.quotationLines[i].Id,
															this.state.quotationLines[i].Quantity,
															this.state.quotationLines[i].SequenceUI
														)}
													</div>
												)}
											</div>
										) : (
											this.getLineDetailsAttributes(
												this.state.quotationLines[i].QuotationLineDetails,
												this.state.quotationLines[i].Id,
												this.state.quotationLines[i].Quantity,
												this.state.quotationLines[i].SequenceUI
											)
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					{this.state['A' + this.state.quotationLines[i].Id] && (
						<>
							<div className='row'>
								<div className='col-12 panel-footer'>
									<table className='footer-table'>
										<tbody>
											<tr>
												<td></td>
												<td>
													<div className='two-cols footer-text'>
														<Trans i18nKey='TOTAL_DOOR_OPENING'>TOTAL_DOOR_OPENING</Trans>
													</div>
													<div className='two-cols-end footer-text bold-font'>
														<span
															className={
																this.state['QLPriceIncomplete' +this.state.quotationLines[i].Id]
																	? 'color-red'
																	: ''
															}
															title={
																this.state['QLPriceIncomplete' +this.state.quotationLines[i].Id]
																	? i18n.t('INCOMPLETE_PRICE')
																	: ''
															}
															id={'linesTotalPrice' + i}
														>
															{' '}
															<PriceComponent 
																value={linesTotalPrice} 
																incompletePriceStateId={this.state['QLPriceIncomplete' + this.state.quotationLines[i].Id]} />
														</span>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							{this.checkUpselling(this.state.quotationLines[i]) && (
								<div className='row panel-content'>
									<div className='col-12 upselling-data'>
										<div className='upselling-title'>
											<Trans i18nKey='UPSELLING_ATTRIBUTES'>UPSELLING_ATTRIBUTES</Trans>
										</div>
										{this.getUpsellingAttributes(this.state.quotationLines[i])}
									</div>
								</div>
							)}
						</>
					)}
				</div>
			);
		}
		return scripts;
	};
	checkUpselling = (quotationLine) => {
		let showUpselling = false;
		for (let i = 0; i < quotationLine.QuotationLineDetails.length; i++) {
			if (quotationLine.QuotationLineDetails[i].UpsellingAttributes.length > 0) {
				showUpselling = true;
				return showUpselling;
			}
		}
		return showUpselling;
	};
	getUpsellingAttributes = (quotationLine) => {
		let scripts = [];
		let lineDetails = quotationLine.QuotationLineDetails;

		// Iterate over the quotation details
		for (let i = 0; i < lineDetails.length; i++) {
			if (lineDetails[i].UpsellingAttributes.length === 0) continue;

			scripts.push(
				<div className='row'>
					<span
						id={'arrowButton' + lineDetails[i].Id}
						className='cursor-pointer'
						dangerouslySetInnerHTML={{
							__html: this.state['CUpselling' + quotationLine.Id + lineDetails[i].Id]
						}}
						onClick={() =>
							this.setState({
								['CUpselling' + quotationLine.Id + lineDetails[i].Id]:
									this.state['CUpselling' + quotationLine.Id + lineDetails[i].Id] === closeAccordian
										? openAccordian
										: closeAccordian,
								['DUpselling' + quotationLine.Id + lineDetails[i].Id]:
									!this.state['DUpselling' + quotationLine.Id + lineDetails[i].Id]
							})
						}
					></span>
					<span className='ml-2 line-detail-name'>
						<b>
						<Trans i18nKey={lineDetails[i].ProductType}>{lineDetails[i].ProductType}</Trans>{' '}
							{lineDetails[i].NameData && lineDetails[i].NameData.OptionDescription
								? ' - ' + lineDetails[i].NameData.OptionDescription
								: lineDetails[i].Name
								? ' - ' + lineDetails[i].Name
								: ''}{' '}
						</b>
					</span>
					{this.state['DUpselling' + quotationLine.Id + lineDetails[i].Id] && (
						<table className='table'>
							<thead>
								<tr>
									<th className='col-1'>
										<Trans i18nKey='UPSELLING_KEY'>UPSELLING_KEY</Trans>
									</th>
									<th className='col-2'>
										<Trans i18nKey='UPSELLING_VALUE'>UPSELLING_VALUE</Trans>
									</th>
									
									<th className='col-2'>
										<Trans i18nKey='UNIT_GROSS_PRICE'>UNIT_GROSS_PRICE</Trans>
									</th>
									<th className='col-1'>
										<Trans i18nKey='DISCOUNT'>DISCOUNT</Trans>
									</th>																						
									<th className='col-2'>
										<Trans i18nKey='UNIT_NET_PRICE'>UNIT_NET_PRICE</Trans>
									</th>	
									<th className='col-1'>
										<Trans i18nKey='QUANTITY'>QUANTITY</Trans>
									</th>
									<th className='col-2'>
										<Trans i18nKey='TOTAL_NET_PRICE'>TOTAL_NET_PRICE</Trans>
									</th>	
									<th className='col-2'>
									    <Trans i18nKey='COMMENT'>COMMENT</Trans>
									</th>
								</tr>
							</thead>
							<tbody>
								{lineDetails[i].UpsellingAttributes &&
									lineDetails[i].UpsellingAttributes.map((x, j) => {
										return (
											<tr>
												<td>{x.KeyData ? x.KeyData.OptionDescription : x.Key}</td>
												<td>{x.ValueData ? x.ValueData.OptionDescription : x.Value}</td>
																								
										    {x.IsNetPrice ? 
											(
												<React.Fragment>
													<td class='col-2'>{' - '}</td>	
													<td class='col-1'>{' - '}</td>
													<td class='col-2'>
														<PriceComponent 
															value={x.AdditionalPrice} 
															incompletePriceStateId={false} />
													</td>	

													<td>{quotationLine.Quantity}</td>
																									
													<td class='col-2'>
														<PriceComponent 
															value={quotationLine.Quantity * x.AdditionalPrice} 
															incompletePriceStateId={false} />
													</td>			

												</React.Fragment>		
											)
											 : 
											(
												<React.Fragment>

													<td class='col-2'>
														<PriceComponent 
															value={x.AdditionalPrice} 
															incompletePriceStateId={false} />
													</td>	
													<td class='col-1'>{lineDetails[i].Discount}</td>																					
													<td class='col-2'>
														<PriceComponent 
															value={(x.AdditionalPrice)-((x.AdditionalPrice) * lineDetails[i].Discount)/100} 
															incompletePriceStateId={false} />
													</td>

													<td>{quotationLine.Quantity}</td>
													
													<td class='col-2'>
														<PriceComponent 
															value={(quotationLine.Quantity * x.AdditionalPrice)-((quotationLine.Quantity * x.AdditionalPrice) * lineDetails[i].Discount)/100} 
															incompletePriceStateId={false} />
													</td>		

												</React.Fragment>
											)} 												
												<td>{x.Comment}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					)}
				</div>
			);
		}
		return scripts;
	};
	getLineAttributes = (attributes) => {
		let scripts = [];
		for (let i = 0; i < attributes.length; i++) {
			scripts.push(
				<tr>
					<td>{attributes[i].Key}</td>
					<td>{attributes[i].Value}</td>
				</tr>
			);
		}
		return scripts;
	};
	getLineDetailsAttributes = (lineDetails, index, quantity,sequenceNumber) => {
		let scripts = [];
		let linesTotalPrice = 0;

		const { viewModeOnly, userRoleDetail,selectedQuotationLineDetailId,discountValue } = this.context;
		var limitsOfARole =	userRoleDetail.find(x=>x.Area === 'Discount');
	
		// Iterate over all quotation details
		for (let i = 0; i < lineDetails.length; i++) {

			let discount = lineDetails[i].Discount;
			var grossUnitPrice = lineDetails[i].Price;

			// Iterate over and add all detail attribute prices
			for (let j = 0; j < lineDetails[i].Attributes.length; j++) {
				grossUnitPrice = grossUnitPrice + lineDetails[i].Attributes[j].AdditionalPrice;
			}

			// Apply discount
			let netUnitPrice = grossUnitPrice - grossUnitPrice * (discount / 100);

			// Apply rate changes
			if(this.state.quotationData != null && this.state.quotationData.RateChangeType.toLowerCase() !== 'none')
			{
				if(this.state.quotationData.RateChangeType.toLowerCase() === 'percentageincrease')
				{
					netUnitPrice = netUnitPrice + ((netUnitPrice / 100) * this.state.quotationData.RateChange);
				}

				if(this.state.quotationData.RateChangeType.toLowerCase() === 'percentagedecrease')
				{
					netUnitPrice = netUnitPrice - ((netUnitPrice / 100) * this.state.quotationData.RateChange);
				}
			}

			// Take quantity into account
			let netTotalPrice = netUnitPrice * quantity;

			// Sum up the totals
			linesTotalPrice = linesTotalPrice + netTotalPrice;

			scripts.push(
				<>
					<table className='table line-items-table'>
						<tbody>
							<tr>
								<td width='30%'></td>
								<td width='35%'></td>
								<td width='35%'></td>
							</tr>
							<tr>
								<td colSpan='2'>
									<span
										id={'arrowButton' + lineDetails[i].Id}
										className='cursor-pointer'
										dangerouslySetInnerHTML={{
											__html: this.state['C' + index + lineDetails[i].Id]
										}}
										onClick={() =>
											this.setState({
												['C' + index + lineDetails[i].Id]:
													this.state['C' + index + lineDetails[i].Id] === closeAccordian
														? openAccordian
														: closeAccordian,
												['D' + index + lineDetails[i].Id]:
													!this.state['D' + index + lineDetails[i].Id]
											})
										}
									></span>
									<span className='ml-2 line-detail-name'>
										<b>
										<Trans i18nKey={lineDetails[i].ProductType}>{lineDetails[i].ProductType}</Trans>{' '}
											<span className='quantity-font'>(x{quantity}) </span>
											{!this.state['D' + index + lineDetails[i].Id] ? (
												<>
													{lineDetails[i].NameData &&
													lineDetails[i].NameData.OptionDescription
														? ' - ' + lineDetails[i].NameData.OptionDescription
														: lineDetails[i].Name
														? ' - ' + lineDetails[i].Name
														: ''}{' '}
												</>
											) : null}
										</b>
									</span>
								</td>

								<td className='border-top-table'>
									{this.state['D' + index + lineDetails[i].Id] ? (
										<div
											className={
												this.state['LDPriceIncomplete' + lineDetails[i].Id + index]
													? 'color-red'
													: ''
											}
											title={
												this.state['LDPriceIncomplete' + lineDetails[i].Id + index]
													? i18n.t('INCOMPLETE_PRICE')
													: ''
											}
										>
											Aantal :{' '}
											<input
												id={'Quantity' + lineDetails[i].Id}
												type='text'
												value={quantity}
												readOnly={true}
												className='input-width ml-3 mr-3'
												disabled={viewModeOnly}
											/>{' '}
											<span className='bold-font'>
												x 
												<PriceComponent 
													value={netUnitPrice} 
													incompletePriceStateId={false} />
												=
												<PriceComponent 
													value={netTotalPrice} 
													incompletePriceStateId={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]} />
											</span>
										</div>
									) : (
										<div
											className={
												this.state['LDPriceIncomplete' + lineDetails[i].Id + index]
													? 'color-red'
													: ''
											}
											title={
												this.state['LDPriceIncomplete' + lineDetails[i].Id + index]
													? i18n.t('INCOMPLETE_PRICE')
													: ''
											}
										>
											<div className='two-cols'>
												<Trans i18nKey='NET_UNIT_PRICE'>NET_UNIT_PRICE</Trans>
											</div>
											<div
												className='two-cols-end bold-font'
												id={'netTotalPrice' + lineDetails[i].Id}
											>
												<PriceComponent 
													value={netUnitPrice} 
													incompletePriceStateId={false} />
												{' x '}
												{quantity} 
												=
												<PriceComponent 
													value={netTotalPrice} 
													incompletePriceStateId={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]} /> 
											</div>
										</div>
									)}
								</td>
							</tr>
							{this.state['D' + index + lineDetails[i].Id] &&
								((lineDetails[i].NameData && lineDetails[i].NameData.OptionDescription) ||
									lineDetails[i].Name) && (
									<tr className='first-row content-row'>
										<td className='line-detail-name' colSpan='2'>
											{lineDetails[i].NameData
												? lineDetails[i].NameData.OptionDescription
												: lineDetails[i].Name}
										</td>

										<td>
											{' '}
											<div className='float-left'>
												<Trans i18nKey='GROSS_BASIC_UNIT_PRICE'>GROSS_BASIC_UNIT_PRICE</Trans>
											</div>
											<div className='float-right bold-font'>
												<PriceComponent 
														value={lineDetails[i].Price} 
														incompletePriceStateId={false} />
											</div>
										</td>
									</tr>
								)}
							{this.state['D' + index + lineDetails[i].Id] &&
								this.innerLineDetails(
									lineDetails[i].Attributes,
									(lineDetails[i].NameData && lineDetails[i].NameData.OptionDescription) ||
										lineDetails[i].Name
								)}
							{this.state['D' + index + lineDetails[i].Id] && (
								<>
									<tr>
										<td className='grey'></td>
										<td className='grey'></td>
										<td>
											<div className='two-cols'>
												<Trans i18nKey='GROSS_UNIT_PRICE'>GROSS_UNIT_PRICE</Trans>
												<br/>
												<div className='float-left'>
													<Trans i18nKey='DISCOUNT'>DISCOUNT</Trans>
												</div>
												{
												lineDetails[i].Id > 0 && (
													<div
														className='history-icon'
														onClick={() => this.getUserTraceDiscount(lineDetails[i].Id,lineDetails[i].ProductType == 'INSTALLATION_PRICE',lineDetails[i].QuotationLineId)}
														title={i18n.t('DISCOUNT_TRACE_HEADER')}
													></div>
													
												)}

												{ (selectedQuotationLineDetailId > 0 && lineDetails[i].Id == selectedQuotationLineDetailId && limitsOfARole.Limit < discountValue ) && (
													<div
														className='discount-approval-icon'														
														title={i18n.t('DISCOUNT_APPROVAL_INFO')}
													/>	
												)}
												{(this.state.quotationData != null && this.state.quotationData.RateChangeType.toLowerCase() !== 'none') && (
													<React.Fragment>
														<br/>
														<div className='float-left'>{i18n.t('RATE_CHANGE_DETAIL')}</div>
													</React.Fragment>
												)}
											</div>

											<div className='two-cols-end bold-font'>

												<span
													className={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]? 'color-red': ''}
													title={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]? i18n.t('INCOMPLETE_PRICE'): ''}
												>
													<PriceComponent 
														value={grossUnitPrice} 
														incompletePriceStateId={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]} />	
												</span>

												<br />
												{this.state['DISCOUNT_' + index + lineDetails[i].Id] 
												? (
													<div className='discount-unit'>
														<div className='discount-input'>
															<input
																id={'inputDiscount' + lineDetails[i].Id}
																className='input-width'
																value={this.state[
																	'DISCOUNT_VALUE_' + index + lineDetails[i].Id
																].toString()}
																onChange={(e) =>
																	this.validateAndStoreDiscount(
																		e,
																		index,
																		lineDetails[i]
																	)
																}
															/>
														</div>
													</div>
												) : (
													<>
														{' '}
														{this.state['DISCOUNT_VALUE_' + index + lineDetails[i].Id].toString()}{' '}
														%{' '}
														{!viewModeOnly &&
															hasEditAccess(userRoleDetail, 'Discount') &&
															lineDetails[i].Id > 0 && (
																<div
																	id={'editDiscount' + lineDetails[i].Id}
																	className='edit-icon-discount'
																	onClick={() =>
																		this.setState({OldDiscountValue :lineDetails[i].Discount,
																			['DISCOUNT_' +
																			index +
																			lineDetails[i].Id]: true,
																		})
																	}
																></div>
															)}
													</>
												)}
												
												{(this.state.quotationData != null &&  this.state.quotationData.RateChangeType.toLowerCase() !== 'none') && (
													<React.Fragment>
														<br/>
														{this.state.quotationData.RateChangeType.toLowerCase() === 'percentageincrease' ?'+' : '-'}
														<FormattedNumber value={round(this.state.quotationData.RateChange, 2)}/>{' '}%
													</React.Fragment>
												)}
												<br />{' '}

											</div>
										</td>
									</tr>
									{this.state['DISCOUNT_' + index + lineDetails[i].Id] && (
										<tr>
											<td className='grey'></td>
											<td className='grey'></td>
											<td>
												<div className='row'>
													<div className='col-12'>
														<textarea
															id={'qLineDetailDiscountComments'}
															className='padding5px discount-comments-text-area'
															onChange={(e) =>
																this.setState({
																	qLineDetailDiscountComments: e.target.value
																})
															}
															disabled={viewModeOnly}
														></textarea>
													</div>
												</div>
												<div className='row'>
													<div className='col-12 edit-button-discount'>
														{this.state.qLineDetailDiscountComments.length > 0 && (
															<div
																id={'okDiscount' + lineDetails[i].Id}
																className='ok-icon-discount'
																onClick={() => {
																	if (
																		!this.state[
																			'DISCOUNT_FLAG_' + index + lineDetails[i].Id
																		]
																	) {
																		this.setState({
																			['DISCOUNT_' +
																			index +
																			lineDetails[i].Id]: false
																		});
																		let calc = (
																			lineDetails[i].Price -
																			(lineDetails[i].Price *
																				this.state[
																					'COMPLETE_DISCOUNT_VALUE' +
																						index +
																						lineDetails[i].Id
																				]) /
																				100
																		).toFixed(2);
																		this.setState({
																			['NETPRICE_' +
																			index +
																			lineDetails[i].Id]: false,
																			['NETPRICE_VALUE_' +
																			index +
																			lineDetails[i].Id]: round(netUnitPrice, 2)
																		});
																		if(lineDetails[i].ProductType == 'INSTALLATION_PRICE')
																		{
																			this.applyInstallationDiscount(lineDetails[i],this.state[
																				'COMPLETE_DISCOUNT_VALUE' +
																					index +
																					lineDetails[i].Id
																			])
																		}
																		else{
																			this.applyManualDiscount(
																				lineDetails[i],
																				this.state[
																					'COMPLETE_DISCOUNT_VALUE' +
																						index +
																						lineDetails[i].Id
																				],
																				lineDetails[i].QuotationLineId,
																				sequenceNumber
																			);
																		}
																		

																		lineDetails[i].Discount =
																			this.state[
																				'COMPLETE_DISCOUNT_VALUE' +
																					index +
																					lineDetails[i].Id
																			];
																	}
																}}
															></div>
														)}
														<div
															id={'cancelDiscount' + lineDetails[i].Id}
															className='cancel-icon-discount'
															onClick={() => {
																this.setState({
																	['DISCOUNT_VALUE_' + index + lineDetails[i].Id]:lineDetails[i].Discount.toFixed(2),
																	['DISCOUNT_ERROR_' + index + lineDetails[i].Id]: '',
																	['NETPRICE_' + index + lineDetails[i].Id]: false,
																	['NETPRICE_VALUE_' + index + lineDetails[i].Id]:round(netUnitPrice, 2),
																	['DISCOUNT_' + index + lineDetails[i].Id]: false,
																	oldDiscountValue : 0
																});
															}}
														></div>
													</div>
												</div>

												<div className='row'>
													<div className='col-12'>
														<span className='red-color-error'>
															{this.state['DISCOUNT_ERROR_' + index + lineDetails[i].Id]}
														</span>
													</div>
												</div>
											</td>
										</tr>
									)}
									<tr>

										<td className='grey'></td>
										<td className='grey'></td>
										<td>
											<div className='two-cols'>
												<Trans i18nKey='NET_UNIT_PRICE1'>NET_UNIT_PRICE1</Trans>
												<br />
												<Trans i18nKey='COUNT_NUMBER'>COUNT_NUMBER</Trans>
											</div>

											<div className='two-cols-end bold-font'>
												{this.state['NETPRICE_' + index + lineDetails[i].Id] ? (
													<div className='discount-unit'>
														<div className='discount-input'>
															<input
																id={'inputDiscount' + index + lineDetails[i].Id}
																className='input-width-second'
																value={this.state['NETPRICE_VALUE_' + index + lineDetails[i].Id]}
																onChange={(e) => this.validateAndStoreNetPrice(e,index,lineDetails[i])}
															/>
														</div>{' '}
													</div>
												) : (
													<>
														{' '}
														<span
															className={
																this.state[
																	'LDPriceIncomplete' + lineDetails[i].Id + index
																]
																	? 'color-red'
																	: ''
															}
															title={
																this.state[
																	'LDPriceIncomplete' + lineDetails[i].Id + index
																]
																	? i18n.t('INCOMPLETE_PRICE')
																	: ''
															}
														>
															<PriceComponent 
																value={this.state['NETPRICE_VALUE_' + index + lineDetails[i].Id]} 
																incompletePriceStateId={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]} />
														</span>
														{!viewModeOnly &&
															hasEditAccess(userRoleDetail, 'Discount') && lineDetails[i].Id > 0 && (
																<div
																	id={'editDiscount' + index + lineDetails[i].Id}
																	className='edit-icon-discount-second'
																	onClick={() =>
																		this.setState({OldDiscountValue :lineDetails[i].Discount,
																			['NETPRICE_' + index + lineDetails[i].Id]: true
																		})
																	}
																></div>
															)}{' '}
													</>
												)}
												<br />
												<div className='width-second'>x {quantity} </div>
												<br />{' '}
											</div>
										</td>
									</tr>

									{this.state['NETPRICE_' + index + lineDetails[i].Id] && (
										<tr>
											<td className='grey'></td>
											<td className='grey'></td>
											<td>

												<div className='row'>
													<div className='col-12'>

														<textarea
															id={'qLineDetailNetPriceComments'}
															className='padding5px discount-comments-text-area'
															onChange={(e) => this.setState({qLineDetailNetPriceComments: e.target.value})}
															disabled={viewModeOnly}
														/>

													</div>
												</div>

												<div className='row'>
													<div className='edit-button-discount col-12'>
														{this.state.qLineDetailNetPriceComments.length > 0 && (
															<div
																id={'okDiscount' + index + lineDetails[i].Id}
																className='ok-icon-discount'
																onClick={() => {
																	if (!this.state['NETPRICE_FLAG_' + index + lineDetails[i].Id]) {
																		this.setState({
																			['DISCOUNT_' + index + lineDetails[i].Id]: false,
																			['NETPRICE_' + index + lineDetails[i].Id]: false,
																			['NETPRICE_VALUE_' +index + lineDetails[i].Id]: round(netUnitPrice, 2)
																		});
																		
																		if(lineDetails[i].ProductType == 'INSTALLATION_PRICE')
																		{
																			this.applyInstallationDiscount(lineDetails[i],this.state[
																				'COMPLETE_DISCOUNT_VALUE' +
																					index +
																					lineDetails[i].Id
																			])
																		}
																		else 
																		{
																			this.applyManualDiscount(lineDetails[i],
																				this.state['COMPLETE_DISCOUNT_VALUE' +index +lineDetails[i].Id],
																				lineDetails[i].QuotationLineId,
																				sequenceNumber);
																		}

																		lineDetails[i].Discount =this.state['DISCOUNT_VALUE_' +index +lineDetails[i].Id];
																	}
																}}
															></div>
														)}

														<div
															id={'cancelDiscount' + index + lineDetails[i].Id}
															className='cancel-icon-discount'
															onClick={() => {
																this.setState({
																	['DISCOUNT_VALUE_' + index + lineDetails[i].Id]: lineDetails[i].Discount.toFixed(2),
																	['NETPRICE_ERROR_' + index + lineDetails[i].Id]: '',
																	['NETPRICE_' + index + lineDetails[i].Id]: false,
																	['NETPRICE_VALUE_' + index + lineDetails[i].Id]:round(netUnitPrice, 2)});
															}}
														></div>
													</div>
												</div>
												<div className='row'>
													<div className='col-12'>
														<span className='red-color-error'>
															{this.state['NETPRICE_ERROR_' + index + lineDetails[i].Id]}
														</span>
													</div>
												</div>
											</td>
										</tr>
									)}

									<tr>
										<td className='grey'></td>
										<td className='grey'></td>
										<td>
											<div className='two-cols'>
												<Trans i18nKey='NETTO_PRICE'>NETTO_PRICE</Trans>
											</div>
											<div className='two-cols-end bold-font'>
												<span
													className={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]? 'color-red': ''}
													title={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]? i18n.t('INCOMPLETE_PRICE'): ''}
												>
													<PriceComponent value={netTotalPrice} 
														incompletePriceStateId={this.state['LDPriceIncomplete' + lineDetails[i].Id + index]} />
												</span>
											</div>
										</td>
									</tr>
								</>
							)}
						</tbody>
					</table>
				</>
			);
		}
		return scripts;
	};
	innerLineDetails = (allInnerDetails, addFirstRowClass) => {
		let scripts = [];
		allInnerDetails = allInnerDetails.filter((x) => x.Key != 'ProductType');
		let visibleAttributes = allInnerDetails.filter((x) => x.IsVisible);
		let innerDetails = visibleAttributes.filter((x) => !x.IsFitting);
		let fittings = visibleAttributes.filter((x) => x.IsFitting);
		for (let i = 0; i < innerDetails.length; i++) {
			let className = 'content-row';
			if (i == innerDetails.length - 1) className += ' last-row';
			if (!addFirstRowClass && i == 0) className += ' first-row';
			this.renderAttribute(scripts, className, innerDetails, i);
		}
		if (fittings.length > 0) {
			scripts.push(
				<tr>
					<td colSpan='2'>
						<div className='ml-2 fittings-label'>
							<b>
								<Trans i18nKey='FITTINGS'>FITTINGS</Trans>
							</b>
						</div>
					</td>
					<td></td>
				</tr>
			);
			for (let i = 0; i < fittings.length; i++) {
				let className = 'fitting-row content-row';
				if (i == fittings.length - 1) className += ' last-row';
				if (i == 0) className += ' first-row';
				this.renderAttribute(scripts, className, fittings, i);
			}
		}
		return scripts;
	};
	updateConditionalSegmentApproval(tempSegmentWithoutsApproval, currentDefaultTheumaSegment, tempSegmentNeedsApproval) {
		if (tempSegmentWithoutsApproval.find((item) => item.SegmentName == currentDefaultTheumaSegment.SegmentId))
		{
			var index = tempSegmentWithoutsApproval.findIndex(x => x.SegmentName === currentDefaultTheumaSegment.SegmentId);
			tempSegmentWithoutsApproval.splice(index, 1);
		}

		if (tempSegmentNeedsApproval.find((item) => item.SegmentName == currentDefaultTheumaSegment.SegmentId)) {
			var index = tempSegmentNeedsApproval.findIndex(x => x.SegmentName === currentDefaultTheumaSegment.SegmentId)
			tempSegmentNeedsApproval.splice(index,  1);				
		}
	}
	renderAttribute(scripts, className, innerDetails, i) {
		scripts.push(
			<tr className={className}>
				<td>{innerDetails[i].KeyData ? innerDetails[i].KeyData.OptionDescription : innerDetails[i].Key}</td>
				<td className={innerDetails[i].AdditionalPrice ? 'bluecolor' : ''}>
					{innerDetails[i].ValueData ? innerDetails[i].ValueData.OptionDescription : innerDetails[i].Value}
				</td>
				<td>
					{innerDetails[i].IsPriceInComplete ? (
						<React.Fragment>
							<span>{i18n.t('SURCHARGE')}</span>
							<span className='price-on-request'>
								<Trans i18nKey='PRICE_ON_REQUEST'>PRICE_ON_REQUEST</Trans>{' '}
							</span>
						</React.Fragment>
					) : (
						<React.Fragment>
							<span style={{ textAlign: 'left' }}>
								{innerDetails[i].AdditionalPrice ? (
									<Trans i18nKey='DIFFERENT_WIDTH'>DIFFERENT_WIDTH</Trans>
								) : (
									''
								)}
							</span>{' '}
							<span style={{ float: 'right' }}>
								{innerDetails[i].AdditionalPrice ? (
									<PriceComponent 
												value={innerDetails[i].AdditionalPrice} 
												incompletePriceStateId={false} />
								) : (
									''
								)}
							</span>
						</React.Fragment>
					)}
				</td>
			</tr>
		);
	}

	innerLineDetailsSpace = (innerDetails) => {
		let scripts = [];
		for (let i = 0; i < innerDetails.length; i++) {
			scripts.push(
				<tr>
					<td className='line-details-space'></td>
					<td className='line-details-space'></td>
				</tr>
			);
		}
		return scripts;
	};

	getAttributeValue = (innerDetails, attributeName) => {
		let attributeValue = "";
		for (let i = 0; i < innerDetails.length; i++) {
			if (innerDetails[i].Key == attributeName) {
				attributeValue = innerDetails[i].ValueData
					? innerDetails[i].ValueData.OptionShort
					: innerDetails[i].Value;
			}
		}

		return attributeValue;
	};

	getFireAlarmResistanceIcon = (attributeValue) => {
		if (attributeValue == "30'") return '30';
		if (attributeValue == "60'") return '60';
		if (attributeValue == "EI130'") return 'ei130';
		return '';
	};

	getSmokeResistanceIcon = (attributeValue) => {
		if (attributeValue == "SAS200") return 'sas200';
		return '';
	};

	getDimensionValue = (value) => {
		let returnedValue = '';
		return this.state.quotationIconAttributes[value];
	};

	getDimensionValuesFromAttributes = (attributes, quotationLineId) => {
		for (let i = 0; i < attributes.length; i++) {
			if (
				attributes[i].Key == 'Doorwidth' ||
				attributes[i].Key == 'Doorheight' ||
				attributes[i].Key == 'DoorThickness' ||
				attributes[i].Key == 'Doorwidthpassive' ||
				attributes[i].Key == 'Jamblength' ||
				attributes[i].Key == 'Wallthickness'
			) {
				this.state.quotationIconAttributes[attributes[i].Key + '_' + quotationLineId] = attributes[i].ValueData
					? attributes[i].ValueData.OptionShort
					: attributes[i].Value;
			}
		}
	};

	logicToDisplaySystemDoubleDoorProfileProperties = (system, doubleDoor, profile) => {
		if (system && doubleDoor && profile) {
			return system + ' - ' + doubleDoor + ' - ' + profile;
		} else if (system && doubleDoor) {
			return system + ' - ' + doubleDoor;
		} else if (system && profile) {
			return system + ' - ' + profile;
		} else if (doubleDoor && profile) {
			return doubleDoor + ' - ' + profile;
		} else if (system) {
			return system;
		} else if (doubleDoor) {
			return doubleDoor;
		} else if (profile) {
			return profile;
		}
	};

	innerDetailsAttributes = (innerDetails) => {
		let scripts = [];

		for (let i = 0; i < innerDetails.length; i++) {
			if (innerDetails[i].Key == 'ProductType' || !innerDetails[i].IsVisible) {
				continue;
			}
			scripts.push(
				<tr>
					<td className='col-6'>
						{innerDetails[i].KeyData ? innerDetails[i].KeyData.OptionDescription : innerDetails[i].Key}
					</td>
					<td className={'col-6' + (innerDetails[i].AdditionalPrice ? 'bluecolor' : '')}>
						{innerDetails[i].ValueData
							? innerDetails[i].ValueData.OptionDescription
							: innerDetails[i].Value}
					</td>
				</tr>
			);
		}
		return scripts;
	};
	innerDetailsPrice = (innerDetails) => {
		let scripts = [];
		for (let i = 0; i < innerDetails.length; i++) {
			scripts.push(
				<tr>
					<td className='backgroundColorBlue'> &nbsp;</td>
					<td className='backgroundColorBlue'>
						{!!innerDetails[i].AdditionalPrice && (
							<PriceComponent 
							value={innerDetails[i].AdditionalPrice} 
							incompletePriceStateId={false} />
						)}
					</td>
				</tr>
			);
		}
		return scripts;
	};

	removeSearchFilter = (index) => {
		let removedSearchFilter = '';
		removedSearchFilter = this.state.appliedSearchFiltersList[index];
		removedSearchFilter.value = '';
		this.state.appliedSearchFiltersList.splice(index, 1);
		this.state.searchFilters.push(removedSearchFilter);
		let addRemovedFilterBack = this.state.searchFilters;
		this.setState(
			{
				searchFilters: addRemovedFilterBack,
				appliedSearchFiltersList: this.state.appliedSearchFiltersList,
				refreshAsyncahead: true
			},
			() => {
				this.setState({ refreshAsyncahead: false });
			}
		);
	};

	removeSupplementItems =(index) =>{
	
		let removedSupplementItem = this.state.supplementList;
		removedSupplementItem.splice(index,1);
		this.setState({
			supplementList:removedSupplementItem
		});

	}

	AddSearchFilter = () => {
		let index = this.state.appliedSearchFiltersList.length;
		this.state.appliedSearchFiltersList.push({
			key: '',
			value: '',
			parentCategory: '',
			Id: 'filter' + index,
			optionDescription: ''
		});
		let newListOfSearchFilters = this.state.appliedSearchFiltersList;
		this.setState({ appliedSearchFiltersList: newListOfSearchFilters });
	};

	toggleAccordianQuotationLine = (i) => {
	
		let updateState = {};
		updateState['A' + this.state.quotationLines[i].Id] = !this.state['A' + this.state.quotationLines[i].Id];
		updateState['B' + this.state.quotationLines[i].Id] = this.state['B' + this.state.quotationLines[i].Id] === closeAccordian ? openAccordian : closeAccordian;
		updateState['SET' + this.state.quotationLines[i].Id] = !this.state['A' + this.state.quotationLines[i].Id] ? openAccordian: closeAccordian;

		for (let j = 0; j < this.state.quotationLines[i].QuotationLineDetails.length; j++) {
			updateState['C' +this.state.quotationLines[i].Id +this.state.quotationLines[i].QuotationLineDetails[j].Id] = !this.state['A' + this.state.quotationLines[i].Id] ? openAccordian : closeAccordian;
			updateState['D' +this.state.quotationLines[i].Id +this.state.quotationLines[i].QuotationLineDetails[j].Id] = !this.state['A' + this.state.quotationLines[i].Id];
		}

		this.setState(updateState);
	};

	loadQuotation(id, selectedQuotationId, updateIsEconPopUpOpen, selectedLanguage) {
		
		this.getQuotation(id ?? selectedQuotationId, i18n.language);

		updateIsEconPopUpOpen(false);
		
		getActivePriceLists(selectedLanguage, this.state.quotationDate)
		.then((response) => {
			this.setState({ priceList: response});
		})
		.catch((reason) => {this.handleError(reason)});

		this.getSearchFilters();
	}

	onQuotationLineSuccess(quotationLines, flag, isSearchApplied) {
		let updateState = {};
		this.setState({ quotationLines: quotationLines, isSearchApplied: isSearchApplied });

		updateState['loading'] = false;
		updateState['QPriceIncomplete'] = false;

		// Iterate over all quotation lines
		for (let i = 0; i < quotationLines.length; i++) {

			let qlIdA = 'A' + quotationLines[i].Id,
				qlIdB = 'B' + quotationLines[i].Id,
				qlPriceIncompleteKey = 'QLPriceIncomplete' + quotationLines[i].Id;

			updateState[qlIdA] = !!this.state[qlIdA];
			updateState[qlIdB] = this.state[qlIdB] ?? closeAccordian;

			if (quotationLines[i].IsPriceInComplete) {
				updateState[qlPriceIncompleteKey] = true;
				if (quotationLines[i].BaseQuotationLineId === null) {
					updateState['QPriceIncomplete'] = true;
				}
			}
			let incompleteAttributes = quotationLines[i].Attributes.find((x) => x.IsPriceInComplete);
			if (incompleteAttributes) {
				updateState[qlPriceIncompleteKey] = true;
				if (quotationLines[i].BaseQuotationLineId === null) {
					updateState['QPriceIncomplete'] = true;
				}
			}

			// Iterate over all quotation details
			for (let j = 0; j < quotationLines[i].QuotationLineDetails.length; j++) {

				let discount = quotationLines[i].QuotationLineDetails[j].Discount;
				let grossUnitPrice = quotationLines[i].QuotationLineDetails[j].Price;

				// Handle incomplete prices for details
				if (quotationLines[i].QuotationLineDetails[j].IsPriceInComplete) {
					updateState[qlPriceIncompleteKey] = true;
					updateState['LDPriceIncomplete' + quotationLines[i].QuotationLineDetails[j].Id + quotationLines[i].Id] = true;

					if (quotationLines[i].BaseQuotationLineId === null) {
						updateState['QPriceIncomplete'] = true;
					}
				}
				
				let quotationLineDetailsAttributes = quotationLines[i].QuotationLineDetails[j].Attributes;
				
				// Iterate over and add all detail attribute prices
				for (let k = 0; k < quotationLineDetailsAttributes.length; k++) {

					grossUnitPrice = grossUnitPrice + quotationLineDetailsAttributes[k].AdditionalPrice;
					
					// Handle incomplete prices for attributes
					if (quotationLineDetailsAttributes[k].IsPriceInComplete) {
						updateState['LDPriceIncomplete' + quotationLines[i].QuotationLineDetails[j].Id + quotationLines[i].Id] = true;
						updateState['QLPriceIncomplete' + quotationLines[i].Id] = true;

						if (quotationLines[i].BaseQuotationLineId === null) {
							updateState['QPriceIncomplete'] = true;
						}
					}
				}
				
				// Apply discount
				let netUnitPrice = grossUnitPrice - grossUnitPrice * (discount / 100);

				// Apply rate change
				if(this.state.quotationData != null && this.state.quotationData.RateChangeType.toLowerCase() !== 'none')
				{
					if(this.state.quotationData.RateChangeType.toLowerCase() === 'percentageincrease')
					{
						netUnitPrice = netUnitPrice + ((netUnitPrice / 100) * this.state.quotationData.RateChange);
					}

					if(this.state.quotationData.RateChangeType.toLowerCase() === 'percentagedecrease')
					{
						netUnitPrice = netUnitPrice - ((netUnitPrice / 100) * this.state.quotationData.RateChange);
					}
				}

				updateState['DISCOUNT_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = false;
				updateState['NETPRICE_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = false;
				updateState['GROSS_VALUE_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = round(grossUnitPrice, 2).toString();
				updateState['DISCOUNT_FLAG_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = false;
				updateState['DISCOUNT_ERROR_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = '';
				updateState['COMPLETE_DISCOUNT_VALUE' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = discount.toString();
				updateState['DISCOUNT_VALUE_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = round(discount, 2).toString();
				updateState['NETPRICE_VALUE_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = round(netUnitPrice, 2).toString();
				updateState['NETPRICE_ERROR_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = '';
				updateState['NETPRICE_FLAG_' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] = false;
				updateState['SET' + quotationLines[i].Id] = this.state['SET' + quotationLines[i].Id] ?? closeAccordian;

				if (flag === 0) {
					updateState['C' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] =
						this.state['C' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] ?? closeAccordian;
					updateState['D' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] =
						!!this.state['D' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id];
					updateState['CUpselling' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] =
						this.state['CUpselling' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] ?? closeAccordian;
					updateState['DUpselling' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id] =
						!!this.state['DUpselling' + quotationLines[i].Id + quotationLines[i].QuotationLineDetails[j].Id];
				}
			}
		}
		this.setState(updateState);
		
	}

	handleChange(selectedOptions, index) {
		if (selectedOptions.length > 0) {
			this.setSelectedSearchField(selectedOptions[0], index);
		}
	}

	setSelectedSearchField = (selectedFilter, index) => {
		let newlyAddedFilter = this.state.appliedSearchFiltersList;
		newlyAddedFilter[index] = selectedFilter;
		let apiSearchFilters = this.state.searchFilters;

		for (var i = 0; i < apiSearchFilters.length; i++) {
			if (apiSearchFilters[i].key == selectedFilter.key) {
				apiSearchFilters.splice(i, 1);
				break;
			}
		}
		this.setState({ appliedSearchFiltersList: newlyAddedFilter, searchFilters: apiSearchFilters });
	};
	setSearchValue = (e, index) => {
		let searchList = this.state.appliedSearchFiltersList;
		searchList[index].value = e.target.value;
		this.setState({ appliedSearchFiltersList: searchList });
	};

	getSearchFilters = () => {
		getValidQuotationLineSearchParameters()
		.then((responseData) => {
				this.setState({
					searchFilters: responseData,
					masterSearchFilterList: cloneArray(responseData),					
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};

	handleInputChange = (option, index) => {
		if (option == '') {
			const removedSearchFilter = this.state.appliedSearchFiltersList[index];
			this.state.appliedSearchFiltersList.splice(index, 1);
			if (this.state.appliedSearchFiltersList.length == 0) {
				this.state.appliedSearchFiltersList.push({
					key: '',
					value: '',
					parentCategory: '',
					Id: 'filter0',
					optionDescription: ''
				});
			}
			this.state.searchFilters.push(removedSearchFilter);
		}
	};

	buildSearchFilterPanel = () => {
		let scripts = [];
		for (let i = 0; i < this.state.appliedSearchFiltersList.length; i++) {
			scripts.push(
				<tr className='row search-popup' key={i}>
					<td className='col-5'>
						<AsyncTypeahead
							defaultSelected={[this.state.appliedSearchFiltersList[i]]}
							id={'typeahead' + i}
							labelKey={(option) => option.optionDescription}
							useCache={false}
							isLoading={false}
							options={this.state.searchFilters}
							placeholder={i18n.t('SEARCH_FILTERS')}
							onInputChange={(option) => this.handleInputChange(option, i)}
							onChange={(option) => this.handleChange(option, i)}
							onSearch={(option) => this.handleChange(option, i)}
						></AsyncTypeahead>
					</td>
					<td className='col-5'>
						<input
							id={'searchFilterInput' + i}
							className='form-control'
							value={this.state.appliedSearchFiltersList[i].value}
							onChange={(e) => {
								this.setSearchValue(e, i);
							}}
						/>
					</td>
					<td className='col-2'>
						<div className='search-clear'>
							<div
								id={'removeSearchFilter' + i}
								className='search-clear-icon'
								onClick={() => this.removeSearchFilter(i)}
							></div>
						</div>
					</td>
				</tr>
			);
		}
		return scripts;
	};

populateSupplementItems = () => {

	let scripts = [];
		for (let i = 0; i < this.state.selectedSupplementLineCount; i++) {
			scripts.push(
				
				
			);
		}
		return scripts;	
};

	populateLineItems = () => {
		let scripts = [];
		for (let i = 0; i < this.state.selectedLineCount; i++) {
			scripts.push(
				<tr className='row line-item-table-content mb-2' key={i}>
					<td className='col-1'>{i + 1}.</td>
					<td className='col-3'>
						<input
							id={'doorOpening' + i}
							className='form-control'
							onChange={(e) => {
								this.setDoorOpeningReference(e, i);
							}}
							value={this.state.quotationLineList[i].DoorOpeningReference}
						/>
					</td>
					<td className='col-3'>
						<input
							id={'setBuilding' + i}
							className='form-control'
							onChange={(e) => {
								this.setBuilding(e, i);
							}}
							value={this.state.quotationLineList[i].Building}
						/>
					</td>
					<td className='col-2'>
						<input
							id={'setFloor' + i}
							type='number'
							placeholder=' '
							className='form-control'
							onChange={(e) => {
								this.setFloor(e, i);
							}}
							value={this.state.quotationLineList[i].Floor}
						/>
					</td>
					<td className='col-2'>
						<input
							id={'setApartment' + i}
							className='form-control'
							onChange={(e) => {
								this.setApartment(e, i);
							}}
							value={this.state.quotationLineList[i].Apartment}
						/>
					</td>
					<td className='col-1'>
						<div className='search-clear-icon' onClick={() => this.onDeleteIndividualDoorway(i)}></div>
					</td>
				</tr>
			);
		}
		return scripts;
	};
	saveQuotationStatus = () => {
		const { selectedQuotationId } = this.context;
		
		let data = {
			QuotationId: selectedQuotationId,
			StatusId: this.state.selectedQuotationStatus
		};

		this.setState({ loading: true });

		saveQuotationStatus(data)
		.then((response) => {
				this.setState({ loading: false });
				this.setState({ showAssignStatusModal: false });
				const { selectedQuotationId } = this.context;
				this.fetchStatusList(selectedQuotationId);
				// response.StatusId
			})
			.catch((reason) => {this.handleError(reason)});
	};

	saveLineQuotation = (isSaveAndContinue) => {
		if (this.state.noOfIdenticalDoorWays == 0) {
			this.setState({ isDoorWaysEmpty: true });
			return;
		}
		this.setState({ loading: true, isDoorWaysEmpty: false });
		const { selectedQuotationId } = this.context;
		let isDoorWayConfigured = false;

		for (var i = 0; i < this.state.quotationLineList.length; i++) {
			if (
				this.state.quotationLineList[i].DoorOpeningReference == '' &&
				this.state.quotationLineList[i].Building == '' &&
				this.state.quotationLineList[i].Floor == '' &&
				this.state.quotationLineList[i].Apartment == ''
			) {
			} else {
				isDoorWayConfigured = true;
			}
		}

		let data = {
			Id: this.state.editQuotationLineId,
			LineTitle:
				this.state.addQuotationTitle == null || this.state.addQuotationTitle == ''
					? ''
					: this.state.addQuotationTitle,
			QuotationId: selectedQuotationId,
			Quantity: this.state.noOfIdenticalDoorWays,
			DoorWays: isDoorWayConfigured ? this.state.quotationLineList : null
		};

		saveQuotationLine(data)
		.then((responseData) => {
				if (this.state.isSearchApplied) this.onApplySearch();
				else this.getQuotationLineDetailsConf();
				this.setState({
					showAddOrEditQuotationModal: false,
					addQuotationTitle: '',
					selectedLineCount: 1,
					quotationLineList: [
						{
							DoorOpeningReference: '',
							Building: '',
							Floor: '',
							Apartment: ''
						}
					],
					noOfIdenticalDoorWays: 1,
					showEditQuotationPopup: false,
					loading: false
				});
				this.setState({ quotationLineId: responseData, editQuotationLineId: responseData.QuotationLineId });
				this.setState({ loading: false });
			
				if (isSaveAndContinue) {
					let quotationLines = this.state.quotationLines.find(
						(x) => x.Id == responseData.QuotationLineId
					);
					let quotationLineDetailWithDiscount = null;
					if (quotationLines)
					{
						quotationLineDetailWithDiscount = quotationLines.IsDiscountManualUpdate;
					}
					if (!quotationLineDetailWithDiscount) this.editQuotationLineConfirmation();
					else {
						this.setState({ showEditQuotationPopup: true });
					}
				} else {
					this.setState({
						editQuotationLineId: 0,
						editQuotationLineEconConfiId: ''
					});
				}
			})
			.catch((reason) => {this.handleError(reason)});
	};

	validateIndividualArticle = () => {
		let validations = this.state.individualArticleValidations;
		validations.isArticleNotSelected = false;
		validations.isArticleNameEmpty = false;
		validations.isArticlePriceEmpty = false;
		validations.isArticleQuantityEmpty = false;
		let isValid = true;
		if (this.state.noOfIndividualArticles == 0) {
			validations.isArticleQuantityEmpty = true;
			isValid = false;
		}
		if (this.state.isManualEntryIndividualArticle) {
			if (!this.state.individualArticleDetails[0].Name) {
				validations.isArticleNameEmpty = true;
				isValid = false;
			}
			if (this.state.individualArticleDetails[0].Price === '') {
				validations.isArticlePriceEmpty = true;
				isValid = false;
			}
		} else {
			if (!this.state.selectedIndividualArticle.GrossBasePrice) {
				validations.isArticleNotSelected = true;
				isValid = false;
			}
		}
		this.setState({ individualArticleValidations: validations });
		return isValid;
	};

	validateSupplement = () => {
	
		let validations = this.state.supplementValidations;
		validations.isSupplementNotSelected = false;
		validations.isSupplementNameEmpty = false;
		validations.isSupplementPriceEmpty = false;
		validations.isSupplementQuantityEmpty = false;
		let isValid = true;
		if (this.state.noOfSupplements == 0) {
			validations.isSupplementQuantityEmpty = true;
			isValid = false;
		}

		for (var i = 0; i < this.state.supplementList.length; i++) 
		{
			if(this.state.supplementList[i].IsUserDefined)
			{
				if (!this.state.supplementList[i].key) {
					validations.isSupplementNameEmpty = true;
					isValid = false;
				}
			}

	    }
		if (this.state.isManualEntrySupplement) {
			if (!this.state.individualArticleDetails[0].Name) {
				validations.isSupplementNameEmpty = true;
				isValid = false;
			}
			if (this.state.individualArticleDetails[0].Price === '') {
				validations.isSupplementPriceEmpty = true;
				isValid = false;
			}
		} else {
			if (!this.state.selectedIndividualArticle.GrossBasePrice) {
				validations.isSupplementNotSelected = true;
				isValid = false;
			}
		}
		this.setState({ supplementValidations: validations });
		return isValid;
	};
	saveIndividualArticle = () => {
		if (!this.validateIndividualArticle()) return;
		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;
		let addQuotationTitle = this.state.quotationLines.length + 1;
		if (!this.state.isManualEntryIndividualArticle) {
			this.state.individualArticleDetails[0].Name = this.state.selectedIndividualArticle.ShortDescription;
			this.state.individualArticleDetails[0].Price = this.state.selectedIndividualArticle.GrossBasePrice;
		}
		let data = {
			LineTitle:
				this.state.addQuotationTitle == null || this.state.addQuotationTitle == ''
					? ''
					: this.state.addQuotationTitle,
			QuotationId: selectedQuotationId,
			QuotationLineDetails: this.state.individualArticleDetails,			
			Quantity: this.state.noOfIndividualArticles,
			PriceListId: this.state.isManualEntryIndividualArticle ? null : this.state.selectedPriceList.Id,
			IsIndividualArticle: true,			
			 Attributes:[ 
					{					
						key : this.state.selectedProductType === 1 ? 'Others' : 'Revenue',
						Value: this.state.selectedProductType === 1 ? "Others" : "Revenue-Set",
						QuotationLineId :this.state.editIndividualArticleData ? this.state.editIndividualArticleData.Id : 0	,
					}
			 ],
		};
		if (!!this.state.editIndividualArticleData) {
			this.state.individualArticleDetails[0].Id = this.state.editIndividualArticleData.QuotationLineDetails[0].Id;
			data.Id = this.state.editIndividualArticleData.Id;			
		}

		saveQuotationLine(data)
		.then((responseData) => {
				this.setState({
					showIndividualArticleModal: false,
					IsIndividualArticleSelected: false,
					addQuotationTitle:''
					
				});
				// Apply the general discounts to the Individual article.
				this.applyAccountDiscounts(i18n.language, responseData.QuotationLineId, this.state.quotationData.AccountNumber);
				
				// Reload the quotation
				this.getQuotation(selectedQuotationId);

				// Reload lines
				if (this.state.isSearchApplied) this.onApplySearch();
				else this.getQuotationLineDetailsConf();
			});
	};
	
	saveSupplement = () => {

		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;

		let data = { quotationId:selectedQuotationId, UpsellingAttributes:[]};
		for (let i = 0; i < this.state.supplementList.length; i++) {
			data.UpsellingAttributes.push({
				Id:this.state.supplementList[i].Id,
				QuotationId: selectedQuotationId,
				Key: this.state.supplementList[i].Key,
				Value: this.state.supplementList[i].Value,
				AdditionalPrice : this.state.supplementList[i].AdditionalPrice,
				Quantity: this.state.supplementList[i].Quantity,
				IsUserDefined: this.state.supplementList[i].IsUserDefined,
				Discount :this.state.supplementList[i].Discount,
				Comment:this.state.supplementList[i].Comment,
				IsNetPrice:this.state.supplementList[i].IsNetPrice
			});
		}

		saveSupplements(data)
		.then((responseData) => {			
				this.setState({ 
					showAssignStatusModal: false,
					showSupplementModal: false ,
					IsSupplementSelected : false,
					selectedPriceListSupplement: this.state.priceList.find((x) => x.Id == this.state.quotationData.PriceListId),					
					selectedSupplementQuantity:1,
					selectedSupplementDiscount:0,
		            selectedSupplementDiscription:'',
		            selectedSupplementComment:'',
					isSupplementUserDefined:false,
					isManualEntrySupplement: false,
					loading:false});
					this.getSupplements(selectedQuotationId);				
				
			})
			.catch((reason) => {this.handleError(reason)});			
	};

	onClosePopup = () => {
		this.setState({
			showAddOrEditQuotationModal: false,
			addQuotationTitle: '',
			selectedLineCount: 1,
			quotationLineList: [
				{
					DoorOpeningReference: '',
					Building: '',
					Floor: '',
					Apartment: ''
				}
			],
			noOfIdenticalDoorWays: 1,
			isDoorWaysEmpty: false
		});
	};
	onCloseSearchPanel = () => {
		let bufferSearchKeys = this.state.bufferSearchQuery.map((x) => {
			return x.key;
		});
		let appliedSearchFiltersKeys = this.state.appliedSearchFiltersList.map((x) => {
			return x.key;
		});

		let dataToBeAdded = appliedSearchFiltersKeys.filter((x) => !bufferSearchKeys.includes(x));
		let dataToBeRemoved = bufferSearchKeys.filter((x) => !appliedSearchFiltersKeys.includes(x));
		let searchFilters = this.state.searchFilters.filter((x) => !dataToBeRemoved.includes(x.key));
		searchFilters = searchFilters.concat(
			this.state.masterSearchFilterList.filter((x) => dataToBeAdded.includes(x.key))
		);
		this.setState({
			showSearchPanel: false,
			appliedSearchFiltersList: cloneArray(this.state.bufferSearchQuery),
			searchFilters: searchFilters
		});
	};
	onClearSearch = () => {
		this.setState({
			appliedSearchFiltersList: [
				{
					key: '',
					value: '',
					Id: 'filter0',
					parentCategory: '',
					optionDescription: ''
				}
			],
			searchFilters: this.state.masterSearchFilterList
		});
		this.getQuotationLineDetailsConf();
	};
	onClearSimpleSearch = () => {
		this.setState({
			simpleSearchString: '',
			isSimpleSearchApplied: false,
			simpleSearchedString: ''
		});
		this.getQuotationLineDetailsConf();
	};

	onToggleCollapseAllLines = () => {
		for (let i = 0; i < this.state.quotationLines.length; i++) {
			this.toggleAccordianQuotationLine(i);
		};
	};

	sumUpAllQuantities = () => {
		if(this.state.quotationLines.length > 0)
		{
			return this.state.quotationLines.map(l => l.Quantity).reduce((prev, next) => prev + next)
		}
		else
		{
			return 0;
		}
	};

	onApplySearch = () => {
		const { selectedQuotationId, selectedLanguage } = this.context;
		let data = this.state.appliedSearchFiltersList;
		this.setState({ loading: true });

		getQuotationLinesBySearchParameters(selectedQuotationId, data)
		.then((responseData) => {
				this.setState({
					showSearchPanel: false
				});
				this.onQuotationLineSuccess(responseData, 0, true);
			})
			.catch((reason) => {this.handleError(reason)});
	};

	onCloseIndividualArticlePopup = () => {
		this.setState({
			showIndividualArticleModal: false,
			editIndividualArticleData: null,
			individualArticleDetails: [
				{
					Name: '',
					Price: 0
				}
			],
			addQuotationTitle: '',
			selectedIndividualArticle: [],
			IsIndividualArticleSelected: false,
			selectedIndividualArticlePrice: '',
			noOfIndividualArticles: 1,
			selectedPriceList: this.state.priceList.find((x) => x.Id == this.state.quotationData.PriceListId),

			selectedProductType: 1,
			isManualEntryIndividualArticle: false,
			individualArticleValidations: {
				isArticleNotSelected: false,
				isArticleNameEmpty: false,
				isArticlePriceEmpty: false,
				isArticleQuantityEmpty: false
			}
		});
	};

	setPriceListDefault = () => {
		this.setState({selectedPriceListSupplement: 
			this.state.priceList.find((x) => x.Id == this.state.quotationData.PriceListId)})
	}

	onCloseSupplementPopup = () => {
		const { selectedQuotationId} = this.context;
		this.setState({
			showSupplementModal: false,
			editSupplementData: null,
			IsSupplementSelected: false,
			selectedPriceListSupplement: this.state.priceList.find((x) => x.Id == this.state.quotationData.PriceListId),
			supplementList:[],
			selectedSupplementQuantity:1,
			selectedSupplementDiscount:0,
		    selectedSupplementDiscription:'',
		    selectedSupplementComment:'',
			isSupplementUserDefined:false,
			isManualEntrySupplement: false,
		});
		this.getSupplements(selectedQuotationId);
	};

	onCloseIframePopup = () => {
		const { updateIsEconPopUpOpen } = this.context;
		updateIsEconPopUpOpen(false);
		this.setState({
			showIframeModal: false,
			editQuotationLineId: 0,
			editQuotationLineEconConfiId: ''
		});
	};
	onCloseAssignStatus = () => {
		this.setState({ showAssignStatusModal: false });
	};
	onCloseQuotationNotes = () => {
		this.setState({ isQuotationNotesPopUpOpen: false, quotationNotes: '' });
	};
	onCloseQuotationInternalNotes = () => {
		this.setState({ isQuotationInternalNotesPopUpOpen: false, quotationInternalNotes: '' });
	};

	onClosePdfJsonModal = () => {
		this.setState({ showPdfJsonModal: false});
	};

	onCloseRateChangeModal = () => {
		this.setState({ showRatechangePopUp: false});
	};

	onQuotationRateChanged = (value) => {
		this.setState({ quotationRate:value});
	}

	onQuotationRateTypeChanged = (rateType) => {
		this.setState({ quotationRateType:rateType});
	}

	onApplyRateChange = () => {

		const { selectedQuotationId, selectedLanguage, updateIsEconPopUpOpen } = this.context;
		applyRateChangeToQuotation(selectedQuotationId, this.state.quotationRateType, this.state.quotationRate)
		.then((response) =>{

			this.onCloseRateChangeModal();

			// Reload the quotation
			this.getQuotation(selectedQuotationId, selectedLanguage)
		})

		.catch((reason) => {this.handleError(reason)});
	}

	onCloseQuotationLineNotes = () => {
		this.setState({ isQuotationLineNotesPopUpOpen: false, quotationLineNotes: '', quotationLineIdForNotes: 0 });
	};
	onCloseShareQuotation = () => {
		this.setState({ isShareQuotationPopUpOpen: false, shareQuotationURL: '' });
	};
	onSaveQuotationLineNotes = () => {
		this.setState({ loading: true });

		let data = {
			QuotationLineId: this.state.quotationLineIdForNotes,
			Notes: this.state.quotationLineNotes
		};

		saveQuotationLineNotes(data)
		.then((responseData) => {
				let quotationLines = this.state.quotationLines;
				quotationLines.find((x) => x.Id === this.state.quotationLineIdForNotes).Notes =
					this.state.quotationLineNotes;
				this.setState({
					loading: false,
					isQuotationLineNotesPopUpOpen: false,
					quotationLines: quotationLines,
					quotationLineNotes: '',
					quotationLineIdForNotes: 0
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};
	setLanguage = (language) => {
		this.getStandardNotes(language.Code);
		this.setState({
			SelectedNotesLanguage: language.Name
		});
	};
	toggleLanguageDD = () => {
		this.setState({
			languageDDIsOpen: !this.state.languageDDIsOpen
		});
	};

	onSaveQuotationNotes = (IsInternalNotes) => {
		this.setState({ loading: true });
	
		let data = {
			QuotationId: this.state.quotationData.QuotationId,
			Notes: IsInternalNotes ? this.state.quotationInternalNotes : this.state.quotationNotes,
			IsInternalNotes: IsInternalNotes
		};

		saveQuotationNotes(data)
		.then((responseData) => {
				let quotationData = this.state.quotationData;
				if (IsInternalNotes) quotationData.InternalNotes = this.state.quotationInternalNotes;
				else quotationData.Notes = this.state.quotationNotes;
				this.setState({
					loading: false,
					isQuotationNotesPopUpOpen: false,
					isQuotationInternalNotesPopUpOpen: false,
					quotationData: quotationData,
					quotationNotes: '',
					quotationInternalNotes: ''
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};
	openQuotaitonNotes = () => {
		const { selectedLanguage } = this.context;
		let language = this.state.Language.find((x) => x.Code === selectedLanguage).Name;
		this.setState({ loading: true, SelectedNotesLanguage: language });

		getStandardNotes(selectedLanguage)
		.then((responseData) => {
			this.setState({
				isQuotationNotesPopUpOpen: true,
				quotationNotes: this.state.quotationData.Notes,
				standardNotes: responseData,
				loading: false
			});
		})
		.catch((reason) => {this.handleError(reason)});
	};

	shareQuotaiton = () => {
		const { selectedQuotationId } = this.context;
		let url = window.location.origin + '/quotation/view?id=' + selectedQuotationId;
		this.setState({ isShareQuotationPopUpOpen: true, shareQuotationURL: url });
	};

	IsLoggedInUserSalesAdmin = () =>{
		const { selectedRole } = this.context;	
		return (selectedRole && selectedRole == 'TheumaCalculation');
	}

	lockQuotation = (flag =0) =>{
		const { accountInfo } = this.context;
		this.setState({loading: true});
		let lockedBy = '';
		this.setState({loading:true});
		if(flag ===0)
		{
			lockedBy = accountInfo.account.accountIdentifier;
		}

		saveQuotationLockStatus(this.state.quotationData.QuotationId, lockedBy)
		.then((response) => {

				if(flag === 0)
				{
					this.setState({
						showLockedQuotationConfirmation: false,					
						loading: false
					});
				}
				else
				{
					this.setState({
						showLockedQuotationConfirmation: false,					
						loading: false
					});				

					
					this.getQuotationLineDetailsConf(0, this.state.quotationData.QuotationId);
					this.getQuotation(this.state.quotationData.QuotationId, i18n.language);
					this.viewModeOnly();
				}
				

			})
			.catch((reason) => {this.handleError(reason)});

	}

	viewModeOnly = () => {
		const {  updateViewModeOnly } = this.context;	
		updateViewModeOnly(false);
	};

	toggleLockQuotation =() =>{	
		const { selectedRole } = this.context;
		if(this.state.isQuotationLocked && selectedRole == 'TheumaCalculation')
		{
			this.setState({showLockedQuotationConfirmation:true});
		}		
	}
	openQuotaitonInternalNotes = () => {
		this.setState({
			isQuotationInternalNotesPopUpOpen: true,
			quotationInternalNotes: this.state.quotationData.InternalNotes
		});
	};

	editQuotation = (editURL) => {
		window.location = editURL;
	};
	handleChangePrefaceCheck = () => {
		this.setState({ PrefaceSelected: !this.state.PrefaceSelected });
	};
	onSaveStatus = () => {
		this.saveQuotationStatus();
		this.setState({ showAssignStatusModal: false });
	};

	filterBy = () => true;

	onStatusChanged = (selectedStatus, e) => {
		let newQuotationStatus = this.state.quotationStatus;
		for (var i = 0; i < this.state.quotationStatus.length; i++) {
			newQuotationStatus[i].CurrentStatus = false;
			if (newQuotationStatus[i].Code == e.target.id) {
				newQuotationStatus[i].CurrentStatus = true;
			}
		}
		this.setState({
			quotationStatus: newQuotationStatus
		});

		this.setState({ selectedQuotationStatus: selectedStatus.StatusId });

		let children = document.querySelector('.table-parent').children;
		for (var i = 0; i < children.length; i++) {
			children[i].style.backgroundColor = 'white';
			children[i].style.color = '#000000';
		}
		e.target.parentElement.parentElement.style.backgroundColor = '#3E648B';
		e.target.parentElement.parentElement.style.color = '#FFFFFF';
	};
	onSimpleSearch = () => {
		if (this.state.simpleSearchString == '') return;

		const { selectedQuotationId, selectedLanguage } = this.context;
		let data = [
			{
				values: this.state.simpleSearchString
			}
		];
		this.setState({ loading: true });

		getQuotationLinesBySimpleSearch(selectedQuotationId, data)
		.then((responseData) => {
				this.setState({
					isSimpleSearchApplied: true,
					simpleSearchedString: this.state.simpleSearchString
				});
				this.onQuotationLineSuccess(responseData, 0, false);
			})
			.catch((reason) => {this.handleError(reason)});
	};
	saveVersionDescription = () => {
		let quotation = this.state.quotationData;
		quotation.VersionDescription = this.state.bufferVersionDescription;

		let data = {
				VersionDescription: quotation.VersionDescription
		};

		this.setState({ loading: true });

		saveQuotationVersionDescription(data, quotation.QuotationId)
		.then((response) => {
				this.setState({
					quotation: quotation,
					bufferVersionDescription: '',
					showVersionDescriptionEdit: false,
					loading: false
				});
			})
			.catch((reason) => {this.handleError(reason)});
	};

	render = () => {
		let quotation = this.state.quotationData;
		let opportunityTitle = i18n.t('LOADING'),
			opportunity = i18n.t('LOADING'),
			offerDate = '',
			salesOrganization = i18n.t('LOADING'),
			validity = '',
			createdBy = i18n.t('LOADING'),
			owner = i18n.t('LOADING'),
			internalSales = i18n.t('LOADING'),
			price = i18n.t('LOADING'),
			accountName = i18n.t('LOADING'),
			accountNumber = i18n.t('LOADING'),
			orderAddress1 = i18n.t('LOADING'),
			orderAddress2 = '',
			orderAddress3 = '',
			contactName = i18n.t('LOADING'),
			contactEmail = '',
			contactNumber = '',
			streetName = '-',
			streetNumber = '',
			town = '',
			postalCode = '',
			land = '',
			versionDescription = '',
			versionNumber = 0,
			lockedByName = '',
			rateChange = 0,
			rateChangeType = 'none'

		if (!!quotation) {
			opportunityTitle = quotation.Title;
			opportunity = quotation.OpportunityNumber;
			offerDate = quotation.Date;
			salesOrganization = quotation.SalesOrganization;
			validity = quotation.ValidityPeriod;
			owner = quotation.OwnerName;
			internalSales = quotation.InternalSalesName;
			createdBy = quotation.CreatedBy;
			price = quotation.PriceListName;
			accountName = quotation.AccountName;
			accountNumber = quotation.ClientNumber;
			lockedByName = quotation.LockedByName;
			rateChange = quotation.RateChange;
			rateChangeType = quotation.RateChangeType;
			
			if (quotation.VersionDescription) versionDescription = quotation.VersionDescription;
			versionNumber = quotation.VersionNumber;
			let orderAddress = quotation.OrderAddress;

			if (!!orderAddress) {
				orderAddress.Street = orderAddress.Street ?? '';
				orderAddress.HouseNumber = orderAddress.HouseNumber ?? '';
				orderAddress.ZipCode = orderAddress.ZipCode ?? '';
				orderAddress.City = orderAddress.City ?? '';
				orderAddress1 = orderAddress.Street + ' ' + orderAddress.HouseNumber;
				orderAddress2 = orderAddress.ZipCode + ' ' + orderAddress.City;
				orderAddress3 = orderAddress.Country;
			}

			if (!!quotation.ContactInformation[0]) {
				contactName = quotation.ContactInformation[0].Name;
				contactEmail = quotation.ContactInformation[0].Email;
				contactNumber = quotation.ContactInformation[0].PhoneNumber;
			}

			if (!!quotation.quotationDeliveryAddress) {
				streetNumber = quotation.quotationDeliveryAddress.StreetNumber;
				streetName = quotation.quotationDeliveryAddress.StreetName;
				town = quotation.quotationDeliveryAddress.Town;
				postalCode = quotation.quotationDeliveryAddress.PostalCode;
				land = quotation.quotationDeliveryAddress.Land;
			}
		}

		const externalCloseBtn = (
			<button
				className='close'
				style={{
					position: 'absolute',
					top: '15px',
					right: '15px',
					display: 'none'
				}}
			>
				&times;
			</button>
		);
		let editUrl = '/quotation/header/edit?id=' + this.state.quotationId;
		let totalPrice = 0;
		let QuotationLines = this.state.quotationLines.filter((x) => x.BaseQuotationLineId == null);

		// Iterate over all quotationlines
		for (let i = 0; i < QuotationLines.length; i++) {
			let lineDetails = QuotationLines[i].QuotationLineDetails;
			let linesTotalPrice = 0;
			let quantity = QuotationLines[i].Quantity;
			
			// Iterate over all quotation details
			for (let k = 0; k < lineDetails.length; k++) {

				let discount = lineDetails[k].Discount;
				var grossUnitPrice = lineDetails[k].Price;
				let optionsPrice = 0;

				// Iterate over and add all detail attribute prices
				for (let j = 0; j < lineDetails[k].Attributes.length; j++) {
					optionsPrice = optionsPrice + lineDetails[k].Attributes[j].AdditionalPrice;
				}

				// Add option prices
				grossUnitPrice = grossUnitPrice + optionsPrice;

				// Apply discount
				let netUnitPrice = grossUnitPrice - grossUnitPrice * (discount / 100);

				// Apply rate changes
				if(quotation != null && quotation.RateChange > 0)
				{
					if(quotation.RateChangeType.toLowerCase() === 'percentageincrease')
					{
						netUnitPrice = netUnitPrice + ((netUnitPrice / 100) * quotation.RateChange);
					}

					if(quotation.RateChangeType.toLowerCase() === 'percentagedecrease')
					{
						netUnitPrice = netUnitPrice - ((netUnitPrice / 100) * quotation.RateChange);
					}
				}

				// Take quantity into account
				let netTotalPrice = netUnitPrice * quantity;

				// Sum up the totals
				linesTotalPrice = linesTotalPrice + netTotalPrice;
			}

			totalPrice = totalPrice + linesTotalPrice;

			// Dealing with a set (net agreement).
			if (this.state.quotationLines[i].SetId && this.state.quotationLines[i].Price !== 0) 
			{			
				// Add all additional prices from all attributes
				let optionPrices = 0;
				for (let x = 0; x < lineDetails.length; x++) {

					for (let y = 0; y < lineDetails[x].Attributes.length; y++) {
						optionPrices = optionPrices + lineDetails[x].Attributes[y].AdditionalPrice;
					}
				}

				linesTotalPrice = (this.state.quotationLines[i].Price + optionPrices) * quantity;
			}
		}

		const { viewModeOnly, selectedLanguage, userRoleDetail } = this.context;
	
		return (
			<div className='row mt-4'>

				{/* IntlProvider provides a context for the i18n translations */}
				<IntlProvider locale={selectedLanguage.split('-')[0]} defaultLocale='nl'>

					<div className='col-12'>
						<div className='view-quotation-body'>

							{/* Title */}
							<div className='row'>
								
								<div className='view-quotation-panel-title col-9'>{opportunityTitle}</div>
								
								{this.state.isQuotationLocked && (
									<div className='col-3'>
										<span className='float-right'>
											{i18n.t('QUOTATION_LOCKED_BY') + lockedByName}
										</span>
									</div>) }
								
							</div>
							
							<br />
							<div className='grey-background row version-details'>
								<div className='col-6'>

									{!this.state.showVersionDescriptionEdit && (
										<>
											<div className='version-description'>
												{i18n.t('VERSION_LOWER')} {versionNumber} - {' '}
												{versionDescription == ''
													? i18n.t('VERSION_DESCRIPTION_EMPTY')
													: versionDescription}
											</div>											
											{!viewModeOnly && (
												<div
													id={'editDescription'}
													className='edit-icon-description ml-2'
													onClick={() => {
														this.setState({
															showVersionDescriptionEdit: true,
															bufferVersionDescription: versionDescription
														});
													}}
												></div>
											)}
										</>
									)}

									{this.state.showVersionDescriptionEdit && (
										<>
											<div className='version-description'>
												{i18n.t('VERSION_LOWER')} {versionNumber} -
											</div>
											<input
												className='version-description-input'
												id={'editDescriptionInput'}
												value={this.state.bufferVersionDescription}
												onChange={(e) => {
													this.setState({
														bufferVersionDescription: e.target.value
													});
												}}
											/>
											<div
												className='ok-btn ml-2'
												id={'okDescription'}
												onClick={this.saveVersionDescription}
											></div>
											<div
												className='cancel-btn ml-2'
												id={'cancelDescription'}
												onClick={() => {
													this.setState({ showVersionDescriptionEdit: false });
												}}
											></div>
										</>
									)}

									<div>
										<Link to={editUrl} className='edit-icon' id={'editUrl'}></Link>
									</div>
								</div>

								{this.state.quotationLines.length !== 0 && !this.state.isSearchApplied && (
									<div className='col-6 simple-search'>
										<div
											className='ok-btn ml-2'
											id={'searchOk'}
											onClick={this.onSimpleSearch}
										></div>

										<input
											id={'simpleSearchInput'}
											value={this.state.simpleSearchString}
											onChange={(e) => {
												this.setState({
													simpleSearchString: e.target.value
												});
											}}
											onKeyUp={(e) => {
												if (e.key === 'Enter' || e.keyCode === 13) this.onSimpleSearch();
											}}
											placeholder={i18n.t('SIMPLE_SEARCH_PLACEHOLDER')}
										/>

										<div className='search-title mr-2'>
											<Trans i18nKey='SIMPLE_SEARCH'>SIMPLE_SEARCH</Trans>
											{' : '}
										</div>
									</div>
								)}

							</div>
							<br />
							<div className='view-quotation-panel'>
								<div className='quotation-panel-sub-title'>
									<Trans i18nKey='QUOTATION_INFORMATION'>QUOTATION_INFORMATION</Trans>
								</div>
								{!viewModeOnly && (
									<Dropdown
										isOpen={this.state.isHeaderKebabMenuOpen}
										toggle={this.toggleHeaderKebabDD}
										className='kebab-menu-dropdown kebab-header-dropdown row mr-4'
										id={'headerKebabMenu'}
									>
										<DropdownToggle>
											<div className='kebab-menu kebab-menu-header'></div>
										</DropdownToggle>

										<DropdownMenu right>
											<DropdownItem onClick={() => this.editQuotation(editUrl)}>
												<div className='kebab-option' id={'editQuotationKebab'}>
													<div className='edit-icon-kebab kebab-option-icon'></div>
													<div className='kebab-option-desc'>
														<Trans i18nKey='EDIT_QUOTATION'>EDIT_QUOTATION</Trans>
													</div>
												</div>
											</DropdownItem>
											{this.state.quotationData && (
												<>
													<DropdownItem onClick={this.openQuotaitonInternalNotes}>
														<div className='kebab-option' id={'openInternalNotesKebab'}>
															<div
																className={
																	'kebab-option-icon internal-notes-icon-kebab' +
																	(this.state.quotationData.InternalNotes
																		? '-filled'
																		: '')
																}
															></div>
															<div
																className={
																	'kebab-option-desc' +
																	(this.state.quotationData.InternalNotes
																		? ' highlight'
																		: '')
																}
															>
																<Trans i18nKey='ADD_EDIT_INTERNAL_NOTES'>
																	ADD_EDIT_INTERNAL_NOTES
																</Trans>
															</div>
														</div>
													</DropdownItem>
													<DropdownItem onClick={this.openQuotaitonNotes}>
														<div className='kebab-option' id={'openNotesKebab'}>
															<div
																className={
																	'kebab-option-icon notes-icon-kebab' +
																	(this.state.quotationData.Notes ? '-filled' : '')
																}
															></div>
															<div
																className={
																	'kebab-option-desc' +
																	(this.state.quotationData.Notes ? ' highlight' : '')
																}
															>
																<Trans i18nKey='ADD_EDIT_EXTERNAL_NOTES'>
																	ADD_EDIT_EXTERNAL_NOTES
																</Trans>
															</div>
														</div>
													</DropdownItem>
												</>
											)}
											<DropdownItem
												onClick={() => {
													this.setState({ showDeleteConfirmationPopUp: true });
												}}
											>
												<div className='kebab-option' id={'deleteQuotationKebab'}>
													<div className='delete-icon-kebab kebab-option-icon'></div>
													<div className='kebab-option-desc'>
														<Trans i18nKey='DELETE_QUOTATION_KEBAB'>
															DELETE_QUOTATION_KEBAB
														</Trans>
													</div>
												</div>
											</DropdownItem>
											{this.state.quotationLines.length !== 0 && (
												<DropdownItem onClick={this.openAssignStatus}>
													<div className='kebab-option' id={'openStatusIcon'}>
														<div className='status-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															<Trans i18nKey='STATUS_BTN'>STATUS_BTN</Trans>
														</div>
													</div>
												</DropdownItem>
											)}
											<DropdownItem onClick={this.onHeaderDiscountClick}>
												<div className='kebab-option' id={'configureDiscount'}>
													<div className='discount-icon-kebab kebab-option-icon'></div>
													<div className='kebab-option-desc'>
														<Trans i18nKey='DISCOUNT_HEADER'>DISCOUNT_HEADER</Trans>
													</div>
												</div>
											</DropdownItem>


											<DropdownItem onClick={this.onReportOverviewClick}>
												<div className='kebab-option' id={'reportOverview'}>
													<div className='euro-icon-kebab kebab-option-icon'></div>
													<div className='kebab-option-desc'>
														<Trans i18nKey='REPORT_OVERVIEW'>REPORT_OVERVIEW</Trans>
													</div>
												</div>
											</DropdownItem>

											<DropdownItem onClick={this.getUserTracesForAQuotation}>
												<div className='kebab-option' id={'allUserTraces'}>
													<div className='discount-history-icon-kebab kebab-option-icon'></div>
													<div className='kebab-option-desc'>
														<Trans i18nKey='ALL_USER_TRACES'>ALL_USER_TRACES</Trans>
													</div>
												</div>
											</DropdownItem>

											{hasEditAccess(userRoleDetail,"ChangeQuotationRate") &&
												<DropdownItem onClick={this.applyRateChangeToQuotation}>
													<div className='kebab-option' id={'rateChange'}>
														<div className='euro-icon-kebab kebab-option-icon'></div>
														<div className='kebab-option-desc'>
															{i18n.t('APPLY_RATE_CHANGE')}
														</div>
													</div>
												</DropdownItem>
											}

										</DropdownMenu>

									</Dropdown>
								)}

								<br />							
								<div className='row quotation-header'>
									<div className='col-3 bold-text'>
										<Trans i18nKey='QUOTATION_TITLE'>QUOTATION_TITLE</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='QUOTATION_DATE'>QUOTATION_DATE</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='VALIDITY_PERIOD'>VALIDITY_PERIOD</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='SALES_ORGANISATION'>SALES_ORGANISATION</Trans>
									</div>
								</div>
								<div className='row quotation-content'>
									<div className='col-3'>{opportunityTitle}</div>
									<div className='col-3'>
										{offerDate ? (
											getFormattedDate(offerDate, selectedLanguage.split('-')[0])
										) : (
											<Trans i18nKey='LOADING'>LOADING</Trans>
										)}
									</div>
									<div className='col-3'>{validity}</div>
									<div className='col-3'>{salesOrganization}</div>
								</div>
								<br />
								<div className='row quotation-header'>
									<div className='col-3 bold-text'>
										<Trans i18nKey='OPPORTUNITY'>OPPORTUNITY</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='OWNER'>OWNER</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='INTERNAL_SALES'>INTERNAL_SALES</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='CREATOR'>CREATOR</Trans>
									</div>
								</div>
								<div className='row quotation-content'>
									<div className='col-3'>{opportunity}</div>
									<div className='col-3'>{owner}</div>
									<div className='col-3'>{internalSales}</div>
									<div className='col-3'>{createdBy}</div>
								</div>
								<br />
								<div className='row quotation-header'>
									<div className='col-3 bold-text'>
										<Trans i18nKey='ACCOUNT'>ACCOUNT</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='CONTACTPERSOON'>CONTACTPERSOON</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='PROJECT_ADDRESS'>PROJECT_ADDRESS</Trans>
									</div>
									<div className='col-3 bold-text'>
										<Trans i18nKey='PRICE_LIST'>PRICE_LIST</Trans>
									</div>
								</div>
								<div className='row quotation-content'>
									<div className='col-3 multi-row-align'>
										<span>{accountName}</span>
										<span>{accountNumber}</span>
									</div>
									<div className='col-3 multi-row-align'>
										<span>{contactName}</span>
										<span>{contactEmail}</span>
										<span>{contactNumber}</span>
									</div>
									<div className='col-3 multi-row-align'>
										<span>
											{streetName} {streetNumber}
										</span>
										<span>
											{postalCode} {town}
										</span>
										<span>{land}</span>
									</div>
									<div className='col-3 multi-row-align'>
										<span>{price}</span>
									</div>									
								</div>
							</div>
						
							{(this.state.appliedSearchFiltersList.length > 1 ||
								(this.state.appliedSearchFiltersList.length == 1 &&
									this.state.appliedSearchFiltersList[0].key != '')) &&
								!this.state.showSearchPanel && (
									<div className='container-fluid search-criteria mt-4 '>
										<div className='row mt-2 mb-2'>
											<div className='col-10'>
												<div className='quotation-panel-sub-title'>
													<Trans i18nKey='SEARCH_CRITERIA'>SEARCH_CRITERIA</Trans>
												</div>
												<div className=' ml-4'>
													{this.state.appliedSearchFiltersList.map((x, i) => {
														return (
															<>
																{' '}
																{(i != 0 ? ' | ' : '') +
																	x.optionDescription +
																	' : "' +
																	(x.value ? x.value : ' ') +
																	'"'}
															</>
														);
													})}
												</div>
											</div>
											<div className='col-2'>
												<button
													className='btn btn-enabled clear-search-button'
													id={'clearSearch'}
													onClick={this.onClearSearch}
												>
													<Trans i18nKey='CLEAR_SEARCH'>CLEAR_SEARCH</Trans>
												</button>
											</div>
										</div>
									</div>
								)}								
							{this.state.isSimpleSearchApplied && (
								<div className='container-fluid search-criteria mt-4 '>
									<div className='row mt-2 mb-2'>
										<div className='col-10'>
											<div className='quotation-panel-sub-title'>
												<Trans i18nKey='SEARCH_CRITERIA'>SEARCH_CRITERIA</Trans>
											</div>
											<div className='ml-4'>
												<span>{this.state.simpleSearchedString}</span>
											</div>
										</div>
										<div className='col-2'>
											<button
												className='btn btn-enabled clear-search-button'
												id={'clearSearch'}
												onClick={this.onClearSimpleSearch}
											>
												<Trans i18nKey='CLEAR_SEARCH'>CLEAR_SEARCH</Trans>
											</button>
										</div>
									</div>
								</div>
							)}
							<br />

							{/* Collapse / Uncollapse lines */}
							<div className='container-fluid collapse-lines mb-2'>
								<div className='row mt-2 mb-2'>
									<div className='col-6'>
										<button
											title='In- en uitklappen'
											className='btn btn-enabled collapse-lines-icon'
											id={'toggleCollapse'}
											onClick={this.onToggleCollapseAllLines}
										>
										</button>
									</div>
									<div className='col-6'>
										<div className='quantity'>
											<span>x{this.sumUpAllQuantities()}</span>
										</div>
									</div>
								</div>
							</div>
	

							{this.econfDetails()}
							
							<div className='container margin-padding-left mb-5-changes'>
								{!viewModeOnly && (
									<div className='row'>
										<div className='quotation-line-add-button col-4'>
											<button
												id={'addQuotaton'}
												type='button'
												className='quotation-line-add-button-btn'
												onClick={this.openAddQuotationModal}
											>
												<Trans i18nKey='ADD_QUOTATION_CONFIGURATOR'>
													ADD_QUOTATION_CONFIGURATOR
												</Trans>
											</button>
										</div>
										<div className='col-4'>
											<button
												id={'addIndividual article'}
												type='button'
												className='quotation-line-add-button-btn'
												onClick={this.openIndividualArticleModal}
											>
												<Trans i18nKey='ADD_INDIVIDUAL_ARTICLE'>ADD_INDIVIDUAL_ARTICLE</Trans>
											</button>
										</div>
										<div className='col-4'>
											<button
												id={'addSupplement '}
												type='button'
												className='quotation-line-add-button-btn'
												onClick={this.openSupplementModal}
											>
												<Trans i18nKey='ADD_SUPPLEMENT_ARTICLE_HEADER'>ADD_SUPPLEMENT_ARTICLE_HEADER</Trans>
											</button>
										</div>
									</div>
								)}
							</div>

							{this.state.quotationLines.length !== 0 &&
								!this.state.isSearchApplied &&
								!this.state.isSimpleSearchApplied && (
									<div className='container-fluid doorway-panel mb-5-changes'>
										<div className='row'>
											<div className='col-12 panel-footer-total '>
												<table className='footer-table'>
													<tbody>
														<tr>
															<td></td>
															<td>
																<div className='two-cols light-white total-sum'>
																	<Trans i18nKey='TOTAL_DOOR_OPENINGS'>
																		TOTAL_DOOR_OPENINGS
																	</Trans>
																</div>
																<div className='two-cols-end light-white bold-font total-sum'>
																	<span id='spanTotalPrice'
																		className={
																			'bold-white' +
																			(this.state['QPriceIncomplete']
																				? ' color-red'
																				: '')
																		}
																		title={
																			this.state['QPriceIncomplete']
																				? i18n.t('INCOMPLETE_PRICE')
																				: ''
																		}
																	>
																		<PriceComponent 
																			value={totalPrice} 
																			incompletePriceStateId={this.state['QPriceIncomplete']} />
																		{this.state['QPriceIncomplete'] && (
																			<div className='warning-red-icon'></div>
																		)}
																	</span>
																</div>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								)}
							<div className='container-fluid'>
							<div className='row'>
								{this.state.supplementList && (this.state.supplementList.length > 0) && (
								
									<table className='table'>
										<thead>
											<tr>
											<th className='col-1'>
												<Trans i18nKey='UPSELLING_KEY'>UPSELLING_KEY</Trans>
											</th>
											<th className='col-2'>
												<Trans i18nKey='UPSELLING_VALUE'>UPSELLING_VALUE</Trans>
											</th>
											<th className='col-1'>
												<Trans i18nKey='UNIT_GROSS_PRICE'>UNIT_GROSS_PRICE</Trans>
											</th>
											<th className='col-1'>
												<Trans i18nKey='DISCOUNT'>DISCOUNT</Trans>
											</th>
											<th className='col-2'>
												<Trans i18nKey='UNIT_NET_PRICE'>UNIT_NET_PRICE</Trans>
											</th>	
											<th className='col-1'>
												<Trans i18nKey='QUANTITY'>QUANTITY</Trans>
											</th>
											<th className='col-2'>
												<Trans i18nKey='TOTAL_NET_PRICE'>TOTAL_NET_PRICE</Trans>
											</th>	
											<th className='col-1'>
												<Trans i18nKey='COMMENT'>COMMENT</Trans>
											</th>											
											</tr>
										</thead>
										<tbody>
										{this.state.supplementList.map((x, i) => {
											return (
												<tr>
												<td class='col-1'>{x.Key}</td>
												<td class='col-2'>{x.Value}</td>

												{!x.IsNetPrice ? 
												(
													<>
														<td class='col-2'> {' - '}</td>	
														<td class='col-1'>{' - '}</td>
														
														<td class='col-2'>
															<PriceComponent 
																value={x.AdditionalPrice} 
																incompletePriceStateId={false} />
														</td>		
														<td class='col-1'>{x.Quantity}</td>
														<td class='col-2'>
															<PriceComponent 
																value={x.Quantity * round(x.AdditionalPrice, 2)} 
																incompletePriceStateId={false} />
														</td>			
													</>
												)
												: 
												(
													<>	
														<td class='col-2'>														
															<PriceComponent 
																value={x.AdditionalPrice} 
																incompletePriceStateId={false} />
														</td>	
														<td class='col-1'>{x.Discount}</td>
																				
														<td class='col-2'>
															<PriceComponent 
																value={((x.AdditionalPrice)-(((x.AdditionalPrice) * x.Discount)/100).toFixed(2)).toFixed(2)} 
																incompletePriceStateId={false} />
															</td>
														<td class='col-1'>{x.Quantity}</td>
														
														<td class='col-2'>
															<PriceComponent 
																value={((x.Quantity) * ((x.AdditionalPrice)-(((x.AdditionalPrice) * x.Discount)/100).toFixed(2)).toFixed(2)).toFixed(2)} 
																incompletePriceStateId={false} />
														</td>			
													</>
												)} 													
												<td class='col-1'>{x.Comment}</td>							
											</tr>
											)})}
											</tbody>
											</table>
									)}
											
							
								</div> 
							</div>
							<div className='row'>
							<div className='col-10'>
							{this.state.quotationLines.length !== 0 && (
								<div className='container mb-4'>									
									<div className='row'>
										<div className='col-12'>
											<div className='quotation-button'>
												{!viewModeOnly && (
													<button
														id={'openStatus'}
														type='button'
														className='btn quotation-button-enabled'
														style={{ marginLeft: '50px' }}
														onClick={this.openAssignStatus}
													>
														<Trans i18nKey='STATUS_BTN'>STATUS_BTN</Trans>
													</button>
												)}
												<button
													id={'openExport'}
													type='button'
													className= {this.state.quotationData ? 'btn quotation-button-enabled' : 'disabled'}
													style={{ marginLeft: '50px' }}
													onClick={this.openExportModal}
												>
													<Trans i18nKey='EXPORT'>EXPORT</Trans>
												</button>

												
											</div>
										</div>
									</div>
								</div>
							)}
							</div>
							{hasEditAccess(userRoleDetail,"XmlDownload") &&
							<div className='col-2'>
								<button
									id={'downloadXml'}
										type='button'
										className= {'btn quotation-button-enabled'}
										style={{ marginRight: '50px' }}
										onClick={this.downloadXMLModal}
								>
								<Trans i18nKey='DOWNLOAD_XML'>DOWNLOAD_XML</Trans>
								</button>
						    </div>}
						</div>
							<div>
								<Modal
									isOpen={this.state.showSearchPanel}
									external={externalCloseBtn}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='QUOTATION_LINE_SEARCH_HEADER'>
												QUOTATION_LINE_SEARCH_HEADER
											</Trans>
										</div>
										<div
											className='modal-close'
											id={'closeSearchPanel'}
											onClick={this.onCloseSearchPanel}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='add-quotation-line-modal-body search-modal-popup'>
											<div>
												<div className='add-quotation-line-title add-quotation-line-panel-label'>
													<Trans i18nKey='QUOTATION_LINE_SEARCH_TITLE'>
														QUOTATION_LINE_SEARCH_TITLE
													</Trans>
												</div>
											</div>
											<div className='col-12'>
												<Table>
													<thead>
														<tr className='row line-item-table-header'>
															<th className='col-5'>
																<Trans i18nKey='QUOTATION_LINE_SEARCH_FIELD'>
																	QUOTATION_LINE_SEARCH_FIELD
																</Trans>
															</th>
															<th className='col-5'>
																<Trans i18nKey='QUOTATION_LINE_SEARCH_FIELD_VALUE'>
																	QUOTATION_LINE_SEARCH_FIELD_VALUE
																</Trans>
															</th>
														</tr>
													</thead>

													{this.state.refreshAsyncahead && (
														<tbody>{this.buildSearchFilterPanel()}</tbody>
													)}

													{!this.state.refreshAsyncahead && (
														<tbody>{this.buildSearchFilterPanel()}</tbody>
													)}
												</Table>
											</div>
											<div
												id={'AddSearchFilter'}
												className='icon-add-search-filter'
												onClick={() => this.AddSearchFilter()}
											></div>
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn btn-enabled'
												id={'SearchOkButton'}
												onClick={() => this.onApplySearch()}
											>
												<Trans i18nKey='QUOTATION_LINE_SEARCH_OK_BUTTON'>
													QUOTATION_LINE_SEARCH_OK_BUTTON
												</Trans>
											</button>

											<button
												id={'SearchCancelButton'}
												className='btn btn-enabled ml-2'
												onClick={() => this.onCloseSearchPanel()}
											>
												<Trans i18nKey='QUOTATION_LINE_SEARCH_CANCEL_BUTTON'>
													QUOTATION_LINE_SEARCH_CANCEL_BUTTON
												</Trans>
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>

							<div>
								<Modal
									isOpen={this.state.showAddOrEditQuotationModal}
									external={externalCloseBtn}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='ADD_QUOTATION_LINE'>ADD_QUOTATION_LINE</Trans>
										</div>
										<div
											className='modal-close'
											id={'closeAddQuotationModal'}
											onClick={this.onClosePopup}
										>
											x
										</div>
									</ModalHeader>

									<ModalBody>
										<div className='add-quotation-line-modal-body'>
											<div>
												<div className='add-quotation-line-title'>
													<Trans i18nKey='QUOTATION_LINE_TITLE'>QUOTATION_LINE_TITLE</Trans>
												</div>
												<div>
													<input
														type='text'
														id={'quotationTitle'}
														className='form-control font-weight-doors'														
														onChange={this.setAddQuotationTitle}
														value={this.state.addQuotationTitle}
														style={{ width: '300px' }}
													/>
												</div>
											</div>
											<br />
											<div className='row'>
												<div className='col-7'>
													<span className='error-message-excel '>
														{this.state.excelParseErrorMessage.length > 0 &&
															this.state.excelParseErrorMessage.map((x, i) => {
																return (
																	<>
																		{x} <br />
																	</>
																);
															})}
													</span>
												</div>
												<div className='col-5'>
													<button
														className='btn btn-enabled upload-excel-btn ml-2'
														id={'importExcel'}
														onClick={this.uploadExcel}
													>
														<Trans i18nKey='IMPORT'>IMPORT</Trans>
													</button>
													<button
														className='btn btn-enabled upload-excel-btn'
														id={'uploadExcel'}
														onClick={this.exportExcel}
													>
														<Trans i18nKey='EXPORT'>EXPORT</Trans>
													</button>

													<input
														type='file'
														ref={(input) => (this.excelInput = input)}
														onChange={(e) => this.fileHandler(e)}
														onClick={(event) => (event.target.value = null)}
														style={{ display: 'none' }}
													/>
												</div>
											</div>
											<br />
											<div className='add-quotation-line-panel row'>
												<div className='col-10 add-quotation-line-panel-label'>
													<Trans i18nKey='COUNT_INDIVIDUAL_DOORWAYS'>
														COUNT_INDIVIDUAL_DOORWAYS
													</Trans>
												</div>
												<div className='col-2'>
													<input
														id={'line-item-count'}
														className={
															'line-item-input' +
															(this.state.isDoorWaysEmpty ? ' invalid-style' : '')
														}
														value={this.state.noOfIdenticalDoorWays}
														name='LineItemCount'
														type='text'
														onChange={this.onLineCountChange}
														onBlur={this.setLineCount}
													></input>
												</div>
											</div>
											{this.state.isDoorWaysEmpty && (
												<div className='row'>
													<div className='col-12 validation-message'>
														<Trans i18nKey='ARTICLE_QUANTITY_EMPTY'>
															ARTICLE_QUANTITY_EMPTY
														</Trans>
													</div>
												</div>
											)}
											<br />
											<div className='doorway-details'>
												<Table>
													<thead>
														<tr className='row line-item-table-header'>
															<th className='col-1'></th>
															<th className='col-3'>
																<Trans i18nKey='DOOR_OPENING_REFERENCE'>
																	DOOR_OPENING_REFERENCE
																</Trans>
															</th>
															<th className='col-3'>
																<Trans i18nKey='BUILDING'>BUILDING</Trans>
															</th>
															<th className='col-2'>
																<Trans i18nKey='FLOOR_DOORWAYS'>FLOOR_DOORWAYS</Trans>
															</th>
															<th className='col-2'>
																<Trans i18nKey='APPARTEMENT'>APPARTEMENT</Trans>
															</th>
															<th className='col-1'></th>
														</tr>
													</thead>
													<tbody>
														{this.populateLineItems()}
														<div
															className='icon-add-search-filter'
															onClick={this.onAddIndividualDoorway}
														></div>
													</tbody>
												</Table>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn btn-enabled'
												id={'saveAndContinueToEcon'}
												onClick={() => this.saveLineQuotation(true)}
											>
												<Trans i18nKey='SAVE_AND_CONTINUE_TO_ECON'>
													SAVE_AND_CONTINUE_TO_ECON
												</Trans>
											</button>

											<button
												id={'saveAndExit'}
												className='btn btn-enabled ml-2'
												onClick={() => this.saveLineQuotation(false)}
											>
												<Trans i18nKey='SAVE_AND_GO_BACK'>SAVE_AND_GO_BACK</Trans>
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showAddAlternative}
									external={externalCloseBtn}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='ADD_ALTERNATIVE'>ADD_ALTERNATIVE</Trans>
										</div>
										<div
											className='modal-close'
											id={'closeAlternative'}
											onClick={this.onCloseAlternativePopup}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='add-individual-article-modal-body'>
											<div>
												<div className='add-quotation-line-title'>
													<Trans i18nKey='ALTERNATIVE_LINE_TITLE'>
														ALTERNATIVE_LINE_TITLE
													</Trans>
												</div>
												<div>
													<input
														id={'alternativeTitle'}
														type='text'
														className='form-control font-weight-doors'
														placeholder={i18n.t('ALTERNATIVE_PLACEHOLDER')}
														onChange={this.setAddAlternativeTitle}
														value={this.state.addAlternativeTitle}
														style={{ width: '50%' }}
													/>
												</div>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn btn-enabled'
												onClick={this.addNewConfigurationAsAlternative}
											>
												<Trans i18nKey='ADD_ALTERNATIVE_NEW'>ADD_ALTERNATIVE_NEW</Trans>
											</button>

											<button
												className='btn btn-enabled ml-3'
												onClick={this.addExistingConfigurationAsAlternative}
											>
												<Trans i18nKey='ADD_ALTERNATIVE_EXISTING'>
													ADD_ALTERNATIVE_EXISTING
												</Trans>
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showEditAlternativePopUp}
									external={externalCloseBtn}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='EDIT_ALTERNATIVE'>EDIT_ALTERNATIVE</Trans>
										</div>
										<div
											className='modal-close'
											id={'closeEditAlternative'}
											onClick={this.onCloseEditAlternativePopup}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='add-individual-article-modal-body'>
											<div>
												<div className='add-quotation-line-title'>
													<Trans i18nKey='ALTERNATIVE_LINE_TITLE'>
														ALTERNATIVE_LINE_TITLE
													</Trans>
												</div>
												<div>
													<input
														id={'editAlternativeTitle'}
														type='text'
														className='form-control font-weight-doors'
														placeholder={i18n.t('ALTERNATIVE_PLACEHOLDER')}
														onChange={this.setEditAlternativeTitle}
														value={this.state.editAlternativeTitle}
													/>
												</div>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn btn-enabled'
												onClick={this.onSaveEditAlternativeTitle}
											>
												<Trans i18nKey='OK'>OK</Trans>
											</button>

											<button
												className='btn btn-enabled ml-3'
												onClick={this.onCloseEditAlternativePopup}
											>
												<Trans i18nKey='CANCEL'>CANCEL</Trans>
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showIndividualArticleModal}
									external={externalCloseBtn}
									size={'lg'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											{this.state.editIndividualArticleData ? (
												<Trans i18nKey='EDIT_INDIVIDUAL_ARTICLE_HEADER'>
													EDIT_INDIVIDUAL_ARTICLE_HEADER
												</Trans>
											) : (
												<Trans i18nKey='ADD_INDIVIDUAL_ITEM'>ADD_INDIVIDUAL_ITEM</Trans>
											)}
										</div>
										<div
											className='modal-close'
											id={'closeIndividualPopUp'}
											onClick={this.onCloseIndividualArticlePopup}
										>
											x
										</div>
									</ModalHeader>

									<ModalBody>
										<div className='add-individual-article-modal-body'>
											<div>
												<div className='add-quotation-line-title'>
													<Trans i18nKey='QUOTATION_LINE_TITLE'>QUOTATION_LINE_TITLE</Trans>
												</div>

												<div>
													<input
														id={'quotationTitle'}
														type='text'
														className='form-control font-weight-doors'
													
														onChange={this.setAddQuotationTitle}
														value={this.state.addQuotationTitle}
														style={{ width: '50%' }}
													/>
												</div>
												<br />
												<div className='row'>
													<div className='col-4 export-title add-quotation-line-title '>
														<Trans i18nKey='SELECT_INDIVIDUAL_ARTICLE_TYPE'>
															SELECT_INDIVIDUAL_ARTICLE_TYPE
														</Trans>
													</div>
													<div className='col-3'>
														<input
															id={'auto'}
															name='articleType'
															type='radio'
															className='width-left mt-1'
															onChange={() => this.onArticleTypeChanged(1)}
															checked={!this.state.isManualEntryIndividualArticle}
														></input>
														<span className='add-quotation-line-content'>
															<Trans i18nKey='ARTICLE_TYPE_AUTO'>ARTICLE_TYPE_AUTO</Trans>
														</span>
													</div>

													<div className='col-3'>
														<input
															id={'manual'}
															name='articleType'
															className='width-left-second mt-1'
															type='radio'
															onChange={() => this.onArticleTypeChanged(2)}
															checked={this.state.isManualEntryIndividualArticle}
														></input>
														<span className='add-quotation-line-content'>
															<Trans i18nKey='ARTICLE_TYPE_MANUAL'>
																ARTICLE_TYPE_MANUAL
															</Trans>
														</span>
													</div>
												</div>
												<br />
												<div className='row'>
													<div className='col-4 export-title add-quotation-line-title '>
														<Trans i18nKey='SELECT_PRODUCT_TYPE'>
														SELECT_PRODUCT_TYPE
														</Trans>
													</div>
													<div className='col-3'>
														<input
															id ={'others'}
															value= {'1'}
															name='productType'
															type='radio'
															className='width-left mt-1'
															onChange={() => this.onProductTypeChanged(1)}
															checked={this.state.selectedProductType === 1}
														></input>
														<span className='add-quotation-line-content'>
														<Trans i18nKey='Others'>
															Others
															</Trans>
														</span>
													</div>

													<div className='col-3'>
														<input
															id ={'set'}
															value={'2'}
															name='productType'
															className='width-left-second mt-1'
															type='radio'
															onChange={() => this.onProductTypeChanged(2)}
															checked={this.state.selectedProductType === 2}
														></input>
														<span className='add-quotation-line-content'>														
															<Trans i18nKey='SET'>SET</Trans>
														</span>
													</div>
												</div>
												<br />
												{!this.state.isManualEntryIndividualArticle ? (
													<>
														<div className='pricelist-dropdown'>
															<div className=' bold-header-font'>
																<Trans i18nKey='PRICE_LIST'>PRICE_LIST</Trans>
															</div>

															<Dropdown
																isOpen={this.state.priceListDDIsOpen}
																toggle={this.togglePriceListDD}
																id={'priceList'}
															>
																<DropdownToggle caret>
																	{!!this.state.selectedPriceList
																		? this.state.selectedPriceList.PriceListShort
																		: 'Meegegeven uit opp.'}
																</DropdownToggle>
																<DropdownMenu>
																	{this.state.priceList.map((x, i) => {
																		return (
																			<DropdownItem
																				key={i}
																				onClick={() => this.setPrice(x)}
																			>
																				{x.PriceListShort}
																			</DropdownItem>
																		);
																	})}
																</DropdownMenu>
															</Dropdown>
														</div>
														<br />
														<div className='add-individual-article-title'>
															<Trans i18nKey='SELECT_ARTICLE'>SELECT_ARTICLE</Trans>
														</div>
														<div className='artical-search'>
															<AsyncTypeahead
																ref={(ref) => (this.typeahead = ref)}
																defaultSelected={this.props.defaultSelected}
																open={this.state.openIndividualArticle}
																filterBy={this.filterBy}
																id='async-example'
																isLoading={this.state.isLoading}
																labelKey='Id'
																className={
																	this.state.individualArticleValidations
																		.isArticleNotSelected
																		? 'rbt-invalid-style'
																		: ''
																}
																minLength={3}
																useCache={false}
																onSearch={this.searchIndividualArticles}
																options={this.state.options}
																placeholder={i18n.t('SEARCH_ARTICLE')}
																renderMenu={(results, menuProps) => (
																	<div className='div-table'>
																		<table className='table table-striped dropdownTable'>
																			<thead>
																				<tr className='individual-search-table-header row'>
																					<th className='col-xl-7 col-lg-3  col-sm-3'>
																						<Trans i18nKey='ARTICLE_NAME'>
																							ARTICLE_NAME
																						</Trans>
																					</th>
																					<th className='col-xl-4 col-lg-3  col-sm-3'>
																						<Trans i18nKey='ARTICLE_PRICE'>
																							ARTICLE_PRICE
																						</Trans>
																					</th>
																				</tr>
																			</thead>
																			<tbody>
																				{this.state.isLoading && (
																					<div className='search-loading mt-4'>
																						<Trans i18nKey='LOADING'>
																							LOADING
																						</Trans>
																					</div>
																				)}
																				{results.length === 0 &&
																					!this.state.isLoading && (
																						<div className='search-loading mt-4'>
																							<Trans i18nKey='NO_DATA_FOUND'>
																								NO_DATA_FOUND
																							</Trans>
																						</div>
																					)}
																				{results.length !== 0 &&
																					results.map((option, index) => (
																						<tr
																							className='individual-row table-content row'
																							id={
																								'selectIndividualArticle' +
																								index
																							}
																							onClick={() =>
																								this.onIndividualArticleSelect(
																									option
																								)
																							}
																							key={index}
																						>
																							<td className='td-bold col-xl-7 col-lg-3  col-sm-3'>
																								{
																									option.ShortDescription
																								}
																							</td>
																							<td className='td-light col-xl-2 col-lg-3  col-sm-3'>
																								<PriceComponent 
																									value={option.GrossBasePrice} 
																									incompletePriceStateId={false} />
																							</td>
																						</tr>
																					))}
																			</tbody>
																		</table>
																	</div>
																)}
															/>
														</div>
														{this.state.individualArticleValidations
															.isArticleNotSelected && (
															<div className='validation-message'>
																<Trans i18nKey='ARTICLE_NOT_SELECTED'>
																	ARTICLE_NOT_SELECTED
																</Trans>
															</div>
														)}
													</>
												) : (
													<>
														<div className='add-quotation-line-title  bold-header-font'>
															<Trans i18nKey='ARTICLE_NAME'>ARTICLE_NAME</Trans>
														</div>

														<div>
															<input
																id={'quotationTitle'}
																type='text'
																className={
																	'form-control font-weight-doors' +
																	(this.state.individualArticleValidations
																		.isArticleNameEmpty
																		? ' invalid-style'
																		: '')
																}
																onChange={this.setIndividualArticleDesc}
																value={this.state.individualArticleDetails[0].Name}
																style={{ width: '50%' }}
															/>
														</div>
														{this.state.individualArticleValidations.isArticleNameEmpty && (
															<div className='validation-message'>
																<Trans i18nKey='ARTICLE_NAME_EMPTY'>
																	ARTICLE_NAME_EMPTY
																</Trans>
															</div>
														)}
														<br />
														<div className='add-quotation-line-title  bold-header-font'>
															<Trans i18nKey='ARTICLE_PRICE'>ARTICLE_PRICE</Trans>
														</div>

														<div>
															<input
																id={'quotationTitle'}
																type='text'
																className={
																	'form-control font-weight-doors' +
																	(this.state.individualArticleValidations
																		.isArticlePriceEmpty
																		? ' invalid-style'
																		: '')
																}
																onChange={this.setIndividualArticlePrice}
																value={this.state.individualArticleDetails[0].Price || 0}
																style={{ width: '50%' }}
															/>
														</div>
														{this.state.individualArticleValidations
															.isArticlePriceEmpty && (
															<div className='validation-message'>
																<Trans i18nKey='ARTICLE_PRICE_EMPTY'>
																	ARTICLE_PRICE_EMPTY
																</Trans>
															</div>
														)}
														<br />
													</>
												)}
											</div>
											<br />

											{!this.state.isManualEntryIndividualArticle && (
												<div
													className={
														!this.state.IsIndividualArticleSelected
															? 'hide-individualArticle-table'
															: ''
													}
												>
													<div>
														<table className='table table-striped'>
															<tr>
																<td className='bold-header-font'>
																	<Trans i18nKey='ARTICLE_NAME'>ARTICLE_NAME</Trans>
																</td>
																<td className='bold-header-font'>
																	<Trans i18nKey='ARTICLE_PRICE'>ARTICLE_PRICE</Trans>
																</td>
															</tr>
															<tr>
																<td>
																	{
																		this.state.selectedIndividualArticle
																			.ShortDescription
																	}
																</td>
																<td>
																	<PriceComponent 
																		value={this.state.selectedIndividualArticlePrice} 
																		incompletePriceStateId={false} />
																</td>
															</tr>
														</table>
													</div>
												</div>
											)}

											<div className='add-quotation-line-panel row'>
												<div className='col-8 add-quotation-line-panel-label'>
													<Trans i18nKey='COUNT_ARTICLE'>COUNT_ARTICLE</Trans>
												</div>
												<div className='col-2'>
													<input
														id={'line-item-count'}
														className={
															'line-item-input' +
															(this.state.individualArticleValidations
																.isArticleQuantityEmpty
																? ' invalid-style'
																: '')
														}
														name='IndividualArticleLineItemCount'
														type='text'
														value={this.state.noOfIndividualArticles}
														onChange={this.onIndividualArticleChange}
													></input>
												</div>
											</div>
											{this.state.individualArticleValidations.isArticleQuantityEmpty && (
												<div className='validation-message'>
													<Trans i18nKey='ARTICLE_QUANTITY_EMPTY'>
														ARTICLE_QUANTITY_EMPTY
													</Trans>
												</div>
											)}

											<br />
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn opportunity-next-step-btn'
												onClick={this.saveIndividualArticle}
											>
												{this.state.editIndividualArticleData ? (
													<Trans i18nKey='EDIT_ARTICLE_SAVE'>EDIT_ARTICLE_SAVE</Trans>
												) : (
													<Trans i18nKey='ADD_ARTICLE'>ADD_ARTICLE</Trans>
												)}
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>

							{/* for supplement ---------------------------------------------------------*/}
							<div>
								<Modal 
									isOpen={this.state.showSupplementModal}
									external={externalCloseBtn}
									size={'xl'}
									onOpened={this.setPriceListDefault}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>

								<ModalHeader>
									<div className='modal-title-text'>
										{this.state.editSupplementData ? 
										(
											i18n.t('ADD_SUPPLEMENT_ARTICLE_HEADER')
										) : (
											<Trans i18nKey='ADD_SUPPLEMENT_ARTICLE_HEADER'>ADD_SUPPLEMENT_ARTICLE_HEADER</Trans>
										)}
									</div>
									<div
										className='modal-close'
										id={'closeIndividualPopUp'}
										onClick={this.onCloseSupplementPopup}
									>
										x
									</div>
								</ModalHeader>

								<ModalBody>
									
									<div className='add-quotation-line-modal-body'>	

										<div className='row'>

											<div className='col-2 export-title add-quotation-line-title'>

											<Trans i18nKey='SELECT_INDIVIDUAL_ARTICLE_TYPE'>
												SELECT_INDIVIDUAL_ARTICLE_TYPE
											</Trans>

											</div>
																	
												<div className='col-2'>
													<input
														id={'supplementAuto'}
														name={'articleType'}
														type='radio'
														className='width-left mt-1'														
														onChange={(e) => this.onSupplementeChanged(1)}
														checked={!this.state.isSupplementUserDefined}
													></input>
														<Trans i18nKey='ARTICLE_TYPE_AUTO'>ARTICLE_TYPE_AUTO</Trans>			
												</div>

												<div className='col-2'>
													<input
														id={'supplementManual'}
														name={'articleType'}
														className='width-left mt-1'
														type='radio'														
														onChange={(e) => this.onSupplementeChanged(2)}
														checked={this.state.isSupplementUserDefined}
													></input>
													
														<Trans i18nKey='ARTICLE_TYPE_MANUAL'>
															ARTICLE_TYPE_MANUAL
														</Trans>
												</div>	
										</div>	

									<br></br>
									 <br></br>							
                                             <div className='row'>
											 {!this.state.isSupplementUserDefined ? 
											(
													<>
												<div className='col-2 export-title add-quotation-line-title'>
												<Trans i18nKey='PRICE_LIST'>PRICE_LIST</Trans>
												</div>
												<div className='col-3 export-title add-quotation-line-title'>
												<Trans i18nKey='SELECT_ARTICLE'>SELECT_ARTICLE</Trans>
												</div> 
												<div className='col-2 export-title add-quotation-line-title'>
											    <Trans i18nKey='SELECTED_ARTICLE_DESCRIPTION'>SELECTED_ARTICLE_DESCRIPTION</Trans>
											    </div>
												</>
											)
											 : 
											(
											<>
												<div className='col-2 export-title add-quotation-line-title'>
												<Trans i18nKey='UPSELLING_KEY'>UPSELLING_KEY</Trans>
												</div>
												
												<div className='col-3 export-title add-quotation-line-title'>
												<Trans i18nKey='DESCRIPTION'>DESCRIPTION</Trans>
												</div>
												</>
											)} 											
											 
											{!this.state.isSupplementUserDefined ?(<></>) :(
												<>
											<div className='col-2 export-title add-quotation-line-title'>
												<Trans i18nKey='PRICE'>PRICE</Trans>
											</div>
											</>)}
											<div className='col-1 export-title add-quotation-line-title'>
												<Trans i18nKey='DISCOUNT'>DISCOUNT</Trans>
											</div>		

											<div className='col-1 export-title add-quotation-line-title'>
												<Trans i18nKey='QUANTITY'>QUANTITY</Trans>
											</div>									
											<div className='col-2 export-title add-quotation-line-title'>
												<Trans i18nKey='COMMENT'>COMMENT</Trans>
											</div> 
							
												{!this.state.isSupplementUserDefined ? (
													<><div className='col-2'>
														<div className="pricelist-dropdown-supplement">															
															<Dropdown															
																isOpen ={this.state.priceListDDIsOpenSupplement}																
																toggle={this.togglePriceListDDSupplement}
																id={'priceList'}
															>
																<DropdownToggle caret >
																{!!this.state.selectedPriceListSupplement
																		? this.state.selectedPriceListSupplement.PriceListShort
																		: 'Meegegeven uit opp.'}
																</DropdownToggle>
																<DropdownMenu>
																	{this.state.priceList.map((x, j) => {
																		return (
																			<DropdownItem
																				key={j}																			
																				id = {j}
																				onClick={() => {  this.setPriceSupplemnt(x) }}
																			>
																				{x.PriceListShort}
																			</DropdownItem>
																		);
																	})}
																</DropdownMenu>
															</Dropdown>
														
														</div>
														</div>

														<div className='col-3'>
															<div className='artical-search-supplement'>
															
																<AsyncTypeahead
																	ref={(ref) => (this.typeahead = ref)}															
																	defaultSelected={this.props.defaultSelected}
																	open={this.state.priceListTypeAheadIsOpenSupplement}
																	filterBy={this.filterBy}
																	id={'async-example'}
																	isLoading={this.state.isLoading}
																	labelKey={'Id'}															
																	className={
																		this.state.individualArticleValidations
																			.isArticleNotSelected
																			? 'rbt-invalid-style'
																			: ''
																	}
																	minLength={3}
																	useCache={false}
																	onSearch={(option)=>{ this.searchSupplement(option)}}
																	options={this.state.options}
																	placeholder={i18n.t('SEARCH_ARTICLE')}
																	
																	renderMenu={(results, menuProps) => (
																		<div className='div-table'>
																			
																			<table className='table table-striped dropdownTable'>
																			<thead>
																					<tr className='individual-search-table-header row'>
																						<th className='col-xl-7 col-lg-3  col-sm-3'>
																							<Trans i18nKey='ARTICLE_NAME'>
																								ARTICLE_NAME
																							</Trans>
																						</th>
																						<th className='col-xl-4 col-lg-3  col-sm-3'>
																							<Trans i18nKey='ARTICLE_PRICE'>
																								ARTICLE_PRICE
																							</Trans>
																						</th>
																					</tr>
																				</thead>																			
																				<tbody>
																				
																					{this.state.isLoading && (
																						<div className='search-loading mt-4'>
																							<Trans i18nKey='LOADING'>
																								LOADING
																							</Trans>
																						</div>
																					)}
																					{results.length === 0 &&
																						!this.state.isLoading && (
																							<div className='search-loading mt-4'>
																								<Trans i18nKey='NO_DATA_FOUND'>
																									NO_DATA_FOUND
																								</Trans>
																							</div>
																						)}
																					{results.length !== 0 &&
																						results.map((option, index) => (
																							<tr
																								className='individual-row table-content row'
																								id={
																									'selectSupplementArticle'
																								}
																								onClick={() =>
																									this.onSupplementSelect(
																										option
																									)
																								}
																								key={index}
																							>
																								<td className='td-bold col-xl-7 col-lg-3  col-sm-3'>
																									{
																										option.Id
																									}
																								</td>
																								<td className='td-light col-xl-4 col-lg-3  col-sm-3'>
																									<PriceComponent 
																										value={option.GrossBasePrice} 
																										incompletePriceStateId={false}/>
																								</td>
																							</tr>
																						))}
																					
																				</tbody>
																			</table>
																		</div>
																	)}
																/>
																
															</div>
														</div>
														
														{this.state.individualArticleValidations
															.isArticleNotSelected && (
															<div className='validation-message'>
																<Trans i18nKey='ARTICLE_NOT_SELECTED'>
																	ARTICLE_NOT_SELECTED
																</Trans>
															</div>
														)}
													</>
												) : (
													<>													
													<div className='col-2'>
														<div>
															<input
																id={'quotationTitle'}
																type='text'
																className={
																	'form-control font-weight-doors' +
																	(this.state.individualArticleValidations
																		.isArticleNameEmpty
																		? ' invalid-style'
																		: '')
																}

																onChange={(e) => {
																	this.setSupplementArticleDesc(e);
																	}}	
																	value={this.state.selectedSupplementArticle.Id}
															/>
														</div>
													</div>
														{this.state.individualArticleValidations.isArticleNameEmpty && (
															<div className='validation-message'>
																<Trans i18nKey='ARTICLE_NAME_EMPTY'>
																	ARTICLE_NAME_EMPTY
																</Trans>
															</div>
														)}
														<br />													
														
														{this.state.individualArticleValidations
															.isArticlePriceEmpty && (
															<div className='validation-message'>
																<Trans i18nKey='ARTICLE_PRICE_EMPTY'>
																	ARTICLE_PRICE_EMPTY
																</Trans>
															</div>
														)}
														<br />
													</>
												) }		

											{!this.state.isSupplementUserDefined ? 
											(
													<>
												<div className='col-2'>
													<div className = 'supplement-quantity'>
														<input
															id={'setDescription' }
															className='form-control'															
															disabled = {true}
															value={this.state.selectedSupplementArticle.ShortDescription}
									
															/>			
													</div>
												</div>
												</>
											)
											 : 
											(
											<>
												<div className='col-3'>
													<div className = 'supplement-quantity'>
														<input
															id={'setDescription' }
															className='form-control'
															onChange={(e) => {
															this.setDescription(e);																														
															}}
															disabled = {!this.state.isSupplementUserDefined}
															value={this.state.selectedSupplementDiscription}
									
															/>			
													</div>
												</div>
												</>
											)} 

									
												
												
												{!this.state.isSupplementUserDefined ? 
											    (
												<>
												
												</>
											    )
												:
												(
													<>
													<div className='col-2'>
														<div>
															<input
																id={'quotationTitle'}
																type='number'
																className={
																	'form-control font-weight-doors' +
																		(this.state.individualArticleValidations
																			.isArticlePriceEmpty
																			? ' invalid-style'
																			: '')
																	}

																	onChange={(e) => {
																		this.setSupplementArticlePrice(e);
																		}}
																		value={this.state.selectedSupplementArticle.GrossBasePrice}

																																
																	
																/>
															</div>
														</div></>
												)}
												<div className='col-1'>
													<div className = 'supplement-quantity'>
														<input
															id={'setDiscount' }
															type='number'
															className='form-control'
															onChange={(e) => {
															this.setDiscount(e);
															
															}}
															value={this.state.selectedSupplementDiscount}
									
															/>			
													</div>
												</div>

												<div className='col-1'>
													<div className = 'supplement-quantity'>
														<input
															id={'setQuantity' }
															type='number'
															className='form-control'
															onChange={(e) => {
															this.setQuantity(e);
															
															}}
															value={this.state.selectedSupplementQuantity}
									
															/>			
													</div>
												</div>
												
												<div className='col-2'>
													<div className = 'supplement-quantity'>
														<input
															id={'setComment' }
															className='form-control'
															onChange={(e) => {
															this.setComment(e);
															
															}}
															value={this.state.selectedSupplementComment}
									
															/>			
													</div>
												</div>
													<div>
														<button className='btn opportunity-next-step-btn' onClick={() => this.onAddSupplement()}>
														<Trans i18nKey='ADD_SUPPLEMENT_ITEM'>ADD_SUPPLEMENT_ITEM</Trans>
														</button>	
													</div>
													
																		
												
												
												
											</div>

										 <br></br>
										 <br></br>
										 <br></br>
										 <div className='row'>
											{this.state.supplementList && (this.state.supplementList.length > 0) && (
										
											<table className='table'>
												<thead>
													<tr>
														<th className='col-1'>{i18n.t('UPSELLING_KEY')}</th>	
														<th className='col-2'>{i18n.t('UPSELLING_VALUE')}</th>	
														<th className='col-2'>{i18n.t('UNIT_GROSS_PRICE')}</th>	
														<th className='col-1'>{i18n.t('DISCOUNT')}</th>	
														<th className='col-2'>{i18n.t('UNIT_NET_PRICE')}</th>	
														<th className='col-1'>{i18n.t('QUANTITY')}</th>	
														<th className='col-2'>{i18n.t('TOTAL_NET_PRICE')}</th>	
														<th className='col-1'>{i18n.t('COMMENT')}</th>																		
														<th className='col-2'></th>
													</tr>
												</thead>
												<tbody>
												{this.state.supplementList.map((x, i) => {
																					return (
											   		<tr>																							
														<td class='col-1'>{x.Key}</td>
														<td class='col-2'>{x.Value}</td>
														{!x.IsNetPrice ? (
															<>
																<td class='col-2'> {' - '}</td>	
																<td class='col-1'>{' - '}</td>			
																<td class='col-2'>
																	<PriceComponent 
																		value={x.AdditionalPrice} 
																		incompletePriceStateId={false} />	
																</td>
																<td class='col-1'>{x.Quantity}</td>																																	
																<td class='col-2'>
																	<PriceComponent 
																		value={x.Quantity * x.AdditionalPrice} 
																		incompletePriceStateId={false} />
																</td>			
															</>
														)
														: 
														(
															<>	
																<td class='col-1'>
																	<PriceComponent 
																		value={x.AdditionalPrice} 
																		incompletePriceStateId={false} />
																</td>			
																<td class='col-1'>{x.Discount}</td>
																<td class='col-2'>
																	<PriceComponent 
																		value={x.AdditionalPrice-(x.AdditionalPrice * x.Discount)/100} 
																		incompletePriceStateId={false} />
																</td>
																<td class='col-1'>{x.Quantity}</td>
																<td class='col-2'>
																	<PriceComponent 
																		value={x.Quantity * x.AdditionalPrice - (x.AdditionalPrice * x.Discount) /100} 
																		incompletePriceStateId={false} />
																</td>																	
															</>
														)} 	
														<td class='col-2'>{x.Comment}</td>
														<td class='col-2'>
															<div
																id='removeSupplementItem'
																className='search-clear-icon'
																onClick={() => this.removeSupplementItems(i)}
															></div>
														</td>
													</tr>
											)})}
												</tbody>
												</table>
												)}
												
								
										 </div> 
										 </div>
											
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn opportunity-next-step-btn'
												onClick={this.saveSupplement}
											>
												{this.state.editSupplementData ? (
													<Trans i18nKey='EDIT_ARTICLE_SAVE'>EDIT_ARTICLE_SAVE</Trans>
												) : (
													<Trans i18nKey='ADD_SUPPLEMENT'>ADD_SUPPLEMENT</Trans>
												)}
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>
							{/* till supplement */}
							<div>
								<Modal
									isOpen={this.state.showIframeModal}
									id='EconFrame'
									external={externalCloseBtn}
									size={'xl'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='ECON_CONFIGURATION'>ECON_CONFIGURATION</Trans>
										</div>
										<div
											className='modal-close'
											id='modal-close-econf'
											onClick={this.onCloseIframePopup}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='econ-config-modal-body'>
											<iframe
												src={this.state.iframeURL}
												height='99%'
												width='99%'
												title='Econf Window'
												frameBorder='0'
												id='econf-iframe'
												onLoad={this.iframeLoad}
											></iframe>
										</div>
									</ModalBody>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showAssignStatusModal}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='MODIFY_STATUS_TEXT'>MODIFY_STATUS_TEXT</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-econf'
											onClick={this.onCloseAssignStatus}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<Table striped>
												<thead>
													<tr>
														<th scope='col'>
															<div className={'table-header-content'}></div>
														</th>
														<th scope='col'>
															<div className={'table-header-content'}>
																<Trans i18nKey='ASSIGN_STATUS'>ASSIGN_STATUS</Trans>
															</div>
														</th>
														<th scope='col'>
															<div className={'table-header-content'}>
																<Trans i18nKey='TIMEOFDAY'>TIMEOFDAY</Trans>
															</div>
														</th>
														<th scope='col'>
															<div className={'table-header-content'}>
																<Trans i18nKey='ABBREVIATED'>ABBREVIATED</Trans>
															</div>
														</th>
													</tr>
												</thead>
												<tbody className='table-parent tbody-parent'>
													{this.state.quotationStatus.map((x, i) => {
														return (
															<tr
																className='table-content'
																key={i}
																style={
																	x.CurrentStatus
																		? {
																				backgroundColor: '#3E648B',
																				color: '#FFFFFF'
																		  }
																		: {
																				backgroundColor: 'white',
																				color: '#000000'
																		  }
																}
															>
																<td className='padding-td'>
																	<input
																		id={x.Code}
																		value={x.StatusId}
																		name='quotationStatus'
																		type='radio'
																		onChange={(e) => this.onStatusChanged(x, e)}
																		checked={x.CurrentStatus}
																	></input>
																</td>

																<td className='padding-td'>
																	<Trans i18nKey={x.Code + '_DESC'}>
																		{x.Code + '_DESC'}
																	</Trans>{' '}
																</td>
																<td className='padding-td'>
																	{x.TimeOfDay
																		? getFormattedDateTime(
																				x.TimeOfDay,
																				selectedLanguage.split('-')[0]
																		  )
																		: ''}
																</td>
																<td className='padding-td'>
																	<i>
																		<Trans i18nKey={x.Code}>{x.Code}</Trans>{' '}
																	</i>
																</td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn opportunity-next-step-btn'
												onClick={this.onSaveStatus}
											>
												<Trans i18nKey='ASSIGN_STATUS_TEXT'>ASSIGN_STATUS_TEXT</Trans>
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.isQuotationNotesPopUpOpen}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='ADD_EDIT_EXTERNAL_NOTES_HEADER'>
												ADD_EDIT_EXTERNAL_NOTES_HEADER
											</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-econf'
											onClick={this.onCloseQuotationNotes}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='row'>
												<div className='col-6'>
													<textarea
														id={'quotationNotes'}
														className='padding5px notes-text-area'
														value={this.state.quotationNotes}
														onChange={(e) =>
															this.setState({
																quotationNotes: e.target.value
															})
														}
														disabled={viewModeOnly}
													></textarea>
												</div>
												<div className='col-6'>
													<div className='row mb-2 notes-language'>
														<div className='notes-language-title col-4'>
															<Trans i18nKey='LANGUAGE'>LANGUAGE</Trans>
														</div>
														<Dropdown
															isOpen={this.state.languageDDIsOpen}
															toggle={this.toggleLanguageDD}
															className='display-inline-export notes-language-dropdown col-8'
															id={'openLanguage'}
														>
															<DropdownToggle caret>
																{this.state.SelectedNotesLanguage}
															</DropdownToggle>
															<DropdownMenu>
																{this.state.Language
																	? this.state.Language.map((x, i) => {
																			return (
																				<DropdownItem
																					id={'language' + i}
																					onClick={() => this.setLanguage(x)}
																					key={i}
																				>
																					{x.Name}
																				</DropdownItem>
																			);
																	  })
																	: ''}
															</DropdownMenu>
														</Dropdown>
													</div>
													<StandardNotes
														standardNotes={this.state.standardNotes}
														addStandardNotes={(x) => {
															let notes = this.state.quotationNotes;
															notes = notes ?? '';
															notes = notes + '\n' + x;
															this.setState({ quotationNotes: notes });
														}}
													/>
												</div>
											</div>
										</div>
									</ModalBody>
									{!viewModeOnly && (
										<ModalFooter>
											<div className='modal-footer-button'>
												<button
													className='btn opportunity-next-step-btn'
													onClick={() => this.onSaveQuotationNotes(false)}
												>
													<Trans i18nKey='SAVE_NOTES'>SAVE_NOTES</Trans>
												</button>
											</div>
										</ModalFooter>
									)}
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.isQuotationInternalNotesPopUpOpen}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='ADD_EDIT_INTERNAL_NOTES_HEADER'>
												ADD_EDIT_INTERNAL_NOTES_HEADER
											</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-econf'
											onClick={this.onCloseQuotationInternalNotes}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='row'>
												<div className='col-12'>
													<textarea
														id={'quotationInternalNotes'}
														className='padding5px notes-text-area'
														value={this.state.quotationInternalNotes}
														onChange={(e) =>
															this.setState({
																quotationInternalNotes: e.target.value
															})
														}
														disabled={viewModeOnly}
													></textarea>
												</div>
											</div>
										</div>
									</ModalBody>
									{!viewModeOnly && (
										<ModalFooter>
											<div className='modal-footer-button'>
												<button
													className='btn opportunity-next-step-btn'
													onClick={() => this.onSaveQuotationNotes(true)}
												>
													<Trans i18nKey='SAVE_NOTES'>SAVE_NOTES</Trans>
												</button>
											</div>
										</ModalFooter>
									)}
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.isQuotationLineNotesPopUpOpen}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='ADD_EDIT_NOTES'>ADD_EDIT_NOTES</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-econf'
											onClick={this.onCloseQuotationLineNotes}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='row'>
												<div className='col'>
													<textarea
														id={'quotationLineNotes'}
														className='padding5px notes-text-area'
														value={this.state.quotationLineNotes}
														onChange={(e) =>
															this.setState({
																quotationLineNotes: e.target.value
															})
														}
														disabled={viewModeOnly}
													></textarea>
												</div>
												{!viewModeOnly && (
													<div className='col'>
														<div className='row mb-2 notes-language'>
															<div className='notes-language-title col-4'>
																<Trans i18nKey='LANGUAGE'>LANGUAGE</Trans>
															</div>
															<Dropdown
																isOpen={this.state.languageDDIsOpen}
																toggle={this.toggleLanguageDD}
																className='display-inline-export notes-language-dropdown col-8'
																id={'openLanguage'}
															>
																<DropdownToggle caret>
																	{this.state.SelectedNotesLanguage}
																</DropdownToggle>
																<DropdownMenu>
																	{this.state.Language
																		? this.state.Language.map((x, i) => {
																				return (
																					<DropdownItem
																						id={'language' + i}
																						onClick={() =>
																							this.setLanguage(x)
																						}
																						key={i}
																					>
																						{x.Name}
																					</DropdownItem>
																				);
																		  })
																		: ''}
																</DropdownMenu>
															</Dropdown>
														</div>
														<StandardNotes
															standardNotes={this.state.standardNotes}
															addStandardNotes={(x) => {
																let notes = this.state.quotationLineNotes;
																notes = notes ?? '';
																notes = notes + '\n' + x;
																this.setState({ quotationLineNotes: notes });
															}}
														/>
													</div>
												)}
											</div>
										</div>
									</ModalBody>
									{!viewModeOnly && (
										<ModalFooter>
											<div className='modal-footer-button'>
												<button
													className='btn opportunity-next-step-btn'
													onClick={this.onSaveQuotationLineNotes}
												>
													<Trans i18nKey='SAVE_NOTES'>SAVE_NOTES</Trans>
												</button>
											</div>
										</ModalFooter>
									)}
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.isShareQuotationPopUpOpen}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='SHARE_QUOTATION'>SHARE_QUOTATION</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-econf'
											onClick={this.onCloseShareQuotation}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='share-quotation'>
												<div className='share-quotaton-body'>
													<div className='link col-10'>
														<a href={this.state.shareQuotationURL} target='_blank'>
															{this.state.shareQuotationURL}{' '}
														</a>
													</div>
													<div
														className='copy-url-icon'
														onClick={() => {
															navigator.clipboard.writeText(this.state.shareQuotationURL);
														}}
														title={i18n.t('COPY_ITEM')}
													></div>
												</div>
											</div>
										</div>
									</ModalBody>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showUserTraceDiscountPopUp}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='DISCOUNT_TRACE_HEADER'>DISCOUNT_TRACE_HEADER</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-discount-trace'
											onClick={this.onCloseDiscountTrace}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='discount-trace-body'>
												<Table striped>
													<thead className={'table-header-content'}>
														<tr className='row'>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='EVENT_TIME'>EVENT_TIME</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='OLD_VALUE'>OLD_VALUE</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='NEW_VALUE'>NEW_VALUE</Trans>
																</div>
															</th>
															<th className='col-3'>
																<div>
																	<Trans i18nKey='USER_NAME'>USER_NAME</Trans>
																</div>
															</th>
															<th className='col-3'>
																<div>
																	<Trans i18nKey='DISCOUNT_COMMENTS'>
																		DISCOUNT_COMMENTS
																	</Trans>
																</div>
															</th>
														</tr>
													</thead>
													<div className='row discount-trace-data'>
														<tbody className='table-content col-12'>
															{this.state.userTraceDiscountData.map((x, i) => {
																return (
																	<tr className='row' key={i}>
																		<td className='col-2'>
																			{getFormattedDateTime(
																				x.EventTime,
																				selectedLanguage.split('-')[0]
																			)}
																		</td>

																		<td className='col-2'>
																			<FormattedNumber
																				value={parseFloat(x.OldValue).toFixed(
																					2
																				)}
																				style='decimal'
																			/>
																		</td>

																		<td className='col-2'>
																			<FormattedNumber
																				value={parseFloat(x.NewValue).toFixed(
																					2
																				)}
																				style='decimal'
																			/>
																		</td>
																		<td className='col-3'>
																			<i>{x.UserName} </i>
																		</td>
																		<td className='col-3'>
																			<i>{x.Comments} </i>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</div>
												</Table>
											</div>
										</div>
									</ModalBody>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showDiscountHeaderPopUp}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='DISCOUNT_HEADER'>DISCOUNT_HEADER</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-header-discount'
											onClick={this.onCloseHeaderDiscount}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='discount-trace-body'>
												<Table striped>
													<thead className={'table-header-content'}>
														<tr className='row'>
															<th className='col-4'>
																<div>
																	<Trans i18nKey='SEGMENT_ID'>SEGMENT_ID</Trans>
																</div>
															</th>
															<th className='col-6'>
																<div>
																	<Trans i18nKey='REMARKS'>REMARKS</Trans>
																</div>
															</th>															
															<th className='col-2'>
																<div>
																	<Trans i18nKey='DISCOUNT'>DISCOUNT</Trans>
																</div>
															</th>
														</tr>
													</thead>
													<div className='row'>
														<tbody className='table-content col-12'>
															{this.state.tempHeaderDiscount.map((x, i) => {
																return (
																	<tr className='row' key={i}>
																		<td className='col-4'>{x.SegmentId}</td>
																		<td className='col-6'>{x.Remarks}</td>
																		<td className='col-2'>
																			<input
																				id={'discountHeader' + i}
																				className={
																					'form-control' +
																					(x.isHeaderDiscountInvalid
																						? ' invalid-style'
																						: '')
																				}
																				value={x.Discount}
																				onChange={(e) => {
																					this.headerDiscountChange(
																						e.target.value,
																						i
																					);
																				}}
																			/>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</div>
												</Table>
											</div>
											{this.state.isHeaderDiscountInvalid && (
												<div className='row'>
													<span className='red-color-error col-12'>
														<Trans i18nKey='HEADER_DISCOUNT_INVALID'>
															HEADER_DISCOUNT_INVALID
														</Trans>
													</span>
												</div>
											)}
										</div>
									</ModalBody>
									{hasEditAccess(userRoleDetail, 'Discount') && (
										<ModalFooter>
											<div className='modal-footer-button'>
												<textarea
													id={'headerDiscountComments'}
													className='padding5px header-comments-text-area'
													onChange={(e) =>
														this.setState({
															headerDiscountComments: e.target.value
														})
													}
													disabled={viewModeOnly}
												></textarea>
											</div>

											<div className='modal-footer-button'>
												<button
													className={
														this.state.headerDiscountComments.length > 0
															? 'btn opportunity-next-step-btn'
															: 'btn btn-disabled ml-2'
													}
													onClick={() => this.headerDiscountSave(true)}
													id='saveAccountDiscounts'
													disabled={this.state.headerDiscountComments.length <= 0}
												>
													<Trans i18nKey='SAVE_AND_APPLY'>SAVE_AND_APPLY</Trans>
												</button>																							
												<button
												className='btn opportunity-next-step-btn ml-2'
												id='refreshAccountDiscounts'
												onClick={() =>  this.setState({showAccountRefreshWarning:true}) }
												>
												<Trans i18nKey='REFRESH_DISCOUNTS'>
														REFRESH_DISCOUNTS
													</Trans>		
												</button>
											</div>
										</ModalFooter>
									)}
								</Modal>
							</div>

								<div>
								<Modal
									isOpen={this.state.showAllUserTracesPopUp}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='DISCOUNT_TRACE_HEADER'>DISCOUNT_TRACE_HEADER</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-discount-trace'
											onClick={this.onCloseAllUserTracesData}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='discount-trace-body'>
											
											
												<Table striped>
													<thead className={'table-header-content'}>
													<tr className='row'>
													<input
															id={'allHistory'}
															name='allHistory'
															type='radio'
															className='width-left mt-1'
															onChange={()=>this.onShowAllHistoryChanged(2)}
															checked={this.state.showAllHistory}
														></input>
														<span className='add-quotation-line-content'>
															<Trans i18nKey='SHOW_ALL_HISTORY'>SHOW_ALL_HISTORY</Trans>
														</span>
														<input
															id={'onlyDiscountHistory'}
															name='allHistory'
															type='radio'
															className='width-left mt-1'
															onChange={()=>this.onShowAllHistoryChanged(1)}
															checked={!this.state.showAllHistory}
														></input>
														<span className='add-quotation-line-content'>
															<Trans i18nKey='SHOW_DISCOUNT_HISTORY'>SHOW_DISCOUNT_HISTORY</Trans>
														</span></tr>
														<tr className='row'>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='EVENT_TIME'>EVENT_TIME</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='OLD_VALUE'>OLD_VALUE</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='NEW_VALUE'>NEW_VALUE</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='USER_NAME'>USER_NAME</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='DISCOUNT_LEVEL'>DISCOUNT_LEVEL</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='DISCOUNT_COMMENTS'>
																		DISCOUNT_COMMENTS
																	</Trans>
																</div>
															</th>
														</tr>
														
													</thead>
													<div className='row discount-trace-data'>
														<tbody className='table-content col-12'>
															{this.state.userTracesFilteredQuotationData.map((x, i) => {
																return (
																	<tr className='row' key={i}>
																		<td className='col-2'>
																			{getFormattedDateTime(
																				x.EventTime,
																				selectedLanguage.split('-')[0]
																			)}
																		</td>
																		<td className='col-2'>
																			{this.formatDiscountValue(x.OldValue)}																			
																		</td>
																		<td className='col-2' id={'newDiscountValue'+i}>
																			{this.formatDiscountValue(x.NewValue)}
																		</td>
																		<td className='col-2'>
																			<i>{x.UserName} </i>
																		</td>
																		<td className='col-2'>
																			<i>{x.LevelText} </i>
																		</td>
																		<td className='col-2'>
																			<i>{x.Comments} </i>
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</div>													
												</Table>
											</div>
										</div>
									</ModalBody>
								</Modal>
							</div>

							<div>
								<Modal
									isOpen={this.state.showApprovalNeededUserTracesPopup}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='USER_TRACES_APPROVAL'>USER_TRACES_APPROVAL</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-discount-trace'
											onClick={this.onCloseApprovalUserTracesData}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='discount-trace-body'>
												<Table striped>
													<thead className={'table-header-content'}>
														<tr className='row'>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='EVENT_TIME'>EVENT_TIME</Trans>
																</div>
															</th>
															<th className='col-1'>
																<div>
																	<Trans i18nKey='OLD_VALUE'>OLD_VALUE</Trans>
																</div>
															</th>
															<th className='col-1'>
																<div>
																	<Trans i18nKey='NEW_VALUE'>NEW_VALUE</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='USER_NAME'>USER_NAME</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='DISCOUNT_LEVEL'>DISCOUNT_LEVEL</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='DISCOUNT_COMMENTS'>
																		DISCOUNT_COMMENTS
																	</Trans>
																</div>
															</th>
														</tr>
													</thead>
													<div className='row discount-trace-data'>
														<tbody className='table-content col-12'>
															{this.state.userTracesApprovalNeededData.map((x, i) => {
																return (
																	<tr className='row' key={i}>
																		<td className='col-2'>
																			{getFormattedDateTime(
																				x.EventTime,
																				selectedLanguage.split('-')[0]
																			)}
																		</td>

																		<td className='col-1'>
																			<FormattedNumber
																				value={parseFloat(x.OldValue).toFixed(
																					2
																				)}
																				style='decimal'
																			/>
																		</td>
																		<td className='col-1'>																	
																		<input
																			type="text"
																			className='form-control textbox-font'
																			value={x.NewValue}
																			onChange={(e) =>
																				this.onDiscountApprovalNewValueChange(e.target.value,i)	}
																		/>		
																		<div className='col-1'>
																			<span className='red-color-error'>
																			{this.state['DISCOUNT_SEGMENT_VALUE_ERROR_' + i ]}
																			</span>
													                     </div>																																			
																		</td>
																		<td className='col-2'>
																			<i>{x.UserName} </i>
																		</td>
																		<td className='col-2'>
																			<i>
																			{x.LevelText}
																				 </i>
																		</td>
																		<td className='col-2'>
																			<i>
																			{x.Comments}
																				 </i>
																		</td>
																		<td className='col-2'>
																				<textarea
																					id={'approvalCommentText'}
																					className='padding5px discount-approval-text-area'
																					value={x.ApprovalComments}
																					onChange={(e) =>
																						this.onDiscountApprovalCommentChange(e.target.value,i)
																					}
																					disabled={viewModeOnly}
																				></textarea>
																					<span class ="color-green">{x.IsApproved ? i18n.t('DISCOUNT_APPROVED') : ''}</span>
																					<span class="color-red">{ x.IsApproved == false ? i18n.t('DISCOUNT_REJECTED') : ''}</span>
																				<div
																				id='approveDiscountRequest'
																				className='ok-icon-discount'																																																																								
																				onClick={() => {this.setDiscountApprovalState(1,i)}}
																				>

																				</div>

																				<div
																				id='approveDiscountRequest'
																				className='cancel-icon-discount'
																				onClick={() => {this.setDiscountApprovalState(0,i)}}>
																						
																				</div>
																				
																		</td>
																	</tr>
																);
															})}
														</tbody>
													</div>
												</Table>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
									<div className='modal-footer-button'>
														<button id='saveApprovalDiscountData'
																onClick={() => {this.onDiscountApprovalOk()}}	
																className = 
																{
																 	this.enableDiscountApprovalSaveButton()
																 	? 'btn opportunity-next-step-btn'
																 	: 'btn btn-disabled ml-2'
																}>
															<Trans i18nKey='OK'>OK</Trans>
														</button>
														{'   '}
														<button id='cancelApprovalDiscountData'
																onClick={() => {this.onCloseApprovalUserTracesData()}}																													
																className='btn opportunity-next-step-btn'>
															<Trans i18nKey='CANCEL'>CANCEL</Trans>
														</button>
													</div>
									</ModalFooter>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showReportingOverview}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='REPORTING_OVERVIEW_HEADER'>REPORTING_OVERVIEW_HEADER</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-header-discount'
											onClick={this.onCloseReportingOverview}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='discount-trace-body'>
												<Table striped>
													<thead className={'table-header-content'}>
														<tr className='row'>
															<th className='col-6'>
																<div>
																	<Trans i18nKey='REPORT_DESCRIPTION'>
																		REPORT_DESCRIPTION
																	</Trans>
																</div>
															</th>
															<th className='col-2'>
																<div>
																	<Trans i18nKey='REPORT_QUANTITY'>
																		REPORT_QUANTITY
																	</Trans>
																</div>
															</th>
															<th className='col-4'>
																<div>
																	<Trans i18nKey='REPORT_PRICE'>REPORT_PRICE</Trans>
																</div>
															</th>
														</tr>
													</thead>
													<div className='row'>
														<tbody className='table-content col-12'>
															{this.state.reportingOverview.map((x, i) => {
																return (
																	<tr className='row' key={i}>
																		<td className='col-6'>
																			<Trans i18nKey={x.Description}>
																				{x.Description}
																			</Trans>{' '}
																		</td>
																		<td className='col-2'>{x.Quantity}</td>
																		<td className='col-4'>€ {x.Price}</td>
																	</tr>
																);
															})}
														</tbody>
													</div>
												</Table>
											</div>
										</div>
									</ModalBody>
									<ModalFooter>
										<div className='modal-footer-button'>
											<div className='row'>
													<div className='col-9'>
													<button
															id='revenueSendToCRM'
															className={
																this.state.reportingOverview.length > 0
																	? 'btn opportunity-next-step-btn'
																	: 'btn btn-disabled ml-2'
															}
															onClick={() => this.sendRevenueToCRM()}
															disabled={this.state.reportingOverview.length <= 0}
														>
															<Trans i18nKey='REVENUE_TO_CRM'>REVENUE_TO_CRM</Trans>
														</button>
													</div>
															<div className='col-3'>
																<div>
																	<Trans i18nKey='REPORT_TOTAL'>REPORT_TOTAL</Trans>
																
																	<b id='revenueTotal'> € {this.getReportingOverviewTotal()}</b>
																</div>
													</div>
											</div>
										</div>
										
									</ModalFooter>
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showManualDiscountPopUp}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='MANUAL_DISCOUNT_INFO_HEADER'>
												MANUAL_DISCOUNT_INFO_HEADER
											</Trans>{' '}
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='discount-trace-body'>
												<div className='row mb-2'>
													<Trans i18nKey='MANUAL_DISCOUNT_MESSAGE_DISCRIPTION'>
														MANUAL_DISCOUNT_MESSAGE_DISCRIPTION
													</Trans>
												</div>
												<div className='row mb-2'>
													<div className='line-title'>
														<Trans i18nKey='LINE_TITLE'>LINE_TITLE</Trans>
														{' : '}
													</div>
													<div>
														{this.state.manualDiscountData.length > 0 &&
															this.state.manualDiscountData[0].Title}
													</div>
												</div>
												<table className='table table-striped'>
													<thead>
														<tr className='row'>
															<th className='col-6'>
																<Trans i18nKey='ITEM_NAME'>ITEM_NAME</Trans>
															</th>
															<th className='col-3'>
																<Trans i18nKey='EXISTING_DISCOUNT'>
																	EXISTING_DISCOUNT
																</Trans>
															</th>
															<th className='col-3'>
																<Trans i18nKey='DEFAULT_DISCOUNT'>
																	DEFAULT_DISCOUNT
																</Trans>
															</th>
														</tr>
													</thead>
													{this.state.manualDiscountData.map((discountItem, index) => {
														return (
															<>
																<tr className='row'>
																	<td className='col-6'>{discountItem.Name}</td>
																	<td className='col-3'>
																		{discountItem.ExistingDiscount}
																	</td>
																	<td className='col-3'>
																		{discountItem.CalculatedDiscount}
																	</td>
																</tr>
															</>
														);
													})}
												</table>
											</div>
										</div>
									</ModalBody>
									{!viewModeOnly && (
										<ModalFooter>
											{this.state.showGeneralDiscountComments && (
												<div>
													<div>
														<textarea
															id={'generalDiscountComments'}
															className='padding5px discount-comments-text-area'
															onChange={(e) =>
																this.setState({
																	generalDiscountComments: e.target.value
																})
															}
															disabled={viewModeOnly}
														></textarea>
													</div>
													<div className='modal-footer-button'>
														<button
															id='generalDiscountCommentOk'
															className={
																this.state.generalDiscountComments.length > 0
																	? 'btn opportunity-next-step-btn'
																	: 'btn btn-disabled ml-2'
															}
															onClick={() => this.manualDiscountUserChoice(false)}
															disabled={this.state.generalDiscountComments.length <= 0}
														>
															<Trans i18nKey='OK'>OK</Trans>
														</button>
														<button
															id='generalDiscountCommentCancel'
															className='btn opportunity-next-step-btn'
															onClick={() =>
																this.setState({ showGeneralDiscountComments: false })
															}
														>
															<Trans i18nKey='CANCEL'>CANCEL</Trans>
														</button>
													</div>
												</div>
											)}
											{!this.state.showGeneralDiscountComments && (
												<div className='modal-footer-button'>
													<button
														className='btn opportunity-next-step-btn'
														onClick={() => this.manualDiscountUserChoice(true)}
													>
														<Trans i18nKey='KEEP_MANUAL_DISCOUNT'>
															KEEP_MANUAL_DISCOUNT
														</Trans>
													</button>
													<button
														className='btn opportunity-next-step-btn ml-2'
														onClick={() =>
															this.setState({ showGeneralDiscountComments: true })
														}
													>
														<Trans i18nKey='OVERRIDE_MANUAL_DISCOUNT'>
															OVERRIDE_MANUAL_DISCOUNT
														</Trans>
													</button>
												</div>
											)}
										</ModalFooter>
									)}
								</Modal>
							</div>
							<div>
								<Modal
									isOpen={this.state.showAttributesPopUp}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											{this.state.attributesPopUpData.BaseQuotationLineId == null ? (
												<Trans i18nKey='ATTRIBUTES_AND_DOORWAY_INFO'>
													ATTRIBUTES_AND_DOORWAY_INFO
												</Trans>
											) : (
												<Trans i18nKey='ATTRIBUTES_INFO'>ATTRIBUTES_INFO</Trans>
											)}
										</div>
										<div
											className='modal-close'
											id='modal-close-attribute-info'
											onClick={this.closeAttributesAndDoorwayInfo}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='attributes-body'>
												{this.state.attributesPopUpData.BaseQuotationLineId == null && (
													<div className='modal-title'>
														<Trans i18nKey='ATTRIBUTES_INFO'>ATTRIBUTES_INFO</Trans>
													</div>
												)}
												<table className='table table-striped'>
													<thead>
														<th>
															<Trans i18nKey='ATTRIBUTE_NAME'>ATTRIBUTE_NAME</Trans>
														</th>
														<th>
															<Trans i18nKey='ATTRIBUTE_VALUE'>ATTRIBUTE_VALUE</Trans>
														</th>
													</thead>
													<tbody>
														{this.state.attributesPopUpData.Attributes &&
															this.innerDetailsAttributes(
																this.state.attributesPopUpData.Attributes
															)}
													</tbody>
												</table>
												{this.state.attributesPopUpData.BaseQuotationLineId == null &&
													this.state.attributesPopUpData.DoorWays &&
													this.state.attributesPopUpData.DoorWays.length > 0 && (
														<>
															<div className='modal-title'>
																<Trans i18nKey='DOORWAY_INFO'>DOORWAY_INFO</Trans>
															</div>
															<table className='table table-striped'>
																<thead>
																	<tr>
																		<th className='col-2'>
																			<Trans i18nKey='BUILDING'>BUILDING</Trans>
																		</th>
																		<th className='col-2'>
																			<Trans i18nKey='FLOOR'>FLOOR</Trans>
																		</th>
																		<th className=' col-2'>
																			<Trans i18nKey='APARTMENT'>APARTMENT</Trans>
																		</th>
																		<th className='col-6'>
																			<Trans i18nKey='DOOR_HOLE_NUMBER'>
																				DOOR_HOLE_NUMBER
																			</Trans>
																		</th>
																	</tr>
																</thead>
																<tbody>
																	{this.buildingDetails(
																		this.state.attributesPopUpData.DoorWays
																	)}
																</tbody>
															</table>
														</>
													)}
											</div>
										</div>
									</ModalBody>

									<ModalFooter>
										<div className='modal-footer-button'>
											<button
												className='btn opportunity-next-step-btn ml-2'
												onClick={() => this.closeAttributesAndDoorwayInfo()}
											>
												<Trans i18nKey='OK'>OK</Trans>
											</button>
										</div>
									</ModalFooter>
								</Modal>
							</div>



							<div>
								<Modal
									isOpen={this.state.showPdfJsonModal}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>
											<Trans i18nKey='PDF_JSON_HEADER'>
											PDF_JSON_HEADER
											</Trans>{' '}
										</div>
										<div
											className='modal-close'
											id='modal-close-pdfData'
											onClick={this.onClosePdfJsonModal}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>
										<div className='quotation-status-modal-body'>
											<div className='row'>
												<div className='col-12'>
													<textarea
														id={'pdfJsonData'}
														className='padding5px notes-text-area'
														value={this.state.pdfJsonData}												
														
													></textarea>
												</div>
											</div>
										</div>
									</ModalBody>									
								</Modal>
							</div>

							{/* Rate change Modal*/}

							<div>
								<Modal
									isOpen={this.state.showRatechangePopUp}
									size={'lg'}
									className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
								>
									<ModalHeader>
										<div className='modal-title-text'>{i18n.t('APPLY_RATE_CHANGE_HEADER')}</div>
										<div
											className='modal-close'
											id='modal-close-rateChange'
											onClick={this.onCloseRateChangeModal}
										>
											x
										</div>
									</ModalHeader>
									<ModalBody>

										<div className='m-2 mr-5'>

											<div className='row'>

												<div className='col-3'> {i18n.t('NEW_RATE')}</div>
												<input
													id={'rateValue'}
													min='0'
													max='1000'
													type='number'
													step='0.01'
													value={this.state.quotationRate}
													className='col-9 form-control font-weight-doors'
													onChange={(e) => this.onQuotationRateChanged(e.target.value)}
												/>

											</div>
								
											<br />

											<div className='row'>

												<div className='col-4'>
													<input
														id={'none'}
														name='articleType'
														type='radio'
														className='width-left mt-1'
														checked={this.state.quotationRateType.toLowerCase() === 'none'}
														onChange={() => this.onQuotationRateTypeChanged('None')}
													></input>
													<span className='add-quotation-line-content'>{i18n.t('NONE')}</span>
												</div>

												<div className='col-4'>
													<input
														id={'increase'}
														name='articleType'
														type='radio'
														className='width-left mt-1'
														checked={this.state.quotationRateType.toLowerCase() === 'percentageincrease'}
														onChange={() => this.onQuotationRateTypeChanged('PercentageIncrease')}
													></input>
													<span className='add-quotation-line-content'>{i18n.t('INCREASE_RATE')}</span>
												</div>

												<div className='col-4'>
													<input
														id={'decrease'}
														name='articleType'
														className='width-left-second mt-1'
														type='radio'
														checked={this.state.quotationRateType.toLowerCase() === 'percentagedecrease'}
														onChange={() => this.onQuotationRateTypeChanged('PercentageDecrease')}
													></input>
													<span className='add-quotation-line-content'>{i18n.t('DECREASE_RATE')}</span>
												</div>
												
											</div>					
										</div>
									</ModalBody>

									<ModalFooter>
										<div className='row'>

											<div className='col modal-footer-button'>
												<button
													className='btn opportunity-next-step-btn'
													onClick={() => this.onCloseRateChangeModal()}
												>{i18n.t('CANCEL')}</button>
											</div>

											<div className='col-auto modal-footer-button'>
												<button
													className='btn opportunity-next-step-btn'
													onClick={() => this.onApplyRateChange()}
												>{i18n.t('OK')}</button>
											</div>

										</div>
									</ModalFooter>

								</Modal>
							</div>

							<div>
								<ConfirmationDialog
									isModelOpen={this.state.showCopyQuotationPopup}
									modalSize=''
									headerText={i18n.t('COPY_CONFIRMATION_HEADER')}
									confirmationText={i18n.t('COPY_CONFIRMATION_MESSAGE')}
									okText={i18n.t('OK')}
									cancelText={i18n.t('CANCEL')}
									onOk={this.copyQuotationLineConfirmation}
									onCancel={this.copyQuotationLineCancel}
								/>
							</div>
							<div>
								<ConfirmationDialog
									isModelOpen={this.state.showEditQuotationPopup}
									modalSize=''
									headerText={i18n.t('WARNING_HEADER')}
									confirmationText={i18n.t('CONTINUE_TO_ECON_CONFIRMATION')}
									okText={i18n.t('OK')}
									cancelText={i18n.t('CANCEL')}
									onOk={this.editQuotationLineConfirmation}
									onCancel={this.editQuotationLineCancel}
								/>
							</div>
							<div>
								<ConfirmationDialog
									isModelOpen={this.state.showSwapAlternativePopUp}
									modalSize=''
									headerText={i18n.t('SWAP_ALTERNATIVE_HEADER')}
									confirmationText={i18n.t('SWAP_ALTERNATIVE_MESSAGE')}
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}
									onOk={this.swapAlternativeConfirm}
									onCancel={this.swapAlternativeCancel}
								/>
							</div>
							<div>
								<ConfirmationDialog
									isModelOpen={
										this.state.showDeleteQuotationPopUp || this.state.showDeleteAlternativePopUp
									}
									modalSize=''
									headerText={
										this.state.showDeleteAlternativePopUp
											? i18n.t('DELETE_ALTERNATIVE_HEADER')
											: i18n.t('DELETE_QUOTATION_HEADER')
									}
									confirmationText={
										this.state.showDeleteAlternativePopUp
											? i18n.t('DELETE_ALTERNATIVE_MESSAGE')
											: i18n.t('DELETE_QUOTATION_MESSAGE')
									}
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}
									onOk={this.deleteQuotationLineConfirm}
									onCancel={this.deleteQuotationLineCancel}
								/>
								<ConfirmationDialog
									isModelOpen={this.state.showDeleteConfirmationPopUp}
									modalSize=''
									headerText={i18n.t('DELETE_QUOTATION_HEADER')}
									confirmationText={i18n.t('DELETE_QUOTATON_MESSAGE')}
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}
									onOk={this.deleteQuotationConfirm}
									onCancel={() => {
										this.setState({ showDeleteConfirmationPopUp: false });
									}}
								/>



								<ConfirmationDialog
									isModelOpen={this.state.showHeaderDiscountApprovalFlow}
									modalSize=''
									headerText={i18n.t('QUOTATION_HEADER_APPROVAL')}
									confirmationText={  i18n.t('QUOTATION_HEADER_APPROVAL_CONFIRMATION') + 
									'\n' +
									this.getHeaderDiscountSegmentsList()
									
								}
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}
									onOk={this.confirmHeaderDiscountUpdate}
									onCancel={() => {
										this.setState({ showHeaderDiscountApprovalFlow: false, headerDiscountNeedApprovalSegments:[],headerDiscountComments:'' });
										this.onHeaderDiscountClick();
									}}
								/>

								<ConfirmationDialog
									isModelOpen={this.state.showAccountRefreshWarning}
									modalSize=''
									headerText={i18n.t('REFRESH_ACCOUNT_HEADER')}
									confirmationText={  i18n.t('REFRESH_ACCOUNT_CONFIRMATION')}
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}
									onOk={this.refreshAccountDiscounts}
									onCancel={()=>{ this.setState({showAccountRefreshWarning:false})}}
								/>	


								<ConfirmationDialog
									isModelOpen={this.state.showQLDiscountApprovalFlow}
									modalSize=''
									headerText={i18n.t('QUOTATION_DETAIL_APPROVAL')}
									confirmationText={  i18n.t('QUOTATION_DETAIL_APPROVAL_CONFIRMATION') }
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}
									onOk={this.sendQuotationLineDetailDiscountForApproval}
									onCancel={() => {
										this.getQuotationLineDetailsConf(0);
										this.setState({ showQLDiscountApprovalFlow: false,
											quotationLineDetailIdForEmail:0 , 
											quotationLineIdForEmail:0,
											quotationLineDetailNewDiscount :0,
											quotationLineSequenceNumber:'',
											quotationLineDetailOptionDescription:'' });
									
									}}
								/>

								<ConfirmationDialog
									isModelOpen={this.state.showLockedQuotationConfirmation}
									modalSize=''
									headerText={i18n.t('QUOTATION_LOCK_HEADER')}
									confirmationText={i18n.t('QUOTATION_LOCK_CONFIRMATION') }									
									okText={i18n.t('YES')}
									cancelText={i18n.t('NO')}									
									onOk={this.lockQuotation}
									onCancel={() => {
									
										this.setState({ showLockedQuotationConfirmation: false,
											 });
									
									}}
								/>

							</div>
						</div>
					</div>
					{ this.state.loading && (
						<div className='fixed-bottom'>
							<Loader type='Bars' color='#ef2312' height={100} width={100} />
						</div>
					)}
					{this.state.showExportPage && (
						<Redirect
							to={{
								pathname: '/quotation/export'
							}}
						/>
					)}
					<div>
						<ToastContainer
							position='top-center'
							autoClose={5000}
							className='toast'
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</div>
					{!viewModeOnly && (
						<>
							{hasEditAccess(userRoleDetail, 'DiscountApproval') && 
							<div
							className='user-traces-approval-icon'
							id='userTracesApprovalIcon'
							title={i18n.t('USER_TRACES_APPROVAL')}
							onClick={this.getApprovalNeededUserTraces}
						></div>}	

							<div
								className='add-quotation-line-icon'
								id='addQuotaitonLineIcon'
								title={i18n.t('ADD_QUOTATION_CONFIGURATOR')}
								onClick={this.openAddQuotationModal}
							></div>
							<div
								id='addIndividualArticleIcon'
								className='add-individual-artical-icon'
								title={i18n.t('ADD_INDIVIDUAL_ARTICLE')}
								onClick={this.openIndividualArticleModal}
							></div>
							<div
								id='shareIcon'
								className='share-icon'
								title={i18n.t('SHARE_QUOTATION')}
								onClick={this.shareQuotaiton}
							></div>												
							{this.state.quotationLines.length !== 0 && (
								<>
									<div
										id='exportIcon'
										className='export-icon'
										title={i18n.t('EXPORT')}
										onClick={this.openExportModal}
									></div>
									{!this.state.isSimpleSearchApplied && (
										<div
											id='searchIcon'
											className={
												this.state.isSearchApplied ? 'search-icon-active' : 'search-icon'
											}
											title={i18n.t('QUOTATION_LINE_SEARCH')}
											onClick={this.openSearchPanelModal}
										></div>
									)}									
								</>
							)}
							{hasEditAccess(userRoleDetail, 'XmlDownload') && 
							 <div
									id='downloadIcon'
									className={this.state.quotationLines.length === 0 ?'download-without-line-icon' :'download-icon'}
									title={i18n.t('DOWNLOAD_XML')}
									onClick={this.downloadXMLModal}
							 ></div> }

							{hasEditAccess(userRoleDetail, 'PDFJson') && 
							 <div
									id='pdfJsonIcon'
									className='pdfjson-icon'
									title={i18n.t('PDF_JSON')}
									onClick={this.openPDFJsonModal}
							 ></div> }
									
						</>
					)}
					{this.state.isQuotationLocked && (<div
								id='lockIcon'
								className={(this.state.isQuotationLocked && this.state.isSalesAdmin)
									? 'lock-icon' : 'lock-icon disable-lock-icon'}							
								title={i18n.t('LOCK_QUOTATION')}
								onClick={this.toggleLockQuotation}
							></div>)}
						
				</IntlProvider>
			</div>
		);
	};
}
ViewQuotation.contextType = QuotationToolContext;
