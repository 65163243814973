// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// Msal Configurations
const config = {
	auth: {
		authority: process.env.REACT_APP_AUTHORITY_URL,
		clientId: process.env.REACT_APP_CLIENT_ID,
		redirectUri: process.env.REACT_APP_REDIRECT_URL
	},
	cache: {
		cacheLocation: 'localStorage',
		storeAuthStateInCookie: true
	}
};

// Authentication Parameters
const authenticationParameters = {
	scopes: [process.env.REACT_APP_SCOPE_ID_URI]
};

// Options
const options = {
	loginType: LoginType.Redirect,
	tokenRefreshUri: window.location.origin + '/oauth2-redirect.html'
};


export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
