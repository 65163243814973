import React from 'react';
import i18n from 'i18next';

class DashBoardColumnHeaderComponent extends React.Component {


    render() {
      return <React.Fragment>

        <div className="container ">

          <div className="row" onClick={() => this.props.sortBy(this.props.columnParameter)}>

            <div className='arrow-buttons'>
              <span
                className={
                  this.props.columnIsSortedFlag === true
                    ? 'arrow-up black-color-up'
                    : 'arrow-up'
                }
              ></span>
              <span
                className={
                  this.props.columnIsSortedFlag === false
                    ? 'arrow-down black-color-down'
                    : 'arrow-down'
                }
              ></span>
            </div>

            <div className={'table-header-content ' + this.props.columnParameter}>
              {i18n.t(this.props.textKey)}
            </div>

          </div>

          <div className="row mt-2 mb-2 align-bottom">

            <input
              type='text'
              id=''
              className='form-control col-12'
              onKeyUp={(e) => this.props.filterBy(this.props.columnParameter, e.target.value)}
              placeholder="*"
            />

          </div>

        </div>

      </React.Fragment>  
    }
}
export default DashBoardColumnHeaderComponent;
