import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './EmailConfiguration.scss';
import '../../assets/scss/Icons.scss';
import { withTranslation, Trans } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { QuotationToolContext } from '../../Providers';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,	
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Table
} from 'reactstrap';
import { saveEmailConfiguration, getEmailConfigurations, getTranslationsByKey, saveTranslations } from '../../Utils/apiCalls'

export default class EmailConfiguration extends Component {


constructor()
{
	super();
	this._handlePreviewClick = this._handlePreviewClick.bind(this);
    this._handleEditClick = this._handleEditClick.bind(this);	
}

	state = {
		tableData: [],
		responseData: [],
		loading: true,
		showEmailConfigurationPopUp: false,
		salesOrganisation:'',
		creator:'',
		mailType:'',
		from:'',
		to:'',
		cc:'',
		bodyKey:'',
		subjectKey:'',
		bcc:'',
		signature:'',
		EmailConfigurationData:[],
		preview:false,
		id:0,
		languageDDIsOpen: false,
		SelectedNotesLanguage: '',	
		emailBodyData:[],	
		mainEmailBodyData:[],
		mailSubjectData:[],	
		optionsData:[],
		currentIndexLanguage : 0,
		updatedBodyText :'',
		updatedBodyTextTemp: '',
		isBodySelected : false,
	};

	setUp = (key) => 
	{ 
		let records = this.state.optionsData;

		let envExample = "Prefix"; 
		let oppoExample = 'Opportunity Number';
		let quoteTitle = i18n.t('EMAIL_EXAMPLE_DEF_QUOTETITLE');
		let userExample = i18n.t('EMAIL_EXAMPLE_DEF_SALESEMPLOYEE');
		let customerExample = i18n.t('EMAIL_EXAMPLE_DEF_CUSTOMER');
		let customerNrExample = 'Account Number';
		let quoteLink = i18n.t('EMAIL_EXAMPLE_DEF_QUOTELINK');
		let discountDescription = i18n.t('EMAIL_EXAMPLE_DEF_DISCOUNTDESCR');

		switch(key)
		{
			// Body
			case 'THEUMA_NV_LARGE_QUOTATION':
			case 'TDS_NV_LARGE_QUOTATION':
			case 'THEUMA_NV_SMALL_QUOTATION':
			case 'MailToCustomer_TheumaNV_TheumaCalculation_BODY':
			case 'MailToCustomer_TheumaNV_TheumaInternalSales_BODY':
			case 'MailToCustomer_TDSNV_TDSCalculation_BODY':
			case 'MailToCustomer_TMIBV_TMICalculation_BODY':
			case 'MailToCustomer_TDSBV_TDSNCalculation_BODY':
				records = [
							{Value: '{0}', Key: customerExample},
							{Value: '{1}', Key: oppoExample},
							{Value: '{2}', Key: quoteTitle},
							{Value: '{3}', Key: "Signature"}
						]	
		    	break;
			
			// Body
		    case 'THEUMA_NV_QUOTATION_HEADER_DISCOUNT':
			case 'MailDiscountChange_TheumaNV_BODY':
				records = [
							{Value: '{0}', Key: oppoExample},
							{Value: '{1}', Key: customerExample},
							{Value: '{2}', Key: customerNrExample},
							{Value: '{3}', Key: discountDescription},
							{Value: '{4}', Key: 'Comments'},						
							{Value: '{5}', Key: quoteLink}
						]
		    	break;
		    
			// Body
		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CREATOR':
			case 'MailToCreator_TDSNV_BODY':
			case 'MailToCreator_TheumaNV_BODY':
				records = [
							{Value: '{0}', Key: userExample},
							{Value: '{1}', Key: oppoExample},
							{Value: '{2}', Key: customerExample},
							{Value: '{3}', Key: customerNrExample},
							{Value: '{4}', Key: discountDescription},
							{Value: '{5}', Key: 'Status'},
							{Value: '{6}', Key: 'Comments'},
							{Value: '{7}', Key: quoteLink }
						]
		    	break;
			
			// Body
		    case 'THEUMA_NV_DISCOUNT_APPROVAL_TO_CREATOR':
			case 'MailDiscountApproval_TheumaNV_BODY':
				records = [
							{Value: '{0}', Key: userExample},
							{Value: '{1}', Key: oppoExample},
							{Value: '{2}', Key: customerExample},
							{Value: '{3}', Key: customerNrExample},
							{Value: '{4}', Key: discountDescription},					
							{Value: '{5}', Key: quoteLink}
						]
		    	break;

			// Subject
		    case 'THEUMA_NV_QUOTATION_HEADER_DISCOUNT_SUBJECT':
		    case 'THEUMA_NV_QUOTATION_LINE_DISCOUNT_SUBJECT':
		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CREATOR_SUBJECT':
		    case 'THEUMA_NV_QUOTATION_LINE_EMAIL_TO_CREATOR_SUBJECT':
			case 'MailDiscountChange_TheumaNV_SUBJECT':
			case 'MailDiscountChangeLineLevel_TheumaNV_SUBJECT':
			case 'MailToCreator_TDSNV_SUBJECT':
			case 'MailToCreator_TheumaNV_SUBJECT':
			case 'MailToCreatorLineLevel_TheumaNV_SUBJECT':
				records = [
							{Value: '{0}', Key: envExample},
							{Value: '{1}', Key: quoteTitle},					
						]
		    	break;
			
			// Subject
		    case 'THEUMA_NV_DISCOUNT_APPROVAL_EMAIL_TO_CREATOR_SUBJECT':
			case 'MailDiscountApproval_TheumaNV_SUBJECT':
				records = [
							{Value: '{0}', Key: envExample},
							{Value: '{1}', Key: userExample},
							{Value: '{2}', Key: quoteTitle},						
						]
		    	break;
		  
			// Body
		    case 'THEUMA_NV_QUOTATION_LINE_EMAIL_TO_CREATOR':
			case 'MailToCreatorLineLevel_TheumaNV_BODY':
				records = [
							{Value: '{0}', Key: userExample},
							{Value: '{1}', Key: oppoExample},
							{Value: '{2}', Key: customerExample},
							{Value: '{3}', Key: customerNrExample},
							{Value: '{4}', Key: 'Line Number'},
							{Value: '{5}', Key: discountDescription},						
							{Value: '{6}', Key: 'Discount Change'},		
							{Value: '{7}', Key: 'Comments'},
							{Value: '{8}', Key: quoteLink}
						]
		    	break;

			// Body
		    case 'THEUMA_NV_QUOTATION_LINE_DISCOUNT_BODY':
			case 'MailDiscountChangeLineLevel_TheumaNV_BODY':
				records = [
							{Value: '{0}', Key: oppoExample},
							{Value: '{1}', Key: customerExample},
							{Value: '{2}', Key: customerNrExample},
							{Value: '{3}', Key: 'Line Number'},
							{Value: '{4}', Key: discountDescription},
							{Value: '{5}', Key: 'Discount Change'},
							{Value: '{6}', Key: 'Comments'},						
							{Value: '{7}', Key: quoteLink}
						]
		    	break;

			// Subject
		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CUSTOMER_PROD_SUBJECT':
				records = [
							{Value: '{0}', Key: oppoExample},
							{Value: '{1}', Key: 'Version Number'},						
						]
		    	break;

		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CUSTOMER_DEV_TEST_SUBJECT':
			case 'MailToCustomer_TDSBV_TDSNCalculation_SUBJECT':
			case 'MailToCustomer_TDSNV_TDSCalculation_SUBJECT':
			case 'MailToCustomer_TheumaNV_TheumaCalculation_SUBJECT':
			case 'MailToCustomer_TheumaNV_TheumaInternalSales_SUBJECT':
			case 'MailToCustomer_TMIBV_TMICalculation_SUBJECT':
				records = [
							{Value: '{0}', Key: envExample},
							{Value: '{1}', Key: oppoExample},
							{Value: '{2}', Key: 'Version Number'},					
						]
		    	break;
		}
		
		this.setState({ optionsData: records });
	}

	onBodySubjectTypeChanged = (type) => {
		this.setState({isBodySelected: type === 2});
		if( type === 2)
				{
					let tempEmailBodyData = this.state.emailBodyData
					this.setState({ mainEmailBodyData : tempEmailBodyData});
					let tempmailSubjectData = this.state.mailSubjectData;
					this.state.emailBodyData = tempmailSubjectData;
					this.state.updatedBodyText=	tempmailSubjectData.find((x) => x.Language === this.state.SelectedNotesLanguage).Value;
					this.state.SelectedNotesLanguage= this.state.SelectedNotesLanguage;
					this.state.currentIndexLanguage =  tempmailSubjectData.findIndex(x => x.Language === this.state.SelectedNotesLanguage);
					this.setUp(tempmailSubjectData[3].Key);
					this.UpdateBodyWithNewLineAndBRTag();					 
				}
				else
				{
					let tempMainEmailBodyData = this.state.mainEmailBodyData
					this.state. emailBodyData = tempMainEmailBodyData;
					this.state.updatedBodyText=	tempMainEmailBodyData.find((x) => x.Language === this.state.SelectedNotesLanguage).Value;			
					this.state.SelectedNotesLanguage= this.state.SelectedNotesLanguage;
					this.state.currentIndexLanguage = tempMainEmailBodyData.findIndex(x => x.Language === this.state.SelectedNotesLanguage);
					 this.setUp(tempMainEmailBodyData[3].Key);
					 this.UpdateBodyWithNewLineAndBRTag();
				}
		
	};
	getLanguagaeTranslation = (key) => {
		this.setState({ loading: true });

		getTranslationsByKey(key)
		.then((response) => {								
					this.setState({
						emailBodyData: response,
						mainEmailBodyData: response,
						updatedBodyText:	response[0].Value,			
						SelectedNotesLanguage: response[0].Language,
						currentIndexLanguage : 0,
						loading: false
					});
					this.setUp(response[3].Key);
					this.UpdateBodyWithNewLineAndBRTag();				
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
				this.setState({ loading: false });
			});
	};

	getLanguagaeTranslationSubject = (key) => {
		this.setState({ loading: true });

		getTranslationsByKey(key)
		.then((responseData) => {								
				this.setState({mailSubjectData : responseData,loading: false});					 									
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
				this.setState({ loading: false });
			});
	};

	addStandardNotes =(x) => {
		let notes = this.state.emailBodyData[this.state.currentIndexLanguage].Value
		notes = notes ?? '';
		if(this.state.isBodySelected)
		{
		  notes = notes  + x;
		}
		else{
			notes = notes + '<br>' + x;
		}		
		this.state.emailBodyData[this.state.currentIndexLanguage].Value = notes ;
		this.state.updatedBodyText = notes;
		this.UpdateBodyWithNewLineAndBRTag();		
	};

	UpdateBodyWithNewLineAndBRTag = () =>
	{
		let tempupdatedBodyTextWithNewLineChar = '';
		
		let updatedBodyTextWithNewLine = this.state.updatedBodyText;
		updatedBodyTextWithNewLine.split('<br>').map((line, i) => (
			<span key={i}>
				 {tempupdatedBodyTextWithNewLineChar = tempupdatedBodyTextWithNewLineChar + line + '\n'}
				<br/>
			</span>
						
		))
		this.setState({ updatedBodyText: tempupdatedBodyTextWithNewLineChar });

		let tempupdatedBodyTextWithBRTag = '';		
		let updatedBodyTextWithBRTag = this.state.updatedBodyText;
		updatedBodyTextWithBRTag.split('\n').map((line, i) => (
			<span key={i}>
				 {tempupdatedBodyTextWithBRTag = tempupdatedBodyTextWithBRTag + line + '<br>'}
				<br/>
			</span>
			
		))
		this.setState({ updatedBodyTextTemp: tempupdatedBodyTextWithBRTag });
	}
	

    setBodyValue(e) {
		
		this.setState({ updatedBodyText: e.target.value });
		this.state.updatedBodyText = e.target.value;
		let tempupdatedBodyTextWithBRTag = '';		
		let updatedBodyTextWithBRTag = this.state.updatedBodyText;
		if(this.state.isBodySelected)
		{
			updatedBodyTextWithBRTag.split('\n').map((line, i) => (
				<span key={i}>
					 {tempupdatedBodyTextWithBRTag = tempupdatedBodyTextWithBRTag + line + ''}
					<br/>
				</span>
				
			))
		}
		else{
			updatedBodyTextWithBRTag.split('\n').map((line, i) => (
				<span key={i}>
					 {tempupdatedBodyTextWithBRTag = tempupdatedBodyTextWithBRTag + line + '<br>'}
					<br/>
				</span>
				
			))
		}		
		this.state.updatedBodyTextTemp= tempupdatedBodyTextWithBRTag;

		 return this.state.emailBodyData[this.state.currentIndexLanguage].Value = tempupdatedBodyTextWithBRTag	 ;
	}

	setLanguagaeTranslation = (language,index) => {
		this.state.SelectedNotesLanguage = language;
		this.state.currentIndexLanguage= index ;
	    this.state.updatedBodyText = this.state.emailBodyData.find((x) => x.Language === language).Value;	
		
		this.UpdateBodyWithNewLineAndBRTag();
	};
	
	_handlePreviewClick(e) {
		e.preventDefault();
		this.setState({ preview: true });
	  }
	  
	  _handleEditClick(e) {
		e.preventDefault();
		this.setState({ preview: false });
	  }

	   htmlText  () {
          return(({__html : this.state.updatedBodyText}) )
	   } 
	   
	  _renderPreview() {
		  if (this.state.signature) {
			  let htmlText = () => ({ __html: this.state.signature });
		  return (
			<div>
			  <div className="preview-box">
				<span className="preview-title">Preview</span>
				<div dangerouslySetInnerHTML={htmlText()}></div>
			  </div>
			  <button className='btn opportunity-next-step-btn'
			  id='editPreviewSignature'
			  onClick={this._handleEditClick}>
				Close
			  </button>
			</div>
		  );
		}
		return null;
	  }

		setPreviewContent(){		
		  let finalPreviewResult= '';

			if(!this.state.isBodySelected)
			{

				let tempBodyKey = this.getKeyValueData(this.state.bodyKey);
				
				finalPreviewResult = this.setPreviewSubjectAndBodyData(tempBodyKey,this.state.updatedBodyText);

				if(this.state.subjectKey != null)
				{
					let tempSubjectKey = this.getKeyValueData(this.state.subjectKey);

					let subjectData = this.state.mailSubjectData.find((x) => x.Key == this.state.subjectKey && x.Language == this.state.SelectedNotesLanguage).Value;
					
					let tempResult = this.setPreviewSubjectAndBodyData(tempSubjectKey,subjectData);
					
					finalPreviewResult =  'Subject : '+ tempResult + '\n\n' + finalPreviewResult;
				}
				
			}
			else
			{
				if(this.state.subjectKey != null)
				{
					let tempSubjectKey = this.getKeyValueData(this.state.subjectKey);

					finalPreviewResult = 'Subject : '+ this.setPreviewSubjectAndBodyData(tempSubjectKey,this.state.updatedBodyText);
				}

				let tempBodyKey = this.getKeyValueData(this.state.bodyKey);

			    let bodyData = this.state.mainEmailBodyData.find((x) => x.Key == this.state.bodyKey && x.Language == this.state.SelectedNotesLanguage).Value;
						
				let tempResult = this.setPreviewSubjectAndBodyData(tempBodyKey,bodyData);

				finalPreviewResult = finalPreviewResult + '\n\n' + tempResult;
			}
		
			let tempUpdatedPreviewTextWithBRTag = '';		
			let updatedPreviewTextWithBRTag = finalPreviewResult;
			updatedPreviewTextWithBRTag.split('\n').map((line, i) => (
				<span key={i}>
					{tempUpdatedPreviewTextWithBRTag = tempUpdatedPreviewTextWithBRTag + line + '<br>'}
					<br/>
				</span>
				
			))
		
			this.state.updatedBodyTextTemp =  tempUpdatedPreviewTextWithBRTag;
			finalPreviewResult = '';	
	  }

	setPreviewSubjectAndBodyData(x,y) {
		let updatedBodyTextWithBRTag = y;
		let tempupdatedBodyTextWithBRTag = '';
		let final = '';
		for (let i = 0; i < x.length; i++) {

			let updatedBodyTextWithBRTag1 = updatedBodyTextWithBRTag.split(x[i].Key);
			for (let j = 0; j < updatedBodyTextWithBRTag1.length; j++) {

				if (j != updatedBodyTextWithBRTag1.length - 1) {
					updatedBodyTextWithBRTag1[j] = updatedBodyTextWithBRTag1[j] + x[i].Value;
				}

				tempupdatedBodyTextWithBRTag = tempupdatedBodyTextWithBRTag + updatedBodyTextWithBRTag1[j];
			}

			updatedBodyTextWithBRTag = tempupdatedBodyTextWithBRTag;
			tempupdatedBodyTextWithBRTag = '';

		}
		return updatedBodyTextWithBRTag;
	}

	  getKeyValueData(x){
		let records = '';

		let envExample = ""; // Laat leeg om een duidelijk voorbeeld te geven
		let oppoExample = '{OPP-00001064}';
		let quoteTitle = i18n.t('EMAIL_EXAMPLE_QUOTETITLE');
		let userExample = i18n.t('EMAIL_EXAMPLE_SALESEMPLOYEE');
		let customerExample = i18n.t('EMAIL_EXAMPLE_CUSTOMER');
		let customerNrExample = '{ACC-01001-MXDBQJ}';
		let quoteLink = i18n.t('EMAIL_EXAMPLE_QUOTELINK');

		switch(x)
		{
			// Body
			case 'THEUMA_NV_LARGE_QUOTATION':
			case 'TDS_NV_LARGE_QUOTATION':
			case 'THEUMA_NV_SMALL_QUOTATION':
			case 'MailToCustomer_TheumaNV_TheumaCalculation_BODY':
			case 'MailToCustomer_TheumaNV_TheumaInternalSales_BODY':
			case 'MailToCustomer_TDSNV_TDSCalculation_BODY':
			case 'MailToCustomer_TMIBV_TMICalculation_BODY':
			case 'MailToCustomer_TDSBV_TDSNCalculation_BODY':
				records = [
							{Key: '{0}',Value: customerExample},
							{Key: '{1}', Value: oppoExample},
							{Key: '{2}', Value: quoteTitle},
							{Key: '{3}', Value: this.state.signature}
						]	
		    	break;
			
			// Body
		    case 'THEUMA_NV_QUOTATION_HEADER_DISCOUNT':
			case 'MailDiscountChange_TheumaNV_BODY':
				records = [
							{Key: '{0}',Value: oppoExample},
							{Key: '{1}', Value: customerExample},
							{Key: '{2}', Value: customerNrExample},
							{Key: '{3}', Value: '{Korting Niet-technische Deur Stomp Vervo - 30 - 45 }'},
							{Key: '{4}', Value: '{Testing Discount comments}'},						
							{Key: '{5}', Value: quoteLink}
						]
		    	break;
		    
			// Body
		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CREATOR':
			case 'MailToCreator_TDSNV_BODY':
			case 'MailToCreator_TheumaNV_BODY':
				records = [
							{Key: '{0}',Value: userExample},
							{Key: '{1}', Value: oppoExample},
							{Key: '{2}', Value: customerExample},
							{Key: '{3}', Value: customerNrExample},
							{Key: '{4}', Value: '{Korting Niet-technische Deur Stomp Vervo - 30 - 45 }'},
							{Key: '{5}', Value: '{Yes}'},
							{Key: '{6}', Value: '{Testing with all comments}'},
							{Key: '{7}', Value: quoteLink }
						]
		    	break;
			
			// Body
		    case 'THEUMA_NV_DISCOUNT_APPROVAL_TO_CREATOR':
			case 'MailDiscountApproval_TheumaNV_BODY':
				records = [
							{Key: '{0}',Value: userExample},
							{Key: '{1}', Value: oppoExample},
							{Key: '{2}', Value: customerExample},
							{Key: '{3}', Value: customerNrExample},
							{Key: '{4}', Value: '{Korting Niet-technische Deur Stomp Vervo - 30 - 45 }'},					
							{Key: '{5}', Value: quoteLink}
						]
		    	break;

			// Subject
		    case 'THEUMA_NV_QUOTATION_HEADER_DISCOUNT_SUBJECT':
		    case 'THEUMA_NV_QUOTATION_LINE_DISCOUNT_SUBJECT':
		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CREATOR_SUBJECT':
		    case 'THEUMA_NV_QUOTATION_LINE_EMAIL_TO_CREATOR_SUBJECT':
			case 'MailDiscountChange_TheumaNV_SUBJECT':
			case 'MailDiscountChangeLineLevel_TheumaNV_SUBJECT':
			case 'MailToCreator_TDSNV_SUBJECT':
			case 'MailToCreator_TheumaNV_SUBJECT':
			case 'MailToCreatorLineLevel_TheumaNV_SUBJECT':
				records = [
							{Key: '{0}',Value: envExample},
							{Key: '{1}', Value: quoteTitle},					
						]
		    	break;
			
			// Subject
		    case 'THEUMA_NV_DISCOUNT_APPROVAL_EMAIL_TO_CREATOR_SUBJECT':
			case 'MailDiscountApproval_TheumaNV_SUBJECT':
				records = [
							{Key: '{0}',Value: envExample},
							{Key: '{1}',Value: userExample},
							{Key: '{2}', Value: quoteTitle},						
						]
		    	break;
		  
			// Body
		    case 'THEUMA_NV_QUOTATION_LINE_EMAIL_TO_CREATOR':
			case 'MailToCreatorLineLevel_TheumaNV_BODY':
				records = [
							{Key: '{0}',Value: userExample},
							{Key: '{1}', Value: oppoExample},
							{Key: '{2}', Value: customerExample},
							{Key: '{3}', Value: customerNrExample},
							{Key: '{4}', Value: '{Line 4}'},
							{Key: '{5}', Value: '{Korting Niet-technische Deur Stomp Vervo}'},						
							{Key: '{6}', Value: '{30% - 45%}'},		
							{Key: '{7}', Value: '{Testing comments}'},
							{Key: '{8}', Value: quoteLink}
						]
		    	break;

			// Body
		    case 'THEUMA_NV_QUOTATION_LINE_DISCOUNT_BODY':
			case 'MailDiscountChangeLineLevel_TheumaNV_BODY':
				records = [
							{Key: '{0}', Value: oppoExample},
							{Key: '{1}', Value: customerExample},
							{Key: '{2}', Value: customerNrExample},
							{Key: '{3}', Value: '{Line 4}'},
							{Key: '{4}', Value: '{Korting Niet-technische Deur Stomp Vervo}'},
							{Key: '{5}', Value: '{30% - 45%}'},
							{Key: '{6}', Value: '{Testing comments}'},						
							{Key: '{7}',Value: quoteLink}
						]
		    	break;

			// Subject
		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CUSTOMER_PROD_SUBJECT':
				records = [
							{Key: '{0}',Value: oppoExample},
							{Key: '{1}', Value: '{Version Number}'},						
						]
		    	break;

		    case 'THEUMA_NV_QUOTATION_EMAIL_TO_CUSTOMER_DEV_TEST_SUBJECT':
			case 'MailToCustomer_TDSBV_TDSNCalculation_SUBJECT':
			case 'MailToCustomer_TDSNV_TDSCalculation_SUBJECT':
			case 'MailToCustomer_TheumaNV_TheumaCalculation_SUBJECT':
			case 'MailToCustomer_TheumaNV_TheumaInternalSales_SUBJECT':
			case 'MailToCustomer_TMIBV_TMICalculation_SUBJECT':
				records = [
							{Key: '{0}', Value: envExample},
							{Key: '{1}',Value: oppoExample},
							{Key: '{2}',  Value: '{Version Number}'},					
						]
		    	break;
		}
		
        return records;
	  }
  
	  _renderBodyPreview() {

		if(this.state.preview)
		{							
			this.setPreviewContent();	
     	}
		if (this.state.updatedBodyTextTemp) {
		  let htmlText = () => ({ __html: this.state.updatedBodyTextTemp  });
		  return (
			<div>
			  <div className="preview-box1">
				<div dangerouslySetInnerHTML={htmlText()}></div>
			  </div>	
			  <button className='btn opportunity-next-step-btn'
			  id='editPreviewSignature'
			  onClick={this._handleEditClick}>
				Close
			  </button>		  
			</div>
		  );
		}
		return null;
	  }

	getEmailConfigurations = () => {			
		this.setState({ loading: true });

		getEmailConfigurations()
		.then((responseData) => {				
			this.setState(
				{						
					responseData: responseData,
					tableData :  responseData,
					loading: false
				},					
			);
		})
		.catch(() => {
			toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG.</Trans>);
			this.setState({ loading: false });
		});
	};
	
	componentDidMount() {	
		this.getEmailConfigurations();
		this.props.setWindowPage(false);
	}

	setOrgnisation = (e) => {
		this.setState({ salesOrganisation:  e.target.value });
	};

	setCreator = (e) => {
		this.setState({ creator:  e.target.value });
	};

	setMailType = (e) => {
		this.setState({ mailType:  e.target.value });
	};
	
	setTo = (e) => {
		this.setState({ to:  e.target.value });
	};

	setFrom = (e) => {
		this.setState({ from:  e.target.value });
	};

	setCC = (e) => {
		this.setState({ cc:  e.target.value });
	};

	setBodyKey = (e) => {
		this.setState({ bodykey:  e.target.value });
	};

	setBCC = (e) => {
		this.setState({ bcc:  e.target.value });
	};

	setSignature = (e) => {
		this.setState({ signature:  e.target.value });
	};

	getVersionList(x){	
		if(x.BodyKey != null){
		this.getLanguagaeTranslation(x.BodyKey);

			if(x.SubjectKey != '')
			{
				this.getLanguagaeTranslationSubject(x.SubjectKey);
			}
	    }
		this.setState({ showEmailConfigurationPopUp : true,

			salesOrganisation : x.SalesOrganisation,
			creator : x.Creator,
			to: x.To,
			from:x.From,
			cc: x.CC,
			bodyKey: x.BodyKey,
			bcc: x.Bcc,
			signature: x.Signature,
			id:x.Id,
			mailType:x.MailType,
			subjectKey : x.SubjectKey

	 });	
	}

	onCloseEmailConfiguration =() => {		
			 
		this.setState({ showEmailConfigurationPopUp: false,preview:false,emailBodyData:[],mailSubjectData:[],mainEmailBodyData:[],updatedBodyText:'',optionsData:[],isBodySelected: false});
	}


	onLanguageTranslationSave=()=>{
		this.setState({ loading: true });

		let data =[]; 		
		for (let i = 0; i < this.state.mainEmailBodyData.length; i++) {
			data.push({
				Id:this.state.mainEmailBodyData[i].Id,				
				Key: this.state.mainEmailBodyData[i].Key,
				Language: this.state.mainEmailBodyData[i].Language,
				Value : this.state.mainEmailBodyData[i].Value,				
			});
		}

		for (let i = 0; i < this.state.mailSubjectData.length; i++) {
			data.push({
				Id:this.state.mailSubjectData[i].Id,				
				Key: this.state.mailSubjectData[i].Key,
				Language: this.state.mailSubjectData[i].Language,
				Value : this.state.mailSubjectData[i].Value,				
			});
		}

		saveTranslations(data)
		.then((responseData)=>{
			this.setState({ emailBodyData: [],preview:false,loading: false,mailSubjectData:[],mainEmailBodyData:[],updatedBodyText:'',optionsData:[],isBodySelected: false});
			
		})
		.catch(() => {
			toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
		});
	}

	onEmailConfigurationSave=()=>{
		this.setState({ loading: true });

		let data = {
			Id: this.state.id,
			Creator:this.state.creator,
			MailType:this.state.mailType,
			From:this.state.from,
			To:this.state.to,
			CC:this.state.cc,
			BCC:this.state.bcc,
			BodyKey:this.state.bodyKey,
			Signature:this.state.signature,
			SalesOrganisation:this.state.salesOrganisation,
			SubjectKey : this.state.subjectKey		
		};

		saveEmailConfiguration(data)
		.then((response)=>{
			
			this.onLanguageTranslationSave();
			this.getEmailConfigurations();
			this.setState({ showEmailConfigurationPopUp: false,preview:false,loading: false,optionsData:[],emailBodyData:[],mailSubjectData:[],mainEmailBodyData:[]});
		})
		.catch(() => {
			toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
		});
	}
	
	toggleLanguageDD = () => {
		this.setState({
			languageDDIsOpen: !this.state.languageDDIsOpen
		});
	};

	setLanguage = (language) => {
		this.setState({
			SelectedNotesLanguage: language.Name
		});
	};
	
	render() {
		const { selectedLanguage , userRoleDetail } = this.context;
		let previewStyle = {};	
		let previewBodyStyles = {};
		previewBodyStyles.display ='block';

		if (this.state.preview) previewBodyStyles.display = 'block';
    	else previewBodyStyles.display = 'none';

		return (
			<React.Fragment>	
				<div className='row mt-4'>
					<div className='col-12'>
						<div className='dashboard-title'>
							<div className='display-inline'>							
								{i18n.t('EMAIL_CONFIGURATION_TITLE')}								
							</div>
							{' '}
						</div>
					</div>
				</div>
							
				<div className='row'>
					<div className='col-12'>
					
						<div className='table-container'>
							<table className='table table-striped dashboard-table'>
								
								<thead>
									<tr className='row'>
										<th scope='col' className='col-2'>											
											<div className={'table-header-content saleorganization'}>
												{i18n.t('EMAIL_EDIT_TITLE_SALESORG')}
											</div>
										</th>
										<th scope='col' className='col-2'>											
											<div className={'table-header-content mailType'}>
												{i18n.t('EMAIL_EDIT_TITLE_MAILTYPE')}	
											</div>
										</th>
										<th  scope='col' className='col-2'>											
											<div className={'table-header-content creator'}>
												{i18n.t('EMAIL_EDIT_TITLE_DEPARTMENT')}
											</div>
										</th>
										<th scope='col' className='col-2' >											
											<div className={'table-header-content mailType'}>
												{i18n.t('EMAIL_EDIT_TITLE_FROM')}
											</div>
										</th>
										<th scope='col' className='col-2' >										
											<div className={'table-header-content to'}>
												{i18n.t('EMAIL_EDIT_TITLE_TO')}
											</div>
										</th>
										<th scope='col' className='col-1' >
											<div className={'table-header-content cc'}>
												{i18n.t('EMAIL_EDIT_TITLE_CC')}
											</div>
										</th>
										<th scope='col' className='col-1' >
											<div className={'table-header-content bodykey'}>
												{i18n.t('EMAIL_EDIT_TITLE_BCC')}
											</div>
										</th>									
									</tr>								
								</thead>

								<tbody>

									{this.state.tableData.length === 0 ? 
									(
										this.state.loading ? 
										(
											<tr style={{ backgroundColor: 'white' }}>
												<td>
													<div className='loading'>
														{' '}{i18n.t('LOADING')}
													</div>
												</td>
											</tr>
										) : 
										(
											<tr style={{ backgroundColor: 'white' }}>
												<td>
													<div className='loading'>
														{' '}{i18n.t('NO_RECORDS_FOUND')}
													</div>
												</td>
											</tr>
										)
									) : null
									}

									{this.state.tableData.map((x, i) => {
										return (
											<tr
												className='table-content row'
												key={i}
												onClick={() => this.getVersionList(x)}
											>												
												<td className='padding-td col-2'>													
													<span className='main-content word-break display-block'>
														{x.SalesOrganisation}
													</span>																							
												</td>	
												<td className='padding-td  col-2'>
													<span className='word-break display-block'>{x.MailType}</span>
												
												</td>										
												<td className='padding-td  col-2'>
													<span className='word-break display-block'>{x.Creator}</span>
												
												</td>
												<td className='padding-td  col-2 word-break'>{x.From}</td>
												<td className='padding-td col-2 word-break'>{x.To}</td>

												<td className='padding-td  col-1 word-break'>{x.CC}</td>
												<td className='padding-td col-1 word-break'>{x.Bcc}</td>
																							
											</tr>
										);
									})}
									{this.state.showViewQuotationPage && ( <Redirect to={{pathname: '/quotation/view'}}/>)}
								</tbody>
							</table>
						</div>
					</div>
					{this.state.loading && (
						<div className='fixed-bottom'>
							<Loader type='Bars' color='#ef2312' height={100} width={100} />
						</div>
					)}
					
					<div>
						<Modal
							isOpen={this.state.showEmailConfigurationPopUp}
							size={'xl'}
							className={process.env.REACT_APP_ICON_ENVIRONMENT + 'Class'}
						>
							<ModalHeader>
								<div className='modal-title-text' >
									<Trans i18nKey='EMAIL_CONFIGURATION'>EMAIL_CONFIGURATION</Trans>{' '}
								</div>
								<div
									className='modal-close'
									id='modal-close-discount-trace'
									onClick={this.onCloseEmailConfiguration}
								>
									x
								</div>
							</ModalHeader>
							<ModalBody>
								<div className='row'>
									<div className='col-7'>
										<div className='quotation-panel'>
									
											<div className='row mb-3'>
												<div className='col-4'>
													<div className='bold-header-font'>
														{i18n.t('EMAIL_EDIT_TITLE_SALESORG')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.salesOrganisation}
														onChange={this.setOrgnisation}
														disabled={true}
													/>
												</div>
												<div className='col-4'>
													<div className=' bold-header-font'>
														{i18n.t('EMAIL_EDIT_TITLE_DEPARTMENT')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.creator}
														onChange={this.setCreator}
														disabled={true}
													/>
												</div>
												<div className='col-4 '>
													<div className=' bold-header-font'>
														{i18n.t('EMAIL_EDIT_TITLE_MAILTYPE')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.mailType}
														onChange={this.setMailType}
														disabled={true}
														/>									
												</div>			
											</div>

											<div className='row mb-3'>
												<div className='col-4'>
													<div className='bold-header-font'>
														{i18n.t('EMAIL_EDIT_TITLE_FROM')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.from}
														onChange={this.setFrom}
														/>
												</div>
												<div className='col-4'>
													<div className=' bold-header-font'>
													{i18n.t('EMAIL_EDIT_TITLE_TO')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.to}
														onChange={this.setTo}
														disabled={true}
														/>									
												</div>
												<div className='col-4'>
													<div className=' bold-header-font'>
														{i18n.t('EMAIL_EDIT_TITLE_CC')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.cc}
														onChange={this.setCC}
														disabled={true}
														/>									
												</div>			
											</div>

											<div className='row mb-3'>
												<div className='col-4'>
													<div className='bold-header-font'>
														{i18n.t('EMAIL_EDIT_TITLE_BCC')}
													</div>
													<input
														className='form-control textbox-font'
														value={this.state.bcc}
														onChange={this.setBCC}
														/>
												</div>
											</div>
										</div>
										<div className='quotation-panel-white'>
											<div className='quotation-status-modal-body'>
												<div className='discount-trace-body'>
													<Table striped>
														<thead className={'table-header-content'}>
															<div className='row'>																							
																<div className='col-12'>

																	<tr className='row'>
																		<div className='col-12'>
																			<div className='row'>
																				<div className='col-4 export-title add-quotation-line-title '>
																					<Trans i18nKey='SELECT_BODY_OR_SUBJECT'>
																						SELECT_BODY_OR_SUBJECT
																					</Trans>
																				</div>
																				<div className='col-4'>
																					<input
																						id={'auto'}
																						name='bodyType'
																						type='radio'
																						className='width-left mt-1'
																						onChange={() => this.onBodySubjectTypeChanged(1)}
																						checked={!this.state.isBodySelected}
																					></input>
																					<span className='add-quotation-line-content'>
																						<Trans i18nKey='BODY_DATA'>BODY_DATA</Trans>
																					</span>
																				</div>

																				<div className='col-4'>
																					<input
																						id={'manual'}
																						name='subjectType'
																						className='width-left-second mt-1'
																						type='radio'
																						onChange={() => this.onBodySubjectTypeChanged(2)}
																						disabled={this.state.mailSubjectData == '' ? true : false }
																						checked={this.state.isBodySelected}
																					></input>
																					<span className='add-quotation-line-content'>
																						<Trans i18nKey='SUBJECT_DATA'>
																							SUBJECT_DATA
																						</Trans>
																					</span>
																				</div>
																			</div>
																		</div>

																		<div className='col-12'>
																				<div className='row mb-2 notes-language'>
																						<div className='notes-language-title col-4'>
																							<Trans i18nKey='LANGUAGE'>LANGUAGE</Trans>
																						</div>
																						<Dropdown
																							isOpen={this.state.languageDDIsOpen}
																							toggle={this.toggleLanguageDD}
																							className='display-inline-export notes-language-dropdown col-8'
																							id={'openLanguage'}
																						>
																							<DropdownToggle caret>
																								{this.state.SelectedNotesLanguage}
																							</DropdownToggle>
																							<DropdownMenu>
																								{this.state.emailBodyData
																									? this.state.emailBodyData.map((x, i) => {
																											return (
																												<DropdownItem
																													id={'language' + i}
																													onClick={() => this.setLanguagaeTranslation(x.Language,i)}
																													key={i}
																												>
																													{x.Language}
																												</DropdownItem>
																											);
																									})
																									: ''}
																							</DropdownMenu>
																						</Dropdown>																																															
																				</div>
																				
																				
																		</div>
																	</tr>

																	<tr className='row'>
																		<div className='col-4'>
																			<div className='languagetranslation ' >
																				{this.state.optionsData &&
																					this.state.optionsData.map((x, i) => {
																						return (
																						<div className='language-translation-content '>
																							{x.Key}
																							<div
																								className='icon-add-search-filter'
																								onClick={() => this.addStandardNotes(x.Value)}
																							></div>
																						</div>);
																					})}
																			</div>
																		</div>
																		<br/>
																			<div className='col-8'>
																			<tr className='row'>										 
																														
																					<textarea style={{whiteSpace: "pre-line"}}
																						id={'quotationNotes'}
																						className='padding5px body-text-area'
																						value={this.state.updatedBodyText}																
																						onChange={(e) => this.setBodyValue(e)															
																						}																	
																					></textarea>
																				</tr> 
																				
																		</div>
																	</tr>	

																</div>																																				
															</div> 																																						
														</thead>																									
													</Table>
												</div>											
											</div>
										</div>						
									</div>
									<div className='col-4'>
										<tr className='row'> 

											<button id='btnSignaturePreview' 
													className='btn opportunity-next-step-btn'
													onClick={this._handlePreviewClick}>
														<Trans i18nKey='PREVIEW'>Preview</Trans>
											</button>
									
											<div style={previewBodyStyles}>
												{this._renderBodyPreview()}
											</div>
											
										</tr>	 
									</div>	
								</div>
																									
							</ModalBody>
							<ModalFooter>
								<div className='modal-footer-button'>
									<button
										id='saveEmailCOnfiguraton'
										className={'btn opportunity-next-step-btn'																
										}	
										onClick={() => {this.onEmailConfigurationSave()}}													
									>
										<Trans i18nKey='OK'>OK</Trans>
									</button>
									{'   '}
									<button
										id='cancelEmailConfiguration'
										className='btn opportunity-next-step-btn'	
										onClick={() => {this.onCloseEmailConfiguration()}}																													
									>
										<Trans i18nKey='CANCEL'>CANCEL</Trans>
									</button>
								</div>		
							</ModalFooter>
						</Modal>
					</div>

					<div>
						<ToastContainer
							position='top-center'
							autoClose={5000}
							className='toast'
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}			
}
EmailConfiguration.contextType = QuotationToolContext;
const Dash = withTranslation()(EmailConfiguration);

