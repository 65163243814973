import React, { Component } from 'react';
import { hasEditAccess,getDistinctOrganisation as getDistinctOrganisations } from '../../Utils/commonFunctions';
import { getSalesOrganisations, getUserRoleDetails, 
	getUserPreferencedLanguage,saveQuotationLockStatus, 
	saveUserPreferencedLanguage, getDashboardQuotations } from '../../Utils/apiCalls';
import { Trans } from 'react-i18next';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect} from 'react-router-dom';
import Loader from 'react-loader-spinner';
import i18n from 'i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './header.scss';
import ConfirmationDialog from '../../components/quotation/ConfirmationDialog';
import { QuotationToolContext } from '../../Providers';

import './styles.css';

export default class Header extends Component {

	
	state = {
		tableData: [],
		windowPage: true,
		typeahead: null,
		quotationViewId: 0,
		showViewQuotationPage: false,
		quotation: null,
		isLoading: false,
		searchText: '',
		loading: false,
		setIsLoading: false,
		options: [],
		setOptions: [],
		flag: 0,
		showDropdown: false,
		salesOrganisatieDDIsOpen: false,
		showWarningAddQuotationPopup:false,
		showWarningChangeRolePopup:false,
		showWarningGoToHomePagePopup:false,
		showHomePage:false,
		goToAddQuotationPage:false,
		organizationList: [],
		selectedOrganization: null,
		isRoleDDOpen:false,
		orderedRolesList :[
			{
				order :1,
				Name: 'TheumaCalculation'
			},
			{
				order :2,
				Name: 'TheumaInternalSales'
			},
			{
				order :3,
				Name: 'TheumaExternalSales'
			},
			{
				order :4,
				Name: 'TDSCalculation'
			},
			{
				order :5,
				Name: 'TDSExternalSales'
			},
			{
				order :6,
				Name: 'TMIQuotationDesk'
			},
			{
				order :7,
				Name: 'TDSNQuotationDesk'
			},
			{
				order :8,
				Name: 'TDSNExternalSales'
			},
			{
				order :9,
				Name: 'TMIExternalSales'
			},
			{
				order :10,
				Name: 'Viewer'
			}

		]
	};

	componentDidMount = () => {		
		this.orderRolesBasedOnSequenceNum();
		
		document.getElementById('applicationTitle').innerHTML = i18n.t('APPLICATION_TITLE');
		const { updateAccountInfo } = this.context;
		this.updateLanguage();
		updateAccountInfo(this.props.accountInfo);	
		
		this.props.setWindowPage(true);

		this.fetchAndMapOrganisations();
	};

	componentDidUpdate = (prevProps) => {};

	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}
	
	fetchAndMapOrganisations() {
		getSalesOrganisations()
			.then((response) => {

				var organisations = getDistinctOrganisations(response);

				// Add catch all organisation
				organisations.unshift({
					Id: -1,
					Name: i18n.t('SELECT_ALL_ORGANIZATION')
				});
				this.setState({ organizationList: organisations });
			})
			.catch((reason) => { this.handleError(reason); });
	}

	toggleRoleKebabDD = () => {
		this.setState({ isRoleDDOpen: !this.state.isRoleDDOpen });
	};
	onHomePageClick = () => {	
	
		this.setState({ showHomePage: false },this.homePageClickLogic);		
	
	};

	homePageClickLogic = () =>{
		const { isQuotationLockedByMe } = this.context;
		if(isQuotationLockedByMe)		
		{
			this.setState({ showWarningGoToHomePagePopup: true });			
		}
		else
		{
			this.lockAndGoToHomePage();
		}
	}
	onSearchPageClick = () => {

		this.setState({ goToAddQuotationPage: false },this.goToAddQuotationPageLogic);	
	
	
	};
	goToAddQuotationPageLogic =()=>{
		const { isQuotationLockedByMe } = this.context;
		if(isQuotationLockedByMe)		
		{
			this.setState({ showWarningAddQuotationPopup: true });			
		}
		else
		{
			this.lockAndGoToAddQuotation();
		}
	}

	lockAndGoToAddQuotation = () =>
	{
		const { updateQuotationToBeCopied,updateIsQuotationLockedByMe } = this.context;			
		this.setState({goToAddQuotationPage:true,showWarningAddQuotationPopup:false});
		updateIsQuotationLockedByMe(false);		
		updateQuotationToBeCopied(null);		
	}
	
	unlockGoToAddQuotation = () =>
	{
		let lockedBy = '';
		this.lockQuotation(lockedBy,this.lockAndGoToAddQuotation); 
	}

	handleClick = (lang) => {
		this.setState({ loading: true });

		saveUserPreferencedLanguage(this.props.accountInfo.account.userName, lang)
		.then((response) => {
			i18n.changeLanguage(lang);
			document.getElementById('applicationTitle').innerHTML = i18n.t('APPLICATION_TITLE');
			const { updateSelectedLanguage } = this.context;
			this.setState({ loading: false });
			updateSelectedLanguage(lang);
			window.location.reload();
		})
		.catch((reason) => {this.handleError(reason)});
	};


	lockQuotation = (lockedBy, onLockSuccess) =>{
		this.setState({ loading: true });
		const { selectedQuotationId } = this.context;		
	
		saveQuotationLockStatus(selectedQuotationId, lockedBy)
		.then((response) => {
			onLockSuccess();
			this.setState({loading: false});	
		})
		.catch((reason) => {this.handleError(reason);});
	}

	getAdvanceSearchResult = (query, salesOrgId) => {
		this.setState({ isLoading: true });

		let orgId = salesOrgId;
		if (!salesOrgId)
			{ orgId = -1 }

		getDashboardQuotations(query, orgId)
		.then((data) => {
			this.props.onSearchData(data);
			this.setState({ loading: false });
		})
		.catch((reason) => {this.handleError(reason);});
	};
	
	handleSearch = (query) => {
		var inputSearch = document.getElementById('inner_search');
		inputSearch.value = '';
		this.setState({ showDropdown: true, searchText: query });
		this.getAdvanceSearchResult(query, this.state.selectedOrganization ? this.state.selectedOrganization.Id : '-1');
	};

	lockAndGoToHomePage = () =>{
		const { updateIsQuotationLockedByMe } = this.context;
		this.setState({showHomePage:true,showWarningGoToHomePagePopup:false});
		updateIsQuotationLockedByMe(false);	
		
		
	}
	unlockGoToHomePage = () =>{
		let lockedBy = '';
		this.lockQuotation(lockedBy,this.lockAndGoToHomePage); 
	}

	setWindowPageState = (booleanValue) => {
		this.setState({ windowPage: booleanValue });
	};
	
	orderRolesBasedOnSequenceNum = () =>{
		//console.log(this.props.accountInfo.account.idToken.roles);
		const tempList = this.props.accountInfo.account.idToken.roles.map((i) => this.state.orderedRolesList.find((j) => j.Name == i));
		const sortedList =  tempList.sort(function(a, b){return a.order - b.order});
		this.setState( { orderedRolesList: sortedList }, () => {
		this.adjustUserRolesBasedOnLoggedInUser();

	});	
		
	}

	adjustUserRolesBasedOnLoggedInUser = () =>{
		const { userRoleDetail,updateSelectedRole } = this.context;
		if (userRoleDetail === null) {
		
			this.updateUserRoleDetails(this.state.orderedRolesList[0].Name);
			updateSelectedRole(this.state.orderedRolesList[0].Name);
		}

	}

	lockAndChangeRole = () =>
	{
		const { updateIsQuotationLockedByMe,updateUserRoleDetail, selectedRole } = this.context;
		updateIsQuotationLockedByMe(false);	

		this.setState({ loading: true });		

		getUserRoleDetails(selectedRole)
		.then((response) =>{
			updateUserRoleDetail(response);
			if(window.location.pathname !=='/') window.location = '/';
			this.setState({loading:false});
		})
		.catch((reason) => {this.handleError(reason);});
	}

	unlockAndChangeRole = () =>{
		let lockedBy = '';
		this.lockQuotation(lockedBy,this.lockAndChangeRole); 
	}

	updateUserRoleDetails = (role,goToHomePage) => {

		this.setState({ loading: true });
		
		const { updateUserRoleDetail,updateSelectedRole,isQuotationLockedByMe } = this.context;
		updateSelectedRole(role);
		if(isQuotationLockedByMe)
		{
			this.setState({ showWarningChangeRolePopup: true,loading:false });	
		}
		else
		{
			updateSelectedRole(role);

			getUserRoleDetails(role)
			.then((response) =>{
				updateUserRoleDetail(response);
				if(goToHomePage && window.location.pathname !=='/') window.location = '/';
				this.setState({loading:false});
			})
			.catch((reason) => {this.handleError(reason)});
		}
	};

	updateLanguage = () => {
		const { updateSelectedLanguage, selectedLanguage } = this.context;
		
		if (selectedLanguage == '') 
		{	
			getUserPreferencedLanguage(this.props.accountInfo.account.userName)
			.then((response) => {

				let preferredLanguage = response.Language == '' ? 'nl-BE' : response.Language;
				i18n.changeLanguage(preferredLanguage);
				updateSelectedLanguage(preferredLanguage);
			})
			.catch((reason) => {this.handleError(reason)});
		}
	};

	ref = React.createRef();

	redirectView = (x) => {
		setTimeout(() => {
			this.setState({ quotationViewId: x.QuotationId, showViewQuotationPage: true }, () => {
				this.setState({ showDropdown: false });
			});
		}, 1000);
	};
	setOrganization = (organization) => {
		this.setState({ selectedOrganization: organization, flag: 1 });
		var inputSearch = document.getElementById('inner_search');
		inputSearch.value = '';
		this.getAdvanceSearchResult(this.state.searchText, organization.Id);
	};

	toggleSalesOrganisatieDD = () => {
		this.setState({
			salesOrganisatieDDIsOpen: !this.state.salesOrganisatieDDIsOpen
		});
	};

	render() {
		const { selectedLanguage , userRoleDetail,accountInfo } = this.context;
		return (
			
			<div className='header container-fluid'>
				<div className=' large-screen-width container-fluid mb-3'>
					<div className='row'>
						{this.props.windowPage ? (
							<div className='col-1'>
								<div className='app-logo' onClick={this.onHomePageClick}></div>
							</div>
						) : (
							<div className='col-1'>
								<div className='app-logo' onClick={this.onHomePageClick}></div>
							</div>
						)}

						{this.props.windowPage ? (
							<div className='col-3 dashboard-search mt-4'>
								<div>
									<AsyncTypeahead
										filterBy={() => true}
										id='async-example'
										isLoading={false}
										labelKey='clientNumber'
										minLength={3}
										useCache={false}
										onInputChange={(text, event) => {
											if (text === '') {
												if (!this.state.selectedOrganization) {
													this.props.onSearchData('');
												} else {
													this.handleSearch('');
												}
											}
										}}
										onBlur={() => {}}
										onSearch={this.handleSearch}
										options={this.state.options}
										placeholder={i18n.t('SEARCH_ALL_QUOTATIONS')}
										open={false}
										ref={this.ref}
									/>
								</div>
							</div>
						) : (
							<div className='col-3'></div>
						)}
						{this.props.windowPage ? (
							<div className='col-3 dashboard-search mt-4 '>
								<div className='padding-organsiation'>
									<Dropdown
										isOpen={this.state.salesOrganisatieDDIsOpen}
										toggle={this.toggleSalesOrganisatieDD}
									>
										<DropdownToggle caret>
											{!!this.state.selectedOrganization
												? this.state.selectedOrganization.Name
												: i18n.t('SELECT_ORGANISATIOIN')}
										</DropdownToggle>
										<DropdownMenu>
											{this.state.organizationList.map((x, i) => {
												return (
													<DropdownItem onClick={() => this.setOrganization(x)} key={i}>
														{x.Name}
													</DropdownItem>
												);
											})}
										</DropdownMenu>
									</Dropdown>
								</div>
							</div>
						) : (
							<div className='col-3'></div>
						)}
						{this.props.showSearch ? (
							<div className='col-5 mt-4'>
								<div className='row'>
									<div className='text-profile col'>
										<span className='user-info'>
											<Trans i18nKey='SIGNED_IN_AS'>SIGNED_IN_AS</Trans>
											{this.props.accountInfo.account.name}
										</span>
									</div>
									{ accountInfo && accountInfo.account.idToken.roles.length > 1 && <Dropdown
										isOpen={this.state.isRoleDDOpen}
										toggle={this.toggleRoleKebabDD}
										className='kebab-menu-dropdown kebab-header-dropdown role-kebab-dropdown'
										id={'headerKebabMenu'}
										>
											<DropdownToggle>
												<div className='kebab-menu kebab-menu-header'></div>
											</DropdownToggle>
											<DropdownMenu right>
												{this.state.orderedRolesList.map((x, i) => {
													return (
														<DropdownItem className={userRoleDetail && userRoleDetail.length !==0  && x.Name === userRoleDetail[0].Role ? 'selected-role' : ''} onClick={() => this.updateUserRoleDetails(x.Name,true)} key={i}>
															<Trans i18nKey={x.Name}>{x.Name}</Trans>
														</DropdownItem>
													);
												})}
											</DropdownMenu>
									</Dropdown>
									}
									{hasEditAccess(userRoleDetail,"Quotation") &&	<div className='button-add-quotation col'>
										 <button
											type='button'
											className='btn btn-color search-button'
											onClick={this.onSearchPageClick}
										>
											<Trans i18nKey='ADD_QUOTATION'>ADD_QUOTATION</Trans>
										</button>

										
									</div>
								}
									{this.state.loading && (
										<div className='fixed-bottom'>
											<Loader type='Bars' color='#ef2312' height={100} width={100} />
										</div>
									)}
								</div>
									
								<div>
									<ToastContainer
										position='top-center'
										autoClose={5000}
										hideProgressBar={false}
										newestOnTop={false}
										closeOnClick
										rtl={false}
										pauseOnFocusLoss
										draggable
										pauseOnHover
									/>
								</div>
							</div>
						) : (
							<div className='col-5 mt-4'>
								<div className='row'>
									<div className='text-profile-only'>
										<span className='user-info'>
											<Trans i18nKey='SIGNED_IN_AS'>SIGNED_IN_AS</Trans>
											{this.props.accountInfo.account.name}
										</span>
									</div>
								</div>
							</div>
						)}
					</div>

					
				</div>

				{this.state.showHomePage && (<Redirect to={{pathname: '/'}}/>)}	

				{this.state.goToAddQuotationPage && (<Redirect to={{pathname: '/opportunity/search'}}/>)}	
			
				<div>
					<ConfirmationDialog
						isModelOpen={this.state.showWarningAddQuotationPopup}
						modalSize=''
						headerText={i18n.t('WARNING_QUOTATION_LOCKED_HEADER')}
						confirmationText={i18n.t('CONFIRMATION_QUOTATION_LOCKED')}
						okText={i18n.t('LOCK')}
						cancelText={i18n.t('UNLOCK')}
						onOk={this.lockAndGoToAddQuotation}
						onCancel={this.unlockGoToAddQuotation}
					/>
				</div>

				<div>
					<ConfirmationDialog
						isModelOpen={this.state.showWarningGoToHomePagePopup}
						modalSize=''
						headerText={i18n.t('WARNING_QUOTATION_LOCKED_HEADER')}
						confirmationText={i18n.t('CONFIRMATION_QUOTATION_LOCKED')}
						okText={i18n.t('LOCK')}
						cancelText={i18n.t('UNLOCK')}
						onOk={this.lockAndGoToHomePage}
						onCancel={this.unlockGoToHomePage}
					/>
				</div>

				<div>
					<ConfirmationDialog
						isModelOpen={this.state.showWarningChangeRolePopup}
						modalSize=''
						headerText={i18n.t('WARNING_QUOTATION_LOCKED_HEADER')}
						confirmationText={i18n.t('CONFIRMATION_QUOTATION_LOCKED')}
						okText={i18n.t('LOCK')}
						cancelText={i18n.t('UNLOCK')}
						onOk={this.lockAndChangeRole}
						onCancel={this.unlockAndChangeRole}
					/>
				</div>

				<div className='overlay-language'>
					{' '}
					<a
						href='#'
						style={{ color: selectedLanguage == 'en-GB' ? '#ef2312' : '' }}
						onClick={() => this.handleClick('en-GB')}
					>
						EN
					</a>{' '}
					|{' '}
					<a
						href='#'
						style={{ color: selectedLanguage == 'nl-NL' ? '#ef2312' : '' }}
						onClick={() => this.handleClick('nl-NL')}
					>
						NL
					</a>{' '}
					|{' '}
					<a
						href='#'
						style={{ color: selectedLanguage == 'nl-BE' ? '#ef2312' : '' }}
						onClick={() => this.handleClick('nl-BE')}
					>
						BE
					</a>{' '}
				</div>
			</div>
		);
	}
}
Header.contextType = QuotationToolContext;
