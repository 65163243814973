import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './Quotation.scss';
import { Redirect } from 'react-router-dom';
import { getDistinctOrganisation } from '../../Utils/commonFunctions';
import { getUsers, getOwnerUsers, getInternalSalesUsers, getActivePriceLists, getSalesOrganisations,
	saveAccountDiscounts, saveQuotation, copyQuotation, saveSecondaryContacts } from '../../Utils/apiCalls';
import { Trans } from 'react-i18next';
import Loader from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import i18n from 'i18next';
import { QuotationToolContext } from '../../Providers';
var DatePicker = require('reactstrap-date-picker');

export default class Quotation extends Component {
	state = {
		salesOrganisatieDDIsOpen: false,
		internalSalesDDIsOpen: false,
		priceListDDIsOpen: false,
		landListDDIsOpen: false,
		ownerDDIsOpen: false,
		offerDate: new Date().toISOString(),
		userList: [],
		ownerList: [],
		internalSalesList: [],
		loading: false,
		selectedOwner: null,
		selectedInternalSales: null,
		opportunity: '',
		opportunityTitle: '',
		validity: 30,
		selectedPrice: null,
		priceList: [],
		organizationList: [],
		selectedOrganization: null,
		selectedContactPerson: null,

		contactList: [],
		secondaryContactList: [],
		landList: [
			{
				ID: 1,
				Name: 'België'
			},
			{
				ID: 2,
				Name: 'Nederland'
			},
			{
				ID: 3,
				Name: 'Frankrijk'
			}
		],
		streetName: '',
		town: '',
		streetNumber: '',
		postCode: '',
		selectedProjectAddress: null,
		selectedLand: null,
		validations: [],
		showViewQuotationPage: false,
		addedQuotationId: null,
		validations: {
			isOfferDateInvalid: false,
			isOrganisationInvalid: false,
			isOfferTitleInvalid: false,
			isOwnerInvalid: false,
			isSalesInvalid: false,
			isValidatityInvalid: false,
			isPriceListInvalid: false
		}
	};

	componentDidMount() {
		const { createdQuotation, selectedLanguage } = this.context;
		
		this.setState({loading:true });

		let quotation = createdQuotation;
		let opportunity = quotation.Number;
		let opportunityTitle = quotation.Name;
		let contactPersonId = quotation.PrimaryContactId;
		let contacts = quotation.AccountInformation.Contacts;
		let ownerName = quotation.OwnerName;

		let internalSalesName = quotation.InternalSalesName;

		getUsers()
		.then((response) => {
			response.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
			this.setState({ userList: response});
		})
		.catch((reason) => { this.handleError(reason)});

		getInternalSalesUsers()
		.then((response) => {
			response.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
			this.setState({ internalSalesList: response });
			this.setInternalSalesDropDownValues(response, internalSalesName);
		})
		.catch((reason) => { this.handleError(reason)});

		getOwnerUsers()
		.then((response) => {
			response.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
			this.setState({ ownerList: response });
			this.setOwnersDropDownValues(response, ownerName);
		})
		.catch((reason) => { this.handleError(reason)});

		getActivePriceLists(selectedLanguage, this.state.offerDate)
		.then((priceListsDTO) => {
			
			getSalesOrganisations()
			.then((organisations) => {
				let distinctSalesOrganization = getDistinctOrganisation(organisations);
				const { createdQuotation } = this.context;

				let selectedOrganization = organisations.find((x) => x.BusinessId == createdQuotation.Aug_businessnew);
				this.setState({ organizationList: distinctSalesOrganization, selectedOrganization: selectedOrganization});					
				this.setOrganization(selectedOrganization);
			})
			.catch((reason) => { this.handleError(reason)});

			this.setState({ priceList: priceListsDTO });
		})
		.catch((reason) => { this.handleError(reason)});

		let selectedContactPerson = contacts.find((x) => x.Id == contactPersonId);
		this.setState({
			loading:false,
			opportunity: opportunity,
			opportunityTitle: opportunityTitle,
			contactList: contacts,
			selectedContactPerson: selectedContactPerson
		});
		this.props.setWindowPage(false);
	}

	handleError = (reasonPhrase) => {
		toast.error(i18n.t('SOMETHING_WENT_WRONG'));
		console.log(reasonPhrase)
		this.setState({loading: false})
	}

	toggleSalesOrganisatieDD = () => {
		this.setState({
			salesOrganisatieDDIsOpen: !this.state.salesOrganisatieDDIsOpen
		});
	};

	toggleInternalSalesDD = () => {
		this.setState({
			internalSalesDDIsOpen: !this.state.internalSalesDDIsOpen
		});
	};

	togglePriceListDD = () => {
		this.setState({
			priceListDDIsOpen: !this.state.priceListDDIsOpen
		});
	};

	toggleLandListDD = () => {
		this.setState({
			landListDDIsOpen: !this.state.landListDDIsOpen
		});
	};

	toggleOwnerDD = () => {
		this.setState({
			ownerDDIsOpen: !this.state.ownerDDIsOpen
		});
	};

	handleChange(value, formattedValue) {
		this.setState({
			offerDate: value // ISO String, ex: "2016-11-19T12:00:00.000Z"
			//formattedValue: formattedValue, // Formatted String, ex: "11/19/2016"
		});
	}

	setSecondaryContactPerson = (e, s) => {
		let secondaryContactList = [...this.state.secondaryContactList];
		if(e.target.checked) {
			secondaryContactList.push(s)
		} else {
			const index = secondaryContactList.findIndex((ch) => ch.ContactId === s.Id);
			secondaryContactList.splice(index, 1);
		}
		this.setState({ secondaryContactList});
	};

	setOwner = (owner) => {
		this.setState({ selectedOwner: owner });
	};

	setInternalSales = (internalSales) => {
		this.setState({ selectedInternalSales: internalSales });
	};

	setPrice = (price) => {
		this.setState({ selectedPrice: price });
	};

	setLand = (land) => {
		this.setState({ selectedLand: land });
	};

	setOrganization = (organization) => {
		this.setState({ selectedOrganization: organization });
		this.selectDefaultPriceList(organization);
	};

	selectDefaultPriceList = (organization) => {
		for (var i = 0; i < this.state.priceList.length; i++) {
			let startDateOfPriceList = new Date(this.state.priceList[i].StartDate);
			let endDateOfPriceList = new Date(this.state.priceList[i].EndDate);
			let offerDateOfQuotation = new Date(this.state.offerDate);
			if (
				this.state.priceList[i].CountryShort == organization.CountryCode &&
				offerDateOfQuotation >= startDateOfPriceList &&
				offerDateOfQuotation <= endDateOfPriceList
			) {
				this.setPrice(this.state.priceList[i]);
				return;
			}
		}
	};	

	setValidity = (e) => {
		this.setState({ validity: e.target.value });
	};

	setName = (e) => {
		this.setState({ opportunity: e.target.value });
	};

	setTitle = (e) => {
		this.setState({ opportunityTitle: e.target.value });
	};

	setContactPerson = (contactperson, event) => {
		this.setState({ selectedContactPerson: contactperson });
	};

	onSearch = (e) => {
		const { createdQuotation } = this.context;
		let searchString = e.target.value;
		let contacts = createdQuotation.AccountInformation.Contacts;

		if (!contacts) return;
		let filteredContacts = contacts.filter(
			(x) =>
				x.Name.toLowerCase().includes(searchString.toLowerCase()) ||
				x.Email.toLowerCase().includes(searchString.toLowerCase()) ||
				x.PhoneNumber.toString().toLowerCase().includes(searchString.toLowerCase()) ||
				x.Position.toLowerCase().includes(searchString.toLowerCase())
		);
		this.setState({ contactList: filteredContacts });
	};

	setInternalSalesDropDownValues = (internalSalesList, internalSalesName) => {
		for (var i = 0; i < internalSalesList.length; i++) {
			if (internalSalesList[i].Name == internalSalesName) {
				this.setState({
					selectedInternalSales: internalSalesList[i]
				});
			}
		}
	};

	setOwnersDropDownValues = (ownerList, ownerName) => {
		for (var i = 0; i < ownerList.length; i++) {
			if (ownerList[i].Name == ownerName) {
				this.setState({
					selectedOwner: ownerList[i]
				});
			}
		}
	};

	setStreetName = (e) => {
		this.setState({ streetName: e.target.value });
	};

	setStreetNumber = (e) => {
		this.setState({ streetNumber: e.target.value });
	};

	setPostCode = (e) => {
		this.setState({ postCode: e.target.value });
	};

	setTowm = (e) => {
		this.setState({ town: e.target.value });
	};
	isPageValid = () => {
		let validations = this.state.validations;
		validations.isOfferTitleInvalid = false;
		validations.isSalesInvalid = false;
		validations.isPriceListInvalid = false;
		validations.isOrganisationInvalid = false;
		validations.isOfferDateInvalid = false;
		validations.isOwnerInvalid = false;
		validations.isValidatityInvalid = false;

		let isValid = true;
		if (!this.state.validity) {
			isValid = false;
			validations.isValidatityInvalid = true;
		}
		if (!this.state.opportunityTitle) {
			isValid = false;
			validations.isOfferTitleInvalid = true;
		}

		if (!this.state.selectedInternalSales) {
			isValid = false;
			validations.isSalesInvalid = true;
		}

		if (!this.state.selectedOrganization) {
			isValid = false;
			validations.isOrganisationInvalid = true;
		}

		if (!this.state.offerDate) {
			isValid = false;
			validations.isOfferDateInvalid = true;
		}

		if (!this.state.selectedOwner) {
			isValid = false;
			validations.isOwnerInvalid = true;
		}

		if (!this.state.selectedPrice) {
			isValid = false;
			validations.isPriceListInvalid = true;
		}
		this.setState({ validations: validations });
		return isValid;
	};

	onQuotationSave = () => {
		
		const { createdQuotation } = this.context;
		if (!this.isPageValid()) {
			document.documentElement.scrollTop = 0;
			return;
		}
		let data = {};		
		if (
			this.state.streetName === '' &&
			this.state.streetNumber === '' &&
			this.state.postCode === '' &&
			this.state.town === '' &&
			this.state.selectedLand === null
		) {
			data = {
					Title: this.state.opportunityTitle,
					Date: this.state.offerDate,
					ValidityPeriod: parseInt(this.state.validity),
					OwnerId: !this.state.selectedOwner ? 0 : this.state.selectedOwner.Id,
					InternalSalesId: !this.state.selectedInternalSales ? 0 : this.state.selectedInternalSales.Id,
					OpportunityId: createdQuotation.Number,
					AccountName : createdQuotation.AccountInformation ? createdQuotation.AccountInformation.Name : '',
					AccountNumber : createdQuotation.AccountInformation ? createdQuotation.AccountInformation.AccountNumber : '',
					ContactId: this.state.selectedContactPerson.Id,
					VersionNumber: 1,
					VersionDescription: '',
					SalesOrganization: !this.state.selectedOrganization ? '' : this.state.selectedOrganization.Name,
					SalesOrganizationId: !this.state.selectedOrganization ? '0' : this.state.selectedOrganization.Id,
					CreatedBy: this.props.accountInfo.account.accountIdentifier,									
					Status: 'New',
                    LastUpdate: new Date(),
					PriceListId: this.state.selectedPrice.Id,
					UpdatedByName : this.props.accountInfo.account.name,
					UpdatedByGuid : this.props.accountInfo.account.accountIdentifier,
					Comments : i18n.t('QUOTATION_CREATED')						
			};

		} else {
			data = {
					Title: this.state.opportunityTitle,
					Date: this.state.offerDate,
					ValidityPeriod: parseInt(this.state.validity),
					OwnerId: !this.state.selectedOwner ? 0 : this.state.selectedOwner.Id,
					InternalSalesId: !this.state.selectedInternalSales ? 0 : this.state.selectedInternalSales.Id,
					OpportunityId: createdQuotation.Number,
					AccountName : createdQuotation.AccountInformation ? createdQuotation.AccountInformation.Name : '',
					AccountNumber : createdQuotation.AccountInformation ? createdQuotation.AccountInformation.AccountNumber : '',
					ContactId: this.state.selectedContactPerson.Id,
					VersionNumber: 1,
					VersionDescription: '',
					SalesOrganization: !this.state.selectedOrganization ? '' : this.state.selectedOrganization.Name,
					SalesOrganizationId: !this.state.selectedOrganization ? '0' : this.state.selectedOrganization.Id,
					CreatedBy: this.props.accountInfo.account.accountIdentifier,
					Status: 'New',
					LastUpdate: new Date(),
					PriceListId: this.state.selectedPrice.Id,
					UpdatedByName : this.props.accountInfo.account.name,
					UpdatedByGuid : this.props.accountInfo.account.accountIdentifier,
					Comments : i18n.t('QUOTATION_CREATED'),
					QuotationDeliveryAddress: {
						StreetName: this.state.streetName ? this.state.streetName : '',
						StreetNumber: this.state.streetNumber ? this.state.streetNumber : '',
						PostalCode: this.state.postCode ? this.state.postCode : '',
						Town: this.state.town ? this.state.town : '',
						Land: this.state.selectedLand ? this.state.selectedLand.Name : ''
					}																		
				
			};
		}	
		this.setState({ loading: true });

		saveQuotation(data)
		.then((response) => {
				toast.success(<Trans i18nKey='QUOTATION_CONFIRMED'>QUOTATION_CONFIRMED</Trans>);
				const { updateselectedQuotationId, updateViewModeOnly, quotationToBeCopied } = this.context;
				updateselectedQuotationId(response.QuotationId);				
				updateViewModeOnly(false);
                this.saveSecondaryContacts();
				
				if (quotationToBeCopied) {
					this.copyQuotation(response.QuotationId, quotationToBeCopied.Id);
				} else {
					this.saveAccountDiscounts(response.QuotationId);
				}
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
				this.setState({ loading: false });
			});
	};

	saveAccountDiscounts = (quotationId) =>{
		const { createdQuotation } = this.context;
		this.setState({ loading: true });
		let data = {
			Account:createdQuotation.AccountInformation.AccountNumber,
			OpportunityNumber :createdQuotation.Number,
			QuotationId : quotationId,
			CreatedOn:this.state.offerDate
		}

		saveAccountDiscounts(data)
		.then((response) => {	
				this.setState({
					showViewQuotationPage: true,
					addedQuotationId: response.QuotationId,
					loading: false
				});		
						
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
				this.setState({ loading: false });
			});
	}

		
	saveSecondaryContacts = () => {
		const { selectedQuotationId } = this.context;
		this.setState({ loading: true });

		let data = [];
		for (let i = 0; i < this.state.secondaryContactList.length; i++) {
			data.push({
				
				QuotationId : selectedQuotationId,
				ContactId: this.state.secondaryContactList[i].Id
			});
		}

		saveSecondaryContacts(data)
		.then((response) => {			
				this.setState({ showAssignStatusModal: false});				
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
				this.setState({ loading: false });
			});
	};
	copyQuotation = (destinationQuotationId, sourceQuotationId) => {

		this.setState({ loading: true });

		copyQuotation(sourceQuotationId, destinationQuotationId)
		.then((responseData) => {
				this.setState({
					showViewQuotationPage: true,
					addedQuotationId: destinationQuotationId,
					loading: false
				});
				const { updateQuotationToBeCopied } = this.context;
				updateQuotationToBeCopied(null);
			})
			.catch(() => {
				toast.error(<Trans i18nKey='SOMETHING_WENT_WRONG'>SOMETHING_WENT_WRONG</Trans>);
				this.setState({ loading: false });
			});
	};

	render() {
		const { createdQuotation } = this.context;
		let quotation = createdQuotation;
		let orderAddress = quotation.AccountInformation.Address;
		let orderAddress1 = '',
			orderAddress2 = '',
			orderAddress3 = '';

		if (!!orderAddress) {
			orderAddress.Street = orderAddress.Street ?? '';
			orderAddress.HouseNumber = orderAddress.HouseNumber ?? '';
			orderAddress.ZipCode = orderAddress.ZipCode ?? '';
			orderAddress.City = orderAddress.City ?? '';
			orderAddress1 = orderAddress.Street + ' ' + orderAddress.HouseNumber;
			orderAddress2 = orderAddress.ZipCode + ' ' + orderAddress.City;
			orderAddress3 = orderAddress.Country;
		}
		return (
			<div className='row mt-4'>
				<div className='col-12'>
					<div className='quotation-body'>
						<div className='create-quotation-title '>
							<Trans i18nKey='ADD_QUOTATION'>ADD_QUOTATION</Trans>
						</div>
						<br />
						<div className='quotation-panel'>
							<div className='opportunity-sub-title'>
								<Trans i18nKey='OPPORTUNITY'>OPPORTUNITY</Trans>
							</div>
							<div className='row mb-3'>
								<div className='col-3'>
									<br />
									<input
										className='form-control textbox-font'
										value={this.state.opportunity}
										onChange={this.setName}
										disabled={true}
									/>
								</div>
								<div className='col-3 '>
									<div className=' bold-header-font'>
										<Trans i18nKey='QUOTATION_DATE'>QUOTATION_DATE</Trans>
									</div>
									<DatePicker
										id='example-datepicker'
										value={this.state.offerDate}
										dateFormat={'DD.MM.YYYY'}
										onChange={(v, f) => this.handleChange(v, f)}
										className={this.state.validations.isOfferDateInvalid ? ' invalid-style' : ''}
									/>
									{this.state.validations.isOfferDateInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='OFFERDATE_MANDATORY_MESSAGE'>
												OFFERDATE_MANDATORY_MESSAGE
											</Trans>
										</div>
									)}
								</div>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='VALIDITY_PERIOD'>VALIDITY_PERIOD</Trans>{' '}
										<Trans i18nKey='DAYS'>DAYS</Trans>
									</div>
									<input
										type='number'
										min='0'
										id='validity-period'
										className={
											'form-control textbox-font' +
											(this.state.validations.isValidatityInvalid ? ' invalid-style' : '')
										}
										value={this.state.validity}
										onChange={this.setValidity}
									/>
									{this.state.validations.isValidatityInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='VALIDITY_MANDATORY_MESSAGE'>
												VALIDITY_MANDATORY_MESSAGE
											</Trans>
										</div>
									)}
								</div>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='SALES_ORGANISATION'>SALES_ORGANISATION</Trans>
									</div>
									<Dropdown
										isOpen={this.state.salesOrganisatieDDIsOpen}
										toggle={this.toggleSalesOrganisatieDD}
										className={this.state.validations.isOrganisationInvalid ? ' invalid-style' : ''}
										id='sales-organisation'
									>
										<DropdownToggle caret>
											{!!this.state.selectedOrganization
												? this.state.selectedOrganization.Name
												: i18n.t('SELECT_ORGANISATIOIN')}
										</DropdownToggle>
										<DropdownMenu>
											{this.state.organizationList.map((x, i) => {
												return (
													<DropdownItem onClick={() => this.setOrganization(x)} key={i}>
														{x.Name}
													</DropdownItem>
												);
											})}
										</DropdownMenu>
									</Dropdown>
									{this.state.validations.isOrganisationInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='ORGANISATION_MANDATORY_MESSAGE'>
												ORGANISATION_MANDATORY_MESSAGE
											</Trans>
										</div>
									)}
								</div>
							</div>

							<div className='row mb-3'>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='QUOTATION_TITLE'>QUOTATION_TITLE</Trans>
									</div>
									<input
										className={
											'form-control textbox-font' +
											(this.state.validations.isOfferTitleInvalid ? ' invalid-style' : '')
										}
										value={this.state.opportunityTitle}
										id='opportunity-title'
										onChange={this.setTitle}
									/>
									{this.state.validations.isOfferTitleInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='OFFERTITLE_MANDATORY_MESSAGE'>
												OFFERTITLE_MANDATORY_MESSAGE
											</Trans>
										</div>
									)}
								</div>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='OWNER'>OWNER</Trans>
									</div>
									<Dropdown
										isOpen={this.state.ownerDDIsOpen}
										toggle={this.toggleOwnerDD}
										className={this.state.validations.isOwnerInvalid ? ' invalid-style' : ''}
									>
										<DropdownToggle caret>
											{!!this.state.selectedOwner
												? this.state.selectedOwner.Name
												: i18n.t('SELECT_OWNER')}
										</DropdownToggle>
										<DropdownMenu>
											{this.state.ownerList.map((x, i) => {
												return (
													<DropdownItem key={i} onClick={() => this.setOwner(x)}>
														{x.Name}
													</DropdownItem>
												);
											})}
										</DropdownMenu>
									</Dropdown>
									{this.state.validations.isOwnerInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='OWNER_MANDATORY_MESSAGE'>OWNER_MANDATORY_MESSAGE</Trans>
										</div>
									)}
								</div>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='INTERNAL_SALES'>INTERNAL_SALES</Trans>
									</div>
									<Dropdown
										isOpen={this.state.internalSalesDDIsOpen}
										toggle={this.toggleInternalSalesDD}
										className={this.state.validations.isSalesInvalid ? ' invalid-style' : ''}
									>
										<DropdownToggle caret>
											{!!this.state.selectedInternalSales
												? this.state.selectedInternalSales.Name
												: i18n.t('SELECT_INTERNAL_SALES')}
										</DropdownToggle>
										<DropdownMenu>
											{this.state.internalSalesList.map((x, i) => {
												return (
													<DropdownItem key={i} onClick={() => this.setInternalSales(x)}>
														{x.Name}
													</DropdownItem>
												);
											})}
										</DropdownMenu>
									</Dropdown>
									{this.state.validations.isSalesInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='INTERNALSALES_MANDATORY_MESSAGE'>
												INTERNALSALES_MANDATORY_MESSAGE
											</Trans>
										</div>
									)}
								</div>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='PRICE_LIST'>PRICE_LIST</Trans>
									</div>
									<Dropdown
										isOpen={this.state.priceListDDIsOpen}
										toggle={this.togglePriceListDD}
										className={this.state.validations.isPriceListInvalid ? ' invalid-style' : ''}
									>
										<DropdownToggle caret>
											{!!this.state.selectedPrice
												? this.state.selectedPrice.PriceListShort
												: i18n.t('SELECT_PRICELIST')}
										</DropdownToggle>
										<DropdownMenu>
											{this.state.priceList.map((x, i) => {
												return (
													<DropdownItem key={i} onClick={() => this.setPrice(x)}>
														{x.PriceListShort}
													</DropdownItem>
												);
											})}
										</DropdownMenu>
									</Dropdown>
									{this.state.validations.isPriceListInvalid && (
										<div className='validation-message'>
											<Trans i18nKey='PRICELIST_MANDATORY_MESSAGE'>
												PRICELIST_MANDATORY_MESSAGE
											</Trans>
										</div>
									)}
								</div>
							</div>
							<div className='row mb-3'>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='ACCOUNT_NAME'>ACCOUNT_NAME</Trans>
									</div>
									<div className='textbox-font'>{quotation.AccountInformation.Name}</div>
								</div>
								<div className='col-3'>
									<div className=' bold-header-font'>
										<Trans i18nKey='ACCOUNT_NUMBER'>ACCOUNT_NUMBER</Trans>
									</div>
									<div className='textbox-font'>{quotation.AccountInformation.AccountNumber}</div>
								</div>
							</div>
						</div>
						<br />
						<div className='quotation-panel-white'>
							<div className='opportunity-sub-title'>
								<Trans i18nKey='SELECT_PROJECT_ADDRESS_OPTIONAL'>SELECT_PROJECT_ADDRESS_OPTIONAL</Trans>
							</div>
							<br />
							<table className='table background-quotation'>
								<tbody>
									<tr className='row bold-header-font bold-header-font-margin '>
										<td className='col-4'>
											<Trans i18nKey='STREETNAME'>STREETNAME</Trans>
										</td>
										<td className='col-4'>
											<Trans i18nKey='POSTALCODE'>POSTALCODE</Trans>
										</td>
										<td className='col-4'>
											<Trans i18nKey='COUNTRY'>COUNTRY</Trans>
										</td>
									</tr>
									<tr className='row bold-header-font-margin'>
										<td className='col-4'>
											<span className={'project-address-input-section padding-top-10'}>
												<input
													type='text'
													className='form-control'
													style={{ width: '70%' }}
													placeholder={i18n.t('STREET_NAME')}
													onChange={this.setStreetName}
												/>
												<input
													type='text'
													className='form-control'
													style={{ width: '30%', marginLeft: '10px' }}
													placeholder={i18n.t('NUMBER')}
													onChange={this.setStreetNumber}
												/>
											</span>
										</td>
										<td className='col-4'>
											<span className={'project-address-input-section padding-top-10'}>
												<input
													type='text'
													className='form-control'
													style={{ width: '70%' }}
													placeholder={i18n.t('POSTCODE')}
													onChange={this.setPostCode}
												/>
												<input
													type='text'
													className='form-control'
													style={{ width: '30%', marginLeft: '10px' }}
													placeholder={i18n.t('TOWN')}
													onChange={this.setTowm}
												/>
											</span>
										</td>
										<td className='col-4'>
											<div className='padding-top-10'>
												<Dropdown
													isOpen={this.state.landListDDIsOpen}
													toggle={this.toggleLandListDD}
												>
													<DropdownToggle caret>
														{!!this.state.selectedLand
															? this.state.selectedLand.Name
															: i18n.t('SELECT_COUNTRY')}
													</DropdownToggle>
													<DropdownMenu>
														{this.state.landList.map((x, i) => {
															return (
																<DropdownItem onClick={() => this.setLand(x)} key={i}>
																	{x.Name}
																</DropdownItem>
															);
														})}
													</DropdownMenu>
												</Dropdown>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<br />
						<div className='quotation-panel'>
							<div className='opportunity-sub-title'>
								<Trans i18nKey='SELECT_CONTACTPERSON'>SELECT_CONTACTPERSON</Trans>
							</div>
							<br />
							<input
								type='text'
								className='form-control rbt-input'
								onKeyUp={(e) => this.onSearch(e)}
								placeholder={i18n.t('SEARCH_CONTACT_PERSON')}
								style={{ width: '30%' }}
							/>
							<br />
							<div className='div-background'>
								<table className='table contact-person-table '>
									<thead>
										<tr className='bold-header-font row'>
											<th scope='col' className='col-1'></th>
											<th scope='col' className='col-2'>
												<Trans i18nKey='CONTACT_PERSON_NAME'>CONTACT_PERSON_NAME</Trans>
											</th>
											<th scope='col' className='col-2'>
												<Trans i18nKey='SECONDARY_CONTACT'>SECONDARY_CONTACT</Trans>
											</th>
											<th scope='col' className='col-2'>
												<Trans i18nKey='EMAIL_ADDRESS'>EMAIL_ADDRESS</Trans>
											</th>
											<th scope='col' className='col-2'>
												<Trans i18nKey='TELEPHONE'>TELEPHONE</Trans>
											</th>
											<th scope='col' className='col-1'>
												<Trans i18nKey='FUNCTION'>FUNCTION</Trans>
											</th>
										</tr>
									</thead>
									<tbody className='table-parent tbody-parent'>
										{!!this.state.contactList &&
											this.state.contactList.map((x, i) => {
												return (
													<tr
														key={i}
														className={
															'row table-content' +
															((this.state.selectedContactPerson &&
															this.state.selectedContactPerson.Id == x.Id) && (x.Email != '')
																? ' selected-row'
																: '')
														}
													>
														<td className='col-1'>
															<input
																type='radio'
																name='contactpersonRadioGroup'
																onClick={(e) => this.setContactPerson(x, e)}
																checked={
																	this.state.selectedContactPerson &&
																	this.state.selectedContactPerson.Id == x.Id
																}
																disabled={!x.Email}
															/>
														</td>
														
														<td className='font-table col-2'>{x.Name}</td>														
														<td className='font-table col-2'>
														{this.state.selectedContactPerson &&
															this.state.selectedContactPerson.Id != x.Id &&
															(																	
																<input
																		type='checkbox'
																		name='secondaryCOntact'
																		checked= {this.state.checked}
																		disabled={!x.Email}
																		onChange={(e) => this.setSecondaryContactPerson(e, x)}																														
																/>
															)
													    }
										             	</td>																								
														<td className='font-table col-2'>{x.Email}</td>
														<td className='font-table col-2'>{x.PhoneNumber}</td>
														<td className='font-table col-2'>{x.Position}</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
							{this.state.showViewQuotationPage && (
								<Redirect
									to={{
										pathname: '/quotation/view'
									}}
								/>
							)}

							<div className='quotation-button'>
								<button
									type='button'
									className={
										'btn ' +
										((this.state.selectedContactPerson == null ? !this.state.selectedContactPerson : this.state.selectedContactPerson.Email == '' ) 
											? 'quotation-button-disabled'
											: 'quotation-button-enabled')
									}
									disabled={!this.state.selectedContactPerson || this.state.selectedContactPerson.Email == ''}
									onClick={this.onQuotationSave}
								>
									<Trans i18nKey='CONFIRM_INFORMATION'>CONFIRM_INFORMATION</Trans>
								</button>
							</div>
						</div>
					</div>
				</div>
				{this.state.loading && (
					<div className='fixed-bottom'>
						<Loader type='Bars' color='#ef2312' height={100} width={100} />
					</div>
				)}
				<div>
					<ToastContainer
						position='top-center'
						autoClose={5000}
						className='toast'
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
				</div>
			</div>
		);
	}	
}
Quotation.contextType = QuotationToolContext;
